<template>
  <div :class='whiteBg ? " footerDown whiteBg" : "footerDown"'>
    <div class="center">
      <div class="cRights">
          <p> &copy; 2022 Carvisor. All rights reserved</p>
      </div>
      <div class="flex">
        <div class="links">
          <router-link id="link" to="/about">About </router-link>
          <router-link id="link" to="/terms">Terms &amp; Privacy </router-link>
          <router-link id="link" to="/help">Help &amp; Feedback </router-link>
        </div>
        <div class="iconFooter">
          <div class="spanImg">
            <a class="aLink" :href="getSocials.linkedin" target="_blank">
              <img :src="require('../../assets/images/linkedin.svg')" alt="/">
            </a>
          </div>
          <div class="spanImg">
            <a class="aLink" :href="getSocials.facebook" target="_blank">
              <img :src="require('../../assets/images/fb.svg')" alt="/">
            </a>
          </div>
          <div class="spanImg">
            <a class="aLink" :href="getSocials.instagram" target="_blank">
              <img :src="require('../../assets/images/instagram.svg')" alt="/">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'footerDown',
    computed: {
      ...mapGetters(['getSocials'])
    },
    data () {
        return {
            whiteBg: false
        }
    },
    watch:{
        '$route':{
            deep: true,
            immediate: true,
            handler: function(){

                if (this.$route.name === 'priceSteps') {
                    this.whiteBg = true
                }
                else {
                   this.whiteBg = false
                }
            }
        }


    }
}
</script>

<style lang="scss" scoped>
.footerDown{
    box-sizing: border-box;
    background: #F8F8F9; //#F8F8F9
    width:100%;
    height:70px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #062931;
    width:100%;
    margin: 0 auto;
    // padding: 0 30px;
    .center{
         box-sizing: border-box;
        width:100%;
        height:70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #062931;
        max-width: 1275px;
        margin: 0 auto;
        padding: 0 30px;
    }
    .cRights{
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 24px;
    }
    .links{
        display: flex;
        justify-content: flex-start;
    }
    .iconFooter{
        display: flex;
        align-items: center;
        padding:0;
        padding-top:3px;
        margin:0;
        .spanImg{
            padding-left:35px;
            //height:16px;
            .aLink{
                box-sizing: border-box;
                display: inline-block;
                img {
                    //margin:1px auto;
                    height:16px;
                }
                img:hover{
                    cursor: pointer;
                }
            }
        }
    }
}
.flex{
    width:60%;
    display:flex;
    justify-content: space-between;
 }
 .whiteBg{
     background: #ffffff !important;
 }
 @media only screen and (max-width: 900px){
     .footerDown{
        //  flex-direction: column;
        //  align-items: flex-start;
            height: 120px;
          //  padding:22px 35px;
          .center{
            box-sizing:border-box;
            justify-content:space-between;
            align-items: center;
            height: 120px;
            padding:22px 15px;
            padding: 0 10px;
        }
        .flex{
           width:initial;
           justify-content: space-between;
           .links{
               padding-left:20px;
           }

         }
     }
     .whiteBg{
     background: #ffffff !important;
 }
}
//  @media only screen and (max-width: 900px){
//      .footerDown{
//         //  flex-direction: column;
//         //  align-items: flex-start;
//             height: 120px;
//           //  padding:22px 35px;
//           .center{
//             box-sizing:border-box;
//            // flex-direction: column;
//             justify-content: space-evenly;
//             align-items: flex-start;
//             height: 120px;
//             padding:22px 35px;
//             padding: 0 10px;
//         }
//         .flex{
//             width:100%;

//          }
//      }
// }
 @media only screen and (max-width: 767px){
     .footerDown{
         height: initial !important;
        .center{
            flex-direction: column-reverse;
            align-items: center;
            justify-content: center;
            padding:22px 35px;
            height:initial;
         }
        .flex{
             width:100%;
             flex-direction: column;
             align-items: center;
             .links{
                flex-direction: column;
                align-items: center;
                padding-left:0px !important;
           

                #link{
                    margin:15px;
                }

             }
             .iconFooter{
                    margin: 20px !important;
                    justify-content: space-between;
                    .spanImg{
                        padding-left:0 !important;
                        padding-right: 35px;
                    }
                    .spanImg:nth-of-type(3){
                        padding-right:0px !important;
                    }
             }

         }
     }
     .whiteBg{
             background: #F8F8F9 !important;
      }
 }

</style>
<style lang="scss">
 .links{
        #link{
            text-decoration: unset !important;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 26px;
            color: #4C4C52;
            margin-left:30px;
            white-space: nowrap;

        }
        #link:hover{
            // color: #132727;
            color: #062931;
            cursor: pointer;
        }
 }
 @media only screen and (max-width: 1100px){
     .links{
         #link{
              margin-left:0;
              margin-right: 30px;
         }
     }
 }

</style>
