<template>
  <div class="wrappHelp">
    <div v-if="!successMsg" class="help-wrapper">
      <div class="content">
        <h1>Help & Feedback</h1>
        <div class="sub-title">To offer you a full-service Carvisor is at your disposal for all further questions and feedback.
Do not hesitate to reach out to us by sending us an email to contact@carvisor.co.nz – or use
the form below.</div>
        <div class="inputs-wrapper">
          <div class="input-wrapper">
            <span class="input-title">What is your name?</span>
            <input @input="errors.name = false, clearMsgs()" :class="{ 'red' : errors.name }" maxlength="255" placeholder="Cameron" type="text" v-model="name">
          </div>
          <div class="input-wrapper">
            <span class="input-title">What is your surname?</span>
            <input @input="errors.surname = false, clearMsgs()" :class="{ 'red' : errors.surname }" maxlength="255" placeholder="Brown" type="text" v-model="surname">
          </div>
        </div>
        <div class="inputs-wrapper">
          <div class="input-wrapper">
            <span class="input-title">Please leave your e-mail</span>
            <input @input="errors.email = false, clearMsgs()" :class="{ 'red' : errors.email }" maxlength="255" placeholder="example@gmail.com" type="text" v-model="email">
          </div>
          <div class="input-wrapper">
            <span class="input-title">What would you like to contact us about</span>
            <input @input="errors.subject = false, clearMsgs()" :class="{ 'red' : errors.subject }" maxlength="255" placeholder="Subject" type="text" v-model="subject">
          </div>
        </div>
        <div class="inputs-wrapper">
          <div style="width: 100%;" class="input-wrapper">
            <span class="input-title">What do you have on your mind?</span>
            <textarea @input="errors.message = false, clearMsgs()" :class="{ 'red' : errors.message }" maxlength="65000" placeholder="Your messege" v-model="message"></textarea>
          </div>
        </div>
        <div @click="send" class="btn round">
          Send
        </div>
        <!-- <span v-if="errorMsg">An error has occurred</span>
        <span v-if="successMsg">Thank you for the feedback</span> -->
      </div>
    </div>
    <div v-else class="success-wrapper">
      <div class="success big">
        Thank you for reaching out!<br> We will get back to you shortly.
      </div>
      <div class="success small">
        Thank you for reaching<br>out! We will get back to<br>you shortly.
      </div>
    </div>
    <footerDown v-if="!showMobileNav" :white="true" />
    <div v-else style="height:60px" ></div>


  </div>
</template>

<script>
import { api } from '@/api'
import footerDown from '../components/newsroom/footerDown'
export default {
  name: 'help',
  components: {
    footerDown
  },
   metaInfo () {
        return {
        title: 'Carvisor',
        titleTemplate: '%s - ' + this.title ,
        meta:[
                {
                    vmid: 'description',
                    name: 'description',
                    // property: 'description',
                    content: this.description
                },
                {
                    vmid: 'og:description',
                    // name: 'og:description',
                    property: 'og:description',
                    content: this.description
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: 'Carvisor - ' + this.title 
                },
                // {
                //     hid: 'keywords',
                //     name: 'keywords',
                //     content:  this.tempData.title
                // },
                // {
                //     vmid: 'og:image',
                //     property: 'og:image',
                //     itemprop: 'image',
                //     content: this.baseURLImg
                // }
            ]
        }
    },
  data () {
    return {
      title:'Help & Feedback',
      description: 'To offer you a full-service Carvisor is at your disposal for all further questions and feedback. Do not hesitate to reach out to us by sending us an email to contact@carvisor.co.nz – or use the form below.',
      name: null,
      surname: null,
      email: null,
      subject: null,
      message: null,
      errorMsg: false,
      successMsg: false,
      errors: {
        name: false,
        surname: false,
        email: false,
        subject: false,
        message: false
      },
      showMobileNav:false
    }
  },
  created () {
      window.addEventListener("resize", this.resize)
      this.resize()
  },
  destroyed () {
      window.removeEventListener("resize", this.resize)
  },
  mounted() {
     this.$analytics.fbq.event('PageView', {
        content_name: 'Help page'
      })
  },
  methods: {
    send () {
      let params = {
        name: this.name,
        lastname: this.surname,
        email: this.email,
        subject: this.subject,
        comment: this.message
      }
      if (!params.name) {
        this.errors.name = true
      }
      if (!params.lastname) {
        this.errors.surname = true
      }
      if (!params.email) {
        this.errors.email = true
      }
      if (!params.subject) {
        this.errors.subject = true
      }
      if (!params.comment) {
        this.errors.message = true
      }
      if (params.name && params.lastname && params.email && params.subject && params.comment) {
        api.sendFeedback(params).then(() => {
          this.setSuccess()
        }).catch(e => {
          if (e.response.data.message === 'bad-email') {
            this.errors.email = true
          }
          this.errorMsg = true
        })
      }
    },
    setSuccess () {
      console.log('set success');
      this.name = null
      this.surname = null
      this.email = null
      this.subject = null
      this.message = null
      this.errorMsg = false
      this.successMsg = true
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    clearMsgs () {
      this.errorMsg = false
      this.successMsg = false
    },
    resize () {
        if (window.innerWidth <= 500) {
        this.showMobileNav = true
        } else {
        this.showMobileNav = false
        }
      }
    }
}
</script>

<style lang="scss">
.success-wrapper {
  min-height: calc(100vh - 150px);
  display: flex;
  align-items: center;
  justify-content: center;

  .success {
    font-size: 32px;
    line-height: 48px;
    color: #082633;
    text-align: center;
    // font-family: 'Gotham Pro Medium';
    margin-bottom: 10%;
    padding: 0 20px;
  }
  .success.small {
    display: none;
  }
}
.wrappHelp {
  min-height: calc(100vh - 150px);
}
.help-wrapper {
  max-width: 1275px;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 30px;
  display: flex;
  min-height: calc(100vh - 150px);

  .content {
    max-width: 842px;
    width: 100%;

    h1 {
      // margin-top: 80px;
      margin-top: 98px;
      // font-size: 32px;
      // line-height: 50px;
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      // color: #082633;
      color: #062931;
      // margin-bottom: 20px;
      margin-bottom: 34px;
    }
    .sub-title {
      // font-size: 16px;
      // line-height: 24px;
      // color: #082633;
      font-weight: normal;
      font-size: 17px;
      line-height: 25px;
      /* or 147% */


      /* black */

      color: #062931;
      // max-width: 624px;
      margin-bottom: 45px;
      margin-bottom: 37px;
    }
    .inputs-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      position: relative;

      .input-wrapper {
        width: auto;
        display: flex;
        flex-direction: column;
        box-sizing: border-box;
        margin-bottom: 20px;
        margin-bottom: 22px;

        .input-title {
          // font-size: 16px;
          // line-height: 30px;
          // color: #082633;
          // font-family: 'Gotham Pro Medium';
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;

          // display: flex;
          // align-items: center;
          color: #062931;
          padding-bottom: 3px;
        }
        input, textarea {
           font-family: 'DM sans' !important;
          width: 406px;
          height: 50px;
          box-sizing: border-box;
          margin: 0;
          border: 1px solid #C3C6D0;
          border-radius: 5px;
          padding-left: 10px;
          // font-size: 16px;
          // line-height: 24px;
          outline: none;
          // color: #132727;
          // font-family: 'Gotham Pro';
          // padding-top: 2px;
          padding-top: 1px;
          font-style: normal;
          font-weight: normal;
          font-size: 15px;
          line-height: 20px;
          /* or 133% */

          display: flex;
          align-items: center;
          letter-spacing: 0.01em;
          // word-spacing:0.1em;

          /* black */

          color: #062931;
        }
        input::placeholder, textarea::placeholder {
          // opacity: 1;
          // color: #929498;
          font-family: 'DM sans' !important;
          font-size: 15px;
          line-height: 20px;
          /* or 133% */

          // display: flex;
          // align-items: center;
          // letter-spacing: 0.1em;

          /* Gray 3 text */

          color: #9498A0;
        }
        textarea {
          height: 160px;
          width: 100%;
          // padding-top: 12px;
          // padding-top: 15px;
          padding-top: 13px;
        }
        input.red, textarea.red {
          // border: 1px solid $main-color;
           border: 1px solid $err-color;
        }
      }
    }
    .btn.round {
      background-color: $main-color;
      height: 50px;
      width: 140px;
      color: white;
      border-radius: 26px;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      /* or 133% */

      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      letter-spacing: 0.01em;

      /* White */

      color: #FFFFFF;
      margin-top: 18px;
      margin-bottom: 30px;
      margin-bottom: 196px;
      cursor: pointer;
      transition-duration: .3s;
    }
    .btn.round:hover {
      background-color: #FF4B49;
    }
  }
}
@media only screen and (max-width: 900px){
  .wrappHelp{
    .inputs-wrapper {
      .input-wrapper {


        input{
            width: 342px!important;
            height: 50px;
        }
      }
    }
  }
}
@media only screen and (max-width: 767px){
  .wrappHelp{
    .success.small {
      display: block !important;
    }
    .success.big {
      display: none;
    }
    .content {
      max-width: 842px;
      width: 100%;

      h1 {
        font-weight: bold;
        font-size: 34px;
        line-height: 40px;
        margin-top: 73px !important;
      
        margin-bottom: 34px;
      }
      .sub-title {
        // font-size: 16px;
        // line-height: 24px;
        // color: #082633;
        margin-bottom: 37px;
        margin-bottom: 35px;
      }
    }
    .inputs-wrapper {
      .input-title {
        //styleName: Mobile/S1_mobile;
        font-size: 16px !important;
        line-height: 25px !important;
        letter-spacing: 0em !important;
      }
      .input-wrapper {
        width: 100% !important;

        input {
          width: 100% !important;
        }
      }
    }
    .btn.round {
        margin-bottom: 41px !important;
    }
    .success-wrapper {
      align-items: flex-start;
      justify-content: flex-start;
      padding-left: 10px;
      padding-top: 45px;
      min-height: calc(100vh - 443px);

      .success {
        text-align: left;
        font-size: 26px;
        line-height: 34px;
      }
    }
  }
}
</style>
