<template>
  <div class="searchBlog">
    <div class="wrapSearch">
      <input type="text" v-model="search" placeholder="Search news by keyword" @keyup="keyUp" id="blog-search">
      <img v-if="!loopHover" class="imgSearch" src="../../assets/images/loop.svg" @click="toggleFocus" @mouseover="loopHover=true" @mouseleave="loopHover=false">
      <img v-else class="imgSearch" src="../../assets/images/loopHover.svg" @click="toggleFocus" @mouseover="loopHover=true" @mouseleave="loopHover=false">
    </div>
    <div class="wrapCategory">
      <checkboxBlog v-if="categoriseApi" @checkcate="checkcate" :categorise="categoriseApi"/>
      
    </div>    
  </div>
</template>

<script>
import checkboxBlog from './checkboxBlog.vue'
import { api } from '@/api'
export default {
  name: 'searchBlog',
  components: {
    checkboxBlog
  },
  data (){
    return{
      search: '',
      categoriseApi: null,
      cat_Check: 'all',
      loopHover: false,
      date:0,
      isActive: false,
    }
  },
  created(){
    this.setCategory()
  },
  methods:{
    checkcate(e){
      console.log('checkcate')
      console.log(e)
      this.cat_Check = e
      this.$emit('search',  [this.search, e])
  },
  keyUp(){
    // console.log(e)
    // console.log(Date.now())
    this.date=Date.now()

    setTimeout(() => {
      if(this.date +1000 <= Date.now()){
      console.log('true - ' + this.search)
      this.$emit('search', [ this.search, this.cat_Check ])
      }else{
         console.log('ne - ' + this.search)
      }
      console.log(this.date +' '+Date.now())
      
    }, 1000);
  },
   async setCategory(){
     await api.getNewsCategories().then(res => {
        console.log(res.data.data)
        this.categoriseApi = res.data.data
      }).catch(e => {
        console.log(e)
      })
   },
   toggleFocus () {
     let el = document.getElementById("blog-search")
     if (!this.isActive) {
       el.focus()
       this.isActive = true
     } else {
       this.isActive = false
     }
   },
  },
  watch:{
    search:{
      handler(newVal){
       console.log(newVal)
       
      //  this.$emit('search', [ newVal, this.cat_Check ])
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.searchBlog{
  width:100%;
  min-height: 100px;
  height:fit-content;
  
  border-radius:5px;
  border: 1px solid #DEE0E5;
  .wrapSearch{
    box-sizing: border-box;
    height: 50px;
    width: 100%;
    position: relative;

    input{
      box-sizing: border-box;
      width:100%;
      height: 100%;
      background: #F8F8F9;
      border: 1px solid transparent;
      border-bottom: 1px solid #DEE0E5;
      border-radius: 5px 5px 0 0;
      outline: none;
      padding-left: 21px;
       font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: #9498A0;
    }
    input::placeholder{
      // font-style: normal;
      // font-weight: normal;
      // font-size: 15px;
      // line-height: 20px;
      // /* identical to box height, or 133% */
      // letter-spacing: 0.01em;

      // /* Gray 3 text */

      // color: #9498A0;


      font-family: DM Sans;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 20px;
      display: flex;
      align-items: center;
      letter-spacing: 0.01em;
      color: #9498A0;


    }

    .imgSearch{
      position: absolute;
      top: 15px;
      right: 12px;
      cursor: pointer;
    }
 

  }
  .wrapCategory{
    min-height: 50px;
    padding-left: 21px;
    display: flex;
    align-items: center;
    
  }
}

</style>