<template>
<div class="ShareIcon">
        <ShareNetwork
             v-for="(obj , index) in list"
            :key="index"
            :url=" obj.network==='email'? 'Check this at: ' + url1 + currentUrl : url1 + currentUrl"
            :title='titleP ? titleP : title'
            :description="descriptionP ? '\n '+ descriptionP +' \n \n'+ url + ' \n \n' : '\n '+description +' \n \n'+ url + ' \n \n'"
            :quote="url"
            :hashtags="isblog ? hashtagsblog : hashtags"
            :network="obj.network"
            class="spanImg"    
        ><a class="aLink" href="#" >
           <img  :src="obj.img" alt="/">
          </a>
        </ShareNetwork>   
        <div class="spanImg lastImg" @click="showModalEmit">
            <a class="aDot" href="#" >
                <img :src="require('../../assets/images/dotBlack.svg')" alt="/">
            </a>
        </div>
        <modalShare 
             v-if="showmodal"
             @closeshowmodal="showModalEmit" 
             :author="author" 
             :descriptionP="descriptionP"
             :titleP="titleP"
             :isblog="isblog"
          />
      </div>
  
</template>

<script>
import facebookImg from '@/assets/images/fb.svg'
import linkedinImg from '@/assets/images/linkedin.svg'
import twitterImg from '@/assets/images/tweetgray.svg'
import modalShare from './modalShare.vue'
export default {
    props: ['titleP', 'descriptionP', 'author', 'isblog'],
    components: {
        modalShare
    },
    data () {
        return {
                title: 'Carvisor Price Evaluation for Year Make Model',
                description: "Our unique car valuation engine constantly analyzes the market, providing you with reliable price estimates and analytics, thereby creating a more efficient car-buying experience.",
                url: "https://carvisor.co.nz/",
                url1: "https://carvisor.co.nz",
                hashtags: "carnews,cars,carsnz,carblogs,carlifestyle,newzealandcar,carlovers,carvaluation,carvisor",
                hashtagsblog: "carblog,cars,carsnz,carblogs,carlifestyle,newzealandcar,carlovers,carvaluation,carvisor",
          
             list: [
                {network: 'facebook', img: facebookImg, txt: 'Facebook'},
                {network: 'linkedIn', img: linkedinImg, txt: 'linkedin'},
                {network: 'twitter', img: twitterImg, txt: 'Twitter'}
            ],
            showmodal:false
        }
    },
    created (){
       this.currentUrl = this.$route.path
       this.setUrl()
      },
      methods: {
        setShareUrl( slash) {
            // if(slash)
            //     return this.baseURL.slice(0,this.baseURL.length-5)
            // else 
            //      return this.baseURL.slice(0,this.baseURL.length-4)
            if(slash)
                return this.baseURL //.concat('/')
            else 
                 return this.baseURL
        },
        setUrl() {
            //  this.url= 'https:'+this.setShareUrl(false) // "https://carvisor.co.nz/"
            //  this.url1= 'https:'+this.setShareUrl(true) //"https://carvisor.co.nz"
            this.url= this.setShareUrl(false) // "https://carvisor.co.nz/"
            this.url1= this.setShareUrl(true) //"https://carvisor.co.nz"

        },
        showModalEmit() {
            this.showmodal= !this.showmodal
             this.$emit('showmodal', this.showmodal)
        }
    },
       computed: {
        baseURL () {
            if (process.env.NODE_ENV !== 'production') {
               return 'https://028x122.mars-t.mars-hosting.com'
            }
            return window.location.protocol + '//' + window.location.host
            }
        }

}
</script>

<style lang="scss" scoped>
     .ShareIcon{
                   display: flex;
                   height: fit-content;
                    .spanImg{
                        justify-content: center;
                        align-items: center;
                        margin-right: 20px;
                        .aLink {
                            img {
                                height: 12px;
                            }
                        }
                    }
                    .lastImg{
                         display: flex;
                         justify-content: center;
                         align-items: center;
                         cursor: pointer;
                        .aDot {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding-bottom: 3px;
                            img {
                               display:block 
                            }
                        }
                    }
                }
</style>