<template>
  <div class="unsubscribe-wrapper">
    <div v-if="showMsg" class="msg">
      <div class="text">
        You have successfuly unsubscribed
      </div>
    </div>
    <div v-else class="msg">
      <div class="text">
        Unsubscribe fail
      </div>
    </div>
    <footerDown class="footerAbso" v-if="!showMobileNav" />
    <div v-else style="height:60px" ></div>
  </div>
</template>

<script>
import footerDown from '../components/footer/footerDown'
import { api } from '@/api'
export default {
  data () {
    return {
      email: null,
      token: null,
      showMsg: false,
      showMobileNav:false
    }
  },
  components: {
    footerDown
  },
  created () {
      window.addEventListener("resize", this.resize)
      this.resize()
  },
  destroyed () {
      window.removeEventListener("resize", this.resize)
  },
  mounted () {
    console.log(this.$route.params);
    this.email = this.$route.params.email
    this.token = this.$route.params.token
    if (this.email && this.token) {
      let params = {
        email: this.email,
        token: this.token
      }
      api.unsubscribe(params).then(res => {
        console.log(res)
        this.showMsg = true
         this.$analytics.fbq.event('trackCustom', { 
            unsubscribe: true,
            email:this.email
          })

      }).catch(e => {
        console.log(e);
        this.$router.push('/')
      })
    } else {
      this.$router.push('/')
    }
  },
  methods: {
    resize () {
      if (window.innerWidth <= 500) {
         this.showMobileNav = true
      } else {
         this.showMobileNav = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.unsubscribe-wrapper {
  height: calc(100vh - 80px);
  display: flex;
  flex-direction: column;

  .msg {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;

    .text {
      padding: 20px;
      border-radius: 5px;
      // border: 2px solid $main-color;
      border: 2px solid $err-color;
      padding-top: 22px;
      font-family: 'Gotham Pro Medium';
      color: $main-color;
      margin-top: -20%;
    }
  }
  .footerAbso {
    margin-top: auto;
  }
}
</style>
