<template>
  <div class="delete-wrapper">
    <div class="tabs">
      <span :class="{ active : active === 'deleteBlog' }" @click="active = 'deleteBlog'">Blog</span>
      <span :class="{ active : active === 'deleteNews' }" @click="active = 'deleteNews'">News</span>
    </div>
    <keep-alive>
      <component :is="getActiveComponent" />
    </keep-alive>
  </div>
</template>

<script>
import deleteBlog from '@/components/admin/deleteBlog.vue'
import deleteNews from '@/components/admin/deleteNews.vue'
export default {
  name: 'Delete',
  components: {
    deleteBlog,
    deleteNews
  },
  data () {
    return {
      active: 'deleteBlog'
    }
  },
  computed: {
    getActiveComponent () {
      return this.active
    }
  }
}
</script>

<style lang="scss" scoped>
.delete-wrapper {
  .tabs {
    margin-bottom: 20px;
    span {
      margin-right: 20px;
      cursor: pointer;
    }
    span:last-child {
      margin-right: 0px;
    }
    span.active {
      color: $main-color;
    }
  }
}
</style>
