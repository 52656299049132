<template>
    <div class="wrappLoginModal">
         <div class="exit" @click="$emit('closeModal')"  @mouseenter="hover=true" @mouseleave="hover=false" >
            <img v-if="!hover" :src="require('@/assets/images/x.svg')">
            <img v-else :src="require('@/assets/images/xHover.svg')">
          </div>
        <div class="loginComponent">
            <div class="loginTitle"> Ready to see<br>the valuation?</div>
            <div class="loginText">We have noticed that you have used our valuation tool multiple times. Glad you like it! Please log in to continue to use our service for free.</div>
           
            <div v-if="false" v-facebook-login-button="appId" class="buttonSocial">
                <span>Continue with Facebook</span>
                <span ><img 
                    :src="require(widthPage?'@/assets/images/fsingin.svg':'@/assets/images/fsinginBright.svg')" />
                </span>
            </div>
            
            <div v-google-signin-button="clientId"  class="buttonSocial">
                <span>Continue with Google</span>
                <span>
                    <img :src="require('@/assets/images/gsinginBright.svg')" />
                    <!-- <img :src="require(widthPage? '@/assets/images/gsingin.svg':'@/assets/images/gsinginBright.svg')" /> -->
                </span>
            </div>

            <div class="or">
                <div class="line"></div>
                or
                <div class="line"></div>
            </div>
            <div class="round">
                 <input v-model="email" :class="emailErr? 'basic inputErr ': 'basic'" @keyup="emailErr=false" placeholder="Enter e-mail" type="text"  v-hideMoblieNav autofocus />
                 <img v-if="emailErr" src="../../assets/images/errimg.svg" />
            </div>
            <div class="round">
                <input v-model="password" :class="passwordErr? 'basic last inputErr ': 'basic last'" @keyup="passwordErr=false"  placeholder="Password" type="password" v-hideMoblieNav  />
                <img v-if="passwordErr" src="../../assets/images/errimg.svg" />
            </div>
            <callForgotpass />
           
            <p v-if="msg" class="errMsg"><img src="../../assets/images/errImgMsg.svg" />{{msg}}</p>
             <rember :remember="remember" @rememberSend="remember=!remember" />
            <div class="buttonSend" @click="checkForm">See the report</div>
            <div class="haveaccount">Don’t have an account yet? <span class="orange" @click="$emit('setlogin')">Sign up</span></div>
            
        </div>
    </div>
</template>

<script>
import helper from '@/js/helperMail'
import rember from './rememberMe.vue'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import FacebookLoginButton from 'vue-facebook-login-button-directive'
import callForgotpass from './callForgotpass.vue'
import { api } from '@/api'
export default {
    name: 'loginComponent',
    directives: {
        GoogleSignInButton,
        FacebookLoginButton
    },
    components: {
        rember,
        callForgotpass
    },
    data () {
        return {
            email: '',
            password: '',
            remember: true,
            emailErr: false,
            passwordErr: false,
            msg: '',
            widthPage: null,
            // id app google
             clientId: '306827492643-at00dqjbklkflbi52fnd1ckrkthpahjj.apps.googleusercontent.com',
            // fb
            appId: 3821175311320178,
            hover:false
        }
    },
    created(){
        window.addEventListener("resize", this.resize)
        this.resize()
    },
    methods: {
        // FACEBOOK
        OnFacebookAuthSuccess(idToken) {
              const params={
                token:idToken
            }
            api.loginFacebook(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                // this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
                this.msg="Something went wrong, please try again."
                console.log(e.message);
            })
        },
        OnFacebookAuthFail(error) {
            console.log(error)
            this.msg="Something went wrong, please try again."

        },
        // end facebook
        // GOOGLE
        OnGoogleAuthSuccess (idToken) {
            const params={
                token:idToken
            }
            api.loginGoogle(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                // this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
                this.msg="Something went wrong, please try again."
                console.log(e.message);
            })
            
        },
        OnGoogleAuthFail (error) {
            if(error && error.error == 'popup_closed_by_user')
                this.msg= 'Popup closed by user'
            else
                this.msg="Something went wrong, please try again."
        },

        // end google


         checkForm(){

            let error = false
            this.clearErr()

            if(this.email === '' || this.email.trim().length == 0 || !helper.matchemail(this.email)){
                error= true
                this.emailErr = true
                this.msg = "You must enter a valid email."
            }
            if(this.password === '' || this.password.length <= 6 ){
                error= true
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "Your password is incorrect.": "All fields must be entered correctly"
            }
            if(error) {
                return
            }else {
                let data = {
                    email:this.email,
                    password: this.password
                }
                // this.$emit('login', data)
                this.loginuser(data)
                this.clearData()
            }

        },
         loginuser(e) {
            api.logInUser(e).then(res => {
                console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                // this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
             this.msg='Username or password incorrect'
             console.log(e.message);
            })

        },
        getUserData(sid) {
            let params = {
                sid : sid
            }
            api.getUserData(params).then(res => {
                console.log('res')
                console.log(res.data.data)
                this.$store.commit('setuserData', res.data.data)
                // this.$emit('closeModal')
            }).catch(e => {
             console.log(e)
            })
        },
        clearErr () {
            this.emailErr = false
            this.passwordErr = false
            this.msg = ''
        },
        clearData () {
            this.email = ''
            this.password = ''
        },
        resize () {
            if (window.innerWidth <= 768) {
                this.widthPage = false
            } else {
                this.widthPage = true
            }
        },
        forgot () {
            this.$router.push({name:'loginForgot'})
        }
  
    },
    destroyed() {
        window.removeEventListener("resize", this.resize);
    }


}
</script>

<style lang="scss" scoped>
 @import '@/assets/css/singUp.scss';
 .wrappLoginModal{
     background: #FFFFFF;
     border-radius: 5px;
     width: 486px;
    //  height: calc(785px - 43px);
    // height: calc(785px - 100px);
    height: calc(785px - 89px);
    //  padding-top:40px;
    padding-top:43px;
    //  padding-bottom: 51px;
    padding-bottom: 5px;
     position: relative;

     .exit{
        position: absolute;
        // right:10px;
        // top:10px;
        right:20px;
        top:20px;
        cursor: pointer;
        text-align: right;
        img{
            width:14px;
        }
    }

 }
.loginComponent{
    //  background-color:rgba(8, 79, 246, 0.527);
        width: 406px;
        // height: 711px;
        margin:auto;

        .loginTitle {
            // font-family: 'Gotham Pro Medium';
            // // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: 500;
            // font-size: 29px;
            // line-height: 40px;
            // /* or 138% */
            // color: #062931;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            color: #062931;
        }
        .loginText {
            // margin-top: 20px;
            // margin-bottom: 33px;
            // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: normal;
            // font-size: 16px;
            // line-height: 24px;
            // /* or 150% */

            margin-top: 24px;
            margin-bottom: 33px;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 25px;
            /* or 147% */


            /* black */

            color: #062931;

        }
       
    }
@media only screen and (max-width: 500px){
    .wrappLoginModal{
        position: fixed;
        bottom: 0;
        left:0;
         z-index:1001;
        width:100%;
        box-sizing:border-box;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.18);
        border-radius: 20px 20px 0px 0px;
        height: fit-content !important;
        max-height: 94%;
        overflow: auto;
         .exit{
            position: absolute;
            right:15px;
            top:20px;
            cursor: pointer;
            text-align: right;
            img{
                width:20px;
            }
         }

    }
    .loginComponent{
    //  background-color:rgba(48, 219, 241, 0.609);
        width: 334px;
        height: 711px;
        // margin-bottom: 71px;

        .loginTitle {
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 15px;
        }
       
    }
}

</style>
<style lang="scss">
 .wrappLoginModal{
    .loginComponent{
        .last{ // basic:last-of-type
            // margin-bottom: 10px;
            // margin-bottom: 8px;
            margin-bottom: 7px;
        }
        .rember {
            // margin-top: 26px;
            // margin-bottom: 26px;
            margin-top: 31px;
            // margin-top: 29px;
            // margin-bottom: 25px;
            margin-top: 37px;
            margin-bottom: 30px ;
        }
    }
    .haveaccount{
        margin-top: 25px !important;
    }
 }
@media only screen and (max-width: 500px){
    .wrappLoginModal{
        .loginComponent{
            // .basic:last-of-type{
            //     margin-bottom: 10px;
            // }
            .rember {
                margin-top: 26px;
                margin-bottom: 23px;
            }
        } 
    } 
}

</style>