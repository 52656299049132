<template>
  <div class="faqFeedback">
          <span>Was this article helpful?</span>
          
          <img  src="../../assets/images/icons/faqPositive.svg" />
          <img  src="../../assets/images/icons/faqNegative.svg" />
  </div>
</template>

<script>
export default {
    name:"faqFeedback",
    props:{
        id:{
            required: true
        }
    },
    data(){
        return{
            
        }
    }

}
</script>

<style lang="scss" scoped>
.faqFeedback{
    display: flex;
    height: fit-content;
    align-items: center;
    padding: 21px 0;
    span{
        padding-right: 10px;
    }
    img{
        padding-right: 5px;
        cursor: pointer;
    }
}
</style>