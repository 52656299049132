<template>
  <div
    :class="{
      wrappFooterDown: true,
      ' wrappFooterDown2': white,
      ' wrappFooterDown3': gray,
      isEstimate: isEstimate,
    }"
  >
    <div v-if="!white && !gray" class="holdContent">
      <div class="content">
        <div class="imgLogo">
          <img :src="require('@/assets/images/logoBlureWhite.svg')" alt="/" />
        </div>
        <div class="copy">&copy; 2022 Carvisor. All rights reserved</div>
      </div>
      <div class="content1">
        <div class="flex">
          <div class="links">
            <router-link class="link" to="/about">About </router-link>
            <router-link class="link" to="/terms"
              >Terms &amp; Privacy
            </router-link>
            <router-link class="link" to="/help"
              >Help &amp; Feedback
            </router-link>
            <router-link class="link" to="/newsroom">Press </router-link>
            <router-link class="link" to="/help">Blog</router-link>
          </div>
        </div>
        <div class="iconFooter">
          <div class="line"></div>
          <div class="iconFooterIcon">
            <div class="spanImg firstImg">
              <a class="aLink" :href="getSocials.instagram" target="_blank">
                <img :src="require('@/assets/images/inWhite.svg')" alt="/" />
              </a>
            </div>
            <div class="spanImg">
              <a class="aLink" :href="getSocials.facebook" target="_blank">
                <img :src="require('@/assets/images/fbWhite.svg')" alt="/" />
              </a>
            </div>
            <div class="spanImg">
              <a class="aLink" :href="getSocials.instagram" target="_blank">
                <img
                  :src="require('@/assets/images/instagramWhite.svg')"
                  alt="/"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--- second part--->
    <div class="holdContent" v-else>
      <div class="footer-wrapper">
        <div class="footer-links">
          <div class="link-section">
            <div class="section-title">CAR VALUATION</div>
						<router-link class="section-link" to="/">How it works</router-link>
						<router-link class="section-link" to="/priceSteps">Value my car</router-link>
						<router-link class="section-link" to="/pricing">Pricing</router-link>
						<router-link class="section-link" to="/blog">Blog</router-link>
          </div>
          <div class="link-section">
            <div class="section-title">COMPANY</div>
						<router-link class="section-link" to="/about">About Carvisor</router-link>
						<router-link class="section-link" to="/newsroom">Press</router-link>
          </div>
          <div class="link-section">
            <div class="section-title">ACCOUNT</div>
						<router-link class="section-link" to="/profile">Personal information</router-link>
						<router-link class="section-link" to="/valuations">My Reports</router-link>
						<router-link class="section-link" to="/favourites">Favorites</router-link>
            <div v-if="getLoged" class="section-link" @click="logout">Log out</div>
          </div>
          <div class="link-section">
            <div class="section-title">SUPPORT</div>
						<router-link class="section-link" to="/faq">FAQ</router-link>
						<router-link class="section-link" to="/help">Help & feedback</router-link>
						<router-link class="section-link" to="/terms">Terms & privacy</router-link>
          </div>
        </div>
        <div class="footer-socials">
          <a class="social-wrapp" :href="getSocials.twitter" target="_blank">
						<img :src="require('../../assets/images/icons/twitter.png')" alt="">
					</a>
          <a class="social-wrapp" :href="getSocials.facebook" target="_blank">
						<img :src="require('../../assets/images/icons/fb.png')" alt="">
					</a>
          <a class="social-wrapp" :href="getSocials.instagram" target="_blank">
						<img :src="require('../../assets/images/icons/instaLight.png')" alt="">
					</a>
        </div>
      </div>
      <div class="copyright">
        © 2020 Carvisor. All rights reserved
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/api'
import { mapGetters } from "vuex";
export default {
  name: "footerDown",
  props: ["white", "gray"],
  computed: {
    ...mapGetters(["getSocials", "getLoged"]),
  },
  data() {
    return {
      isEstimate: false,
    };
  },
	methods: {
		logout(){
			api.logOutUser().then(() => {
				localStorage.removeItem('sid')
				localStorage.removeItem('userName')
				this.$store.commit('setuserData', {name:'', email:'',img:''})
				this.$store.commit('changeLoged', false)
				this.$store.commit('setUserName', null)
				this.$store.commit('setUserPrivilege', 0)
				this.$router.push({ name: 'Home' })
			}).catch(() => {
				localStorage.removeItem('sid')
				localStorage.removeItem('userName')
				this.$store.commit('setuserData', {name:'', email:'',img:''})
				this.$store.commit('changeLoged', false)
				this.$store.commit('setUserName', null)
				this.$store.commit('setUserPrivilege', 0)
			})

			window.scrollTo({
				top: 0,
				left: 0,
				behavior: 'smooth'
			})
		},
	},
  watch: {
    $route: {
      handler() {
        if (this.$route.name === "estimate") {
          this.isEstimate = true;
        } else {
          this.isEstimate = false;
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.wrappFooterDown {
  background: #103d49;
  .holdContent {
    width: 100%;
    height: 330px;
    display: flex;
    flex-direction: column;
		background-color: #F8F8F9;
    .footer-wrapper {
			max-width: 1272px; // 1920 - 2x 324px
			width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
      .footer-links {
        height: 100%;
        max-width: 725px;
				width: 100%;
        display: flex;

        .link-section {
					height: calc(100% - 55px);
					padding-top: 55px;
					width: auto;
          max-width: 145px;
					margin-right: 80px;
          display: inline-flex;
          flex-direction: column;
          .section-title {
						inline-size: max-content;
						font-weight: 500;
						font-size: 14px;
						letter-spacing: 0.05em;
						text-transform: uppercase;
						color: #5A7E87;

						margin-bottom: 7px;

          }
          .section-link {
						font-weight: 400;
						font-size: 15px;
						letter-spacing: 0.01em;
						line-height: 25px;
						color: rgba(6, 41, 49, 0.8);
						inline-size: max-content;
						text-decoration: none;
						cursor: pointer;
          }
        }
      }
      .footer-socials {
        height: calc(100% - 60px);
        width: 110px;
        display: flex;
        justify-content: space-between;
				padding-top: 60px;

        .social-wrapp {
          height: 20px;
          width: 20px;
					cursor: pointer;
					img {
						height: 100%;
					}
        }
      }
    }
    .copyright {
      max-width: 1272px;
			width: 100%;
      height: 15px;
			font-weight: 400;
			font-size: 12px;
			letter-spacing: 0.01em;
			color: rgba(6, 41, 49, 0.4);
      margin: 0 auto;
			margin-bottom: 22px;
    }
  }
}
.wrappFooterDown2 {
  background: #ffffff;
}
.wrappFooterDown3 {
  background: #f3f5f6 !important;
}

@media only screen and (max-width: 768px) {
	.wrappFooterDown {
		.holdContent {
			height: 370px;
			padding: 0 27px;
			.footer-wrapper {
				flex-direction: column;
				.footer-links {
					.link-section {
						padding-top: 45px;
						margin-right: 60px;
					}
				}
				.footer-socials{
					padding-top: 0;
					padding-bottom: 53px;
					max-height: 20px;
				}
			}
		}
	}
}
</style>
