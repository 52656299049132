<template>
  <div :class="badge === 1 || badge === 2 ? 'placeholder positio' : 'placeholder'" @mouseenter="toggleHover()" @mouseleave="toggleHover()" >
    <div :class="isOpen ? 'cover1': 'none'"></div>
    <div v-if="badge === 1" @click="open" v-click-outside="close" >
      <badge :text="'GREAT PRICE'" :backgroundColor="'#51E6FB'" :color="'#134753'" />
    </div>
    <div v-else-if="badge === 2" @click="open" v-click-outside="close" >
      <badge :text="'PRICEY'" :backgroundColor="'#4982FF'" :color="'white'" />
    </div>
    <div v-else @click="open" v-click-outside="close" class="divImg"> 
      <img v-if="isHover" class="infoImg" :src="require('@/assets/images/InfoHover.svg')" alt="I"> 
      <img v-else  class="infoImg" :src="require('@/assets/images/Info.svg')" alt="I"> 
    </div>
    <div :class="badge === 1 || badge === 2 ? 'tooltip-wrapper1 badge' : 'tooltip-wrapper1'" v-if="isOpen">
          <div class="exit2"><img @click="close" :src="require('@/assets/images/ePopup.svg')" /></div>
           <div class="msg-tooltip1" v-html="msg">
          </div>
    </div>
  </div>
</template>

<script>
import badge from '@/components/badge.vue'
export default {
    props:[ 'badge', 'maintitle', 'msg'],
    data () {
      return{
        isOpen: false,
        invisible: false,
        isHover: false
      }
    },
    components: {
      badge
    },
    methods:{
        close () {
           this.invisible = true
          // console.log('this.invisible = true')
           this.isOpen=false
           setTimeout(()=>{
             this.invisible = false
            // console.log('this.invisible = false')
           },500)


        },
        open () {
          if(this.invisible){
           // console.log('open ================== return')
            return
            }
           this.isOpen= true
           this.invisible = true
        },
        toggleHover () {
          this.isHover = !this.isHover
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/tooltip.scss';
.placeholder{
  //height: 50px;
  // align-self: flex-start;
  height: 30px;
  max-height: 36px;
  position:relative;
  display:flex;
  align-items: center;
  justify-content: center;
  background: transparent !important;
  cursor: pointer;
  img{
    // width: 30px !important;
  }
  .divImg{
    height: 100%;
  }
  .infoImg{
    width:36px !important;
  }

  .tooltip-wrapper1 {
    z-index:110;
    position: absolute;
    margin-bottom: -5px;
    // top:-7px !important;
    // right:-8px !important;
    top:-12px !important;
    right:-12px !important;
    // width:390px;
    width: 408px;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    border: 2px solid #DEE0E7;
    box-shadow: 0px 0px 11px rgba(0, 0, 0, 0.14);
    border-radius: 5px;
    // padding:10px 20px 33px 20px;
    padding:55px 66px 55px 30px;
  
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 23px;
    color: #062931;
        .exit2{
          // display: initial;
          position: absolute;
          top:10px;
          right: 10px;
          text-align: right;
          // height:20px;
          img{
            // width:14px;
            // width:20px !important;
          }
  }

  }
}
.cover1{
    position: fixed;
    //top:80px;
    top:0px;
    left:0;
    width:100vw;
    height: 100vh;
    background: #06293110 !important;
    // opacity: 0.06;
    z-index:100;
  }
  .none{
    display:none;
  }

.msg-tooltip1{
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* or 133% */

    letter-spacing: 0.01em;

    /* black */
    text-transform:initial;
    color: #062931;
    margin: 0;
}
.tooltip-wrapper1.badge{
    width:290px !important;
    top:-8px !important;
    left:-209px !important;
    // color:red!important;
    padding: 10px 10px 14px 18px;
    .msg-tooltip1{
      box-sizing:border-box;
      padding:0;
      margin:0 0 15px 0;
    }
  }
@media only screen and (max-width: 768px){
  .placeholder{
    .infoImg{
      width:30px !important;
    }
    .tooltip-wrapper1 {
      top:-8px !important;
      right:-11px !important;
      width: 338px;
      padding:60px 71px 50px 30px;
    }
  }
  .tooltip-wrapper1.badge{
     padding: 20px 20px 25px 20px;
        top:8px !important;
        left:0%!important;
        right:0%!important;
      .msg-tooltip1{
         max-width:65vw;
      }
  }

}
@media only screen and (max-width: 500px){
  .msg-tooltip1{
    font-weight: normal;
    font-size: 14px;
    line-height: 20px
  }
    .placeholder{
    .infoImg{
      width:30px !important;
    }
    .tooltip-wrapper1 {
      // top:-8px !important;
      // right:-11px !important;
      padding:60px 60px 55px 30px;
      width: 335px;
      .exit2{
        img{
           width: 30px;
        }
       
      }
    }
  }
  .placeholder.positio{
    position: initial;
  }
   .tooltip-wrapper1.badge{
        padding: 20px 20px 25px 20px;
        top:8px !important;
        left:0%!important;
        right:0%!important;
        // position: fixed !important;
        // // margin-bottom: -5px;
        // // top:-8px !important;
        // // left:-349px !important;
        // top: calc(50vh - 170px) !important;
        // left: calc(50vw - 145px) !important;
        // // width:390px;
        .exit2{
          display:none
        }
        .msg-tooltip1{
            box-sizing:border-box;
            padding:0;
            margin:0 0 0px 0;
        }
  }
}
@media only screen and (max-width: 375px){
   .tooltip-wrapper1.badge{
        width:280px !important;
        max-width:85vw;
        top:-8px !important;
        left:0%!important;
        right:0%!important;
        // color:red!important;
        box-sizing: border-box;
        .exit2{
          display:none
        }
        .msg-tooltip1{
            box-sizing:border-box;
            padding:0;
            margin:0 0 0px 0;
        }
  }
}
</style>
