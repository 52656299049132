<template>
  <div class="more-menu-wrapper">
		<div v-for="section in sections" :key="section.title" class="section">
			<div class="section-item title"> {{ section.title }} </div>
			<div v-for="link in section.links" :key="link.title" @click="reroute(link.path)" class="section-item"> {{ link.title }} </div>
		</div>
		<div class="section">
			<div class="section-item title">stay informed</div>
			<div class="section-item" @click="reroute(`/newsroom`)">Newsletter</div>
			<div class="section-item special">
				<span>Follow us</span>
				<div class="icons-wrap">
					<a :href="getSocials.linkedin" target="_blank" class="icon-wrap">
						<img :src="require(`@/assets/images/icons/linkedinIcon.svg`)" alt="">
					</a>
					<a :href="getSocials.facebook" target="_blank" class="icon-wrap">
						<img :src="require(`@/assets/images/icons/fbIcon.svg`)" alt="">
					</a>
					<a :href="getSocials.instagram" target="_blank" class="icon-wrap">
						<img :src="require(`@/assets/images/icons/instaIcon.svg`)" alt="">
					</a>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
// import socialIcon from "./socialIcon.vue";
import { mapGetters } from "vuex";
// import { api } from "@/api";
export default {
  name: "moreMenu",
  components: {
    // socialIcon,
  },
  props: ["pisi"],
  data() {
    return {
			sections: {
				car: {
					title: "car valuation",
					links: {
						how: {
							title: "How it works",
							path: "/"
						},
						price: {
							title: "Pricing",
							path: "/pricing"
						}
					}
				},
				company: {
					title: "company",
					links: {
						about: {
							title: "About Carvisor",
							path: "/about"
						},
						press: {
							title: "Press",
							path: "/newsroom"
						}
					}
				},
				support: {
					title: "support",
					links: {
						faq: {
							title: "FAQ",
							path: "/faq"
						},
						help: {
							title: "Help & Feedback",
							path: "/help"
						},
						terms: {
							title: "Terms & Privacy",
							path: "/terms"
						}
					}
				},
			},
      viewRegion: false,
      pathName: "",
    };
  },
  methods: {
		reroute (param) {
			if (this.$route.path !== param) {
				this.$router.push(param)
			}
			this.$emit("close", false)
		},
  },
  computed: {
    ...mapGetters(["getLoged", "getuserData", "getSocials"]),
  },
  watch: {
    $route: {
      handler() {
        this.pathName = this.$route.name;
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.more-menu-wrapper {
	// height: calc(100vh - 61px);
	height: calc(100% - 61px);
	overflow-y: auto;
	// height: 836px;
	// height: 100vh;
	width: 100%;
	background-color: #fff;
	box-sizing: border-box;
	position: absolute;
	z-index: 999;
	bottom: 60px;
	left: 0;
	padding-top: 10px;
	pointer-events: auto;
	div:last-child {
		border-bottom: none;
	}

	.section {
		width: 100%;
		border-bottom: 2px solid #DEE0E5;
		div:last-child {
			border-bottom: none;
		}
		.section-item {
			padding: 0 30px;
			height: 60px;
			// width: 100%;
			display: flex;
			align-items: center;
			border-bottom: 1px solid #eeeff3;

			.icons-wrap {
				width: 132px;
				height: 36px;
				display: flex;
				justify-content: space-between;
				.icon-wrap {
					display: flex;
					justify-content: center;
					align-items: center;

					height: 36px;
					width: 36px;
					border-radius: 50%;
					background-color: #eeeff3;
				}
			}
		}
		.special {
			justify-content: space-between;
		}
		.title {
			font-size: 14px;
			font-weight: 500;
			letter-spacing: 0.05em;
			color: #467F8F;
			text-transform: uppercase;
		}
	}
	> *:last-child {
		> *:last-child {
			padding-bottom: 12px;
		}
	}
}
@supports (-webkit-overflow-scrolling: touch) {
  .setcover1 {
    // mobileNav - height: 90px;
    bottom: 90px !important;
  }
}
</style>
