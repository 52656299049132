<template>
  <div :class="(index === 2) ? 'explanationStep ' : 'explanationStep'"> <!--smallSize -->

      <div class="expText" v-if="!small">
          <!-- source :src='this.expData.img' type="video/mp4" /  controls   -->
          <video :poster="expData.poster" ref="videoRef" id="video-container" width="100%" class="imgExp" @ended="endVideo" muted playsinline>
              <source ref="sourceRef" src=""  type="video/ogg"/>
              <source ref="sourceRef2" src=""  type="video/mp4"/>
            </video>
          <p v-if="false">{{txt}}</p>
          <p class="expTitle">{{expData.title}}</p>
          <p class="expDesc" v-html="expData.description"></p>
      </div>
      <!-- div v-if="index !==2 " class="imgExp"></div -->
       <div class="expText" v-else v-waypoint="{ active: activeWayPoint, callback: onWaypoint, options: intersectionOptions }">
          <!-- source :src='this.expData.img' type="video/mp4" /  controls   -->
          <video :poster="expData.poster" ref="videoRef1" id="video-container" width="100%" class="imgExp" @ended="endVideo" muted playsinline>
               <source ref="sourceRef" src=""  type="video/ogg"/>
               <source ref="sourceRef2" src=""  type="video/mp4"/>
            </video>
          <p v="false">{{txt}}</p>
          <p class="expTitle">{{expData.title}}</p>
          <p class="expDesc" v-html="expData.description"></p>
      </div>

  </div>
</template>

<script>
export default {
    name: 'explanationStep',
    props: {
        expData: {
            type: Object,
            require: true
        },
        index:{
            type: Number,
        },
        playIndex: {
            type: Number,
            // default: 0
        },
        txt:{
            type: String
        }
    },
    data() {
        return {
         small:false,
         intersectionOptions: {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                threshold: [0.5, 0.5]  // [0.25, 0.75] if you want a 25% offset!
            } ,
            activeWayPoint:true
        }
    },
    created () {
      window.addEventListener("resize", this.resize)
      this.resize()
    },
    mounted () {
        this.$refs.sourceRef.src = this.expData.img
        this.$refs.sourceRef2.src = this.expData.img

       // this.startVideo (this.playIndex)
    },
    methods: {
        startVideo (x) {
            if(this.index == x || this.playIndex === this.index){
               this.$refs.videoRef.play()
               this.$emit('start', {index: this.index, videoRef: this.$refs.videoRef})
             }
        },
        endVideo () {
            if(this.small) this.$refs.videoRef1.play()
            else this.$emit('end',this.index)
        },
         resize () {
            if (window.innerWidth <= 787) {
            this.small = true
            } else {
            this.small = false
            }
         },
          onWaypoint ({ going, direction }) {
            // going: in, out
            // direction: top, right, bottom, left
            if (going === this.$waypointMap.GOING_IN) {
                console.log('waypoint going IN!')
                this.$refs.videoRef1.play()
                //this.activeWayPoint = false
            }
            if (going === this.$waypointMap.GOING_OUT) {
                console.log('waypoint going OUT!')
                this.$refs.videoRef1.pause()
           }

            if (direction === this.$waypointMap.DIRECTION_TOP) {
               // console.log('waypoint going top!')
            }
        }
    },
    watch: {
    playIndex: {
      handler (setNewValue) {
          if(this.small)return
         this.startVideo(setNewValue)
      }
    }
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  }

}
</script>

<style lang="scss" scoped>
.explanationStep{
     width: 28%;
     width:25%;
     margin: 0 1%;
     display: flex;
     justify-content: center;
     align-items: flex-start;

//     background-image: url("../../assets/images/bgImage/Background-Icons.png");
//     background-repeat: no-repeat;
//    /* background-position: center top; */
//     background-position: center;
//     background-size: cover;
//     background:red;
    .imgExp{
       // padding-top:10px;
        max-width: 296px;
        max-height: 296px; 
        max-width: 188px;
        max-height: 188px; 
//       // width:30%; ---arrow is visible
//       // width:45%;
        margin:0 auto;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        
      }
.expText {
    width:100%;
    text-align: center;
    box-sizing: border-box;
    font-style: normal;
    color: #062931;

    .expTitle {
        // font-weight: bold;
        // font-size: 20px;
        // line-height: 24px;
        // /* or 120% */
        // // text-align: center;
        // /* black */
        // color: #132727;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 30px;
        text-align: center;
        color: #062931;
        margin-top: 31px ;
        margin-bottom: 11px;
    }
    .expDesc {
        font-size: 17px;
        line-height: 25px;
        text-align: center;
        color: #062931;
        margin-top: 0px ;
        margin-bottom: 0px;
    }
  }
}
.smallSize{
    width:21.46%;
}
 @media only screen and (max-width: 1000px){
    .explanationStep{
        width:25%;
        width: 29%;

        .expText {
   

        .expTitle {
            font-size: 26px;
            line-height: 30px;
        }
        .expDesc {
            font-size: 17px;
            line-height: 25px;
        }
     }
    }
    // .smallSize{
    //    width:26.46%;
    // }

  }
  @media only screen and (max-width: 767px){
    .explanationStep{
        box-sizing: border-box;
        width:100%;
        padding: 3% 7%;
        flex-direction: column;
        align-items: center;
    .expText {
        .expTitle {
            font-weight: bold;
            font-size: 19px;
            line-height: 24px;
            /* or 120% */
            // text-align: center;
            /* black */
            color: #132727;
        }
        .expDesc {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            color: #062931;
        }

    }
  }
    .smallSize{
        box-sizing: border-box;
        width:100%;
    }
    #video-container{
        width:75%;
        padding:0px;
    }
    // .imgExp{
    //      width:10%;
    //     img{
    //        transform: rotate(90deg) !important;
    //     }
    // }

  }
</style>
