<template>
  <div class="newShare" :style="{top: altTop + 'px'}">
     <div :class="sampleReport?'imgWrapp logo cursorIni': 'imgWrapp logo'" >
        <img :src="require('../../assets/images/icons/newSideShareLogo.svg')" />
     </div>  
     <div class="line"></div>
     <div  :class="[{'cursorIni' : sampleReport}, {'safary': getIsSafari},'imgWrapp', 'imgWrapp1']" @click="editData" @mouseover="editHover=true" @mouseleave="editHover=false">
        <img v-if="!editHover" :src="require('../../assets/images/icons/Edit.svg')" />
        <img v-else :src="require('../../assets/images/icons/EditHover.svg')" />
     </div> 
     <div class="line"></div>
     <div  :class="[{'cursorIni' : sampleReport}, {'safary': getIsSafari},'imgWrapp', 'imgWrapp2']" @click="refresh" @mouseover="resetHover=true" @mouseleave="resetHover=false"> 
        <img v-if="!resetHover" :src="require('../../assets/images/icons/Reset.svg')" />
        <img v-else  :src="require('../../assets/images/icons/Reset.svg')" />
     </div> 
     <div class="line"></div>
     <div  :class="[{'cursorIni' : sampleReport}, {'safary': getIsSafari},'imgWrapp', 'imgWrapp2']" @click="showmodalopen" @mouseover="shareHover=true" @mouseleave="shareHover=false"> 
        <img v-if="!shareHover" :src="require('../../assets/images/icons/Share.svg')" />
        <img v-else :src="require('../../assets/images/icons/Share.svg')" />
     </div> 
     <div class="line"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: 'newShare',
    props: {
        altTop: {
            type: Number,
        },
    },
    data(){
			return{
				noEdit: false,
				sampleReport: false,
				editHover: false,
				resetHover: false,
				shareHover: false,
			}
    },
    methods:{
         editData() {
            if(this.sampleReport)return
            // this.$store.commit('resetValue')
            this.$router.push('/priceSteps')
        },
          refresh () {
            if(this.sampleReport)return
            this.$emit('changeactivebadge', 'make')
            this.$store.commit('resetValue')
      },
      showmodalopen (){
          if(this.sampleReport)return
          this.$emit('showmodalopen')
      },
    },
    watch:{
        '$route': {
            handler: function() {
                //  console.log(this.$route.name)
                if (this.$route.name === 'sampleReport') {
                this.sampleReport = true
                } else {
                this.sampleReport = false
                }
            },
            deep: true,
            immediate: true
         }
    },
    computed:{
      ...mapGetters(['getIsSafari']),
    },

}
</script>

<style lang="scss" scoped>
.newShare{
    // position: static;
    align-self: flex-start;
    padding-left:20px;
    display: flex;
    flex-direction: column;
    position: -webkit-sticky;
    position: sticky;
    top: 60px;
    //  align-items: flex-start;
    .line{
        display: none;
    }
   
    .imgWrapp{
        // height: 70px;
         width: 60px;
         height: 60px;
         margin-bottom: 10px;
         box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
         border-radius: 30px;
         display: flex;
         justify-content: center;
         align-items: center;
        img{
           
            //  width: 60px!important;
            //  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            //  border-radius: 30px;

             width: 24px!important;
        }

    }
    .imgWrapp1{
         background-color: #FB9100;
    }
     .imgWrapp1:hover{
         background-color: #FFAA35;
    }
    .imgWrapp2{
         background-color: #FFFFFF;
         img{
            width: 15.4px;
            height: 17.8px;
         }
    }
    .imgWrapp2:last-of-type{
        img{
            width: 17.4px!important;
            height: 19.3px;
        }
    }
     .imgWrapp2:hover{
        //  background-color: rgba(255, 255, 255, 0.6);
         background-color: #D1DEE1;
    }
  

//  .imgWrapp{
//         // height: 60px;
//         margin-bottom: 10px;
//         // box-sizing: border-box;
//             // width: 60px!important;
//             // height: 60px!important;
//             // max-width: 60px!important;
//             // height: 60px!important;
            
//             //   -webkit-border-radius: 30px;
//             // -moz-border-radius: 30px;
//             // -o-border-radius: 30px;
          
           
//         img{
//             // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
//             // // -webkit-box-shadow:0px 0px 10px rgba(0, 0, 0, 0.1);  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
//             // // -moz-box-shadow:0px 0px 10px rgba(0, 0, 0, 0.1);
//             // border-radius: 30px;
        
//             width: 60px!important;
//             height: 60px!important;
//             //  min-width: inherit !important;
//             //  min-height:inherit !important;
//             // width: 100% !important;
//             // height:100% !important;

//         }

// }







    .imgWrapp:hover{
        cursor: pointer;
    }
    .cursorIni,
    .cursorIni:hover{
        cursor: default;
        // box-shadow: transparent !important;
    }
     .logo{
        display: none;
        box-shadow: transparent !important;
    }

}
@media only screen and (max-width: 1024px){
    .newShare{
        top:0;
        right: 0;
        // height: calc(100vh - 80px);
        height: 100vh;
        z-index:211;
        position: fixed;
        padding-left:0px;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: #F8F8F9;
        //  background-image: linear-gradient( #F8F8F9, #EEEFF3);
        width: 70px;


        .line{
            display: initial;
             border-bottom: 1px solid #DEE0E5;
             margin-bottom: 10px;
             width: 100%;
        }
        .imgWrapp{
            // width: 100%;
            // height: 70px;
            // box-sizing: border-box;
            // display: flex;
            // justify-content: center;
            // align-items: center;
           
            // box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
            // border-radius: 25px;
            // img{
                
            // }


         }
// 
    .imgWrapp{
        // height: 70px;
         width: 50px;
         height: 50px;
         margin-bottom: 10px;
         box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.1);
         border-radius: 25px;
         display: flex;
         justify-content: center;
         align-items: center;
        img{
             width: 24px!important;
        }

    }
    .imgWrapp:hover{
         box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
     
    .imgWrapp2{
        //  background-color: #FFFFFF;
         background-color: #D1DEE1;
         img{
            width: 15.4px;
            height: 17.8px;
         }
    }
    .imgWrapp2:last-of-type{
        img{
            width: 17.4px!important;
            height: 19.3px;
        }
    }
    
  






// 
        .logo{
            display: flex;
            justify-content: center;
            align-items: center;
             box-shadow: none !important;
            img{
                width: 29px!important;
                 box-shadow: none !important;
            }
        }
    }
}
@media only screen and (max-width: 800px){
     .newShare{
        // height: calc(100vh - 166px);
     }
}
@media only screen and (max-width: 500px){
	.newShare{
		position: fixed;
		top:0;
//    bottom: 60px;
		left:0;
		height: 70px;
		z-index: 999;
		padding-left:0px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-end;
		// background: #F8F8F9;
		background-color: transparent;
		width: 100%;
		.line{
			display: none;
		}
		.imgWrapp{
			box-sizing: border-box;
			display: flex;
			justify-content: center;
			align-items: center;
			// border-bottom: 1px solid #DEE0E5;
			width:fit-content;
			margin: 0 10px 0 0;
			width: 40px;
			height: 40px;
			
			img{
					
			}
		}
		.imgWrapp2{
			// background-color: #FFFFFF;
			img{
				width: 15.4px;
				height: 17.8px;
			}
		}
		.imgWrapp2:last-of-type{
			img{
				width: 17.4px!important;
				height: 19.3px;
			}
		}

		.logo{
			display: none;
			justify-content: center;
			align-items: center;
		}
	}
}
</style>
