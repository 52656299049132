<template>
  <div v-click-outside="closeDropdown" class="home-dropdown-wrapper">
    <input
      v-if="isSearchable"
      @focus="openDropdown($event), $emit('focused')"
      @click="checkClose"
      v-on:keyup.enter="$emit('select')"
      v-on:keyup="search = $event.target.value"
      type="text"
      :placeholder="selected ? selected.value : type | capitalize"
      v-model="search"
      :class="{'black' : selected && !search }"
      :disabled="!(filterOptions && filterOptions.length > 0) && !search"
      v-hideMoblieNav
    >
    <div v-html="selected ? selected.value : type.charAt(0).toUpperCase() + type.slice(1)" class="not-searchable" :class="{  'black' : selected && !search }" @click="openDropdown(), $emit('focused')" v-else>

    </div>
    <div v-if="show" :class="{small : smallScreen}" class="home-dropdown">
      <div class="scrollbar" v-if="filterOptions && filterOptions.length > 0">
        <div
          v-for="(option, index) in filterOptions"
          :key="option.key + '-' + index"
          class="option-wrapper"
          :class="{ active : selected ? option.value === selected.value : false, 'before-line' : filterOptions[index + 1] && filterOptions[index + 1].key === 'line', 'line-wrapper' : option.key === 'line' }"
          onmouseover="this.classList.add('hover')"
          onmouseout="this.classList.remove('hover')"
          >
          <div v-if="option.key !== 'line'" @click="setOption(option)" :class="{ 'dark' : $route.name === 'priceSteps' ? true : false }" class="option">
            <div class="option-inner">
              <span v-html="option.value"></span>
            </div>
          </div>
          <div v-else class="line">
            LIST&thinsp;(A-Z)
          </div>
        </div>
      </div>
      <div v-else class="option">
        Nothing found
      </div>
      <div v-if="backText" @click="$emit('backClick')" class="return">
        <img :src="require('@/assets/images/arrowLeft.png')" alt="">
        {{backText}}
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/js/eventBus.js'
export default {
  name: 'homeDropdown',
  props: {
    type: {
      type: String,
      required: true
    },
    selected: {
      type: Object,
      required: false
    },
    options: {
      type: Array,
      required: false
    },
    fixed: {
      type: Boolean,
      default: false
    },
    backText: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      search: null,
      show: false,
      searchable: false,
      focused: 0,
      smallScreen: false
    }
  },
  mounted () {
    this.setSearchable()
    window.addEventListener("resize", this.setSearchable)
    if (this.fixed) {
      this.show = true
    }
  },
  methods: {
    setSearchable () {
      if (this.type === 'make' || this.type === 'model' || this.type === 'year') {
        this.searchable = true
      } else {
        this.searchable = false
        this.search = null
      }
      if (window.innerWidth > 768) {
        this.smallScreen = false
      } else {
        this.smallScreen = true
      }
    },
    checkClose () {
      if (this.fixed) {
        return
      }
      this.focused += 1
      if (this.show && this.focused >= 2) {
        document.activeElement.blur();
        this.closeDropdown()
      }
    },
    openDropdown () {
      if (this.fixed) {
        return
      }
      // this.$emit('closedrop', false)
      this.options && this.options.length > 0 ? this.show = true : this.show = false
      if (this.type === 'make' || this.type === 'model') {
        eventBus.$emit('setKeyboard', true)
      }
    },
    closeDropdown () {
      if (this.fixed) {
        return
      }
      this.focused = 0
      this.search = null
      this.show = false
      // this.$emit('closedrop', true)
      if (this.type === 'make' || this.type === 'model') {
        eventBus.$emit('setKeyboard', false)
      }
    },
    setOption (option) {
      let payload = {
        key: this.type,
        value: option
      }
      this.$store.commit('setOption', payload)
      this.$store.dispatch('getCars')
      this.search = null
      this.closeDropdown()
    }
  },
  computed: {
    filterOptions () {
      const search = this.search ? this.search.toLowerCase().trim() : null
      if (!search) return this.options;
      return this.options.filter(c => String(c.value).toLowerCase().indexOf(search) > -1 && !Object.prototype.hasOwnProperty.call(c, 'popular') && c.value !== 'line');
    },
    isSearchable () {
      return this.searchable
    }
  },
  filters: {
    capitalize: function (value) {
      if (!value) return ''
      value = value.toString()
      return value.charAt(0).toUpperCase() + value.slice(1)
    }
  },
  watch: {
    type: function () {
      this.setSearchable()
    },
    // show: {
    //   handler (newVal) {
    //     this.$emit('closedrop', !newVal)
    //   }
    // }
  },
  destroyed () {
    document.removeEventListener("resize", this.setSearchable)
  }
}
</script>

<style lang="scss" scoped>
.home-dropdown-wrapper {
  width: 167px;
  position: relative;
  height: 38px;
  box-sizing: border-box;
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #787883;

  .not-searchable {
    width: 100%;
    border: none;
    margin: 0;
    padding: 0;
    outline: none;
    // border-right: 1px solid #B7B9C0;
    padding-right: 10px;
    color: #92949B;
    font-size: 16px;
    padding-top: 3px;
    cursor: pointer;
  }
  .not-searchable.black {
    color: #132727;
  }

  input {
    max-width: calc(100% - 10px);
    border: none;
    margin: 0;
    padding: 0;
    outline: none;
    height: 38px;
    // border-right: 1px solid #B7B9C0;
    padding-right: 10px;
    color: #132727;
    // font-size: 16px;
    // cursor: pointer;
    // // font-family: "Gotham Pro";
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;
    display: flex;
    align-items: center;
    // color: #9498A0;

  }
  input::placeholder {
    opacity: 1;
    color: #9498A0;
   // font-family: "Gotham Pro";
  }
  input.black::placeholder {
    color: #132727;
  }
  input.black::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: #132727;
  }
  input.black::-moz-placeholder { /* Firefox 19+ */
    color: #132727;
    opacity: 1;
  }
  input:disabled {
    background: white;
  }
  .home-dropdown {
    position: absolute;
    z-index: 2;
    background: white;
    display: flex;
    flex-direction: column;
    top: calc(100% + 20px);
    left: 0px;
    width: 354px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 15px;
    padding: 15px 0;
    max-height: 356px;
    padding-right: 5px;

    .scrollbar {
      height: 100%;
      overflow-y: scroll;
      padding-right: 5px;
    }
    .scrollbar::-webkit-scrollbar {
      width: 6px;
    }
    .scrollbar {
      scrollbar-width: thin;
      scrollbar-color: #4C4C52 white;
    }
    .scrollbar::-webkit-scrollbar-track {
      background: white;
    }
    .scrollbar::-webkit-scrollbar-thumb {
      background-color: #4C4C52;
      border-radius: 3px;
    }
    .scrollbar::-moz-scrollbar-thumb {
      background-color: #4C4C52;
      border-radius: 3px;
    }
    .option-wrapper {
      .line {
        // height: 1px;
        border-top: 1px solid #9CB2B5;
        color: #9CB2B5;
        font-size: 11px;
        line-height: 32px;
        padding-left: 20px;
      }
    }

    .option-wrapper.active {
      background-color: #F8F8F9;
      position: relative;
      // margin-top: -1px;
      .option {
        // border-bottom: none;
      }
    }
    .option-wrapper.hover {
      background-color: #F8F8F9;
      position: relative;
      // margin-top: -1px;
      .option {
        // border-bottom: none;
      }
    }
    .option-wrapper.hover.line-wrapper {
      background-color: white !important;
    }
    .option.dark {
      border-bottom: 1px solid #EEEFF3;
    }
    .option {
      min-height: 60px;
      display: flex;
      align-items: center;
      width: calc(100% - 20px);
      margin: 0 auto;
      box-sizing: border-box;
      cursor: pointer;
      border-bottom: 1px solid #F8F8F9;
      padding-left: 10px;

      .option-inner {
        height: 50px;
        display: flex;
        align-items: center;
        width: 100%;

        span {
          margin-bottom: -5px;
        }
      }
    }
    // .option-wrapper:last-child .option {
    //   border-bottom: none;
    // }
    .option-wrapper.before-line .option {
      border-bottom: 1px solid transparent;
    }
    .option-wrapper.before-line:hover .option {
      border-bottom: 1px solid transparent !important;
    }
  }
  .home-dropdown.small {
    max-height: 302px;
  }
  .return {
    display: none;
    position: absolute;
    z-index: 2;
    top: calc(100% + 10px);
    font-size: 13px;
    line-height: 28px;
    color: #5A7E87;
    // font-family: 'Gotham Pro Medium';
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
}
.priceSteps {
  .return {
    display: block;
  }
  input {
    background-color: #F8F8F9 !important;
  }
  .home-dropdown {
    box-shadow: none;
    // width: 315px;
  }
  .option-wrapper.active, .option-wrapper.hover {
    background-color: white !important;
    margin-top: 0px !important;
    .option {
      border-bottom: 1px solid #EEEFF3 !important;

      .option-inner {
        // width: 300px !important;
        position: absolute;
        left: 0px;
        background-color: #F8F8F9;
        border-radius: 5px;
        padding-left: 20px;
        box-sizing: border-box;
      }
    }
  }
  .option-wrapper {
    .line {
      padding-left: 0px !important;
      width: calc(100% - 20px);
      margin-left: 10px;
    }
  }
}
@media only screen and (max-width: 767px){
  #home-page {
    .home-dropdown-wrapper {
      .home-dropdown {
        width: 310px;
      }
    }
  }
}
@media only screen and (max-width: 450px){
  .home-dropdown-wrapper {
    .home-dropdown {
      width: 279px;
    }
  }
}
</style>
<style media="screen">
.home-dropdown-wrapper:first-child {
  border-left: none;
}
</style>
