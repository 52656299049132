<template>
  <div id="app">
    <!-- <div v-if="false" class="gate">
      <div class="inputs">
        <span>Gate log in:</span>
        <input type="email" v-model="email">
        <input type="password" v-model="password">
        <div @click="gateLogIn" class="submit">
          log in
        </div>
      </div>
    </div> -->
    <div v-if="isIe" class="ie-wrapper">
      <div>
        Browser not supported.<br>
        Please use Egde, Chrome, Mozilla or Safari.
      </div>
      <div class="">
        <img style="margin-top: 0px;" :src="require('@/assets/images/noIe.png')" alt="no internet explorer support">
      </div>
      <img :src="require('@/assets/images/logoText.svg')" alt="carvisor logo">
    </div>
    <div v-else style="position:relative" >
      <app-header v-if="!showMobileNav" :sampleReport="sampleReport"></app-header>
      <router-view />
      <go-top v-if="showGoToTop" />
      <div v-if="showMobileNav && closeDrop" class="workaround">
        <moreMenu v-if="showMoreMenu" @close="more"/>
        <mobileNav @more="more" :pisi="pisi" :mobile='mobile' :sampleReport="sampleReport" :closeMoreMenu="closeMoreMenuVar"/>
      </div>
    </div>
  </div>
</template>
<script>
import goTop from '@/components/goTop.vue'
import { api } from '@/api'
import AppHeader from '@/components/header/header.vue'
import mobileNav from '@/components/mobileToolbar/mobileNav.vue'
import moreMenu from '@/components/mobileToolbar/moreMenu.vue'
import eventBus from '@/js/eventBus'
import { mapActions } from "vuex";
export default {
  name: 'App',
  components: {
    AppHeader,
    goTop,
    mobileNav,
    moreMenu
  },
  data () {
    return {
      sampleReport: false,
      estimatePage: false,
      gate: true,
      email: null,
      password: null,
      isIe: false,
      showGoToTop: false,
      showMobileNav:false,
      closeDrop: true,
      mobile:false,
      pisi:'',
      thisUserAgent:'',
      baseWindowHeight: '',
      showMoreMenu: false,
      closeMoreMenuVar: true,
      userMobInfo:[
        'Mozilla/5.0 (Apple-iPhone7C2/1202.466; U; CPU like Mac OS X; en) AppleWebKit/420+ (KHTML, like Gecko) Version/3.0 Mobile/1A543 Safari/419.3',
        'Mozilla/5.0 (iPhone9,4; U; CPU iPhone OS 10_0_1 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) Version/10.0 Mobile/14A403 Safari/602.1',
        'Mozilla/5.0 (iPhone9,3; U; CPU iPhone OS 10_0_1 like Mac OS X) AppleWebKit/602.1.50 (KHTML, like Gecko) Version/10.0 Mobile/14A403 Safari/602.1',
        'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.38 (KHTML, like Gecko) Version/11.0 Mobile/15A5370a Safari/604.1',
        'Mozilla/5.0 (iPhone; CPU iPhone OS 11_0 like Mac OS X) AppleWebKit/604.1.34 (KHTML, like Gecko) Version/11.0 Mobile/15A5341f Safari/604.1',
        'Mozilla/5.0 (iPhone; CPU iPhone OS 12_5_2 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.2 Mobile/15E148 Safari/604.1',
        // 'Mozilla/5.0 (iPhone; CPU iPhone OS 14_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 Mobile/15E148 Safari/604.1',
        'Mozilla/5.0 (iPhone; CPU iPhone OS 12_4_6 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/12.1.2 Mobile/15E148 Safari/604.1',
        'Mozilla/5.0 (iPhone; CPU iPhone OS 14_0_1 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/14.0 Mobile/15E148 Safari/604.1'
      ],
      setSortSimi:true

    }
  },
  created () {
      window.addEventListener("resize", this.resize)
      this.resize()
      this.call()
      this.check()
  },
  destroyed () {
      eventBus.$off('closedrop')
      eventBus.$off('showUpButtonSimilar')
      eventBus.$off('showSort')
      eventBus.$off('ofgotop')
      // window.removeEventListener("resize", this.resize)
  },
  updated() {
    eventBus.$on('closedrop', (e) => {
      this.closeDrop = e
    })
  },
  mounted () {
    this.baseWindowHeight = Math.max(window.innerHeight);
    // hide reCaptcha:
    this.$recaptchaLoaded().then(() => {
      const recaptcha = this.$recaptchaInstance
      recaptcha.hideBadge()
    })
    this.checkGate()
    this.checkIe()
    this.checkSession()
    setInterval(this.checkSession, 300000)
    // this.getDropdownData()
    this.handleToTopButton()
    window.addEventListener('resize', this.handleToTopButton)
    window.addEventListener('scroll', this.handleToTopButton)
    eventBus.$on('closedrop', (e) => {
      this.closeDrop=e
    })
    eventBus.$on('showUpButtonSimilar',(e) => {
      this.setSortSimi=e
      this.more2(!e) // use this for stop scroll
      if(!e)
       this.showGoToTop = false
      })
    eventBus.$on('ofgotop', (e) =>  {
       this.showGoToTop = e
    })
  },
  methods: {
    ...mapActions(["setIsIosTenAndMore"]),
    call () {
      if( /webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
      this.mobile=true
      }
    },
    // check device and versions device
    check () {
           this.thisUserAgent=navigator.userAgent
           
       for (let i =0 ; i < this.userMobInfo.length; i++) {
          
          if (this.thisUserAgent.localeCompare(this.userMobInfo[i]) === 0) {
            this.pisi=false // ios versions below 10 not show exsta pixels
            // eventBus.$emit('pisi', false)
           
             this.$store.commit('SET_IS_IOS_TEN_AND_MORE', false)
             return
          }else{
            this.pisi=true
            // eventBus.$emit('pisi', true)
            this.$store.commit('SET_IS_IOS_TEN_AND_MORE', true)
          }
        }  
           
    },
    scrollHight () {
      var newWindowHeight = Math.max(window.innerHeight);

      if(newWindowHeight-50 > this.baseWindowHeight) {
        // this.showBigMenu=true
        this.$store.commit('setShowBigMenu', true)
      } else {
        //  this.showBigMenu=false
        this.$store.commit('setShowBigMenu', false)
      }
     
    },
    // getDropdownData () {
    //   this.$store.dispatch('getCars')
    // }
    handleToTopButton () {
      var body = document.body,
      html = document.documentElement;
      var height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
      if (height > 3000 && document.documentElement.scrollTop > 2000 && this.setSortSimi && (this.estimatePage || this.sampleReport)) {
        this.showGoToTop = true
      } else {
        this.showGoToTop = false
      }
    },
    checkSession () {
      api.checkSession().then(res => {
        this.$store.commit('changeLoged', true)
        this.$store.commit('setUserName', res.data.name)
        this.$store.commit('setUserPrivilege', res.data.type)
        this.getUserData()
        console.log('gotov check session');
      }).catch(() => {
        localStorage.removeItem('sid')
        this.$store.commit('changeLoged', false)
        localStorage.removeItem('userName')
        this.$store.commit('setUserName', null)
        this.$store.commit('setUserPrivilege', 0)
      })
    },
    checkIe () {
      var ua = window.navigator.userAgent;
      var isIE = /MSIE|Trident/.test(ua);

      if ( isIE ) {
        this.isIe = true
      } else {
        this.isIe = false
      }

      // Safari 3.0+ "[object HTMLElementConstructor]" 
      var isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
      console.log('safary - '+isSafari)
      this.$store.dispatch('isSafariAction', isSafari)
      // alert('safary - '+isSafari);
    },
    getUserData() {
            let params = {
                sid : localStorage.getItem('sid')
            }
            api.getUserData(params).then(res => {
                this.profileInfo = res.data.data
                console.log(res.data.data)
                this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
             console.log(e)
            })
    },
    checkGate () {
      let dateNow = new Date
      if (localStorage.getItem("lastGateLogIn")) {
        let lastGateLogIn = new Date(localStorage.getItem("lastGateLogIn"))
        if (dateNow < lastGateLogIn) {
          // console.log('proso date');
          this.gate = false
        } else {
          // console.log('nije jos proso date');
          this.gate = true
        }
      }
    },
    gateLogIn () {
      let params = {
        username: this.email,
        password: this.password
      }
      api.gateLogIn(params).then(() => {
        let date = new Date
        date.setHours(date.getHours() + 1)
        date.setMinutes(date.getMinutes() + 30)
        localStorage.setItem("lastGateLogIn", date)
        this.gate = false
      }).catch(e => {
        console.log(e);
      })
    },
    resize () {
      if (window.innerWidth <= 500) {
      this.showMobileNav = true
      } else {
      this.showMobileNav = false
      }
    },
    more(e) {
      console.log(e)
      this.showMoreMenu = e
      this.closeMoreMenuVar = e
      this.showmodal(e)
    },
    more2(e){
      this.showmodal(e && this.showMobileNav) // only in mobile case stop scroll background in similar sort
    },
    showmodal(e) {
      let wheelOpt = this.supportsPassive ? { passive: false } : false;
      let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
      if(e){
        // call this to Disable
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
        window.addEventListener("mousedown", this.preventDefault, { passive:false });
        document.body.style.overflow = "hidden"
      }else{
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
        window.removeEventListener("mousedown", this.preventDefault, { passive:false });
        document.body.style.overflow = "initial"
      }
    },
    preventDefault(e) {
      //console.log(e)
      e.preventDefault()
    },
    preventDefaultForScrollKeys(e) {
      var keys = {37: 1, 38: 1, 39: 1, 40: 1}
      if (keys[e.keyCode]) {
           this.preventDefault(e)
           return false
      }
    },
    // closeMoreMenu () {
    //   this.closeMoreMenuVar = !this.closeMoreMenuVar
    //   console.log(this.closeMoreMenuVar);
    //   this.showMoreMenu = false
    // }
  },
  watch: {
    '$route': {
      handler: function() {
        //  console.log(this.$route.name)
         if (this.$route.name === 'sampleReport') {
           this.sampleReport = true
           this.estimatePage = false
         } else if(this.$route.name === 'estimate'){
            this.sampleReport = false
           this.estimatePage = true
         } else {
           this.sampleReport = false
           this.estimatePage = false
         }
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
<style lang='scss'>
// @font-face {
//   font-family: 'Gotham Pro';
//   font-style: normal;
//   font-weight: normal;
//   src: local('Gotham Pro'), url('./assets/fonts/GothaProReg.woff') format('woff');
// }
// @font-face {
//   font-family: 'Gotham Pro Bold';
//   font-style: normal;
//   font-weight: bold;
//   src: local('Gotham Pro Bold'), url('./assets/fonts/GothaProBol.woff') format('woff');
// }
// @font-face {
//   font-family: 'Gotham Pro Light';
//   font-style: normal;
//   font-weight: lighter;
//   src: local('Gotham Pro Light'), url('./assets/fonts/GothaProLig.woff') format('woff');
// }
// @font-face {
//   font-family: 'Gotham Pro Medium';
//   font-style: normal;
//   font-weight: lighter;
//   src: local('Gotham Pro Medium'), url('./assets/fonts/GothaProMed.woff') format('woff');
// }
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'DM Sans';
  // min-height: 700px;
  letter-spacing: 0.02em;

  input{
    letter-spacing: inherit;
  }
}

#app {
  height: calc(100vh - 100px);
  // width: 100vw;
  //  #header{
  //    z-index:1110;
  //  }
  .ie-wrapper {
    // width: 450px;
    margin: 0 auto;
    text-align: center;
    padding-top: 10%;

    div {
      text-align: center;
      margin-bottom: 50px;
      font-family: 'DM Sans';
      font-size: 20px;
      line-height: 30px;
      color: #062931;
      width: 100%;
    }
    img {
      margin: 0 auto;
      width: auto;
    }
  }
}
.gate {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;
  .inputs {
    border: 1px solid $main-color;
    padding: 20px;
    display: flex;
    flex-direction: column;
    margin-top: -20%;

    span {
      margin-bottom: 20px;
    }
    input {
      margin-bottom: 20px;
      // border: none;
      outline: none;
      background: #F8F8F9;
      border: 1px solid #DEE0E7;
      padding: 5px;
      border-radius: 5px;
    }
    .submit {
      background-color: $main-color;
      height: 50px;
      width: 140px;
      color: white;
      border-radius: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 24px;
      font-size: 15px;
      font-family: 'DM Sans';
      margin-top: 10px;
      cursor: pointer;
      transition-duration: .3s;
    }
    .submit:hover {
      background-color: #FF4B49;
    }
  }
}
.workaround {
  height: 100%;
  width: 100%;
  z-index: 1000;
  position: fixed;
  bottom: 0;
  left: 0;
  pointer-events: none;
}
@media only screen and (max-width: 500px) {
  #app {
  //  height: fit-content !important;
  }
}
</style>
