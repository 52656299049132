import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import vClickOutside from 'v-click-outside'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import VTooltip from 'v-tooltip'
import VueWaypoint from 'vue-waypoint'
import VueSocialSharing  from 'vue-social-sharing'
import VueGtag from "vue-gtag"
import VueFacebookPixel from 'vue-facebook-pixel'
import pixels from '@/pixels.js';
import Clipboard from 'v-clipboard'
import VueMeta from 'vue-meta'
import eventBus from '@/js/eventBus.js'

Vue.prototype.$pixel = pixels;

Vue.use(VueGtag, {
  config: { id: "UA-188413547-1" },
  includes: [
    { id: 'G-S8LXKHJ796' }
  ]
}, router);
/**
* Init facebook tracking pixel
* @param  {String} appId 
* @param  {object} [data={}] 
*/
Vue.use(VueFacebookPixel, { router, debug: true })

Vue.analytics.fbq.init('265139458332030',{
  em:''
})
//  Vue.analytics.fbq.event('ViewContent', {
//   content_name: ''
// })
//All component instances can call this.$analytics.fbq


Vue.use(VueWaypoint)
Vue.use(VueReCaptcha, { siteKey: '6LeBW9YUAAAAAAvurD0jpk0du13qr3mdNJ96sxVN' })
Vue.use(vClickOutside)
Vue.use(VTooltip)
// Vue.use(VueSocialSharing)
Vue.use(VueFacebookPixel)
Vue.use(Clipboard)
Vue.config.productionTip = false
Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  // ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
})
Vue.use(VueSocialSharing, {
  networks: {
    // fakeblock: 'https://fakeblock.com/share?url=@url&title=@title',
    messenger: 'fb-messenger://share/?link=@u'
  }
})


// custom directive
// we hide the mobile toolbar when the mobile keyboard appears and that is when the field is in focus


Vue.directive('hideMoblieNav', {

  bind(el /* ,binding, vnode  */) {
    el.addEventListener('focus', (e)=>{
      console.log(e.type)
      // the input is in focus so this event will hide mobile toolbar
      eventBus.$emit('closedrop', false)
    })

    el.addEventListener('blur',(e)=>{
      console.log(e.type)
      // the input is not focus so this event will show mobile toolbar
      eventBus.$emit('closedrop', true)
    })

  }
})



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
