<template>
  <div class="estimate-wrapper-background">
    <!-- <div class="badges">
      <wrappBadgesEstim :noEdit='true' />
    </div> -->
    <div v-if="newShareTop" class="sample-report-wrapper">
      <div v-for="(i, index) in 20" :key="index" :style="{ 'animation-delay': '-' + (index * 2) + 's' }" class="sample-report">
        sample report
      </div>
    </div>
    <div class="estimate-wrapper" :style="altMobileStyle">

      <div class="left-wrapper2" :style="{top: altTop + 'px'}">
        <sidebar :activeArr="activeArr"/>
      </div>

      <div class="right-wrapper">
         <price :price="getIdealPrice" @unlock="unlock()"   id="estimate"/>

        <div class="main-wrapper" id="debug">
          <div :class="!visibility ? 'h3-wrapper visibilityMain' : 'h3-wrapper'" id="trans-trigg">
            <h3>Transaction trigger</h3>
            <div>
              <hideShow @visibility="changeVisibility"/>
              <div @click="transactionInfo = true" v-click-outside="closeTransactionInfo" class="">
                <!-- <img :src="require('@/assets/images/info.png')" alt=""> -->
                <tooltip
                  :isOpen="transactionInfo"
                  :msg="'Our tool constantly analyses the market and forms a fair price range for the searched vehicle. By moving the slider on the diagram you can find three Transaction triggers:<br><br>Above the market  - shows where the price of the vehicle is higher than the fair price, increasing the time needed to sell the vehicle.<br><br>Below the market  - shows where the price of the vehicle is lower than the fair price, decreasing the time the vehicle spends on the market.<br><br>Great price badge indicates the price that is significantly below fair price, enabling you to sell your car faster.<br><br>Please have in mind that certain cars are in a better condition than others and come with additional equipment which can affect asking price.'"
                />
               </div>
            </div>
          </div>
          <div  v-if="visibility" class="slider-parent-wrapper">
            <slider :horizontal="horizontal" :price="price" @changePrice="price = $event" :min="min" :max="max" :above="above" :below="below" :greatPrice="greatPrice" :badPrice="badPrice" />
          </div>
            <div  v-if="visibility" class="instruction">
                <img :src="require('@/assets/images/sliderMove.svg')" alt="slider move icon">
                <span>Move the slider to analyze the price</span>
            </div>
            <div  v-if="visibility" class="generalMsg">
                <p  v-html="getSliderMsg"> </p>
            </div>
            <div  v-if="visibility" class="analytics">
                 <div class="left1">
                     <span class="analGreen">Great price</span> <br /> <span>&lt;</span> <span v-html="getNumber(greatPrice)"></span>
                </div><!-- end left1-->
                <div  class="left">
                    <span class="analTxt">Current market price</span> <br><span v-html="getNumber(min)"></span> - <span v-html="getNumber(max)"></span>
                    <br />
                    <span class="analTxt">Fair Price</span> <br /><span v-html="getNumber(below)"></span> - <span v-html="getNumber(above)"></span><br>
                </div><!-- end left-->
                <div class="right">
                   <span class="analTxt">Below Market</span> <br /><span>&lt; </span><span v-html="getNumber(below)"></span><br>
                   <span class="analTxt">Above Market</span> <br /><span>&gt; </span><span v-html="getNumber(above)"></span><br>
                </div><!-- end right-->
            </div><!-- end analytics-->
        </div><!-- end main-wrapper-->

        <div  class="report-wrapper2">
            <attractivness  :score="score" id="model-att"/>
            <avtimeMarket :timeMarket="timeMarket"/>
        </div>

        <wrappMarketValue v-if="getIdealPrice" :valueMarket="setmarket(true)" :i="1" id="market-val"/>
        <pie-chart :regions="regions" />
        <myChart />
        <advertised-price-chart v-if="price" :price="price" :advertisedPrice="advertisedPrice" id="advertised-price-wrapper"/>
        <wrappMarketValue v-if="getIdealPrice" :valueMarket="setmarket(false)" :i="0" id="seller-type"/>
        <expMarket
          :interestedBuyersPr ="interestedBuyersPr"
          :insights="insights"
          :looksBuyersPr ='looksBuyersPr'
          :looksBuyersN ='looksBuyersN'
          :carOfModelOnMarket ='carOfModelOnMarket'
          :modelValue ='modelValue'
          :makeValue ='makeValue'
          :bodyValue ='bodyValue'
          id="insights"
        />
      <similar-cars :hardCode="similarCars" :title="'Recently on the market'" :isSample="true" id="recent"/>
   

    </div><!-- right-wrapper-->
     <newShare v-if="newShareTop" :altTop="altTop" />
    </div>
    <!-- <newShare v-if="!newShareTop" :class="{'is-scrolled': navBgHelper}"/> -->
    <div v-if="!newShareTop" class="mobile-top-nav">
      <div class="back" @click="backToHome">
        <img :src="require('@/assets/images/arrowLeft.png')" alt="">
        <span>Back</span>
      </div>
      <div class="sample-report-wrapper">
        <div v-for="(i, index) in 20" :key="index" :style="{ 'animation-delay': '-' + (index * 2) + 's' }" class="sample-report">
          sample report
        </div>
    </div>
    </div>
    <footerDown v-if="!showMobileNav" />
    <div v-else style="height:60px" ></div>
  </div>
</template>

<script>
import Slider from '@/components/slider/sliderNew.vue'
import PieChart from '@/components/pieChart.vue'
import SimilarCars from '@/components/similarCars.vue'
import Tooltip from '@/components/tooltip2.vue'
import { mapGetters } from 'vuex'
// import wrappBadgesEstim from '../components/badges/wrappBadgesEstimFloat.vue'
import footerDown from '../components/footer/footerDown'
import attractivness from '../components/attractivnesMarket/attractivness.vue'
import avtimeMarket from '../components/attractivnesMarket/avtimeMarket.vue'
import price from '../components/attractivnesMarket/newPrice.vue'
import wrappMarketValue from '../components/writeMarketValue/wrappMarketVaule.vue'
import expMarket from '../components/writeMarketValue/expMarket2.vue'
import myChart from '../components/myChart.vue'
import AdvertisedPriceChart from '@/components/advertisedPriceChart.vue'
import sidebar from '@/components/sidebar.vue'
import newShare from '@/components/shareComponents/newShare.vue'
import hideShow from '@/components/HideShow.vue'

export default {
  name: 'estimatePage',
  components: {
    // wrappBadgesEstim,
    Slider,
    footerDown,
    PieChart,
    attractivness,
    avtimeMarket,
    price,
    SimilarCars,
    wrappMarketValue,
    expMarket,
    Tooltip,
    myChart,
    AdvertisedPriceChart,
    sidebar,
    newShare,
    hideShow
    
  },
   metaInfo () {
        return {
        title: 'Carvisor',
        titleTemplate: '%s - ' + this.title ,
        meta:[
           {name: 'robots', content: 'index,follow'} ,
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.description
                },
                {
                     vmid: 'og:description',
                    property: 'og:description',
                    content:  this.description
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.title 
                }
            ]
        }
    },
  data () {
    return {
      title: 'Sample report',
      description: 'Sample report, Carvisor estimate',
      getIdealPrice: 8635,  // posle da se promeni
      regions: [
        {reg_name: "Auckland", value: 201, percentage: 56.5, reg_id: 74},
        {reg_name: "Wellington", value: 38, percentage: 10.7, reg_id: 75},
        {reg_name: "Canterbury", value: 31, percentage: 10.4, reg_id: 77},
        {reg_name: "Waikato", value: 17, percentage: 4.8, reg_id: 78},
        {reg_name: "Otago", value: 12, percentage: 3.4, reg_id: 81},
        {reg_name: "Other Regions", value: 41, reg_id: 0, percentage: 14.2}
      ],
      advertisedPrice: [
        {value: 32.9, min: 7000, max: 10000},
        {value: 48, min: 10000, max: 13000},
        {value: 14.6, min: 13000, max: 16000},
        {value: 3.9, min: 16000, max: 19000},
        {value: 0.3, min: 19000, max: 22000},
        {value: 0.3, min: 22000, max: 25000}
      ],
      similarCars: [
        {bod_name: "Hatchback", fue_name: "Hybrid", mak_name: "Toyota", mod_name: "Aqua", reg_name: "Auckland", tma_badge: 0, tma_km: 127800, tma_price: 8495, tra_name: "Automatic", year: 2014, ens_shortsize:1.5, market_comparison: 0 },
        {bod_name: "Hatchback", fue_name: "Hybrid", mak_name: "Toyota", mod_name: "Aqua", reg_name: "Auckland", tma_badge: 0, tma_km: 127947, tma_price: 8440, tra_name: "Automatic", year: 2014, ens_shortsize:1.5, market_comparison: 0 },
        {bod_name: "Hatchback", fue_name: "Hybrid", mak_name: "Toyota", mod_name: "Aqua", reg_name: "Auckland", tma_badge: 1, tma_km: 129227, tma_price: 7995, tra_name: "Automatic", year: 2014, ens_shortsize:1.5, market_comparison: 0 },
        {bod_name: "Hatchback", fue_name: "Hybrid", mak_name: "Toyota", mod_name: "Aqua", reg_name: "Canterbury", tma_badge: 0, tma_km: 126600, tma_price: 9000, tra_name: "Automatic", year: 2014, ens_shortsize:1.5, market_comparison: 0 },
        {bod_name: "Hatchback", fue_name: "Hybrid", mak_name: "Toyota", mod_name: "Aqua", reg_name: "Otago", tma_badge: 0, tma_km: 126285, tma_price: 8990, tra_name: "Automatic", year: 2014, ens_shortsize:1.5, market_comparison: 0 },
        {bod_name: "Hatchback", fue_name: "Hybrid", mak_name: "Toyota", mod_name: "Aqua", reg_name: "Wellington", tma_badge: 0, tma_km: 124000, tma_price: 8750, tra_name: "Automatic", year: 2014, ens_shortsize:1.5, market_comparison: 0 },
        {bod_name: "Hatchback", fue_name: "Hybrid", mak_name: "Toyota", mod_name: "Aqua", reg_name: "Auckland", tma_badge: 0, tma_km: 122504, tma_price: 8690, tra_name: "Automatic", year: 2014, ens_shortsize:1.5, market_comparison: 0 },
        {bod_name: "Hatchback", fue_name: "Hybrid", mak_name: "Toyota", mod_name: "Aqua", reg_name: "Auckland", tma_badge: 1, tma_km: 130000, tma_price: 7299, tra_name: "Automatic", year: 2014, ens_shortsize:1.5, market_comparison: 0 },
        {bod_name: "Hatchback", fue_name: "Hybrid", mak_name: "Toyota", mod_name: "Aqua", reg_name: "Auckland", tma_badge: 1, tma_km: 125000, tma_price: 7999, tra_name: "Automatic", year: 2014, ens_shortsize:1.5, market_comparison: 0 },
        {bod_name: "Hatchback", fue_name: "Hybrid", mak_name: "Toyota", mod_name: "Aqua", reg_name: "Auckland", tma_badge: 0, tma_km: 121357, tma_price: 8500, tra_name: "Automatic", year: 2014, ens_shortsize:1.5, market_comparison: 0 }
      ],
      transactionInfo: false,
      reportInfo: false,
      showStats: true,
      price: null,
      min: null,
      max: null,
      above: null,
      below: null,
      greatPrice: null,
      badPrice: null,
      horizontal: true,
      msg: '',
      score: 2.9,
      timeMarket:53,
      interestedBuyersPr: 5,// promeniti u procentima 1
      looksBuyersPr: 5,  // 3
      looksBuyersN:3844, // promeniti u broju osoba zainteresovani 2
      carOfModelOnMarket:356, //promeniti broj koliko trenutno ima odabraanog modela na trzistu 4
      insights:{
        interestedBuyersPr:'',
        looksBuyersPr:'',
        looksBuyersN:'X',
        carOfModelOnMarket:'X',
      },
      // for expMarket komponrntu
      modelValue:'Aqua', // posle da se promeni
      makeValue:'Toyota', // posle da se promeni
      bodyValue:'Hatchback', // posle da se promeni
      privatePrice: 8060,
      dealerPrice: 8965,
      showMobileNav:false,
      newShareTop: true,
      visibility: true,
      // 
      lastScrollTop: 0,
      value2: 5,// 30,
      value2Mob:20,
      mobileDevice:false,
      navBgHelper: false,
      bgHelper: false,
      activeArr: [],
      helperArr: [
        'estimate',
        'trans-trigg',
        'model-att',
        'market-val',
        'pie-chart-wrapper',
        'chart',
        'advertised-price-wrapper',
        'seller-type',
        'insights',
        'recent'
      ],
      isLevel: false,
      altTop: 60
    }
  },
  created() {
    window.addEventListener('scroll', this.moveBackground)
    window.addEventListener("resize", this.handleResize);
    this.handleResize()
    this.setUpPrices()
  },
  mounted () {
    this.setUpPrices()
     this.$analytics.fbq.event('PageView', {
        content_name: 'Sample Report'
      })
    window.scrollTo({top: 0, behavior: 'smooth'});
  },
  methods: {
    closeTransactionInfo (e) {
      if (this.hasParentWithMatchingSelector(e.target, '.tooltip')) {
        return
      }
      this.transactionInfo = false
    },
    closeReportInfo (e) {
      if (this.hasParentWithMatchingSelector(e.target, '.tooltip')) {
        return
      }
      this.reportInfo = false
    },
    hasParentWithMatchingSelector (target, selector) {
      return [...document.querySelectorAll(selector)].some(el =>
        el !== target && el.contains(target)
      )
    },
    setUpPrices () {
      console.log(JSON.parse(JSON.stringify(this.getIdealPrice)));

      this.price = parseInt(this.getIdealPrice)
      this.above = parseInt(this.price * 1.1)
      this.below = parseInt(this.price * 0.95)
      this.greatPrice = parseInt(this.price * 0.9)
      this.badPrice = parseInt(this.price * 1.15)
      this.min = parseInt(this.price * 0.75)
      this.max = parseInt(this.price * 1.25)
    },
    getNumber (num) {
      num = Math.floor(num/5)*5
      let res = '$&thinsp;'
      res += num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&thinsp;');
      return res
    },
    restartBadge () {
      this.$router.push('/priceSteps')
    },
    handleResize () {
       if (window.innerWidth <= 500) {
          this.horizontal = false
          this.showMobileNav = true
          this.newShareTop = false
      } else  if (window.innerWidth < 768) {
        this.horizontal = false
        this.showMobileNav = false
        this.newShareTop = true
      } else {
        this.horizontal = true
        this.showMobileNav = false
        this.newShareTop = true
      }
    },
    sampleReaport () {
      console.log('sampleReaport')
    },
    setmarket (x) {
      let obj= {}
      if(x) {
        //obj['Private']= this.getNumber(this.privatePrice)
       // obj['Dealer']= this.getNumber(this.dealerPrice)    // ispraviti cenu za dilera
        obj['Private']=  this.privatePrice
        obj['Dealer']=  this.dealerPrice

        // obj['i']= 1
       } else {
          obj['Private']= 12   //  ISPRAVITI ZA POSTOTKE KADA SE DOBIJE STA SE TACNO TRAZI
          obj['Dealer']= 88   //
          // obj['i']= 0
       }
       console.log(obj['Private']+' -- PRIVATEEEEEE')
      return obj
    },
     helpFunction (x) {
       if(x == null )  return null
        // else if (x < 100 ) return this.getNumber(null)
        else if (x < 100 ) return null
        else return this.getNumber(x)
    },
    changeVisibility(e){
      this.visibility=e
    },
    moveBackground() {
      // does nothing atm
            let page = document.getElementsByClassName("estimate-wrapper-background")[0]
            
            page.style.backgroundPosition = 50 +"%" + this.value2+ "%" 
    
            var st = window.pageYOffset || document.documentElement.scrollTop
            if (st <= 5) this.value2=5
            if((st >= document.documentElement.scrollHeight/4 -50)  &&  (st <= document.documentElement.scrollHeight/4 +50) ){
              this.value2=10
            }
            if((st >= document.documentElement.scrollHeight/2 -50)  &&  (st <= document.documentElement.scrollHeight/2 +50) ){
              // this.value2=20
              this.value2=13
            }
            //  if (st >= document.documentElement.scrollHeight-250) this.value2=30
// console.log("this.value2")
// console.log(this.value2)

            if (st > this.lastScrollTop){
                // downscroll code
                this.value2 += 0.15  
                //  if(!this.getIsSafari)
                 page.style.backgroundPosition = 50 +"%" + this.value2+ "%" 
              //  else 
              //   page.style.backgroundPositionY= this.value2+ "%" 
            } else {
                // upscroll code
                this.value2 -= 0.15
                //  if(!this.getIsSafari)
                  page.style.backgroundPosition = 50 +"%" + this.value2+ "%" 
                //  else 
                //  page.style.backgroundPositionY= this.value2+ "%" 
            }
            this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
                        
          this.calcNavBg();
          this.estimateBgHelper();
          this.handleScroll();
         },
      calcNavBg () {
      if (window.scrollY > 0) {
        if(!this.navBgHelper) {
          this.navBgHelper = true
        }
      } else {
        if(this.navBgHelper){
          this.navBgHelper = false
        }
      }
    },
    estimateBgHelper () {
      let el = document.getElementById("debug")
      if(el.getBoundingClientRect().top + 10 <= 0) {
        if (!this.bgHelper) {
          this.bgHelper = true
        }
      } else {
        if (this.bgHelper) {
          this.bgHelper = false
        }
      }
    },
    handleScroll () {
      let oldArr = this.activeArr;
      let newArr = [];
      for(let id in this.helperArr) {
        let rect = document.getElementById(this.helperArr[id]).getBoundingClientRect();
        let res = rect.top >= 0 && rect.bottom - 50 <= (window.innerHeight || document.documentElement.clientHeight) 
        newArr.push( res )
      }
      let isSame = true;
      for(let i = 0; i < newArr.length; i++){
        if(newArr[i] !== oldArr[i]){
          isSame = false;
        }
      }
      if (!isSame) {
        this.activeArr = newArr;
      }
      if (document.querySelector('.newPrice').getBoundingClientRect().top <= 60) {
        this.isLevel = true;
        this.calcTop();
      } else {
        this.isLevel = false;
        this.calcTop();
      }
    },
    calcTop () {
      if(this.isLevel) {
        if (document.querySelector('.newPrice').getBoundingClientRect().top >= 0) {
          this.altTop = 130 + (60 - document.querySelector('.newPrice').getBoundingClientRect().top)
        } else {
          this.altTop = 182 + Math.abs(document.querySelector('.newPrice').getBoundingClientRect().top)
        }
      } else {
        if (this.altTop !== 60) {
          this.altTop = 60;
        }
      }
    },
    backToHome () {
      this.$router.push('/')
    }
  },
  computed: {
        ...mapGetters(['getIsSafari']),
    getSliderMsg () {
      if (this.price < this.below) {
        return `This price is <b>${this.getNumber(this.below - this.price)}</b> bellow fair price.`
      } else if (this.price >= this.below && this.price <= this.above) {
        return `This is the fair market price.`
      } else {
        return `This price is <b>${this.getNumber(this.price - this.above)}</b> above fair price.`
      }
    },
    altMobileStyle () {
      if(this.bgHelper && window.innerWidth <= 550) {
        return {
          backgroundColor: '#eeeff3'
        }
      } else {
        return {}
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener('scroll', this.moveBackground)
  },
  watch: {
    price: function () {
      this.getSliderMsg
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/statsOverlay.scss';
.estimate-wrapper-background {
  // transparent navbar
  margin-top: -130px;
  padding-top: 49px;
  // contain: paint;
  overflow-x: hidden;

    // background-color: #F8F8F9;
    // background-image: linear-gradient( #467F8F, #EEEFF3);
    // background: linear-gradient(180deg, rgba(255, 255, 255, 0) 36.84%, #FFFFFF 100%), linear-gradient(270deg, #FFFFFF 0.03%, rgba(255, 255, 255, 0) 16.69%), linear-gradient(90deg, #FFFFFF 0.05%, rgba(255, 255, 255, 0) 16.85%), #EEEFF3;// min-height: calc(100vh - 80px);
    // background:linear-gradient(#467F8F 0%, #467f8f86 13%, #EEEFF3 25%);
    // width: 100%;
    // position: relative;

  //  background-color: #ffffff;
    background: linear-gradient(#467f8f 6.7%, #467f8fc9,#eeeff3ea, #EEEFF3);
      // background: linear-gradient(#467f8f 6.7%, #467f8fc9);

// style with img
     background-image: url('../assets/images/Background.svg');
    // background-image: url('../assets/images/appSmall.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 5%;


    .sample-report-wrapper {
    background: $main-color;
    height: 40px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow-x: hidden;
    z-index: 999;
    position: fixed !important;
    // align-self: flex-start;
    top: calc(100vh - 40px);
    // top: 70px;


    @keyframes example {
      from {right: -200px;}
      to {right: 2760px;}
    }

    .sample-report {
      width: auto;
      // font-family: 'Gotham Pro Bold';
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #E7ECED;
      cursor: default;
      position: absolute;
      animation-duration: 40s;
      animation-timing-function: linear;
      animation-name: example;
      animation-iteration-count: infinite;
      white-space: nowrap;
      animation-fill-mode: forwards;
      right: -200px;
    }
  }
  .view-similar-weapper {
    min-height: calc(100vh - 80px);
    background: white;

    .view-similar-weapper-inner {
      min-height: calc(100vh - 150px);

      .view-similar {
        .view-similar-message {
          min-height: 430px;
          height: fit-content;
          margin: 0 auto;
          max-width: 582px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          box-sizing: border-box;

          span {
            // font-size: 20px;
            // line-height: 30px;
            // text-align: center;
            // color: #062931;
            // margin-bottom: 35px;
            margin-bottom: 53px;
            margin-top: -30px;

            font-style: normal;
            font-weight: bold;
            font-size: 22px;
            line-height: 30px;
            /* or 136% */

            text-align: center;

            /* black */

            color: #062931;

          }
          .btn {
            height: 50px;
            width: 170px;
            // line-height: 52px;
            // color: white;
            // font-family: 'Gotham Pro Medium';
            // text-align: center;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            /* identical to box height, or 133% */

            display: flex;
            justify-content: center;
            align-items: center;
            // text-align: center;
            letter-spacing: 0.01em;
            color: #FFFFFF;

            background: $main-color;
            border-radius: 52px;
            cursor: pointer;
            transition-duration: .3s;
          }
          .btn:hover {
            background: $btn-hover;
          }
        }
        .similar-wrapper {
          max-width: 886px;
          padding: 0 20px;
          box-sizing: border-box;
          margin: 0 auto;
          // border-top: 1px solid #C3C6D0;
          margin-bottom: 60px;

          .section-wrapper {
            padding-left: 0px;
            padding-right: 0px;
            min-height: 0px;
          }
        }
      }

    }
  }

  
 .h3-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin: 0 20px;
    margin-bottom: 30px;
    

    h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #5A7E87;
        margin: 0px;

    }
    div{
      display:flex;
      
      align-items: initial;
      justify-content: flex-end;
    }
    img {
      cursor: pointer;
    }
  }
  .h3-wrapper.visibilityMain {
    padding-top: 17px !important;
    margin-bottom: 15px !important;
  }

  .lower-sections-wrapper {
    max-width: 1275px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
  }
  .estimate-wrapper {
    max-width: 1275px;
    margin: 0 auto;
    // padding: 20px 10px 0px 10px;
    padding: 120px 10px 80px 10px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    margin-bottom: 10px;
    position: relative;

    .left-wrapper2,
    .right-wrapper {
      border-radius: 5px;
      box-sizing: border-box;
    }

    .left-wrapper2{
      margin-right: 10px;
      max-width: 311px;
      overflow: hidden;
      width: 100%;
      padding: 0;
      height: 625px;
      display:flex;
      flex-direction: column;
      justify-content: center;

      position: -webkit-sticky;
      position: sticky;
      top: 60px;
    }
    .right-wrapper {
      max-width: 846px;
      width: 100%;
      max-height: fit-content;
       height: 100%;
        box-sizing: border-box;
      .report-wrapper2 {
          display: flex;
          margin-top: 10px;  
          box-sizing:border-box;
          width:100%;
          display: flex;
          flex-direction: row;
          // position: relative;
      }
      .main-wrapper {
        background: white;
        min-height: 735px;
        display: block;
        box-sizing: border-box;
        position: relative;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 5px 5px;
        // add this for gap in safari
          position: relative;
          height: fit-content;
          min-height: fit-content;

         .slider-parent-wrapper {
          margin-bottom: 100px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .instruction{
            display:flex;
            justify-content: flex-end;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;

            color: #B7B9C0;
            span{
                margin-left: 20px;
                margin-right: 20px;

            }
        }
         .generalMsg{
            background: #FEF5EB;
            box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.05);
            border-radius: 0px;
            height: 59px;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #F18700;
            margin-top: 17px;
            margin-bottom: 42px;
           
        }

        @media only screen and (max-width: 450px){
          .slider-parent-wrapper {
            margin-bottom: 52px;
            padding: 0px;
          }
          // .instruction {
          //   margin-bottom: 52px;
          // }
        }
        .analytics {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

            font-family:'DM Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            /* or 133% */

            letter-spacing: 0.01em;

            /* black */
            color: #062931;
            margin-left:41px;
            margin-bottom: 60px;
            margin-bottom: 10px;
            padding-bottom: 50px;

                        .left1 {
                margin-right: 81px;
                width: fit-content;
                .analGreen{
                    margin-bottom: 0px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 25px;
                    /* or 156% */

                    letter-spacing: 0.01em;

                    /* blue primary text */

                    color: #06DBF9;

                }

            }
            .left{
                margin-right: 50px;
                width: 204px;

            }
            .right, .left, .left1{
                span{
                    display: inline-block;
                    margin-bottom: 10px;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 17px;
                    line-height: 25px;
                    /* or 156% */

                    letter-spacing: 0.01em;
                } 

           .analTxt{
                margin-bottom: 0px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 0.01em;

                color: #9498A0;
           }
            
          }
          span {
            font-weight: 700;
          }
        }
      }
    }
  }
  .mobile-top-nav {
    width: 100%;
    height: 90px;
    position: fixed;
    top: 0;
    background: #fff;
    z-index: 999;
    .back {
      width: 70px;
      position: relative;
      top: 14px;
      left: 15px;
      span {
        margin-left: 5px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 25px;

        color: #787883;
      }
    }
  }

}
.msg{
    height: 24px;
    margin: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: $main-color !important;
}
// .colorBlackBlure{
//   position: fixed !important;
//   top: 0;
//   left: 0;
//   background: #062931f1;
//   // opacity: 0.94;
//   width: 100vw;
//   height: 100vh;
//   z-index:1001;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   overflow: auto !important;
// }
@media only screen and (max-width: 1024px){
  .estimate-wrapper-background {
    // .lower-sections-wrapper {
    //   padding: 0px;
    // }
    .estimate-wrapper {
      width: 100%;
      padding: 0px;
      .left-wrapper,
      .left-wrapper2 {
        display: none;
      }
      .line {
        display: none;
      }
      .right-wrapper {
        // max-width: 100%;
        max-width: calc(100% - 70px); // 70px side newShare
        height: 100%;
        box-sizing: border-box;

        .main-wrapper {
          // display: block;
          // margin-bottom: 10px;
          // padding: 20px;
          // border-radius: 0px;
          // position: relative;
          display: block;
          margin-bottom: 10px;
          padding-bottom: 1px;
          //   padding: 20px;
          border-radius: 0px;
          position: relative;
          height: fit-content;
          min-height: fit-content;
           .h3-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding-top: 20px;
              margin: 0 20px;
              // margin-right: 0;
              // padding-right: 20px;
              margin-bottom: 20px;
              
              div{
                display:flex;
                // width: 94px;
                align-items: center;
                justify-content:space-between !important;
                div{
                  width:fit-content
                }
              }
            }
        }
        
      //      .price-wrapper{
      //       box-sizing: border-box;
      //       // height:418px;
      //       min-height: 382px ;
      //       position: relative;
      //       padding-top:25px;
      //       padding-bottom: 33px;
      //       .br{
      //         display:none;
      //       }
      //       .txt{
      //         box-sizing:border-box;
      //         // min-height:20%;
      //         // padding-top: 25px;
      //         p{
      //           margin: 0 !important;
      //           // font-style: normal;
      //           // font-weight: bold;
      //           // font-size: 18px;
      //           // line-height: 26px;
      //           // color: #082633;
      //           font-family:'DM Sans';
      //           font-style: normal;
      //           font-weight: 500;
      //           font-size: 22px;
      //           line-height: 30px;
      //           /* or 136% */


      //           /* black */

      //           color: #062931;

      //         }
      //       }
      //       // .price{
      //       //   height: 268px;
      //       //   height: 268px;
      //       //   // padding-top:109px;
      //       //   display:flex;
      //       //   justify-content: center;
      //       //   align-items: center;
      //       //   .p{
      //       //     // font-family: 'Gotham Pro Bold';
      //       //     // font-style: normal;
      //       //     // font-weight: bolder;
      //       //     // font-size: 48px;
      //       //     //line-height: 48px;
      //       //     /* identical to box height, or 109% */
      //       //     // margin-bottom: 40px;

      //       //     font-family:'DM Sans';
      //       //     font-style: normal;
      //       //     font-weight: bold;
      //       //     font-size: 51px;
      //       //     line-height: 55px;
      //       //     /* or 108% */

      //       //     display: flex;
      //       //     align-items: center;
      //       //     text-align: center;

      //       //     /* black */

      //       //     color: #062931;
      //       //     text-align: center;
      //       //     color: #082633;
      //       //   }
      //       // }
      // }
    //  .line {
    //          display:block !important;
    //           box-sizing: border-box;
    //           width:100%;
    //           height: 3px;
    //           position: absolute;
    //           left: 0px;
    //           bottom: 0px;
    //           background: #103D49;
    //   }
      .report-wrapper2{
       // height:220px;
        box-sizing:border-box;
        width:100%;
        display: flex;
        flex-direction: column;
        div{
          box-sizing: border-box;
          width:49.25%;
          display:block;
        }
        div:first-of-type{
          margin-right: 1.5%;
        }

      }
      // .report-wrapper{
      //   height:454px;
      //   box-sizing:border-box;
      //   width:100%;
      //   .txt{
      //     box-sizing:border-box;
      //     min-height:20%;
      //     padding-top: 25px;
      //     p{
      //       margin: 0 !important;
      //       font-style: normal;
      //       font-weight: 500;
      //       font-size: 18px;
      //       line-height: 26px;
      //       color: #082633;
      //     }
      //   }
      //   .secPart{
      //     width: 100%;
      //     height: 80%;
      //     display:flex;
      //     flex-direction:column;
      //     justify-content: space-around;
      //     align-items: flex-start;
      //     p{
      //      font-style: normal;
      //       font-weight: normal;
      //       font-size: 15px;
      //       line-height: 24px;
      //       color: #4C4C52;
      //     }
      //     .roundInput{
      //       box-sizing:border-box;
      //       width:100% !important;
      //       // padding-bottom: 20px;
      //      @import '@/assets/css/inputButton.scss';
      //     }
      //      .sample{
      //        font-style: normal;
      //         font-weight: 500;
      //         font-size: 13px;
      //         line-height: 24px;
      //         letter-spacing: 0.02em;
      //          color: #5A7E87 !important;
      //      }
      //      .sample:hover{
      //        cursor: pointer;
      //      }
      //   }
      // }
      }
    }
  }
}
@media only screen and (max-width: 768px){
   .estimate-wrapper-background {
     .estimate-wrapper {
       .right-wrapper{
         .price-wrapper{
            position: relative !important;
          .line{
              display:block !important;
              box-sizing: border-box;
              width:100%;
              height: 3px;
              position: absolute;
              left: 0px;
              bottom: 0px;
              background: #103D49;
          }
       }
          .report-wrapper{
            .secPart{
               .roundInput{
                 width:100% !important;
               }
            }
          }
           .report-wrapper2{
       // height:220px;
          box-sizing:border-box;
          width:100%;
          display: block;
          div{
            box-sizing: border-box;
            width:100%;
            display:block;
          }
          div:first-of-type{
            margin-right: 0 !important;
          }

        }
       }
     }
      .view-similar {
        .view-similar-message {
          box-sizing: border-box;
          
         min-height: 396px !important;
         span{
          //  margin-top: 0px!important;
           padding-top: 63px;
           margin-bottom:42px!important ;
         }
        }
         
      }
   }

}
@media only screen and (max-width: 550px){
  .estimate-wrapper-background {
    // padding-top: 165px;
    padding-top: 222px;
    background-position: top !important;
     .estimate-wrapper {
      .right-wrapper {
          .price-wrapper{
                padding-left: 16px;
                padding-right: 16px;
                padding-top: 33px;
                .br{
                  display:initial;
                }

          }
          .main-wrapper{
            //  padding-left: 16px;
            //  padding-right: 16px;
          }
      }
     }
  }
  .is-scrolled {
    background-color: rgba(252, 252, 252, 1);
    border-bottom: 1px solid #dee0e5;
  }
 }

@media only screen and (max-width: 500px){
  .estimate-wrapper-background {
    .sample-report-wrapper{
      top: calc(100vh - 101px);
    }
     .estimate-wrapper {
       margin-bottom: 0px ; // newShare 
      .right-wrapper {
           max-width: 100%;
          // .price-wrapper{
          //       padding-left: 16px;
          //       padding-right: 16px;
          //       min-height: 190px;
          //       .br{
          //         display:initial;
          //       }
          //       .price{
          //         height: 131px;
          //         .p{
          //           font-size: 40px;
          //           line-height: 48px;
          //         }
          //       }

          // }
          .main-wrapper{
            //  padding-left: 16px;
            //  padding-right: 16px;
          }
      }
     }
  }
 }


</style>

<style lang="scss" scoped>


// .estimate-wrapper-background {
//   background-color: #F8F8F9;
//   min-height: calc(100vh - 80px);
//   width: 100%;
//   position: relative;
//   // .badges {
//   //   width: 100%;
//   //   min-height: 80px;
//   //   background: $green-color;
//   // }
//   .h3-wrapper {
//     display: flex;
//     flex-direction: row;
//     align-items: center;
//     justify-content: space-between;
//     margin-bottom: 30px;

//     h3 {
//       font-size: 18px;
//       line-height: 28px;
//       letter-spacing: 0.01em;
//       color: $text-darker;
//       margin: 0px;
//     }
//     img {
//       cursor: pointer;
//     }
//   }

//   .lower-sections-wrapper {
//     max-width: 1275px;
//     margin: 0 auto;
//     box-sizing: border-box;
//     padding: 0 10px;
//     display: flex;
//     flex-direction: row;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     align-items: flex-start;
//   }
//   .estimate-wrapper {
//     max-width: 1275px;
//     margin: 0 auto;
//     // margin-top: 20px;
//     /* padding-top: 20px;
//     padding-left: 10px;
//     padding-right: 10px;
//     padding-bottom: 114px; */
//     padding: 20px 10px 0px 10px;
//     display: flex;
//     flex-direction: row;
//     box-sizing: border-box;
//     margin-bottom: 10px;

//     .left-wrapper,
//     .left-wrapper2,
//     .right-wrapper {
//       border-radius: 5px;
//       box-sizing: border-box;
//     }

//     .left-wrapper2{
//       margin-right: 10px;
//       max-width: 418px;
//       width: 100%;
//       // border: 1.4px solid #929498;
//       background-color: $background-color;
//       padding: 0;
//       height: 696px;
//       display:flex;
//       flex-direction: column;
//       justify-content: center;
//     }
//     .left-wrapper {
//       margin-right: 10px;
//       max-width: 418px;
//       width: 100%;
//       border: 1.4px solid #929498;
//       box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.14);
//       background: white;
//       padding: 0 20px;
//       height: 696px;
//       display:flex;
//       flex-direction: column;
//       justify-content: center;
//       .price-wrapper{
//         box-sizing: border-box;
//         height:49%;
//         .txt{
//           box-sizing:border-box;
//           min-height:20%;
//           padding-top: 25px;
//           p{
//             margin: 0 !important;
//             font-style: normal;
//             font-weight: bold;
//             font-size: 18px;
//             line-height: 26px;
//             color: #082633;
//           }
//         }
//         .price{
//           height: 75%;
//           display:flex;
//           justify-content: center;
//           align-items: center;
//           .p{
//             font-style: normal;
//             //font-weight: bold;
//             font-weight: bolder;
//             font-size: 44px;
//             line-height: 48px;
//             /* identical to box height, or 109% */

//             text-align: center;
//             color: #082633;
//           }
//         }
//       }
//       .line{
//         border-bottom: 1px solid #92949B;
//       }
//       .report-wrapper{
//         height:50%;
//         box-sizing:border-box;
//         width:100%;

//         .txt{
//           box-sizing:border-box;
//           min-height:20%;
//           padding-top: 25px;
//           p{
//             margin: 0 !important;
//             font-style: normal;
//             font-weight: 500;
//             font-size: 18px;
//             line-height: 26px;
//             color: #082633;
//           }
//         }
//         .secPart{
//           width: 100%;
//           height: 75%;
//           display:flex;
//           flex-direction:column;
//           justify-content: space-around;
//           align-items: flex-start;
//           p{
//            font-style: normal;
//             font-weight: normal;
//             font-size: 15px;
//             line-height: 24px;
//             color: #4C4C52;
//           }
//           .roundInput{
//             box-sizing:border-box;
//             width:100%;
//             padding-bottom: 20px;
//            @import '@/assets/css/inputButton.scss';
//           }
//            .sample{
//              font-style: normal;
//               font-weight: 500;
//               font-size: 13px;
//               line-height: 24px;
//               letter-spacing: 0.02em;
//               color: #92949B;
//            }
//            .sample:hover{
//              cursor: pointer;
//            }
//         }
//       }
//     }
//     .right-wrapper {
//       max-width: 846px;
//       width: 100%;

//       .price-wrapper, .report-wrapper, .report-wrapper2 {
//         display: none;
//       }
//       .price-wrapper, .main-wrapper, .report-wrapper {
//         background: white;
//       }
//       .main-wrapper {
//         height: 100%;
//         border-radius: 5px;
//         background: white;
//         min-height: 696px;
//         padding: 20px;
//         box-sizing: border-box;

//         .instruction {
//           display: flex;
//           align-items: center;
//           margin-bottom: 90px;
//           padding: 0 20px;

//           img {
//             margin-right: 8px;
//             margin-top: -3px;
//           }
//           span {
//             font-size: 14px;
//             line-height: 24px;
//             letter-spacing: 0.01em;
//             color: $text-darker;
//           }
//         }
//         .slider-parent-wrapper {
//           margin-bottom: 100px;
//           padding: 0 20px;
//         }
//         .analytics {
//           display: flex;
//           flex-direction: row;
//           flex-wrap: wrap;

//           .left {
//             font-size: 16px;
//             line-height: 26px;
//             color: $text-darker;
//             margin-right: 50px;
//             margin-bottom: 25px;
//             width: 300px;

//             .dynamic {
//               margin-bottom: 21px;
//             }
//           }
//           .right {
//             font-size: 14px;
//             line-height: 24px;
//             text-transform: capitalize;
//             color: $text-darker;
//           }
//           span {
//             font-family: 'Gotham Pro Medium';
//           }
//         }
//       }
//     }
//   }
// }
// .msg{
//     font-weight: 500;
//     font-size: 14px;
//     line-height: 24px;
//     text-align: center;
//     color: $main-color !important;
// }
// @media only screen and (max-width: 1024px){
//   .estimate-wrapper-background {
//     .lower-sections-wrapper {
//       padding: 0px;
//     }
//     .estimate-wrapper {
//       width: 100%;
//       padding: 0px;
//       .left-wrapper,
//       .left-wrapper2 {
//         display: none;
//       }
//       .line {
//         display: none;
//       }
//       .right-wrapper {
//         max-width: 100%;
//         height: 100%;
//         box-sizing: border-box;

//         .price-wrapper, .report-wrapper, .main-wrapper {
//           display: block;
//           margin-bottom: 10px;
//           padding: 20px;
//           border-radius: 0px;
//         }
//         .report-wrapper {
//           margin-bottom: 0px;
//         }
//            .price-wrapper{
//             box-sizing: border-box;
//             height:418px;
//             position: relative;
//             .br{
//               display:none;
//             }
//             .txt{
//               box-sizing:border-box;
//               min-height:20%;
//               padding-top: 25px;
//               p{
//                 margin: 0 !important;
//                 font-style: normal;
//                 font-weight: bold;
//                 font-size: 18px;
//                 line-height: 26px;
//                 color: #082633;
//               }
//             }
//             .price{
//               font-family: 'Gotham Pro Bold';
//               height: 75%;
//               display:flex;
//               justify-content: center;
//               align-items: center;
//               .p{
//                 font-style: normal;
//                 //font-weight: bold;
//                 font-weight: bolder;
//                 font-size: 44px;
//                 line-height: 48px;
//                 /* identical to box height, or 109% */

//                 text-align: center;
//                 color: #082633;
//               }
//             }
//       }
//       .line{
//              display:block !important;
//               box-sizing: border-box;
//               width:100%;
//               height: 3px;
//               position: absolute;
//               left: 0px;
//               bottom: 0px;
//               background: #103D49;
//       }
//       .report-wrapper2{
//        // height:220px;
//         box-sizing:border-box;
//         width:100%;
//         display: flex;
//         div{
//           box-sizing: border-box;
//           width:49.25%;
//           display:block;
//         }
//         div:first-of-type{
//           margin-right: 1.5%;
//         }

//       }
//       .report-wrapper{
//         height:454px;
//         box-sizing:border-box;
//         width:100%;
//         .txt{
//           box-sizing:border-box;
//           min-height:20%;
//           padding-top: 25px;
//           p{
//             margin: 0 !important;
//             font-style: normal;
//             font-weight: 500;
//             font-size: 18px;
//             line-height: 26px;
//             color: #082633;
//           }
//         }
//         .secPart{
//           width: 100%;
//           height: 75%;
//           display:flex;
//           flex-direction:column;
//           justify-content: space-around;
//           align-items: flex-start;
//           p{
//            font-style: normal;
//             font-weight: normal;
//             font-size: 15px;
//             line-height: 24px;
//             color: #4C4C52;
//           }
//           .roundInput{
//             box-sizing:border-box;
//             width:60%;
//             padding-bottom: 20px;
//            @import '@/assets/css/inputButton.scss';
//           }
//            .sample{
//              font-style: normal;
//               font-weight: 500;
//               font-size: 13px;
//               line-height: 24px;
//               letter-spacing: 0.02em;
//               color: #92949B;
//            }
//            .sample:hover{
//              cursor: pointer;
//            }
//         }
//       }
//       }
//     }
//   }
// }
// @media only screen and (max-width: 768px){
//    .estimate-wrapper-background {
//      padding-bottom: 60px!important;
//      .estimate-wrapper {
//        .right-wrapper{
//          .price-wrapper{
//             position: relative !important;
//           .line{
//               display:block !important;
//               box-sizing: border-box;
//               width:100%;
//               height: 3px;
//               position: absolute;
//               left: 0px;
//               bottom: 0px;
//               background: #103D49;
//           }
//        }
//           .report-wrapper{
//             .secPart{
//                .roundInput{
//                  width:100%;
//                }
//             }
//           }
//            .report-wrapper2{
//        // height:220px;
//           box-sizing:border-box;
//           width:100%;
//           display: block;
//           div{
//             box-sizing: border-box;
//             width:100%;
//             display:block;
//           }
//           div:first-of-type{
//             margin-right: 0 !important;
//           }

//         }
//        }
//      }
//    }

// }
// @media only screen and (max-width: 450px){
//   .estimate-wrapper-background {
//      .estimate-wrapper {
//       .right-wrapper {
//           .price-wrapper{
//                 padding-left: 16px;
//                 padding-right: 16px;
//             .br{
//               display:initial;
//             }
//           }
//           .main-wrapper{
//              padding-left: 16px;
//              padding-right: 16px;
//           }
//       }
//      }
//   }
//  }


</style>
