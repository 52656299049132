<template>
  <div  ref="down" :class="visibility? 'myChart': 'myChart hideContent'" id="chart"  v-waypoint="{ active: activeWayPoint, callback: onWaypoint, options: intersectionOptions }">
      <div v-if="blur" class="overlay">
        <div v-if="!falseData" @click="$emit('unlock')" class="overlay-btn">
            <img :src="require('@/assets/images/lock.png')" alt="lock image">
            Unlock results
        </div>
      </div>
      <div class="up">
          <p><span>Market breakdown by </span><span style="white-space: nowrap;">travelled km</span></p>
         <div>
            <hideShow @visibility="changeVisibility"/>
            <tooltip
              :msg="msg"
            />
        </div>
      </div>
    <p class="p1" v-if="none && !blur && visibility">Avg&thinsp;=&thinsp;<strong><span class="p1class" v-html="showLabel1(avg)+'&thinsp;k'"></span></strong></p>
    <p class="p1" v-else-if="visibility"></p>
      <div v-if="!rotate && visibility" class="down" id="downMyChart" >

            <div v-if="distance_breakdown" class="labels">
                <div v-for="(lab, index) in label"
                    :key="index"
                    class="label" v-html="showLabel(lab.min)+'&thinsp;-&thinsp;'+showLabel(lab.max)+'&thinsp;k'">

                </div>

            </div><!-- end of labels1-->
             <div v-else class="labels">
                <div v-for="(lab, index) in label"
                    :key="index"
                    class="label"
                    v-html='lab'> <!-- v-html='lab.split(" ").join("&thinsp;")' -->
                    {{lab}}
                </div>

            </div><!-- end of labels2-->
            <div class="graf">

                <div v-if="calcPosition(avg) !== false "
                    class='avg'
                    v-bind:style="{ top:calcPosition(avg)+'%'}"
                > 
                    <div v-if="blur" class="val"
                        v-bind:style="{top:setPosition(avg, kmR, 'avg')+'px'}"
                        v-html="'Avg'">
                    </div>
                    <div v-else-if="!none" class="val"
                        v-bind:style="{top:setPosition(avg, kmR, 'avg')+'px'}"
                        v-html="'Avg<span>&thinsp;=&thinsp;'+ showLabel1(avg)+'&thinsp;k</span>'">
                    </div>
                     <div v-else class="val"
                        v-bind:style="{top:setPosition(avg, kmR, 'avg')+'px'}"
                        v-html="'Avg'">
                    </div>
                </div>

                <div v-if="calcPosition(kmR) !== false"
                    class='km'
                    v-bind:style="{ top:calcPosition(kmR)+'%'}">
                    <div v-if="false" class="val"
                    v-bind:style="{top:setPosition(avg, kmR, 'kmR') + 'px'}"
                    v-html="getNumber(kmR)+'&thinsp;km'">
                    </div >
                    <div class="val"
                        v-if="blur"                    
                        v-bind:style="{top:setPosition(avg, kmR, 'kmR')+'px'}"
                        v-html="'km'">
                    </div>

                    <div class="val"
                        v-else
                        v-bind:style="{top:setPosition(avg, kmR, 'kmR')+'px'}"
                        v-html="showLabel1(kmR)+'&thinsp;k'">
                    </div>
                </div>

                <div v-for="(val, index) in values"
                    :key="index"
                    class="bar"
                    style="width:0px;"
                    v-bind:style ="[ {width:val+'%'}]"
                    v-tooltip.right="val + '%' +' cars'"
                >
                </div>
            </div><!-- end of graf-->

      </div><!-- end down-->



      <!-- rotate -->
         <div v-else-if="visibility" class="down rotate" v-bind:style="{height:returnWidthChart+'px'}">

            <div v-if="distance_breakdown" class="labels">
                <div v-for="(lab, index) in label"
                    :key="index"
                    class="label" v-html="showLabel(lab.min)+'&thinsp;-&thinsp;'+showLabel(lab.max)+'&thinsp;k'">

                </div>

            </div><!-- end of labels1-->
             <div v-else class="labels">
                <div v-for="(lab, index) in label"
                    :key="index"
                    class="label"
                    v-html='lab'> <!-- v-html='lab.split(" ").join("&thinsp;")' -->
                    {{lab}}
                </div>

            </div><!-- end of labels2-->
            <div class="graf">

                <div v-if="calcPosition(avg) !== false "
                    class='avg'
                    v-bind:style="{ top:calcPosition(avg)+'%'}"
                > 
                    <div v-if="blur" class="val"
                        v-bind:style="{top:setPosition(avg, kmR, 'avg')+ 65 +'px',
                                        right: '-35px'}"
                        v-html="'Avg'">
                    </div>
                    <div v-else-if="!none" class="val"
                        v-bind:style="{top:setPosition(avg, kmR, 'avg')+ 65 +'px',
                                        right: '-35px'}"
                        v-html="'Avg<span>&thinsp;=&thinsp;'+ showLabel1(avg)+'&thinsp;k</span>'">
                    </div>
                     <div v-else class="val"
                        v-bind:style="{top:setPosition(avg, kmR, 'avg')+'px'}"
                        v-html="'Avg'">
                    </div>
                </div>

                <div v-if="calcPosition(kmR) !== false"
                    class='km'
                    v-bind:style="{ top:calcPosition(kmR)+'%'}"
                >
                    <div v-if="false" class="val"
                    v-bind:style="{top:setPosition(avg, kmR, 'kmR')+'px'}"
                    v-html="getNumber(kmR)+'&thinsp;km'">
                    </div >
                    <div class="val"
                        v-if="blur"                    
                        v-bind:style="{top:setPosition(avg, kmR, 'kmR')+'px',
                                        right: '-5px'}"
                        v-html="'km'">
                    </div>

                    <div class="val"
                        v-else
                        v-bind:style="{top:setPosition(avg, kmR, 'kmR') +'px',
                                        right: '-5px'}"
                        v-html="showLabel1(kmR)+'&thinsp;k'">
                    </div>
                </div>

                <div v-for="(val, index) in values"
                    :key="index"
                    class="bar"
                    style="width:0px;"
                    v-bind:style ="[ {width:val+'%'}]"
                    v-tooltip.center="val + '%'"
                    
                >
                <!-- <div class="replaceTooltip"> {{ val + '%'}} </div> -->
                </div>
            </div><!-- end of graf-->

      </div><!-- end down-->
      <!-- end rotate -->
  </div><!-- end myChart -->
</template>

<script>
import tooltip from './tooltip2.vue'
import hideShow from '@/components/HideShow.vue'

export default {
    name:'myChart',
    components:{
        tooltip,
        hideShow
    },
    props:{
        distance_breakdown:{
            required: false,
        },
        average_km:{
            required: false,
        },
        km:{
            required: false,
        },
        falseData:{
            required: false,
            // default: false,
            type: Boolean
        },
        blur:{
            required: false,
            default: false,
            type: Boolean
         }
    },
    data () {
        return {
             maintitle:'Market breakdown by traveled km',
             msg: 'The market is divided into ten groups. Compare your car mileage with other vehicles on the market. ',
            avg:95000,
            kmR:130000,
            label:[
                '8 - 24 k',
                '24 - 39 k',
                '39 - 54 k',
                '54 - 70 k',
                '70 - 85 k',
                '85 - 101 k',
                '101 - 116 k',
                '116 - 131 k',
                '131 - 147 k',
                '147 - 162 k'
                ],
                values:[
                      0, 0, 0, 0, 0, 0, 0, 0, 0, 0
                ],
                 intersectionOptions: {
                    root: null,
                    rootMargin: '0px 0px 0px 0px',
                    threshold: [0.5, 0.5]  // [0.25, 0.75] if you want a 25% offset!
                },
                activeWayPoint: true,
                none: false,
                rotate: true,
                visibility: true,
                setWidthChart: 0
                

        }
    },
    methods:{
        setValuesChart () {
            if(this.distance_breakdown !== undefined) {
                 this.values = []
               for(let i=0; i < this.label.length; i++) {
                     this.values.push(this.label[i].value)
               }
            }else{
                this.values=[
                        20, 15.5, 39.3, 12.2, 50.2, 8.2, 22, 11, 8.6, 10
                    ]
            }
        },
        setValue () {
           if(this.distance_breakdown !== undefined) {
               this.label = []
               this.label = this.distance_breakdown
               }
               console.log("this.average_km + '--'+this.km")
               console.log(this.average_km + '--'+this.km)
           if(this.average_km !== undefined) {
               this.avg = this.average_km
               }
            if(this.km !== undefined) {
                this.kmR = this.km
            }


        },
         getNumber (num) {
            if (!num) { return ''}
            let res = ''
            res += num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&thinsp;');
            return res
        },
        calcPosition (val) {
            // console.log('this.distance_breakdown ' +this.distance_breakdown)
            // console.log('this.label ' +this.label)
            if( this.distance_breakdown == undefined || this.distance_breakdown === null){
                // let xhelp = this.label[0].split(" ")[0]
               // let help = this.label[this.label.length-1].split(" ")[3]
                        // '58.2 - 100.6 k' = x[0] and x[2]
                 if(val < this.label[0].split(" ")[0] * 1000 || val > this.label[this.label.length-1].split(" ")[3] * 1000) {
                      return false
                      }

                    for(let i=0; i < this.label.length; i++) {
                        let x = this.label[i].split(" ")
                        // '58.2 k - 100.6 k' = x[0] and x[3]
                        x[0] = x[0] * 1000
                        x[2] = x[2] * 1000
                        if (val >= x[0] && val <= x[2]) {
                            let distance = x[2] - x[0]

                            let grth = val - x[0]

                            let rez= (Math.floor(grth) / Math.floor(distance)) *100
                            rez = rez/10

                            let help = ((i) * 9.92)+rez


                            return help
                        }
                    }
            }else {
                //value: 2.6, min: 27000, max: 39935}
                if(val < this.label[0].min || val > this.label[this.label.length -1].max) {return false}
                 let help = 0
                 for(let i=0; i < this.label.length; i++) {
                        if (val >= this.label[i].min && val <= this.label[i].max) {
                            let distance = this.label[i].max - this.label[i].min

                            let grth = val - this.label[i].min

                            let rez= (Math.floor(grth) / Math.floor(distance)) *100
                            rez = rez/10

                            help= ((i) * 9.91)+rez


                            return help
                        }
                    }
                    // if(val < this.label[0].min) {return 0}
                    // if(val > this.label[this.label.length -1].max) {return 99.5}



            }

        },
        setPosition (x, y, z) {
            if (x <= y)
               if(z === 'avg') return -24
               else return 3

            if (x > y)
               if(z === 'avg') return 1
               else return -24
        },
        onWaypoint ({ going }) {
            // going: in, out
            // direction: top, right, bottom, left
            if (going === this.$waypointMap.GOING_IN) {
                console.log('waypoint going IN!')
                this.setValuesChart()
                this.activeWayPoint = false
                this.setValue();
            // setTimeout(() => {
            //             this.activeWayPoint = true
            // },300)
         }

        },
        showLabel (x) {
           x = x / 1000
           return x.toFixed(0)
         },
         showLabel1 (x) {
           x = x / 1000
           return x.toFixed(1)
         },
         resize () {
             if(this && this.$refs && this.$refs.down){
                 console.log(this.$refs.down.clientWidth)
                 this.setWidthChart=this.$refs.down.clientWidth 
             }else{
                 setTimeout(() => {
                  console.log(this.$refs.down.clientWidth)   
                  this.setWidthChart=this.$refs.down.clientWidth 
                 }, 100);
             }

            if (window.innerWidth <= 768) {
                this.none = true
                this.rotate = false
            } else {
                this.none = false
                this.rotate = true
            }
         },
         
          changeVisibility(e){
            this.visibility=e
            // let help= this.distance_breakdown
            // this.distance_breakdown = undefined

            this.setValue();
            this.values=[
                        0, 0,0, 0, 0, 0, 0, 0, 0, 0
                    ]
            
            // this.distance_breakdown = help
            if(this.visibility){
                setTimeout(() => {
                    this.setValue();
                     this.setValuesChart()
                }, 100);
                
            }
             
        }
    },
    watch: {
    avg: {
      handler () {
         this.activeWayPoint=true
      }
    },
    distance_breakdown: {
         deep: true,
         immediate: true,
          handler () {
         this.setValue()
         //this.setValuesChart()
      }
    }
  },
  computed:{
      returnWidthChart(){
             return this.setWidthChart - 40
         },
  },
   created(){
        window.addEventListener("resize", this.resize)
        this.resize()
   },
    mounted(){
        this.setValue();
        // setTimeout(() => {
        //                 this.avg = 100
        //     },10000)

    },
    destroyed() {
        window.removeEventListener("resize", this.resize);
    }

}
</script>

<style lang='scss' scoped>
@import '@/assets/css/statsOverlay.scss';
.myChart{
    //    border: 1.4px solid #929498;
    //    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    background: #FFFFFF;
    border-radius: 5px;
    margin-bottom: 10px;
    border-radius: 5px;
    padding:10px 20px;
    // padding-bottom: 59px;
    box-sizing: border-box !important;
    // width: calc(50% - 5px);
    // width: calc(70% - 5px);
    width: 100%;
    max-height: 540px !important;
   
    //display: flex;
    position: relative;
    .p{
        display: flex;
        align-items: center;
        margin:26px 0;
        margin-bottom: 4px;
       // margin-bottom: 10px;
        font-size: 13px;
        color: #132727;
        letter-spacing: 0.01em;
       span{
           margin-right: 4px;
       }
    }
    .p1{
       margin:33px 0;
       margin-top:35px;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 156% */


        /* black */

        color: #062931;

        .p1lass{
            font-weight: 500;
            color: #062931 !important;
        }
    }
     .up{
        display:flex;
        width:100%;
        box-sizing: border-box;
        // padding:0px 20px;
        justify-content: space-between;
        align-items: center;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 156% */
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #5A7E87;
        // padding-top: 10px;
        padding-top: 6px;
        div{
            display: flex;
            align-items: center;
        }
        p{
            margin: 0;
        }
     }
     .down{
            display:flex;
            width:100%;
            box-sizing: border-box;
            height: 270px ;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: $text-lighter;
            margin: 0px 20px;
            .labels{
                box-sizing:border-box;
                width:17%;
                padding-left:1px;
                height:100% ;
								margin-bottom: 5px;
                .label{
                    box-sizing:border-box;
                    display:flex;
                    justify-content: left;
                    align-items: center;
                    height: 10%;
                    font-size: 13px;
                    line-height: 23px;
                    display: flex;
                    align-items: center;
                    color: #9498A0;
                }

            }
            .graf{
                border-left: 1px solid $green-color;
                box-sizing:border-box;
                width:83%;
                height:100% !important;
                position:relative;
                padding-top:1px;
                .avg{
                    position: absolute;
                    width:60%;
                    // border-bottom: 1px solid $green-color;
                    border-bottom: 0.5px solid #062931;
                    height:0px;
                    left:0;
                    font-style: normal;
                    font-weight: bold;
                    font-size: 21px;
                    line-height: 25px;
                    text-align: right;
                    color: #062931;
                }
                .km{
                    position: absolute;
                    width:90%;
                    border-bottom: 1px solid #FF9F59;
                    height: 0px;
                    left:0;
                    font-weight: bold;
                    font-size: 25px;
                    line-height: 30px;
                    text-align: right;
                    color: #062931;

                }
                .val{
                    // background:#ffffff71;
                    padding:0 3px;
                    position: absolute;
                    z-index: 10;
                    top:-20px;
                    right: 0px;
                }
                .bar{
                    box-sizing: border-box;
                    background: rgba(241, 135, 0, 0.2);
                    height: calc(10% - 3px);
                    margin-bottom: 3px;
                    /* OUTLINE gray */
                    border: 1px solid rgba(241, 135, 0, 0.2);
                    border: transparent;
                    width:0;
                    animation-name: example;
                    transition-duration: 2s;
                }
                .bar:hover{
                    background: rgba(241, 137, 0, 0.233);
                    border: 1px solid rgba(241, 137, 0, 0.233);
                    border: transparent;
                }
            }
     }

      .down.rotate{
            // transform: rotate(-90deg) translate(-100%, 10%);
            transform: rotate(-90deg) translate(-100%, 0%);
            //  transform-origin: top left;
            // transform-origin: 0 0;
            transform-origin: 0 0;
            // vertical-align: center;
            // display:flex;
            width: 400px;
            box-sizing: border-box;
            height: 766px !important;
            font-style: normal;
            font-weight: normal;
            font-size: 13px;
            color: $text-lighter;
            // margin: auto;

            .labels{
                box-sizing:border-box;
                width:17%;
                padding-left:1px;
                height:100% !important;
                .label{
                    transform: rotate(40deg) translate(10%, -5%);
                   
                    box-sizing:border-box;
                    display:flex;
                    justify-content: left;
                    // align-items: center;
                    // align-items: flex-end;
                    height: 10%;
                    display: flex;
                    align-items: center;
                    font-weight: normal;
                    font-size: 13px;
                    line-height: 25px;
                    /* identical to box height, or 192% */

                    text-align: center;
                    letter-spacing: 0.01em;

                    /* 55%black */

                    color: #76898E;
                }

            }
            .graf{
                border-left: 1px solid $green-color;
                box-sizing:border-box;
                width:83%;
                height:100% !important;
                position:relative;
                padding-top:1px;
                margin-left: 4px;

                .avg{
                    position: absolute;
                    width:60%;
                    // border-bottom: 1px solid $green-color;
                     border-bottom: 0.5px solid $green-color;
                    height:0px;
                    left:0;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    text-align: right;
                    color: #062931;

                }
                .km{
                    position: absolute;
                    width:90%;
                    border-bottom: 1px solid #FF9F59;
                    height: 0px;
                    left:0;
                    font-weight: bold;
                    font-size: 26px;
                    line-height: 35px;
                    color: #062931;
                }
                .val{
                    // background:#ffffff71;
                    padding:0 3px;
                    position: absolute;
                    z-index: 10;
                    // top:-20px ;
                    right: -10px;
                    bottom: -95px;
                    
                    transform: rotate(90deg);
                    // height: fit-content;
                }
                .avg{
                    .val{
                        
                        // //  right: -10px;
                         bottom: -85px;
                        // // bottom: initial;
                        // // top: -85px !important;
                        right: -55px;
                        height: fit-content;

                    // on side of line
                        // right: -35px;
                        // top: 40px !important;

                    }
                }
                .bar{
                    box-sizing: border-box;
                    background: rgba(241, 135, 0, 0.2);
                    height: calc(10% - 3px);
                    margin-bottom: 3px;
                    /* OUTLINE gray */
                    border: 1px solid rgba(241, 135, 0, 0.2);
                    border: transparent;
                    width:0;
                    animation-name: example;
                    transition-duration: 2s;
                    position: relative;

                    .replaceTooltip{
                        position: absolute;
                        right:-30px;
                        top:0;
                        bottom: 0;
                        transform: rotate(90deg);

                    }
                }
                .bar:hover{
                    background: rgba(241, 137, 0, 0.233);
                    border: 1px solid rgba(241, 137, 0, 0.233);
                    border: transparent;
                }
            }
     }
     /*end rotate */
}

.none{
    display: none !important;
}
.myChart.hideContent{
    padding-bottom: 15px;
    .up{
        padding-top: 8px;
    }
}

@media only screen and (max-width: 1275px) {
    .myChart{
        .down.rotate{
            //  transform: rotate(-90deg) translate(-100%, 0%);
            // //  transform-origin: top left;
            // // transform-origin: 0 0;
            // transform-origin: 0 0;
            // // height: 560px !important;
            .labels{
                .label{
                    // transform: rotate(40deg) translate(35%, -20%);
                    // display: flex;
                    // align-items: flex-start;
                    // font-weight: normal;
                    }
            }
            .graf{
                width: calc(100% - 90px);
                }
            }
    }
}

@media only screen and (max-width: 1024px){
  .myChart {
    border-radius: 0px;
    width: 100%;
     .down.rotate{
        //   transform: rotate(-90deg) translate(-100%, 10%);
       .labels{
          width: 90px;
          }
       .graf{
           width: calc(100% - 90px);
        }
     }
  }
}
@media only screen and (max-width: 768px){
  .myChart {
          padding-bottom:30px;
          padding-left: 16px;
          padding-right: 16px;
          .up{
                align-items: initial;
              p{
                 padding: initial !important;
                 line-height: 28px;
              }
          }
          .p1{
              //padding: initial !important;
               padding-left:10px;
               //line-height: 26px;
              
          }
            .down{
                box-sizing:border-box;
                .labels{
                     padding-left:10px;
                     box-sizing:border-box;
                     width: 87px;
                     .label{
                        box-sizing:border-box;
                        display:flex;
                        // justify-content: right!important;
                        justify-content: left!important;
                        align-items: center;
                        font-weight: normal;
                        font-size: 13px;
                        line-height: 25px;
                        /* or 192% */


                        /* 55%black */

                        color: #76898E;

                     }
                 }
                .graf{
                    width: calc(100% - 87px);
                    border-left: 1px solid $green-color;

                    .avg{
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 20px;
                        /* identical to box height, or 143% */

                        text-align: right;
                        letter-spacing: 0.01em;
                        text-transform: uppercase;
                        /* black */

                        color: #062931;
                        border-bottom: 0.75px solid #062931;
                        // border: 0.5px solid #062931;
                            .val{
                                  span{
                                        display:none !important;
                                  }
                             }
                    }
                .km{

                     font-style: normal;
                     font-weight: bold;
                     font-size: 22px;
                     line-height: 24px;
                     color: #062931;
                    //  border-bottom: 1px solid $green-color;
                    border-bottom: 1px solid #FF9F59;
                }

             }
         }
    }
}
 @media only screen and (max-width: 550px){
    .myChart{
        
    padding-left: 16px;
    padding-right: 16px;
    
    .up{
        align-items: initial;
            padding-top: 5px;
        p{
            font-weight: 500;
            font-size: 15px;
            line-height: 25px;
            letter-spacing: 0.05em;
            text-transform: uppercase;

            /* Gray turquoise */

            color: #5A7E87;
            width: 200px;
            margin-top: 6px;

        }
    }
        .up>div{
        height: fit-content;
        align-self: flex-start;
    }
    }

    .myChart.hideContent{
        padding-bottom: 15px;
        .up{
            padding-top: 5px;
        }
    }
}

</style>
<style lang="scss" >
.rotate{
    .tooltip{
        
    }
}
</style>
