<template>
  <div class="outer-wrapper">
    <div class="admin-page-wrapper">
      <div class="left">
        <span @click="component = 'Create'" :class="{ active : component === 'Create'}" class="section">Create</span>
        <span @click="component = 'Edit'" :class="{ active : component === 'Edit'}" class="section">Edit</span>
        <span @click="component = 'Delete'" :class="{ active : component === 'Delete'}" class="section">Delete</span>
        <span @click="component = 'Media'" :class="{ active : component === 'Media'}" class="section">Media</span>
        <span @click="component = 'htmlSnippets'" :class="{ active : component === 'htmlSnippets'}" class="section">HTML snippets</span>
        <span @click="component = 'anime'" :class="{ active : component === 'anime'}" class="section">Anime</span>
        <span @click="component = 'Reports'" :class="{ active : component === 'Reports'}" class="section">Reports</span>
        <div @click="logout" class="logout-btn section">
          logout
        </div>
      </div>
      <div class="right">
        <keep-alive>
          <component :is="activeComponent" />
        </keep-alive>
      </div>
    </div>
    <footerDown v-if="!showMobileNav" :gray="true" />
    <div v-else style="height:80px" ></div>
  </div>
</template>

<script>
import footerDown from '../components/newsroom/footerDown'
import Media from '@/components/admin/media.vue'
import Create from '@/components/admin/create.vue'
import Edit from '@/components/admin/edit.vue'
import Delete from '@/components/admin/delete.vue'
import htmlSnippets from '@/components/admin/htmlSnippets.vue'
import anime from '@/components/anime/anime.vue'
import Reports from '@/components/admin/Reports.vue'
import { api } from '@/api'
export default {
  name: 'admin',
  components: {
    Media,
    Create,
    Edit,
    Delete,
    footerDown,
    htmlSnippets,
    anime,
    Reports,
    showMobileNav:false
  },
  data () {
    return {
      component: 'Create'
    }
  },
  created () {
      window.addEventListener("resize", this.resize)
      this.resize()
  },
  destroyed () {
      window.removeEventListener("resize", this.resize)
  },
  methods: {
    resize () {
      if (window.innerWidth <= 500) {
      this.showMobileNav = true
      } else {
      this.showMobileNav = false
      }
    },
    logout () {
      api.logOut().then(() => {
        localStorage.removeItem('sid')
        this.$store.commit('changeLoged', false)
        localStorage.removeItem('userName')
        this.$store.commit('setUserName', null)
        this.$store.commit('setUserPrivilege', 0)
        this.$router.push({ name: 'Home' })
      }).catch(() => {
        localStorage.removeItem('sid')
        this.$store.commit('changeLoged', false)
        localStorage.removeItem('userName')
        this.$store.commit('setUserName', null)
        this.$store.commit('setUserPrivilege', 0)
      })
    }
  },
  computed: {
    activeComponent () {
      return this.component
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-page-wrapper {
  min-height: calc( 100vh - 80px );
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 30px;
  max-width: calc(1440px + 60px);
  margin: 0 auto;
  box-sizing: border-box;
  padding-top: 30px;

  .left {
    display: flex;
    flex-direction: column;
    width: 120px;

    .section {
      border-right: 1px solid #4C4C52;
      // font-family: "Gotham Pro Medium";
      cursor: pointer;
      color: #4C4C52;
      padding: 7px 0;
      cursor: pointer;
      transition-duration: .3s;
    }
    .section.active, .section:hover {
      color: $main-color;
    }
    span {
      text-transform: uppercase;
    }
  }
  .right {
    padding-left: 20px;
    width: 100%;
  }
}
</style>
