<template>
  <div class="latestPress">
      <div class="latestHeding">Latest Press Releases</div>
      <div class="latestContent"> 
        <div class="contentWrapp">
           <latestPressContentItem v-for="(i, index) in item" :key="index" :itemData="i" @gotoonepress="goToOnePress"/>
        </div>
      </div>
      <div class="latestFooter">
          <router-link class="buttonWhite" to="pressReleases">View all</router-link>
      </div>

  </div>
</template>

<script>
import latestPressContentItem from './latestPressContentItem.vue'
// import pressimg from '@/assets/images/pressImg.svg'
import { api }from '@/api'
export default {
    components:{
        latestPressContentItem
    },
      data (){
        return{
            item:[
                // {title:'Compare EVs: Guide To Range, Specs,<br>Pricing & More ', date:'19.11.2020', img:pressimg},
                // {title:'The Ultimate Guide to Buying a Used<br>Car in New Zealand', date:'19.11.2020', img:''},
                // {title:'The cheapest cars to run in New<br>Zealand', date:'19.11.2020', img:''}
            ],
            news: []
            
        }
    },
    mounted () {
        this.getNews()
    },
    methods: {
        go () {
             this.$emit('gopress')
        },
        getNews () {
            let params= {
                count: 3,
            }
            api.getNews(params).then( res => {
                console.log(res.data.data)
                this.item = res.data.data
            }).catch(e => {
                console.log(e);
            })

        },
         goToOnePress (id) {
              //itemData.blogLink
            console.log(id)
            // this.$router.push({name:'onePressReleases', params:{id}})
            this.$router.push(id)
        }
    }


}
</script>

<style lang="scss" scoped>
.latestPress{
    // height:702px; 
    width:100% !important;
    .latestHeding{
        padding:39px 0;
        padding-bottom: 40px;
        width:100%;
        box-sizing: border-box;
        text-align: center;
        // font-family: Gotham Pro;
        // font-style: normal;
        // font-weight: 500;
        // font-size: 15px;
        // //line-height: 36px;
        // letter-spacing: 0.02em;
        // text-transform: uppercase;
        // color: #9498A0;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        letter-spacing: 0.03em;
        text-transform: uppercase;

        color: #485C6B;




    }
    .latestContent{
        // padding-top:7px;
        //box-sizing: border-box;
        width: 100%;
        .contentWrapp{
            display:flex;
            flex-wrap: wrap;
            //background: #4C4C52;
            margin:0 auto;
            width: 1278px;
           // min-height: 398px;
            height: 398px;
            height: 420px;
        }
        
    }
    .latestFooter{
        width: 100%;
        box-sizing:border-box;
        display:flex;
        justify-content: center;
        height:px;
        // padding-top:60px;
        // padding-bottom:80px;
        padding-top: 51px;
        padding-bottom: 100px;
        .buttonWhite{
            width:max-content;
            width: 220px;
            width: 150px;
            // padding:15px 30px 15px 31px; // set top and bottom from 14 to 13 because border and height to be 52px
            // border: 1px solid #4C4C52;
            // padding: 0px 30px;
            height:50px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            border-radius: 52px;
            font-weight: 500;
            font-size: 15px;
            // line-height: 20px;
            // color: #4C4C52;
            /* rgb(6, 41, 49) is the same as #062931; */
            border: 1px solid rgb(6, 41, 49, .8);
            color: #062931;
            text-decoration: none;
        }
        .buttonWhite:hover{
           color:#062931;
           cursor: pointer;
           //    border: 1.8px solid #062931;
           border: 2px solid #062931;
         }
    }
    
    
}
@media only screen and (max-width: 1330px){
.latestPress{
    min-height:702px;
    height:fit-content;
    .latestHeding{
    }
    .latestContent{
        box-sizing: border-box;
        padding:25px;
        // padding-top:7px;
        padding-top: 0px;
        .contentWrapp{
            box-sizing: border-box;
            display:flex;
            flex-wrap: wrap !important;
            //background: #4C4C52;
            
            margin:0 auto;
            max-width: 100% !important;
            min-height: 398px;
            height:fit-content;
        }
        
    }
    .latestFooter{
        width: 100%;
        box-sizing:border-box;
        display:flex;
        justify-content: center;
        padding-top:60px;
        padding-bottom:80px;
        .buttonWhite{
            // width:max-content;
            // border: 1px solid #4C4C52;
            box-sizing: border-box;
            border-radius: 52px;
        }
        .buttonWhite:hover{
                // cursor: pointer;
        }
    }
    
    
}
}
@media only screen and (max-width: 768px){
    .latestPress{
        .latestHeding{
             padding:40px 0 40px 0;  
        }
    .latestContent{
        box-sizing: border-box;
        padding:0 0 0 20px;
        // padding-top:7px;
        .contentWrapp{
        //     display:flex;
        //     flex-wrap: wrap !important;
             margin:0 !important;
        //    // width: calc(100% - 54px);
        //    width:100%;
        //     min-height: 398px;
        //     height:fit-content;
        }
        
    }
    .latestFooter{
        padding-top:41px;
        padding-bottom:99px;
        .buttonWhite{
           
        }
        .buttonWhite:hover{
    }
    }
    
    
}

}
@media only screen and (max-width: 395px){
 .latestPress{
        .latestHeding{
          padding: 40px 0 39px 0;   
          padding: 25px 0 25px 0;  
        }
    .latestContent{
        // padding-top: 7px;
        .contentWrapp{
            display:flex;
            flex-wrap: wrap !important;
            //background: #4C4C52;
            margin:0 auto;
            //max-width: 90vw !important;
            min-height: 398px;
            height:fit-content;
        }
        
    }
    .latestFooter{
        padding-top:52px;
        padding-bottom:80px;   
        .buttonWhite{
           
        }
        .buttonWhite:hover{
        }
    }
    
    
}

}
</style>
<style lang="scss">
.latestPress{
    .latestContent{
        .contentWrapp{
            .latestPressContentItem{
                margin-right:30px;
                position: relative;
            }
            .latestPressContentItem:nth-of-type(3){
                margin-right: 0px!important;
            }
           
        }
    }  
}
@media only screen and (max-width: 1330px){
    .latestPress{
        .latestContent{
              padding-left: 15px !important;
            .contentWrapp{
                .latestPressContentItem{
                    margin-right:30px;
                    position: relative;
                }
                .latestPressContentItem:nth-of-type(2){
                    margin-right: 30px!important;
                }
                .latestPressContentItem:nth-of-type(3){
                    // margin-right: 30px!important;
                    // margin-top: 40px;
                    
                }
            
            }
        }  
    }

}
@media only screen and (max-width: 954px){ //768
    .latestPress{
    .latestContent{
        .contentWrapp{
            .latestPressContentItem{
                margin-right:20px;
            }
            .latestPressContentItem:nth-of-type(2){
                margin-right: 0px!important;
            }
             .latestPressContentItem:nth-of-type(3){
                margin-right: 30px!important;
                margin-top: 56px!important;
            }
           
        }
    }  
}
}
@media only screen and (max-width: 939px) {
    .latestPress{
    .latestContent{
        .contentWrapp{
            .latestPressContentItem{
                margin-right:20px;
            }
            .latestPressContentItem:nth-of-type(2){
                margin-right: 0px!important;
            }
             .latestPressContentItem:nth-of-type(3){
                margin-right: 30px!important;
                margin-top: 56px!important;
            }
           
        }
    }  
}
    
}
@media only screen and (max-width: 740px){ //375
    .latestPress{
    .latestContent{
          padding-left: 0px !important;
        .contentWrapp{
            
            .latestPressContentItem{
                margin-right:0px;
                margin:0 auto;
                margin-top: 32px;
            }
             .latestPressContentItem:nth-of-type(1){
                 margin:auto !important;
                 margin:0 auto !important;
                 margin-top: 0px;
            }
             .latestPressContentItem:nth-of-type(2){
                margin: auto !important;
                 // margin:0 auto !important;
                 margin-top: 32px !important;
            }
             .latestPressContentItem:nth-of-type(3){
                margin: auto !important;
                margin-top: 32px !important;
            }
        }
    }  
}
}
</style>