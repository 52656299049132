<template>
  <div class="sharePressRele">
      <div class="downloadDiv">
          <p @click="$emit('download')"> Download <span><img :src="require('../../assets/images/arrowPress.svg')" /></span></p>
      </div>
      <div class="iconShare">
          <div class="txt">
              <p>Share release</p>
          </div>
      <shareIcon 
        :titleP='titleP' 
        :descriptionP='descriptionP' 
        :author='author'
        @showmodal='showmodal'
      /> 
      </div>
  </div>
</template>

<script>
import shareIcon from '../shareComponents/ShareIcon.vue'
export default {
    name: 'sharePressRele2',
    components: {
        shareIcon
    },
    props: ['titleP', 'descriptionP', 'author'],
    computed: {
    },
    data () {
        return {
          supportsPassive : false  
        }
    },
    created() {
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get:  this.supportsPassive = true  
            }));
        } catch(e){
             console.log(e)
         }
    },
    methods: {
          showmodal(e) {
      let wheelOpt = this.supportsPassive ? { passive: false } : false;
      let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
      if(e){
        // call this to Disable
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
        window.addEventListener("mousedown", this.preventDefault, { passive:false });
        document.body.style.overflow = "hidden"
      }else{
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
        window.addEventListener("mousedown", this.preventDefault, { passive:false });
        document.body.style.overflow = "initial"
      }
    },
    preventDefault(e) {
      e.preventDefault()
    },
    preventDefaultForScrollKeys(e) {
      var keys = {37: 1, 38: 1, 39: 1, 40: 1}
      if (keys[e.keyCode]) {
           this.preventDefault(e)
           return false
      }
    }
  },


}
</script>

<style lang="scss" scoped>
.sharePressRele { 
           // margin-top:16px;
           background: #ffffff;
            margin-left: 130px;
            // font-family: 'Gotham Pro Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 22px;
            width: 297px;
            color: #062931;
            height: fit-content;
            position: -webkit-sticky;
            position: sticky;
            top: 20px;
            padding-bottom:20px;
            z-index: 230;
            .downloadDiv{
                border-top: 1px solid #DEE0E5;
                border-bottom: 1px solid #DEE0E5;
               p{
                   margin: 10px 0;
                   cursor: pointer;
                   width:fit-content;
                }
            }
            .iconShare {
                .txt{
                    p{
                        margin-top: 11px;
                        margin-bottom: 6px;
                    }

                }

            }

        }

@media only screen and (max-width: 1000px){ //change from 768px, due to the design and display of other elements
    .sharePressRele{
       width: 100% !important;
       margin-left: 0px !important;
    }
}
@media only screen and (max-width: 1000px){ //change from 768px, due to the design and display of other elements
    .sharePressRele{
       width: 100% !important;
       margin-left: 0px !important;
       // margin-bottom:60px; /* set in sharePressRele2 style */
    }
}
</style>