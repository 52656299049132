<template>
  <div v-if="cars.length > 0" :class="[{'hide-section': !visibility}, 'section-wrapper']">
    <div v-if="blur" class="overlay">
      <div v-if="!falseData" @click="$emit('unlock')" class="overlay-btn">
        <img :src="require('@/assets/images/lock.png')" alt="lock image">
        Unlock results
      </div>
    </div>
    <div class="section-header">
      <div class="titleLeft">
        <h3>{{title}} <span v-if="cars && cars.length > 0">/ {{cars[0].mak_name}} {{cars[0].mod_name}}, {{cars[0].year}}, {{cars[0].tra_name}}, {{cars[0].bod_name}}, {{cars[0].fue_name}}, {{cars[0].ens_shortsize.toFixed(1) + '&thinsp;L'}}</span></h3>
        <sortSimilar v-if="getIdealPrice && !showSmallBtn && !getViewSimilar && hakic && visibility" @savesort="sortSimilarCar" class="hakic"/>
      </div>
     <div class="titleRight">
      <sortSimilar v-if="getIdealPrice && !showSmallBtn && !getViewSimilar && !hakic && visibility" @savesort="sortSimilarCar" class="better-pos"/>
      <hideShow  @visibility="changeVisibility"/>
        <div v-if="(getIdealPrice && !getViewSimilar) || isSample" @click="tooltip = true" v-click-outside="closeTooltip" class="better-pos">
          <tooltip 
            :isOpen="tooltip"
            :maintitle="'Similar cars'"
            :msg="msgMark2"
          />
        </div>
      </div>
       <!-- <sortSimilar v-if="getIdealPrice" @savesort="sortSimilarCar"/> -->
    </div>
    <sortSimilar v-if="getIdealPrice && showSmallBtn && !getViewSimilar && visibility" @savesort="sortSimilarCar"/>
<!--inserted code -->



    <div v-if="false && getIdealPrice" style="paddingBottom:20px">
      <!-- div>Sort:</div -->
      <div style="display:flex; justifyContent:space-around">
        <div>Price
            <span @click="price=1" class="spanArrow">
              <img v-if="price===1" style="background:#9294989f" :src="require('@/assets/images/arrowDown.svg')">
              <img v-else :src="require('@/assets/images/arrowDown.svg')">
            </span>
             <span @click="price=-1" class="spanArrow" >
               <img v-if="price===-1" style="background:#9294989f" class="rotate" :src="require('@/assets/images/arrowDown.svg')">
               <img v-else class="rotate" :src="require('@/assets/images/arrowDown.svg')">
             </span>
        </div>
        <div>Milage
          <span @click="milage=1" class="spanArrow" >
            <img v-if="milage===1" style="background:#9294989f" :src="require('@/assets/images/arrowDown.svg')">
            <img v-else :src="require('@/assets/images/arrowDown.svg')">
          </span>
          <span @click="milage=-1" class="spanArrow" >
            <img v-if="milage===-1" style="background:#9294989f" class="rotate" :src="require('@/assets/images/arrowDown.svg')">
            <img v-else class="rotate" :src="require('@/assets/images/arrowDown.svg')">
          </span>
        </div>
      </div>
    </div>
<!-- end inserted code -->

    <div class="content" v-show="visibility">
      <!-- <item v-for="(car, index) in getCarsArray" :key="index" :itemData="car" /> -->
      <div v-for="(car, index) in cars" :key="index" class="item">
        <div class="mobile-wrapper">
          <div v-if="car.tma_badge === 1" class="indicator greatPrice"></div>
          <span v-if="!blur" class="dark" v-html="getNumber(car.tma_price)"></span>
          <span v-else class="dark">$</span>
          <div class="circle-empty"></div>
          <span class="dark" v-html="getMileage(car.tma_km)"></span>
          <!-- <div class="circle"></div> -->
          <!-- <span>{{car.tra_name}}</span> -->
          <!-- <div class="circle"></div> -->
          <!-- <span>{{car.bod_name}}</span> -->
          <!-- <div class="circle"></div> -->
          <!-- <span>{{car.fue_name}}</span> -->
          <!-- <div class="circle"></div> -->
          <!-- <span v-html="car.ens_shortsize.toFixed(1) + '&thinsp;L'"></span> -->
          <div v-if="car.market_comparison !== null" class="circle wider"></div>
          <span v-if="car.market_comparison !== null" v-html="getPriceMsg(car.market_comparison)" class="last-two"></span>
          <div class="circle" :class="{ wider: car.market_comparison === null}"></div>
          <span class="last-two">{{car.reg_name}}</span>

        </div>
        <tooltip
          v-if="car.tma_badge && car.tma_badge === 1"
          :badge="car.tma_badge"
          :msg="msgBadgeGreatPr"
          class="price-tooltip"
        />
         <!-- :msg="car.tma_badge === 1 ? msgBadgeGreatPr: msgBadgeOther" -->
      </div>
      <!-- <div v-if="cars.length > 7" class="view-all-wrapper">
        <div @click="showAll = !showAll" class="view-all">
          {{showAll ? 'show less':'view all'}}
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import Item from '@/components/item/gridItemWrapper.vue'
import Tooltip from "@/components/tooltip2.vue";
// import badge from '@/components/badge.vue'
import { api } from "@/api";
import { mapGetters } from "vuex";
import helper from "@/js/helper.js";
import sortSimilar from "@/components/sort/sortSimilar";
import hideShow from '@/components/HideShow.vue';
export default {
  name: "similarCars",
  components: {
    // badge
    Tooltip,
    sortSimilar,
    hideShow,
    // Item
  },
  props: [
    "title",
    "hardCode",
    "idealPrice",
    "blur",
    "falseData",
    "getViewSimilar",
    "isSample"
  ],
  data() {
    return {
      tooltip: false,
      cars: [],
      showAll: false,
      price: "", // descending === 1
      milage: "", // ascending === -1,
      showSmallBtn: false,
      hakic: false,
      msgMark:
        "This is a list of similar ads recently available on the market. You can sort it by selecting multiple options simultaneously. <br><br> <strong>TIP:</strong> Select low price and low mileage and see the results.",
      msgMark2:
        "This is a list of cars recently available on the market. You can sort it by selecting multiple options simultaneously.<br><br> <strong>TIP:</strong> Select low price and low mileage and see the results.",
      msgBadgeGreatPr:
        "This price is significantly below the car's current market value.",
      msgBadgeOther:
        "Great price shows the value below the market that will enable you to sell your car faster.",
      visibility: true,
    };
  },
  mounted() {
    if (this.hardCode) {
      this.cars = this.hardCode;
    } else {
      this.getCars();
    }
    window.addEventListener("resize", this.resize);
    this.resize();
    window.addEventListener('resize', this.hakicFunc);
    this.hakicFunc();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
    window.removeEventListener('resize', this.hakicFunc)
  },
  methods: {
    hakicFunc () {
      if(window.innerWidth <= 768 || document.documentElement.clientWidth <= 768){
        this.hakic = true;
      } else {
        this.hakic = false;
      }
    },
    resize() {
      if (window.innerWidth <= 500) {
        this.showSmallBtn = true;
      } else {
        this.showSmallBtn = false;
      }
    },
    closeTooltip(e) {
      if (this.hasParentWithMatchingSelector(e.target, ".tooltip")) {
        return;
      }
      this.tooltip = false;
    },
    hasParentWithMatchingSelector(target, selector) {
      return [...document.querySelectorAll(selector)].some(
        (el) => el !== target && el.contains(target)
      );
    },
    getCars() {
      let params = helper.fixParams(this.getAllSelected);
      api
        .getSimilarCars(params)
        .then((res) => {
          this.cars = res.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getString(car) {
      return car.make;
    },
    getNumber(num) {
      // if (!num) {
      //   return ''
      // }
      let res = "$&thinsp;";
      res += num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&thinsp;");
      return res;
    },
    getMileage(num) {
      let res = "";
      res += num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, "&thinsp;");
      res += "&thinsp;km";
      return res;
    },
    getPriceMsg(marketComparison) {
      if (marketComparison < 0) {
        return `${this.getNumber(
          Math.abs(marketComparison.toFixed(0))
        )} below market`;
      } else if (marketComparison === 0) {
        return `Fair market price`;
      } else {
        return `${this.getNumber(
          Math.abs(marketComparison.toFixed(0))
        )} above market`;
      }
    },
    sortSimilarCar(e) {
      console.log(e);

      // let params= {
      //   price_sort:this.price !=='' ? this.price : 'descending 1',
      //   milage_sort:this.milage !=='' ? this.milage : 'descending'
      // }
      let params = helper.fixParams(this.getAllSelected);
      if (e.recommended === undefined) {
        // params.price_sort = e.price !=='' ? e.price : 1,
        // params.milage_sort = e.milage !=='' ? e.milage : 1

        if (e.price != false && e.price !== "") {
          params.price_sort = e.price;
        }
        if (e.milage != false && e.milage !== "") {
          params.milage_sort = e.milage;
        }
      } else {
        this.getCars();
        return;
      }

      api
        .getSimilarCars(params)
        .then((res) => {
          this.cars = res.data.data;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    changeVisibility (e) {
      this.visibility=e
      setTimeout(() => {
      if( this.observed > 1)
      this.show = e
      }, 100);
    }
  },
  computed: {
    ...mapGetters(["getAllSelected", "getIdealPrice"]),
    getCarsArray() {
      if (this.showAll) {
        return this.cars;
      } else {
        if (this.cars.length > 7) {
          return this.cars.slice(0, 8);
        } else {
          return this.cars;
        }
      }
    },
  },
  watch: {
    price: {
      handler() {
        console.log(this.price);
        if (this.milage === "") {
          this.milage = 1;
        } else this.sortSimilarCar();
      },
    },
    milage: {
      handler() {
        console.log(this.milage);
        if (this.price === "") {
          this.price = 1;
        } else this.sortSimilarCar();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/statsOverlay.scss";
// .similar-wrapper .section-wrapper {
//   border-top: 1px solid #C3C6D0;
//   border-radius: 0;
// }

.section-wrapper {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 20px;
  padding-top: 22px;
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  min-height: 420px;
  display: flex;
  flex-direction: column;
  position: relative;

  .section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-bottom: 60px;
    .titleLeft{
      display: flex;
      flex-direction: column;
      margin-top: 3px;
      h3 {
        // font-size: 18px;
        // line-height: 28px;
        // letter-spacing: 0.01em;
        // color: $text-darker;
        margin: 0px;
  
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* identical to box height, or 143% */
  
        letter-spacing: 0.05em;
        text-transform: uppercase;
  
        /* Gray turquoise */
  
        color: #5a7e87;
  
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 50px;
      }
      span {
        // margin-top: -5px;
        margin-bottom: 4px;
        font-weight: 400;
        text-transform: none;
      }
      .hakic{
        margin-left: 0;
        margin-top: 53px;
      }

    }
    .titleRight {
      align-self: flex-start;
      display: flex;
      min-width: fit-content;
      margin-top: 5px;

      .better-pos {
        margin-top: -2px;
      }
    }

    img {
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: -5px;
    margin-bottom: 40px;

    .view-all-wrapper {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 52px;
      margin-bottom: 40px;
      .view-all {
        // font-size: 15px;
        // line-height: 24px;
        // color: #4C4C52;
        color: #082633;
        width: 168px;
        height: 52px;
        // border: 1px solid #4C4C52;
        box-sizing: border-box;
        border-radius: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        // font-family: 'Gotham Pro Medium';
        font-weight: 500;
        cursor: pointer;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        /* identical to box height, or 133% */

        letter-spacing: 0.01em;
      }
    }

    .item {
      width: calc(100% - 4px);
      padding: 15px 5px;
      padding-right: 16px;
      // line-height: 29px;
      box-sizing: border-box;
      // font-size: 14px;
      position: relative;
      // border-top: 1px solid #DEE0E5;
      // padding-top: 11px;
      // padding-bottom: 9px;

      display: flex;
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;

      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      /* identical to box height, or 133% */

      letter-spacing: 0.01em;

      /* black */

      color: #062931;
      .mobile-wrapper {
        width: 80%;
        display: flex;
        flex-direction: row;
        align-items: center;
        flex-wrap: wrap;
      }
      .dark {
        // font-family: 'Gotham Pro Medium';
        font-weight: 400;
      }
      .wider {
        margin-left: 3px;
        margin-right: 3px;
      }
      .circle-empty {
        width: 5px;
        height: 5px;
        box-sizing: border-box;
        border: 1px solid #062931;
        border-radius: 2.5px;
        margin-top: -2px;
      }
      .circle {
        width: 5px;
        height: 5px;
        box-sizing: border-box;
        background: #062931;
        border-radius: 2.5px;
        margin-top: -2px;
      }
      .last-two {
        font-weight: 400;
      }
      .indicator {
        position: absolute;
        height: 100%;
        width: 5px;
        left: 0;
        top: 0;
      }
      .indicator.greatPrice {
        background-color: #51e6fb;
      }
      .indicator.pricy {
        background-color: #4982ff;
      }
      span {
        margin-left: 5px;
        margin-right: 5px;
      }

      .price-tooltip {
        font-weight: 700;
        font-size: 14px;
        line-height: 12px;
        color: #062931;
        letter-spacing: 0.04em;
        font-style: normal;

        height: 20px;
        width: 101px;

        margin-left: auto;
      }
    }

    .item:nth-child(odd) {
      background-color: #fcf9f2;
    }
  }

  .spanArrow {
    padding: 0 5px;
    cursor: pointer;
    .rotate {
      transform: rotate(180deg);
    }
  }
}
@media only screen and (max-width: 1024px) {
  .section-wrapper {
    border-radius: 0px;
    width: 100%;
  }
}
@media screen and (max-width: 768px){
  .section-wrapper{
    .section-header{
      margin-bottom: 29px;
    }
  }
}

@media only screen and (max-width: 650px) {
  .section-wrapper {
    padding-bottom: 60px !important;
    .section-header {
      // margin-bottom: 20px;
      margin-bottom: 33px;

      h3 {
        // font-size: 18px;
        // line-height: 28px;
        // letter-spacing: 0.01em;
        font-family: "Poppins";
        font-size: 15px;
        line-height: 25px;
        /* or 167% */

        letter-spacing: 0.05em;
        // color: $text-darker;
        margin: 0px;
        margin-right: 20px;
        span {
          text-transform: capitalize;
        }
      }
      img {
        cursor: pointer;
      }
    }
  }
}
@media only screen and (max-width: 450px) {
  .section-wrapper {
    // padding: 20px 16px;
    padding: 22px 16px;
    .section-header {
      .titleLeft {
        h3 {
          // width: 90%;
          display: unset;
          span {
            width: 240px;
            display: block;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        } 
      }
    }
    .content {
      .item {
        align-items: unset;
        .mobile-wrapper {
          max-width: 50%;
          width: 50%;
          .circle {
            display: none;
          }
        }
        .dark:first-of-type {
          font-weight: 600;
        }
        .dark:nth-of-type(2) {
          font-weight: 600;
        }
      }
    }
  }
}

@media screen and (max-width: 375px){
  .section-wrapper{
    .section-header{
      margin-bottom: 19px;
      .titleLeft{
        .hakic{
          margin-top: 33px;
        }
      }
    }
  }
}
.hide-section {
  min-height: min-content !important;
  padding-bottom: 0 !important;
}
</style>
