<template>
  <div class="estimate-wrapper-background">
     <estimateVersionFourth />
  </div>
</template>

<script>
import { api } from '@/api'
import { mapGetters } from 'vuex'
import helper from '@/js/helper.js'
// import estimateVersionFourth from '@/components/estimatePageVersions/estimateVersionFourth.vue'
import estimateVersionFourth from '@/components/estimatePageVersions/estimateVersionFourth.vue'


export default {
  name: 'estimatePage',
  components: {
    estimateVersionFourth
  },
   metaInfo () {
        return {
        title: 'Carvisor',
        titleTemplate: '%s - ' + this.title ,
       
        }
    },
  data () {
    return {
      title:'Estimate',
      stats: null,
      transactionInfo: false,
      reportInfo: false,
      showLoader: false,
      showStats: false,
      price: null,
      min: null,
      max: null,
      above: null,
      below: null,
      greatPrice: null,
      badPrice: null,
      horizontal: true,
      email: null,
      msg: '',
      score: 3.8,
      timeMarket:null,
      interestedBuyersPr:29,// promeniti u procentima
      looksBuyersPr:75, //promeniti u procentima
      looksBuyersN:25, // promeniti u broju osoba zainteresovani
      carOfModelOnMarket:37, //promeniti broj koliko trenutno ima odabraanog modela na trzistu
      distance_breakdown:null,
      average_km:null,
      orangeMsg :'<p><strong>Full report</strong></p><p>Fill in your email for a full report<br />including advanced analytics.</p>',
      tooltipOrg1:false,
      tooltipOrg2:false,
      msgfree:'Advanced analytics unravel plenty of details about the car you’re interested in and its current market value. Our report includes model attractiveness, estimated time to sell, private & dealer market value, market breakdowns, interesting insights and a selection of cars currently available on the market.',
      show:false,
      mobile:false,

      // 
      lastScrollTop: 0,
      value2: 5,// 30,
      value2Mob:20,
      mobileDevice:false
    }
  },
  created() {
    window.addEventListener('scroll', this.moveBackground)
    window.addEventListener("resize", this.handleResize);
    this.handleResize()
    // if (!this.getIdealPrice) {
    //   this.setUpData()
    // } else {
    //   this.show = true
    // }
  },
  mounted () {
      
    // (function(w) {
    //   console.log(w)
    //     var s = document.createElement('script');
    //     s.src = 'https://survey.survicate.com/workspaces/a7baeddd251ed804aec17bdd6b8b5fd6/web_surveys.js';
    //     s.async = true;
    //     var e = document.getElementsByTagName('script')[0];
    //     e.parentNode.insertBefore(s, e);
    //   })(window)

  

   
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener('scroll', this.moveBackground)

  },
  methods: {
    async setUpData () {
      if (this.$route.name === 'report') {
        console.log('report');
      } else {
        let params = {}
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('estimate').catch(() => {})
        console.log('this.$route.params.key - ----------------- - '+ this.$route.params.key)
        await api.getEstimateKey(this.$route.params.key, this.$route.params.odometer, token).then(res => {
          params = JSON.parse(JSON.stringify(res.data.parameters))
          this.$store.commit('setIdealPrice', res.data.ideal_price)
        }).catch(e => {
          if (e.response.data.message === 'no-cars') {
            console.log('get similar');
            this.$store.commit('setSimilarCars', true)
            this.$store.commit('setMileageLow', false)
            this.$store.commit('setMileageHigh', false)
            this.$store.commit('setNoPrice', false)
            this.$store.commit('setNumCars', e.response.data.num_cars)
          } else if (e.response.data.message === 'bad-request') {
            this.$store.commit('setNoPrice', false)
            if (e.response.data.milage && e.response.data.milage === 'low') {
              this.$store.commit('setMileageLow', true)
            } else {
              this.$store.commit('setMileageLow', false)
            }
            if (e.response.data.milage && e.response.data.milage === 'high') {
              this.$store.commit('setMileageHigh', true)
            } else {
              this.$store.commit('setMileageHigh', false)
            }
            this.$store.commit('setSimilarCars', true)
            this.$store.commit('setNumCars', null)
          } else if (e.response.data.message === 'no-price') {
            this.$store.commit('setSimilarCars', true)
            this.$store.commit('setMileageLow', false)
            this.$store.commit('setMileageHigh', false)
            this.$store.commit('setNoPrice', true)
            this.$store.commit('setNumCars', e.response.data.num_cars)
          } else {
            this.$store.commit('setSimilarCars', false)
            this.$store.commit('setMileageLow', false)
            this.$store.commit('setMileageHigh', false)
            this.$store.commit('setNoPrice', false)
            this.$store.commit('setNumCars', null)
            this.$router.push('/')
          }
          if(e.response && e.response.data && e.response.data.parameters) {
           params = JSON.parse(JSON.stringify(e.response.data.parameters))
          }
        })
        params.car_km = this.$route.params.odometer
        if (!this.isAllSelected) {
          await this.$store.dispatch('setParams', params)
        }
        this.setUpPrices()
      }
    },
    resetParameters () {
      this.$store.commit('resetValue')
      this.$router.push('/priceSteps')
    },
    closeTransactionInfo (e) {
      if (this.hasParentWithMatchingSelector(e.target, '.tooltip')) {
        return
      }
      this.transactionInfo = false
    },
    closeReportInfo (e) {
      if (this.hasParentWithMatchingSelector(e.target, '.tooltip')) {
        return
      }
      this.reportInfo = false
    },
    hasParentWithMatchingSelector (target, selector) {
      return [...document.querySelectorAll(selector)].some(el =>
        el !== target && el.contains(target)
      )
    },
    setUpPrices () {
      this.price = parseInt(this.getIdealPrice)
      this.above = parseInt(this.price * 1.1)
      this.below = parseInt(this.price * 0.95)
      this.greatPrice = parseInt(this.price * 0.9)
      this.badPrice = parseInt(this.price * 1.15)
      this.min = parseInt(this.price * 0.75)
      this.max = parseInt(this.price * 1.25)
    },
    getNumber (num) {
      if (!num) {
        return ''
      }
      num = Math.floor(num/5)*5
      let res = '$&thinsp;'
      res += num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&thinsp;');
      return res
    },
    restartBadge () {
      this.$router.push('/priceSteps')
    },
    handleResize () {
      if(window.innerWidth < 550) {
        this.mobile = true
      }else{
        this.mobile = false
      }
      if (window.innerWidth < 768) {
        this.horizontal = false
      } else {
        this.horizontal = true
      }
     if (window.innerWidth < 1024) {
        this.tooltipOrg1 = false
        this.tooltipOrg2 = true
      } else {
        this.tooltipOrg1 = true
        this.tooltipOrg2 = false      }
    },
    async send (e) {
      this.email= e
      console.log(this.email)
      this.msg= ''
      if (this.email === '' || this.email === null) {
         this.msg= "Please enter a valid email"
          return
          }
      if (this.matchemail(this.email)){
        let params = helper.fixParams(this.getAllSelected)
        params.email = this.email
        this.showLoader = true
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('estimate').catch(() => {})
        params.token = token
        api.getStats(params).then(res => {
          console.log(res)
          this.stats = res.data
          this.showStats = true
          this.$analytics.fbq.event('trackCustom', {
            subscribed: true,
            email:this.email
          })
          this.email = null
          this.msg= ''
          this.setMyChartValue(this.stats)
         // window.scrollTo({top:1692, behavior: 'smooth'}) //1692
         // window.location.hash = "#scrollMail"
         // const id = 'scrollMail'
          //

          // const ELEMENT = document.getElementById('scrollMail')
          // console.log('EL' + ELEMENT)

          // const Y = ELEMENT.getBoundingClientRect().top //+ window.pageYOffset
          if(window.innerWidth >= 1025){
           this.$nextTick(() => {
            //console.log(this.$refs); // returns obj
           //console.log(this.$refs.scrollmail); // returns el
            let y = this.$refs.scrollmail.getBoundingClientRect().top + window.pageYOffset
            console.log(this.$refs.scrollmail.getBoundingClientRect().top)
            console.log('W '+window.pageYOffset)
            console.log('Y '+y)
            window.scrollTo({top: y, behavior: 'smooth'})
          })
          }
          if(window.innerWidth >= 768){
             window.scrollTo({top:1290, behavior: 'smooth'})
          }else{
             window.scrollTo({top:1650, behavior: 'smooth'})
           }
          // const yourElement = document.getElementById('scrollMail');
          // const y = yourElement.getBoundingClientRect().top + window.pageYOffset;
         // console.log(window)
          //console.log('YZYZYZY '+y)
          //window.scrollTo({top: y, behavior: 'smooth'});


          //console.log('YYYY -- ' + Y)
         // window.scrollTo({top: Y, behavior: 'smooth'});
          this.showLoader = false
        /*  this.$gtag.event('email_subscribed', {
            'event_category': 'subscription',
            'event_label': 'E-Mail subscribed',
            'value': 1
          })
          */
        }).catch(e => {
          console.log(e)
          this.msg= "Please enter a valid email"
          this.showLoader = false
        })
      } else {
          this.msg= "Please enter a valid email"
      }


    },
    sampleReaport () {
      let routeData = this.$router.resolve({name: 'sampleReport'});
      window.open(routeData.href, '_blank');
    },
    matchemail (v) {
      let mailForm = /^(\w+.?)+@(\w+.?)+\.\w+$/
      if (v.match(mailForm)) {
        return true
      } return false
    },
    setmarket (x) {
      let obj= {}
      if(x) {
           obj['Private']= this.isShowPrice(this.stats.general.private_avg_price )
           obj['Dealer']=  this.isShowPrice(this.stats.general.dealer_avg_price )
       } else {
          obj['Private']= this.stats.general.private_percentage
          obj['Dealer']= this.stats.general.dealer_percentage
       }
      return obj
    },
    helpFunction (x) {
       if(x == null )  return null
        else if (x < 100 ) return null
        else return x
    },
    setMyChartValue (res) {
      if(res !== undefined){
        this.distance_breakdown = res.distance_breakdown
        this.average_km = res.general.average_km
        this.interestedBuyersPr = res.general.body_percentage
        this.looksBuyersPr = res.general.view_precentage
        this.looksBuyersN = res.general.watchers
        this.carOfModelOnMarket = res.general.on_market
        this.score= res.general.model_attractiveness
        this.timeMarket= res.general.avg_time_on_market
      }
    },
    isShowPrice (setPrice) {
      console.log(setPrice)
      if (this.isIdealInPriceRange (this.stats.general.private_avg_price, this.stats.general.dealer_avg_price, this.getIdealPrice )
         && this.isPriceInPercentRange (setPrice, this.getIdealPrice) ) {
        return this.helpFunction(setPrice)
      }
      return null
    },
    isPriceInPercentRange ( price, ideal) {
      if(price < ideal * 0.9 || price > ideal * 1.1) return false
      return true
    },
    isIdealInPriceRange (privatePr, dealer, ideal){
      if (privatePr == null || dealer == null) return true
      if((ideal >= dealer && ideal >= privatePr)
        || (ideal <= dealer && ideal <= privatePr)) {
                return false;
      }
      return true;
    },

     moveBackground () {
            let page = document.getElementsByClassName("estimate-wrapper-background")[0]
            
            page.style.backgroundPosition = 50 +"%" + this.value2+ "%" 
    
            var st = window.pageYOffset || document.documentElement.scrollTop

            // console.log(document.documentElement.scrollHeight)

            // if (st <= 5) this.value2=5
            // if((st >= document.documentElement.scrollHeight/2 -50)  &&  (st <= document.documentElement.scrollHeight/2 +50) ){
            //   this.value2=50
            // }
            // if (st >= document.documentElement.scrollHeight-50)
            if (st <= 5) this.value2=5
            if((st >= document.documentElement.scrollHeight/4 -50)  &&  (st <= document.documentElement.scrollHeight/4 +50) ){
              this.value2=10
            }
            if((st >= document.documentElement.scrollHeight/2 -50)  &&  (st <= document.documentElement.scrollHeight/2 +50) ){
              // this.value2=20
              this.value2=15
            }

            if (st > this.lastScrollTop){
                // downscroll code
                //  this.value2 += 0.3
                this.value2 += 0.1   
               page.style.backgroundPosition = 50 +"%" + this.value2+ "%" 
            } else {
                // upscroll code
                // this.value2 -= 0.3
                this.value2 -= 0.1
                         
                page.style.backgroundPosition = 50 +"%" + this.value2+ "%" 
            }
            this.lastScrollTop = st <= 0 ? 0 : st; // For Mobile or negative scrolling
                        

         },

  },
  computed: {
    ...mapGetters(['getIdealPrice', 'getAllSelected', 'getViewSimilar', 'getMileageHigh', 'getMileageLow', 'getNoPrice', 'getNumCars', 'getDeleted']),
    isAllSelected () {
      if (this.getAllSelected.make && this.getAllSelected.model &&
          this.getAllSelected.year && this.getAllSelected.body &&
          this.getAllSelected.fuel && this.getAllSelected.transmission &&
          this.getAllSelected.engine && this.getAllSelected.odometer) {
        return true
      } else {
        return false
      }
    },
    getSliderMsg () {
      if (this.price < this.below) {
        return `This price is <b>${this.getNumber(this.below - this.price)}</b> bellow fair price.`
      } else if (this.price >= this.below && this.price <= this.above) {
        return `This is the fair market price.`
      } else {
        return `This price is <b>${this.getNumber(this.price - this.above)}</b> above fair price.`
      }
    }
  },
  watch: {
    price: function () {
      this.getSliderMsg
    },
    getIdealPrice :{
        handler () {
            if(this.getIdealPrice) {
              this.show = true
            }
        }
    }
 }
}
</script>

<style lang="scss" scoped>
// body{
//   overflow: hidden;
// }

.estimate-wrapper-background {
    //  transparent navbar
  overflow-x: hidden;
  // contain: paint;

    margin-top:-110px;
    padding-top: 110px;


    //  background-color: #467F8F;
    background-color: #ffffff;
    //         //   background: linear-gradient(90deg, rgba(238, 239, 243, 0) 1.8%, #EEEFF3 74.8%), #51E6FB;

    // background-image: linear-gradient(#467f8f 6.7%, #467f8fc9,#467f8f4d, #EEEFF3);

     background-image: linear-gradient(#467f8f 6.7%, #467f8fc9,#eeeff3ea, #EEEFF3);
      
    //         //background: linear-gradient(167.51deg, #467F8F 14.17%, rgba(70, 127, 143, 0) 79.85%);
    //  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 36.84%, #FFFFFF 100%), linear-gradient(270deg, #FFFFFF 0.03%, rgba(255, 255, 255, 0) 16.69%), linear-gradient(90deg, #FFFFFF 0.05%, rgba(255, 255, 255, 0) 16.85%), #EEEFF3;// min-height: calc(100vh - 80px);
    //         // background:linear-gradient(#467F8F 0%, #467f8f86 13%, #EEEFF3 25%);
    //  background: linear-gradient(167.51deg, #467F8F 14.17%, rgba(70, 127, 143, 0) 79.85%);

    //   width: 100%;
    //   position: relative;


    //   background-attachment: fixed;
    //          // background-position: center;
    //   
    //           // object-fit: scale-down;
    //   background-size: cover;
    //           // background-repeat: no-repeat;

    //   height: 100vh;
    //   height: fit-content;
               // overflow: auto;




// style with img
    // background:linear-gradient( #467f8f 0%, #EEEFF3 75%);
    background-image: url('../assets/images/Background.svg');
    // background-image: url('../assets/images/appSmall.png');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: 50% 5%;
    background-size: cover;
  
}
// .estimate-wrapper-background::before {
//   background: 50% 50% / cover;
//   bottom:0;
//   content:"";
//   left: 0;
//   position: absolute;
//   right: 0;
//   top: 0;
//   display: block;
//   //  background-image: linear-gradient(#467f8f 6.7%, #467f8fc9,#467f8f4d, #EEEFF3);
//    background-image: url('../assets/images/Background.svg');
//   background-size: cover;
//   transform-origin: center center 0;
//   transform: translateZ(-1px) scale(2);
//   z-index: -1;
//   min-height: 100vh;

// }

@media screen and (max-width: 450px) {
  .estimate-wrapper-background {
    padding-top: 180px;
  }
}

</style>
