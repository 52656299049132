<template>
  <div class='terms'>
    <div class="content">
      <div class="inner-wrapper">
        <div>
          <p class="main">Terms &amp; Privacy</p>
          <p class="text">Carvisor provides users with advanced car valuation technology and value analytics for the auto market in New Zealand (the “Services”) through its website – <a class="siteLink" href="http://www.carvisor.co.nz"> http://www.carvisor.co.nz</a> (the “Site”). Services are provided using our proprietary technology developed by our team of engineers and auto market enthusiasts.
          </p>
        </div>
        <termsComp v-for="(i, index) in textOrigin" :key="index" :textExp="i"/>
        <p class="team">Your Carvisor team</p>
      </div>
    </div>
    <footerdown :gray="true"  v-if="!showMobileNav" />
    <div v-else style="height:80px" ></div>
  </div>
</template>

<script>
import footerdown from '../components/newsroom/footerDown.vue'
import termsComp from '../components/terms/termsComp.vue'
export default {
 name: 'terms',
    components: {
        footerdown,
        termsComp
    },
    created () {
      window.addEventListener("resize", this.resize)
      this.resize()
    },
    destroyed () {
        window.removeEventListener("resize", this.resize)
    },
    mounted (){
       this.$analytics.fbq.event('PageView', {
        content_name: 'Terms page'
      })
    },
     metaInfo () {
        return {
        title: 'Carvisor',
        titleTemplate: '%s - ' + this.title ,
        meta:[
           {name: 'robots', content: 'index,follow'} ,
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.description
                },
                {
                     vmid: 'og:description',
                    property: 'og:description',
                    content:  this.description
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.title 
                }
                // ,
                // {
                //     hid: 'keywords',
                //     name: 'keywords',
                //     content:  this.tempData.title
                // },
                // {
                //     vmid: 'og:image',
                //     property: 'og:image',
                //     itemprop: 'image',
                //     content: this.baseURL + this.blog.image
                // }
            ]
        }
    },
    data () {
      return {
        description: 'Carvisor provides users with advanced car valuation technology and value analytics for the auto market in New Zealand (the “Services”) through its website – <a class="siteLink" href="http://www.carvisor.co.nz"> http://www.carvisor.co.nz</a> (the “Site”). Services are provided using our proprietary technology developed by our team of engineers and auto market enthusiasts.',
        title: 'Terms & Privacy',
        showMobileNav: false,
        textOrigin: [
          { 
            num:null,
            maintext:null,
            p:['Information provided through the Services (the “Information”) is proprietary and its use is subject to these terms of service. By accessing or using the Services, including this Site, you accept and agree to be bound by these terms of service (“Terms of Service”) and all applicable laws, rules, and regulations associated with your use of the Services. By accepting the Terms of Service you also agree and commit not to access the Services by any means other than through the interface that is provided by Carvisor for use in accessing the Services. If you do not agree to the Terms of Service, you are not authorized to use this Site or the Services. Information provided on the Site are for personal use only and you may not take any of the Information, re-format it, display or copy it on other websites, in any format, without a prior written consent from isor. By using our Services, you agree not to sell, store, distribute, download, sell, display, reproduce, modify or exploit in other ways the Information provided to you by the use of our Services. Furthermore, the Information provided to you on the Site or via email is meant for your personal non-commercial use. Should you want to use the Information for commercial use or further distribution, we encourage you to get in touch with our team and agree the terms of such use.'],
            list:[]
          },
          { 
            num:null,
            maintext:null,
            p:['We might require you to register in order to access certain features and/or information on this Site. By providing Carvisor with your data and/or email address, you allow Carvisor to send you additional information or periodic reports with respect to your use of the Site. We respect your privacy, shall protect your personally information and treat it as confidential.'],
            list:[]
          },{ 
            num:null,
            maintext:null,
            p:['This Site or any third-party website, product or service linked to or from this Site are provided without warranty or any kind, either expressed or deemed, fitness for a particular purpose, timeliness and/or non-infringement. We respect all intellectual property and if you believe any of your intellectual property has been used without your knowledge or consent in rendering the Services, we encourage you to contact us with exact supporting information.'],
            list:[]
          },{ 
            num:null,
            maintext:null,
            p:['Carvisor shall not be liable for any direct or indirect damages, including damages for loss of profit, arising from or in connection with the Services. Any information or data obtained or downloaded from the Site is at your own discretion and risk and you hereby waive any and all claims with respect to any damages that might result from the use of the Services. Furthermore, you will indemnify, defend and/or hold harmless Carvisor and its subsidiaries, affiliates, partners, officers, directors, employees, and agents from all claims, whether actual or alleged that arise out of or in connection with a breach of these Terms of Service and/or the use of the Services.'],
            list:[]
          },{ 
            num:null,
            maintext:null,
            p:['Should you need more information on the Terms of Service, please feel free to contact us.'],
            list:[]
          } /*,{ 
            num:null,
            maintext:null,
            p:['Your Carvisor team'],
            list:[]
          }
          */
        ]
      }
    },
    methods:{
       resize () {
        if (window.innerWidth <= 500) {
          this.showMobileNav = true
        } else {
          this.showMobileNav = false
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
.terms{
  width:100%;
  box-sizing: border-box;
  .content{
    max-width: 1275px;
    margin: 0 auto;
    box-sizing: border-box;
    // padding: 50px 30px;
    padding: 98px 30px;
    padding-bottom: 113px;

    .inner-wrapper {
      max-width: 733px;

        .main{
          // font-style: normal;
          // font-weight: bold;
          // font-size: 30px;
          // line-height: 50px;
          /* identical to box height, or 167% */

          // display: flex;
          // align-items: center;

          // /* black */

          // color: #062931;

          font-weight: bold;
          font-size: 36px;
          line-height: 40px;
          /* identical to box height, or 111% */

          display: flex;
          align-items: center;

          /* black */

          color: #062931;
          margin-top: 0;
          margin-bottom: 54px;
        }
        .text{
          // font-style: normal;
          // font-weight: normal;
          // font-size: 16px;
          // line-height: 24px;
          // /* or 150% */


          // /* black */

          // color: #062931;
          // margin-bottom:33px;

          font-weight: normal;
          font-size: 17px;
          line-height: 25px;
          /* or 147% */


          /* black */

          color: #062931;
          .siteLink{
            color: #062931;
            font-weight: 500;
            text-decoration: none;
          }
          .siteLink:hover{
            color: $btn-hover;
          }

        }
        .team {
          // font-family: 'Gotham Pro Medium';
          // font-style: normal;
          // font-weight: 500;
          // font-size: 16px;
          // line-height: 24px;

          color: #062931;
          font-weight: 500;
          font-style: italic; 
          font-size: 17px;
          line-height: 25px;
        }
      }
  }
}
@media only screen and (max-width: 1200px) {
  .terms{
    width:100%;
    box-sizing: border-box;
    .content{
      box-sizing: border-box;
      //margin: 0 30px;
      padding: 48px 27px 93px 31px ;
      // margin: 0 auto;
      //width: 633px;
       box-sizing: border-box;
       width:100%;
       .inner-wrapper {
         box-sizing: border-box;
          max-width:100%;

          .team{
            margin-bottom:0px;
          }
       }
    }
  }
}
 @media only screen and (max-width: 767px) {
   .terms{
     box-sizing: border-box;
     width:100%;
    .content{
      box-sizing: border-box;
      width: 100% !important;
      padding: 73px 16px;
      padding: 78px 16px;
      margin-left: 0;
      .inner-wrapper{
        .main{
          // font-size: 28px;
          // line-height: 50px;
          font-size: 34px;
          line-height: 40px;
         
          color: #082633;
          margin-bottom: 34px;
        }
        .text{
          // font-family: Gotham Pro;
          // font-style: normal;
          // font-weight: normal;
          // font-size: 15px !important;
          // line-height: 24px;

          font-size: 16px;
          line-height: 25px;  
          
          color: #082633;
        }
        .team{
          font-size: 16px;
          line-height: 25px;  
        }
      }
    }
  }
 }
  // @media only screen and (max-width: 767px) {
  //  .terms{
  //   .content{
      
  //     .inner-wrapper{
  //       .main{
  //         font-size: 28px;
  //         line-height: 50px;
  //         // margin-top:72px;
  //         color: #082633;
  //       }
  //     }
  //   }
  //  }
  // }
</style>
<style lang='scss'>
  // .termsComp:last-of-type{
  //   text-align: right !important;
  // }
</style>
