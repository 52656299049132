<template>
  <!--div :class="showBigMenu && pisi && pathName!=='priceSteps' ? 'mobileNav big':'mobileNav'" -->

  <!-- pisi => ios versions below 10 not show exsta pixels -->
  <div
    :class="
      getShowBigMenu && pisi && pathName !== 'priceSteps'
        ? 'mobileNav big'
        : 'mobileNav'
    "
    :style="sampleFix"
  >
    <div v-if="sampleReport" class="sample-report-bar">
      <div class="sample-report-wrapper-in-nav">
        <div
          v-for="(i, index) in 20"
          :key="index"
          :style="{ 'animation-delay': '-' + index * 2 + 's' }"
          class="sample-report-in-nav"
        >
          sample report
        </div>
      </div>
    </div>
    <div class="separator">
      <!-- <moreMenu
        v-if="showeMoreMenu"
        @close="closeMoreMenu"
        :showBigMenu="getShowBigMenu"
        :pisi="pisi"
      /> -->
      <userMenu 
        v-if="showAccountMenu"
        @close="closeAccountMenu"
      />
      <router-link
        class="toolbarItem"
        v-for="menu in menus"
        :to="{ name: menu.path }"
        :key="menu.title"
      >
        <div class="iconWrapper" @click="handleCloseMoreMenu">
          <img
            :src="menu.active ? menu.imgActive : menu.img"
            :alt="menu.title"
          />
        </div>
        <div :class="!menu.active ? 'menuTitle' : 'menuTitle activeTitle'">
          <div>{{ menu.title }}</div>
        </div>
      </router-link>

			<!-- Account Option -->
			<div @click="accOptions" class="toolbarItem">
        <div class="iconWrapper">
          <img
            class="imgMore"
            :src="account.active ? account.imgActive : account.img"
            :alt="account.title"
          />
        </div>
        <div :class="!account.active ? 'menuTitle' : 'menuTitle activeTitle'">
          <div>{{ account.title }}</div>
        </div>
      </div>
			<!-- END Account Option -->

      <!-- MORE OPTION-->
      <div @click="moreOptions" class="toolbarItem">
        <div class="iconWrapper">
          <img
            class="imgMore"
            :src="more.active ? more.imgActive : more.img"
            :alt="more.title"
          />
        </div>
        <div :class="!more.active ? 'menuTitle' : 'menuTitle activeTitle'">
          <div>{{ more.title }}</div>
        </div>
      </div>
      <!-- END MORE OPTION-->
    </div>
  </div>
</template>

<script>
import icons from "@/js/getIcons.js";
// import moreMenu from "./moreMenu.vue";
import userMenu from "./userMenu.vue";
import { mapGetters } from "vuex";
export default {
  name: "mobileNav",
  components: {
    // moreMenu,
    userMenu
  },
  props: ["mobile", "pisi", "sampleReport", 'closeMoreMenu'],
  data() {
    return {
      menus: {
        home: {
          title: "Home",
          path: "Home",
          active: true,
          img: icons.homeIcon,
          imgActive: icons.homeSolidIcon,
        },
        priceSteps: {
          title: "Estimate",
          path: "priceSteps",
          active: false,
          img: icons.estimateIcon,
          imgActive: icons.estimateSolidIcon,
        },
        blog: {
          title: "Blog",
          path: "blog",
          active: false,
          img: icons.blogIcon,
          imgActive: icons.blogSolidIcon,
        },
        // account: {
        // title: 'Account',
        // path: 'login',
        // active: false,
        // img: icons.blogIcon,
        // imgActive: icons.blogSolidIcon
        // }
      },
      more: {
        title: "More",
        path: "",
        active: false,
        img: icons.moreIcon,
        imgActive: icons.moreSolidIcon,
      },
			account: {
				title: "Account",
				active: false,
				img: icons.accIcon,
				imgActive: icons.accSolidIcon
			},
      showeMoreMenu: false,
			showAccountMenu: false,
      baseWindowHeight: 0,
      showBigMenu: false,
      pathName: "",
    };
  },
  mounted() {
    this.baseWindowHeight = Math.max(window.innerHeight);
    window.addEventListener("scroll", this.scrollHight);
  },
  methods: {
    setActive(menuPath) {
      let help = 0;
      for (let menu in this.menus) {
        // console.log(menuPath)
        // console.log(this.menus[menu].path+'---'+this.menus[menu].active)
        this.menus[menu].active = false;
        if (this.menus[menu].path === menuPath) {
          this.menus[menu].active = true;
          help++;
          // console.log(help)
        }
      }
      if (help >= 1) {
        this.more.active = false;
				this.account.active = false
        this.showeMoreMenu = false;
        this.showAccountMenu = false;
      } else if (menuPath === "estimate") {
        this.menus["priceSteps"].active = true;
        this.more.active = false;
				this.account.active = false
        this.showAccountMenu = false;
      } else if (menuPath === "singleBlog") {
        this.menus["blog"].active = true;
        this.more.active = false;
				this.account.active = false
        this.showAccountMenu = false;
      } else if (menuPath === 'profile' || menuPath === 'login' || menuPath === 'valuations' || menuPath === 'signin') {
        this.account.active = true
        this.more.active = false
      } else {
        this.account.active = false
        this.more.active = true
      }
    },
    deactive() {
      this.more.active = true;
      for (let menu in this.menus) {
        this.menus[menu].active = false;
      }
    },
		accOptions () {
			this.more.active = false
      // this.showeMoreMenu = false;
      this.$emit("more", false);
			for (let menu in this.menus) {
				this.menus[menu].active = false;
      }
			this.account.active = true
      this.showAccountMenu = true
      document.getElementById('app').style.overflowY = 'hidden'
		},
    moreOptions() {
      this.deactive();
      this.account.active = false;
      this.showAccountMenu = false;
      // this.showeMoreMenu = true;
      document.getElementById('app').style.overflowY = 'hidden'
      this.$emit("more", true);
    },
    handleCloseMoreMenu() {
      console.log('i was called');
      this.$emit("more", false);
      // this.showeMoreMenu = false;
      document.getElementById('app').style.overflowY = 'initial'
      this.setActive(this.$route.name);
    },
    scrollHight() {
      // console.log(this.baseWindowHeight)
      var newWindowHeight = Math.max(window.innerHeight);
      // console.log(this.baseWindowHeight)
      // console.log(this.newWindowHeight)

      if (newWindowHeight - 50 > this.baseWindowHeight) {
        this.showBigMenu = true;
        this.$store.commit("setShowBigMenu", true);
      } else {
        this.showBigMenu = false;
        this.$store.commit("setShowBigMenu", false);
      }
    },
    closeAccountMenu () {
      this.showAccountMenu = false
      this.account.active = false
      document.getElementById('app').style.overflowY = 'initial'
      this.$emit("more", false);
      this.setActive(this.$route.name);
    }
  },
  watch: {
    $route: {
      handler() {
        console.log(this.$route.name);
        this.pathName = this.$route.name;
        this.setActive(this.$route.name);
      },
      deep: true,
      immediate: true,
    },
    closeMoreMenu: {
      handler () {
        // this.$emit("more", false);
        // this.showeMoreMenu = false;
        document.getElementById('app').style.overflowY = 'initial'
        // this.setActive(this.$route.name);
      }
    }
  },
  computed: {
    ...mapGetters(["getShowBigMenu"]),
    sampleFix() {
      if (this.sampleReport) {
        return {
          height: "101px",
        };
      } else {
        return {};
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mobileNav {
  position: absolute;
  pointer-events: auto;
  // position:sticky;

  /*position: fixed doesn't work in most of the older versions of iOS and Blackberry.
    I have tried this fix in most of the mobile browsers and it worked smoothly without any JavaScript plugins.*/
  -webkit-backface-visibility: hidden !important;

  left: 0;
  bottom: 0px;
  width: 100vw;
  // height: 60px;
  // left: calc(50% - 375px/2);
  height: 61px;
  // height: fit-content!important;
  border-top: 1px solid #eeeff3;
  background: #ffffff;
  box-sizing: border-box;
  display: flex;
  // justify-content: space-around;
  flex-direction: column;
  //  transform: translate3d(0,0,0);
  .separator {
    padding: 12px 47px 0px 47px;
    width: calc(100% - 94px);
    height: 61px;
    display: flex;
    justify-content: space-around;
  }
  .toolbarItem {
    cursor: pointer;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .iconWrapper {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      padding-bottom: 7px;
      box-sizing: border-box;
      height: 27px;
      .imgMore {
        //  height: 20px;
      }
    }
    .menuTitle {
      // font-family: Gotham Pro;
      // font-style: normal;
      // font-weight: 500;
      // font-size: 10px;
      // line-height: 10px;
      /* identical to box height */
      font-style: normal;
      font-weight: 500;
      font-size: 10px;
      line-height: 10px;

      display: flex;
      align-items: flex-end;
      text-align: center;
      letter-spacing: 0.05em;
      text-transform: capitalize;

      /* black */

      color: #787883;
    }
    .activeTitle {
      font-weight: 500;
      color: #062931;
    }
  }
  .sample-report-bar {
    // position: fixed;
    // left: 0;
    // bottom: 61px;
    height: 40px;
    width: 100%;

    .sample-report-wrapper-in-nav {
      background: $main-color;
      height: 40px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      overflow-x: hidden;
      z-index: 999;
      // align-self: flex-start;
      // top: 100px;

      @keyframes example {
        from {
          right: -200px;
        }
        to {
          right: 2760px;
        }
      }

      .sample-report-in-nav {
        width: auto;
        // font-family: 'Gotham Pro Bold';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #e7eced;
        cursor: default;
        position: absolute;
        animation-duration: 40s;
        animation-timing-function: linear;
        animation-name: example;
        animation-iteration-count: infinite;
        white-space: nowrap;
        animation-fill-mode: forwards;
        right: -200px;
      }
    }
  }
}
.big {
  // height: 61px;
}
.red {
  color: red !important;
}
.alt {
  height: 101px;
}
// just for safari because he is only who support this
@supports (-webkit-overflow-scrolling: touch) {
  .mobileNav {
    // padding-bottom:70px
    // height: 60px; standard
    // height: 90px;
  }
  .big {
    height: 90px !important;
    //  background: brown;
  }
}
</style>
