<template>
  <div class="delete-news-wrapper">
    <optionsPress
        v-for="(i, index) in news " 
        :key="index" 
        :itemPressReleases="i" 
        @gotoonepress="gotoonepress"
     />
    <div v-if="existsMoreNews" class="showeMore" @click="showeMore">Showe more</div>
  </div>
</template>

<script>
import optionsPress from '@/components/newsroom/optionsPress.vue'
import { api } from '@/api'
export default {
  name: 'deleteNews',
  components: {
    optionsPress
  },
  data () {
    return {
      news: [],
      offset: 0,
      count: 5,
      isDelete: false,
      existsMoreNews: true
    }

  },
    activated () {
        this.getNews()
    },
    methods: {
        gotoonepress (id) {
            console.log(id)
            this.deleteNews(id)
            // this.$router.push({name:'onePressReleases', params:{id}})
           // this.$router.push(id)
        },
        getNews () {
            let params= {
                count: this.count,
                offset: this.offset
            }
            api.getNews(params).then( res =>{
                console.log(res.data.data)
                this.news= res.data.data
                this.checkIfExistsMoreNews(this.news)
            }).catch(e => {
                console.log(e);
            })

        },
        showeMore () {
          let offsetCurr = this.news.length
          
          let params = {
             count: this.count,
             offset: offsetCurr
          }
           api.getNews(params).then( res =>{
                console.log(res.data.data)
               // this.news= res.data.data
                for (let i = 0; i < res.data.data.length; i++) {
                  this.news.push(res.data.data[i])
                }
                this.checkIfExistsMoreNews(this.news)
            }).catch(e => {
                console.log(e);
            })

        },
        checkIfExistsMoreNews (news) {
          let params2 = {
            count: 1,
            offset: news.length
          }
          api.getNews(params2).then( res => {
            if(res.data.data.length > 0)
              this.existsMoreNews = true
            else
              this.existsMoreNews = false
          }).catch(e => {
            console.log(e)
          })

        },
        deleteNews (id) {
          let confirm = window.confirm('Are you sure you want to delete this news?')
          if (confirm) {
            let params = {
              id: id.id
            }
            api.deleteNews(params).then(() => {
              this.getNews()
              alert('News deleted successfuly')
            }).catch(() => {
              alert('Error deleting news')
            })
          }
        },
    }


}

</script>

<style lang="scss" scoped>
.delete-news-wrapper {
   padding-bottom: 100px;
  .showeMore {
    margin: 35px auto;
    // font-family: Gotham Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    width:fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4C4C52;
    border: 1px solid #C3C6D0;
    box-sizing: border-box;
    border-radius: 52px;
    padding: 14px 30px;
    cursor:pointer;

  }
}

</style>
