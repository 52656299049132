<template>
  <div class="pricing-wrapper">
    <div class="header-banner">
      <h1>Pricing</h1>
    </div>
    <div class="select-text">
      Select a plan to join
    </div>
    <div class="cards-wrapper">
      <planCard v-for="card in cards" :key="card.title" :cardObj="card" />
    </div>
    <div class="contact-us-wrap">
      <div class="question">
        <h1>Need more?</h1>
      </div>
      <div class="contact-text">
        Contact us at <strong>pricing@carvisor.co.nz</strong> to inquire about custom solutions
      </div>
      <button class="contact-btn">Contact us</button>
    </div>
    <div class="faq-wrap">
      <div class="faq-title">
        <h1>FAQ</h1>
      </div>
      <div class="faq-list">
        <oneFaq v-for="faq in faqList" :key="faq.faq_id" :faq="faq" :showFeedback="true"/>
      </div>
      <div class="faq-btn">
        <span>
          Didn't find the answer to you question? Contact us
        </span>
      </div>
    </div>
    <appStoreCard />
    <footerDown v-if="!isMobile" :white="true"/>
    <div v-else class="filler"></div>
  </div>
</template>

<script>
import planCard from '@/components/pricing/planCard.vue';
import appStoreCard from '@/components/pricing/appStoreCard.vue';
import footerDown from '@/components/newsroom/footerDown.vue';
import oneFaq from '@/components/faq/oneFaq.vue';

export default {
  name: 'pricing',
  components: {
    planCard,
    oneFaq,
    appStoreCard,
    footerDown
  },
  data() {
    return {
      cards: {
        lite: {
          title: 'lite',
          numOfReps: "1 report",
          search: 15,
          canFav: false,
          price: 9.99
        },
        esse: {
          title: 'essential',
          numOfReps: "3 reports",
          search: 30,
          canFav: true,
          price: 19.99,
          isSpecial: true
        },
        pro: {
          title: 'pro',
          numOfReps: "30 reports",
          search: 90,
          canFav: true,
          price: 49.99
        },
      },
      faqList:[
        {faq_id:1, question: 'Why should I use Carvisor’s pricing tool?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:2, question: 'How are the valuations calculated', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:3, question: 'What information is included with the Carvisor Valuation Report?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:4, question: 'How the Carvisor Valuation Report benefits the seller?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:5, question: 'How the Carvisor Valuation Report benefits the buyer?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:6, question: 'How much is the Valuation Report', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:7, question: 'Which plan should I choose', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'}
      ],
      isMobile: false
    }
  },
  created () {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  methods: {
    resize () {
      if (window.innerWidth <= 500) {
      this.isMobile = true
      } else {
      this.isMobile = false
      }
    }
  },
  destroyed () {
    window.removeEventListener("resize", this.resize);
  },
}
</script>

<style lang="scss" scoped>
.pricing-wrapper {
  .header-banner {
    width: 100%;
    height: 185px;
    background: linear-gradient(116.78deg, #104756 13.98%, #3D7484 75.44%);
    position: relative;
    display: flex;
    justify-content: center;

    h1 {
      color: #fff;
      font-size: 36px;
      margin: 0;
      position: absolute;
      bottom: 47px;
    }
  }
  .select-text {
    width: 100%;
    font-size: 17px;
    font-weight: 500;
    color: #787883;
    opacity: 0.7;
    margin: 52px 0 43px 0;
    text-align: center;
  }
  .cards-wrapper {
    width: 1041px;
    height: 255px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    margin-bottom: 98px;
  }
  .contact-us-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .question {
      h1 {
        font-size: 36px;
        line-height: 40px;
        color: #5A7E87;
        margin: 0;
      }
    }
    .contact-text {
      width: 418px;
      line-height: 30px;
      font-size: 18px;
      color: #062931;
      text-align: center;
      margin: 26px 0 41px 0;
    }
    .contact-btn {
      height: 44px;
      width: 160px;
      color: #fff;
      background-color: #FF6359;
      border-radius: 55px;
      border: none;
      font-family: inherit;
      font-size: 15px;
      cursor: pointer;
    }
  }
  .faq-wrap {
    width: 100%;
    margin-top: 99px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .faq-title {
      h1 {
        margin: 0;
        font-size: 36px;
        line-height: 40px;
        color: #5A7E87;
      }
      margin-bottom: 42px;
    }
    .faq-list {
      width: 743px;
      max-width: 743px;
    }
    .faq-btn {
      width: 743px;
      height: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin: 60px 0 100px 0;
      border-radius: 50px;
      border: 1px solid rgba(6, 41, 49, 0.8);

      font-weight: 500;
      font-size: 17px;
      color: #062931;

      &:hover {
        border: 1.8px solid #062931;
      }
    }
  }
  .filler {
    height: 60px;
  }
}

@media only screen and (max-width: 768px) {
  .pricing-wrapper {
    .cards-wrapper {
      width: 466px;
      height: 825px;
      flex-direction: column;

    }
  }
}

@media only screen and (max-width: 480px) {
  .pricing-wrapper {
    .header-banner {
      h1 {
        font-size: 34px;
        left: 16px;
        bottom: 32px;
      }
    }
    .select-text {
      width: 343px;
      text-align: unset;
      margin: 32px auto 23px auto;
      font-size: 16px;
    }
    .cards-wrapper {
      width: 343px;
      margin-bottom: 58px;
    }
    .contact-us-wrap {
      width: 343px;
      text-align: unset;
      align-items: unset;
      margin: 0 auto;

      .contact-text {
        text-align: unset;
        width: 343px;
        margin: 32px 0 30px 0;
      }
    }
    .faq-wrap {
      width: 343px;
      margin: 59px auto 0;

      .faq-list {
        width: 343px;
      }

      .faq-btn {
        width: 343px;
        height: 75px;
        font-size: 16px;
        margin: 40px 0 60px;
        span {
          width: 295px;
          text-align: center;
        }
      }
    }
  }
}
</style>