<template>
  <div class="wrappItemPressReleases">
        <p class="dateItemPressReleases" ><span class="upp">{{setDate}}</span></p>
        <p class="titleItemPressReleases" >{{itemPressReleases.title}}</p>
       <router-link  :to=" this.itemPressReleases.link" class="readMore">Read more<img src="../../assets/images/arrowReadMore.svg" /> </router-link>
  </div>
</template>

<script>
import helper from '@/js/helperDate.js'
export default {
  name: 'itemPressReleasesNew',
    props: {
        itemPressReleases:{
            type: Object
        }
    },
    mounted(){
      this.setDate = helper.formatDate(this.itemPressReleases.date)
    },
    data () {
      return{
        setDate:null
      }
    },
    methods: {
      goTo () {
            //this.$emit('gotoonepress', this.itemPressReleases.link)
      }
    }

}
</script>

<style lang="scss" scoped>
.wrappItemPressReleases {
     margin-bottom: 28px;
     text-decoration: none;
     .upp {
        text-transform: capitalize;
     }
    .titleItemPressReleases {
        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
        /* identical to box height, or 136% */


        /* black */

        color: #062931;

        margin-bottom: 5px;
        margin-top:2px;
        cursor: pointer;

    }
    .dateItemPressReleases {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        letter-spacing: 0.04em;

        /* black */

        color: #062931;
        margin:0;


    }
    .readMore{
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      text-decoration: none;
      // color: #5A7E87;
      color: #467F8F;

      img{
        margin-left: 5px;
      }

    }
    .readMore:hover{
      opacity: .8;
    }
}
// .wrappItemPressReleases:hover{
//     .titleItemPressReleases {
//         color:#000000;
//     }
// }

</style>
