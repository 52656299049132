<template>
  <div>
    <div class="about-outer-wrapper">
      <!-- <div class="img-wrapper"> -->
      <!-- <img src="@/assets/images/ValuationBackground.jpg" alt=""> -->
      <!-- </div> -->
      <div class="content">
        <div class="outer-wrapper">
          <div class="about-video-wrapper">
            <iframe
              v-if="false"
              id="about-video"
              src="https://www.youtube.com/embed/sbcQUgtAOkA"
              title="YouTube video player"
              frameborder="0"
              allow=""
              allowfullscreen
            ></iframe>
            <iframe
              id="about-video"
              src="https://www.youtube.com/embed/_gKQJ9ioKgQ?rel=0"
              title="YouTube video player"
              frameborder="0"
              allow=""
              allowfullscreen
            ></iframe>
          </div>
        </div>
        <h1>About Carvisor</h1>
        <div class="about">
          <p>
            Hello, we are Carvisor and we are here to help you find the best
            price for the vehicle you’re interested in. It is hard to make
            decisions when there is too much information on the used car market,
            too many cars to look at. It’s tiring, time-consuming, and still,
            you're never quite sure how much a certain car is worth?
          </p>
          <p>
            We use proprietary machine learning technology to analyse the car
            market in New Zealand so that we can provide you with reliable car
            valuations and in-depth analytics, creating a more efficient
            car-buying experience. In order to more accurately calculate car
            value, our algorithm updates the data in real time, as we strive to
            keep our vehicle pricing in-line with today’s dynamic environment.
          </p>
          <p>
            The Carvisor team is happy to be at your side to empower you to make
            informed and sensible decisions that you will never regret. Whether
            you are buying or selling, with us, you will always feel like you’re
            the one behind the wheel.
          </p>
        </div>
        <div class="address-wrapper">
          <span v-if="false">FIND US</span>
          <span>CONTACT US</span>
          <div class="lower">
            Carvisor, ltd.<br />
            Suite 11370, 17B Farnham St<br />
            Parnell, Auckland 1052 <br />
            New Zealand<br />
             <a class="linkMail" href="mailto:info@carvisor.co.nz">info@carvisor.co.nz</a>
          </div>
          <span v-if="false">CALL US </span>
          <div v-if="false" class="lower">(09) 869 7399</div>
          <span v-if="false">EMAIL US</span>
          <div v-if="false" class="">info@carvisor.co.nz</div>
        </div>
      </div>
    </div>

    <footerDown v-if="!showMobileNav" :gray="true" />
    <div v-else style="height: 60px"></div>
  </div>
</template>

<script>
import footerDown from "../components/newsroom/footerDown";

export default {
  name: "about",
  components: {
    footerDown,
  },
  data() {
    return {
      showMobileNav: false,
      title: "About us",
      description:
        "Hello, we are Carvisor and we are here to help you find the best price for the vehicle you’re interested in. It is hard to make decisions when there is too much information on the used car market, too many cars to look at. It’s tiring, time-consuming, and still, you're never quite sure how much a certain car is worth?<br><br>We use proprietary machine learning technology to analyse the car market in New Zealand so that we can provide you with reliable car valuations and in-depth analytics, creating a more efficient car-buying experience. In order to more accurately calculate car value, our algorithm updates the data in real time, as we strive to keep our vehicle pricing in-line with today’s dynamic environment.<br><br>The Carvisor team is happy to be at your side to empower you to make informed and sensible decisions that you will never regret. Whether you are buying or selling, with us, you will always feel like you’re the one behind the wheel.",
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  metaInfo() {
    return {
      title: "Carvisor",
      titleTemplate: "%s - " + this.title,
      meta: [
        {
          vmid: "description",
          name: "description",
          // property: 'description',
          content: this.description,
        },
        {
          vmid: "og:description",
          // name: 'og:description',
          property: "og:description",
          content: this.description,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: "Carvisor - " + this.title,
        },
        // {
        //     hid: 'keywords',
        //     name: 'keywords',
        //     content:  this.tempData.title
        // },
        // {
        //     vmid: 'og:image',
        //     property: 'og:image',
        //     itemprop: 'image',
        //     content: this.baseURLImg
        // }
      ],
    };
  },
  methods: {
    resize() {
      if (window.innerWidth <= 500) {
        this.showMobileNav = true;
      } else {
        this.showMobileNav = false;
      }
    },
  },
  mounted() {
    this.$analytics.fbq.event("PageView", {
      content_name: "Aboute page",
    });
  },
};
</script>

<style scoped lang="scss">
.about-outer-wrapper {
  min-height: calc(100vh - 150px);

  .img-wrapper {
    height: 700px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 300px;
    overflow: hidden;
    background-image: url("../assets/images/ValuationBackground3.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;

    img {
      height: 100%;
      margin-top: -150px;
    }
  }

  .content {
    max-width: 1275px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 30px;
    padding-bottom: 114px;
    display: flex;
    flex-direction: row;
    // margin-bottom: 30px;
    flex-wrap: wrap;
    justify-content: space-between;

    .outer-wrapper {
      width: 100%;
      max-width: 735px;
      position: relative;
      display: flex;
      // margin-top: 30px;
      margin-top: 60px;
    }

    .about-video-wrapper {
      position: relative;
      overflow: hidden;
      width: 100%;
      padding-top: 56.25%;
      max-width: 735px;
      border-radius: 5px;

      #about-video {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
      }
    }

    h1 {
      // font-size: 30px;
      // line-height: 30px;
      // margin-top: 40px;
      margin-top: 50px;
      margin-bottom: 34px;
      width: 100%;

      font-weight: bold;
      font-size: 36px;
      line-height: 40px;

      color: #062931;
    }
    .about {
      // font-size: 16px;
      // line-height: 24px;
      // color: #062931;
      max-width: 735px;
      font-weight: normal;
      font-size: 17px;
      line-height: 25px;
      /* or 147% */

      /* black */

      color: #062931;
      p{
        margin-bottom: 15px;
        margin-top: 0;
      }
    }
    .address-wrapper {
      width: 100%;
      margin-top: 46px;
      margin-top: 38px; // 14px up
      margin-top: 24px;
      span {
        // color: #6A7F83;
        // font-size: 13px;
        // line-height: 24px;
        // font-family: 'Gotham Pro Medium';
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        /* or 143% */

        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Gray turquoise - */

        color: #9cb2b5;
      }
      div {
        color: #062931;
        // font-size: 16px;
        // line-height: 24px;
        // font-family: 'Gotham Pro';
        font-size: 17px;
        line-height: 25px;
      }
      div.lower {
        // margin-bottom: 20px;
        a{
          color: #062931;
        }
      }
      
    }
    
  }
  .linkMail{
    text-decoration: none;
    font-weight: 500;
    color: #062931 !important;
    }
    .linkMail:hover{
      opacity: .8;
    }
}

@media only screen and (max-width: 767px) {
  .img-wrapper {
    background-position: calc(50% + 120px) 50% !important;
  }
  // .content {
  //   padding: 0 30px !important;
  // }
}
@media only screen and (max-width: 1150px) {
  .about-outer-wrapper {
    .content {
      flex-direction: column !important;
      // margin-bottom: 84px !important;
      // margin-bottom: 93px;
      padding-left: 24px;
      padding-right: 27px;
      padding-bottom: 93px;
      margin-bottom: 0 !important;

      .outer-wrapper {
        margin-top: 30px;
      }
    }
  }
}
@media only screen and (max-width: 500px) {
  .about-outer-wrapper {
    // padding-bottom: 40px;
    .content {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 53px;
      //  margin-bottom: 100px;
      .outer-wrapper {
        // margin-top: calc( 68px + 10px)!important;
        margin-top: 80px;
      }

      h1 {
        font-size: 34px;
        line-height: 40px;
        margin-top: 30px !important;
        margin-bottom: 34px;
      }
    }
    .about {
      font-size: 16px;
      line-height: 25px;
    }
    .address-wrapper {
      margin-top: 53px !important;
    }
  }
}
@media only screen and (max-width: 450px) {
  .content {
    margin-bottom: 76px !important;
  }
}
</style>
