<template>
  <div class="createNewsWrapper">
    <div class="input-wrapper">
      <span class="title">Title: (1 - 255 characters)</span>
      <input type="text" v-model="news.title" :class="{err:errTitle}" @keyup="errTitle=false" >
    </div>
    <div class="input-wrapper">
      <span class="title">Description: (1 - 500 characters)</span>
      <input type="text" v-model="news.description" >
    </div>
    <div v-if="false" class="input-wrapper">
      <span class="title">Minutes to read:</span>
      <input type="number" v-model="news.readTime">
    </div>
    <div class="input-wrapper">
      <span class="title">Author: (1 - 255 characters)</span>
      <input type="text" v-model="news.author">
    </div>
    <div class="input-wrapper">
      <span class="title">Link: (1 - 255 characters)</span>
      <input type="text" v-model="news.link" :class="{err:errSlug}" @keyup="errSlug=false">
    </div>
    <div class="input-wrapper ">
      <span class="title">Image small view (and for sharing - 1.91:1 aspect ratio): </span>
      <div v-if="prewImage" class="img">
        <img v-if="prewImage" :src="prewImage" alt="" class="current-image">
      </div>
      <input type="file" ref="thumb" @change="setImg($event.target.files)" accept=".png, .jpg, .jpeg" >
      <div v-if="errImg != ''" class="errTxt">
              {{errImg}}
      </div>
    </div>
    <div class="input-wrapper">
      <span class="title">Image alt: (1 - 255 characters)</span>
      <input type="text" v-model="news.thumb_alt">
    </div>
    <div class="input-wrapper">
      <span class="title">Image name: (1 - 255 characters)</span>
      <input type="text" v-model="news.thumb_name">
    </div>
    <div class="input-wrapper ">
      <span class="title">File: </span>
      <input :class="{errTxt:errFile}" type="file" ref="file" @change="setFile($event.target.files)" accept=".pdf">
    </div>
    <div class="input-wrapper">
      <span class="title">Content: (1 - 60 000 characters)</span>
      <vue-editor v-model="news.content" @keyup="errContent=''"></vue-editor>
       <div v-if="errContent != ''" class="errTxt">
         {{errContent}}
      </div>
      <div v-if="resOk != ''" class="errTxt">
         {{resOk}}
      </div>
      <div v-html="getPreview" class="">
      </div>
    </div>
    <div class="flexButton">  
      <div @click="resetFields" class="buttonReset">
        <p>Reset </p>
      </div>
      <div @click="create" class="buttonCreate">
        <p>Create</p>
      </div>
    </div>
  </div>
</template>

<script>

import { api } from '@/api'
import { VueEditor } from "vue2-editor";
export default {
  name: 'createNews',
  components: {
    VueEditor
  },
  data () {
    return {
      news: {
        title: null,
        description: null,
        // date: null, data base set date
        // readTime: null,
        author: null,
      
        link: null,

        thumb: null,
        thumb_alt: null,
        thumb_name: null,
        file: null,
        content: "<h1>Some initial content</h1>",
        type: 1
      },
        prewImage: null,
        errContent:'',
        errTitle:false,
        errSlug:false,
        resOk:'',
        errImg: '',
        errFile:false
   
    }
  },
  methods: {
    create () {
      this.resetErr()
      if(this.checkForm() === false) return
      
      let params = new FormData()
      for (let i in this.news) {
        if(this.news[i] === null) continue
        else params.append(i, this.news[i])
      }
      api.createNews(params).then(res => {
        console.log(res)
        if(res.statusText === 'OK' ){
          this.resOk = 'You have successfully created news.'
          this.resetFields ()
            // setTimeout(() => {
            //     this.resOk = false
            // }, 2000);
        } 

        
      }).catch(e => {
          this.errContent = 'Something went wrong. Check the parameters.'
        console.log(e.message)
      })
    },
    checkForm () {
      this.resetErr()
      for(let i in this.news) {
     // if(i ==='content') console.log(i + ' - ' + this.news[i].length)
        if(i ==='content' && (this.news[i].length <=13 || this.news[i] == null || this.news[i]== '')) {
          this.errContent = 'You must enter some reasonable content. '
        }
        if(i ==='title' && (this.news[i] == null || this.news[i]== '')) {
          this.errTitle = true 
        }
        if(i ==='news' && (this.news[i] == null || this.news[i]== '')) {
           this.errSlug = true
        }
         if(i ==='file' && (this.news[i] == null || this.news[i]== '')) {
           this.errFile = true
        }
      }
       if(this.errContent != '' || this.errTitle == true || this.errSlug == true || this.errFile == true) {
         if ( this.errTitle == true || this.errSlug == true) this.errContent += 'Required fields must be filled.'
          return false
        }else {
         return true 
         }
    },
     resetErr () {
        this.errContent = '' 
        this.errTitle = false 
        this.errSlug = false
        this.errImg = ''
        this.errFile = false
     },
     resetFields () {
       for (let i in this.news) {
         this.news[i] = null
         if(i ==='content') this.news[i] = "<h1>Some initial content</h1>"
       }
       this.prewImage = null
       this.$refs.thumb.value = null
        this.$refs.file.value = null
        this.$refs.file
     },
     setImg (files) {
      //  news.image = $event.target.files[0] => in input field
      this.errImg = '' 
        if (files[0].size > 400000) {
          this.$refs.thumb.value = null
          this.errImg ='The image must not be larger than 400kb'
          return
        }
        this.news.thumb = files[0]
        let reader = new FileReader()
        reader.onload = (e) => {
            this.prewImage = e.target.result
        }
        reader.readAsDataURL(files[0])

     },
     setFile (files) {
        this.errFile = false
        this.news.file = files[0]
     }
  },
  computed: {
    getPreview () {
      return this.news.content.replace('&lt;', '<').replace('&gt;','>')
    }
  },
  mounted () {
    console.log(        this.$refs.file
)
  }
}
</script>

<style lang="scss" scoped>
.createNewsWrapper {
  padding-bottom: 100px;
  .input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .title {
      margin-bottom: 5px;
    }
    //   .img{
    //     box-sizing:border-box;
    //     width: 286px;
    //     height: 215px;
    //     background: #F8F8F9;
    //     overflow: hidden;
    //     img{
    //          object-fit: cover;
    //          min-width: 100%;
    //          min-height: 100%;
    //     }
    // }
    .img{
        box-sizing:border-box;
        width: 280px;
        height: 215px;
        background: #F8F8F9;
        overflow: hidden;
        img{
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            height: 215px;
            width: auto;
        }
    }

  }
  .err{
    border: 1px solid  $main-color;
    border: 1px solid $err-color;
  }
  .errTxt {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        // color: $main-color;
        color: $err-color;
        margin-top: 40px;
        // font-family: 'Gotham Pro Medium'
  }
  .flexButton{
    display: flex;
   .buttonCreate,
   .buttonReset{
        height: 50px;
        width: 170px;
        line-height: 52px;
        color: white;
        // font-family: 'Gotham Pro Medium';
        text-align: center;
        background: $main-color;
        border-radius: 52px;
        cursor: pointer;
        transition-duration: .3s;
        margin-bottom: 40px;
       p {
         margin: auto;

       }
    }
    .buttonReset {
      background: white;
      border: 1px solid $main-color;
      color: $main-color;
      margin-right: 20px;
    }
    .buttonCreate:hover{
      background: $btn-hover;
    }
    .buttonReset:hover{
      border: 1px solid rgb(206, 15, 2);;
      color: rgb(206, 15, 2);
    }
  }
}
</style>
