<template>
  <div class="wrappPressReleases">
    <div v-if="false" class="pressLine"></div>
    <!-- <div v-if="news.years" class="holdContent">
        <div class="title">Press releases</div>
     </div> -->
    <div v-else class="holdContent">
      <div class="title">Press releases</div>
      <div class="roundTxt">
        <p>
          For all press related enquiries regarding Carvisor, or to be added to the
          mailing list, please reach us at
          <a class="link" href="mailto:contact@carvisor.co.nz">contact@carvisor.co.nz</a>.
        </p>
        <p>
          More information about us can be found
          <a class="link" href="about">here</a>.
        </p>
      </div>
      <div v-for="(onenews, ind) in news" :key="ind + Math.random()">
        <p v-if="false" class="year">{{ onenews.year }}<span></span></p>
        <div class="reverse">
          <itemPressReleases
            v-for="(i, index) in onenews.items"
            :key="index"
            :itemPressReleases="i"
            @gotoonepress="gotoonepress"
          />
        </div>
      </div>
      <p v-if="false && existsMoreNews" class="showMore" @click="showeMore">
        Show more
      </p>
    </div>
    <footerDown :white="true" v-if="!showMobileNav" />
    <div v-else style="height: 60px"></div>
  </div>
</template>

<script>
import itemPressReleases from "../components/newsroom/itemPressReleasesNew.vue";
import footerDown from "../components/newsroom/footerDown";
import { api } from "@/api";
export default {
  name: "PressReleases",
  components: {
    footerDown,
    itemPressReleases,
  },
  metaInfo() {
    return {
      title: "Carvisor",
      titleTemplate: "%s - " + this.title,
      meta: [
        { name: "robots", content: "index,follow" },
        {
          vmid: "description",
          name: "description",
          content: this.description,
        },
        {
          vmid: "og:description",
          property: "og:description",
          content: this.description,
        },
        {
          vmid: "og:title",
          property: "og:title",
          content: this.title,
        },
        // ,
        // {
        //     hid: 'keywords',
        //     name: 'keywords',
        //     content:  this.tempData.title
        // },
        // {
        //     vmid: 'og:image',
        //     property: 'og:image',
        //     itemprop: 'image',
        //     content: this.baseURL + this.blog.image
        // }
      ],
    };
  },
  created() {
    window.addEventListener("resize", this.resize);
    this.resize();
  },
  destroyed() {
    window.removeEventListener("resize", this.resize);
  },
  data() {
    return {
      title: "Press releases",
      description: "Press releases",
      arrayPress: [
        {
          id: 1,
          title: "The Ultimate Guide to Buying a Used Car in New Zealand",
          date: "19 Nov 2021",
        },
        {
          id: 2,
          title: "The Ultimate Guide to Buying a Used Car in New Zealand",
          date: "19 Nov 2021",
        },
        {
          id: 3,
          title: "The Ultimate Guide to Buying a Used Car in New Zealand",
          date: "19 Nov 2021",
        },
      ],
      news: [],
      offset: 0,
      count: 5,
      existsMoreNews: true,
      showMobileNav: false,
    };
  },
  mounted() {
    this.getNewsYears();
  },
  methods: {
    resize() {
      if (window.innerWidth <= 500) {
        this.showMobileNav = true;
      } else {
        this.showMobileNav = false;
      }
    },
    gotoonepress(id) {
      console.log(id);
      // this.$router.push({name:'onePressReleases', params:{id}})
      // this.$router.push(id)
    },
    getNewsYears() {
      api
        .getNews()
        .then((res) => {
          //console.log(JSON.stringify( res.data.years))
          this.news = res.data.years;
          // this.checkIfExistsMoreNews(this.news) /* is called in case we need the "show more" option - removed with the condition and remove false from the display condition existsMoreNews */
        })
        .catch((e) => {
          console.log(e);
        });
    },
    getNews() {
      let params = {
        count: this.count,
        offset: this.offset,
      };
      api
        .getNews(params)
        .then((res) => {
          console.log(res.data.data);
          this.news = res.data.data;
          // this.checkIfExistsMoreNews(this.news) /* is called in case we need the "show more" option - removed with the condition and remove false from the display condition existsMoreNews */
        })
        .catch((e) => {
          console.log(e);
        });
    },
    showeMore() {
      let offsetCurr = this.news.length;
      let params = {
        count: this.count,
        offset: offsetCurr,
      };
      api
        .getNews(params)
        .then((res) => {
          console.log(res.data.data);
          // this.news= res.data.data
          for (let i = 0; i < res.data.data.length; i++) {
            this.news.push(res.data.data[i]);
          }
          // this.checkIfExistsMoreNews(this.news) /* is called in case we need the "show more" option - removed with the condition and remove false from the display condition existsMoreNews */
        })
        .catch((e) => {
          console.log(e);
        });
    },
    checkIfExistsMoreNews(news) {
      let params2 = {
        count: 1,
        offset: news.length,
      };
      api
        .getNews(params2)
        .then((res) => {
          if (res.data.data.length > 0) this.existsMoreNews = true;
          else this.existsMoreNews = false;
        })
        .catch((e) => {
          console.log(e);
        });
    },
    pullOutTheYear(news) {
      for (let i = 0; i <= news.length; i++) {
        console.log(news[i]);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrappPressReleases {
  .reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .pressLine {
    width: 100%;
    box-sizing: border-box;
    border-top: 1px solid #dee0e5;
  }
  .holdContent {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    // justify-content: space-between;
    width: 1278px;
    padding-bottom: 113px;
    min-height: 61vh;
    .title {
      font-family: "DM Sans";
      font-style: normal;
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      /* identical to box height, or 111% */

      display: flex;
      align-items: center;

      /* black */

      color: #062931;
      margin-top: 98px;
      margin-bottom: 59px;
    }
    .roundTxt{
        max-width: 733px;
        font-family: 'DM Sans';
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;
        /* or 147% */
        /* black */

        color: #062931;
        margin-bottom: 53px;
        p{
            margin-top: 0;
        }
        p:last-of-type{
            margin-bottom: 0;
        }


        .link{
            // color: $main-color;
            color: #062931;
            text-decoration: none;
            font-weight: 500;
        }
        .link:hover{
            // color: $btn-hover;
            opacity: .8;
        }
    }
    .year {
      width: fit-content;
      font-family: "Gotham Pro Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      text-decoration: line;
      letter-spacing: 0.02em;
      color: #062931;
      position: relative;
      span {
        // display:block;
        position: absolute;
        width: 100%;
        height: 0px;
        left: 0px;
        bottom: 0px;
        border-bottom: 1px solid #062931;
      }
    }
    .showMore {
      text-align: center;
      font-family: Gotham Pro;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      color: #5a7e87;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 1330px) {
  .wrappPressReleases {
    .holdContent {
      box-sizing: border-box;
      margin: 0 auto;
      //width:1278px;
      width: calc(100% - 58px);
      margin: 0 29px;
      .title {
        // margin-top: 46px;
        // margin-bottom: 64px;
        // font-size: 40px;
        // line-height: 46px;
      }
    }
  }
}
@media only screen and (max-width: 620px) {
  //375
  .wrappPressReleases {
    .holdContent {
      padding-top: 47px;
      padding-bottom: 38px;
      margin: 0 auto;
      height: initial;
    }
  }
}
@media only screen and (max-width: 475px) {
  //375
  .wrappPressReleases {
    // background: bisque;
    .holdContent {
      padding-top: 34px;
      // padding-left: 16px;
      // padding-right: 16px;
      height: initial;
      margin-left: 16px;
      margin-right: 16px;
      width: initial;
    }
  }
}
</style>