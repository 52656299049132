<template>
  <div :class="visibility? 'section-wrapper' : 'section-wrapper hideContent'" id="advertised-price-wrapper">
    <div v-if="blur" class="overlay">
      <div v-if="!falseData" @click="$emit('unlock')" class="overlay-btn">
        <img :src="require('@/assets/images/lock.png')" alt="lock image">
        Unlock results
      </div>
    </div>
    <div class="section-header">
      <h3><span>Market breakdown by</span> <span style="white-space: nowrap;">advertised price ($)</span></h3>
       <div>
          <hideShow @visibility="changeVisibility"/>
          <div @click="tooltip = true" v-click-outside="closeTooltip" class="">
            <tooltip
              :isOpen="tooltip"
              :msg="'The market is divided into six price ranges. Figures inside represent the share of vehicles in each group. Indicator shows the position of the vehicle you selected and in which group it belongs.'"
            />
          </div>
      </div>
    </div>
    <div v-show="visibility" class="content">
      <div class="chart-wrapper">
        <canvas v-show="!small" id="advertised-price-chart" width="100%" height="auto"></canvas>
        <div v-show="indicator && !small" :style="{ 'left' : parseInt(indicator) + '%' }" class="indicator">
          <div style="position: relative;">
            <span :class="{ 'left' : leftText }" v-html="blur ? 'k km' : getNumber(price)"></span>
          </div>
        </div>
        <canvas v-show="small" id="advertised-price-chart2" width="100%" height="auto"></canvas>
        <div v-show="indicator && small" :style="{ 'top' : parseInt(indicator) + '%' }" class="indicator horizontal">
          <span v-html="getNumber(price)"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/tooltip2.vue'
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import hideShow from '@/components/HideShow.vue'

export default {
  name: 'advertisedPrice',
  components: {
    Tooltip,
    hideShow
  },
  props: {
    advertisedPrice: {
      required: true
    },
    falseData:{
            required: false,
            // default: false,
            type: Boolean
    },
    price: {
      required: true
    },
    blur: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      leftText: false,
      observed: 0,
      show: false,
      small: false,
      tooltip: false,
      chartData: {
        datasets: [{
          barPercentage: 1.10,
          data: [],
          backgroundColor: 'rgba(241, 135, 0, 0.2)',
          hoverBackgroundColor: 'rgba(241, 137, 0, 0.233)',
          // borderColor: '#C3C6D0',
          borderColor: 'rgba(241, 135, 0, 0.2)',
          borderWidth: 0
        }],
        labels: [
        ]
      },
      indicator: null,
      visibility: true
    }
  },
  created(){
    window.addEventListener("resize", this.resize)
    this.resize()
  },
  async mounted () {
    this.createObserver()
    await this.setUpChartData()
  },
  methods: {
    createObserver() {
      let observer;

      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.6
      };
      observer = new IntersectionObserver(this.handleIntersect, options);
      let boxElement = document.querySelector("#advertised-price-wrapper");
      observer.observe(boxElement);
    },
    handleIntersect () {
      this.observed += 1
      if (this.observed > 1) {
        this.show = true
      }
    },
    resize () {
      if (window.innerWidth <= 768) {
       this.small = true
      } else {
       this.small = false
      }
    },
    closeTooltip (e) {
      if (this.hasParentWithMatchingSelector(e.target, '.tooltip')) {
        return
      }
      this.tooltip = false
    },
    hasParentWithMatchingSelector (target, selector) {
      return [...document.querySelectorAll(selector)].some(el =>
        el !== target && el.contains(target)
      )
    },
    setUpChartData () {
      this.chartData.datasets[0].data = []
      this.chartData.labels = []
      for (var i = 0; i < this.advertisedPrice.length; i++) {
        this.chartData.datasets[0].data.push(this.advertisedPrice[i].value)
        let min = parseFloat(this.advertisedPrice[i].min) / 1000
        let minK = parseFloat(min.toFixed(0))
        let max = parseFloat(this.advertisedPrice[i].max) / 1000
        let maxK = parseFloat(max.toFixed(0))
        let str = minK + ' - ' + maxK + ' k'
        this.chartData.labels.push(str)
      }
    },
    getNumber (num) {
      if (!num) {
        return ''
      }
      let res = ''
      let a = num / 1000
      res += a.toFixed(1)
      res += '&thinsp;k'
      return res
    },
    setUpChart () {
      var ctx = document.getElementById('advertised-price-chart');
      new Chart(ctx, {
        type: 'bar',
        // type: 'horizontalBar',
        plugins: [ChartDataLabels],
        data: this.chartData,
        options: {
          animation: {
            duration: 2000,
          },
          scaleBeginAtZero : true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
              color: '#062931',
              position: 'outside',
              formatter: function(value) {
                return value + '%';
              },
              font: {
                size: this.blur ? 0 : 15,
                family: 'DM sans',
                 weight: 500
                // family: 'Gotham Pro Medium'
              }
            }
          },
          scales:{
            yAxes: [{
              // display: false, //this will remove all the x-axis grid lines
              gridLines: {
                // display: false,
                color: "transparent",
                zeroLineWidth: 1,
                zeroLineColor: "#062931",
                z: 1,
                drawBorder: false
              },
              ticks: {
                beginAtZero:true,
                display: false
              }
            }],
            xAxes: [{
              gridLines: {
                display: false
              },
              ticks: {
                fontColor: "#76898E",
                fontSize: 13,
                fontFamily: 'DM sans'
              }
            }]
          },
          layout: {
            padding: {
              left: 10,
              right: 10,
              top: 60,
              bottom: 10
            }
          },
          tooltips: {
            backgroundColor: 'white',
            displayColors: false,
            bodyFontColor: '#062931',
            // 'DM sans'
            bodyFontFamily:'DM sans',
            // bodyFontFamily: 'Gotham Pro Light',
            bodyFontSize: 14,
            titleFontColor: '#062931',
            titleFontFamily: 'DM sans',
            // titleFontFamily: 'Gotham Pro Medium',
            titleFontSize: 14,
            cornerRadius: 0,
            xPadding: 15,
            yPadding: 15,
            borderColor: '#C3C6D0',
            borderWidth: 1,
            callbacks: {
              label: function(tooltipItem, data) {
                return data.datasets[0].data[tooltipItem.index] + '%'
              },
              title: function(tooltipItem, data) {
                return data.labels[tooltipItem[0].index]
              }
            }
          }
        }
      });
      var ctx2 = document.getElementById('advertised-price-chart2');
      new Chart(ctx2, {
        // type: 'bar',
        type: 'horizontalBar',
        plugins: [ChartDataLabels],
        data: this.chartData,
        options: {
          animation: {
            duration: 2000,
          },
          scaleBeginAtZero : true,
          maintainAspectRatio: false,
          legend: {
            display: false
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
              color: '#062931',
              position: 'outside',
              formatter: function(value) {
                return value + '%';
              },
              font: {
                size: this.blur ? 0 : 16,
                weight: 500,
                family: 'DM sans'
                // family: 'Gotham Pro Medium'
                
              }
            }
          },
          scales:{
            xAxes: [{
              // display: false, //this will remove all the x-axis grid lines
              gridLines: {
                // display: false,
                color: "transparent",
                zeroLineWidth: 1,
                zeroLineColor: "#062931",
                z: 1,
                drawBorder: false,
              },
              ticks: {
                beginAtZero:true,
                display: false
              }
            }],
            yAxes: [{
              gridLines: {
                display: false,
              },
              ticks: {
                fontColor: "#76898E",
                fontFamily: "DM sans",
                fontSize: 13,
                mirror: true,
                padding: 77
              }
            }]
          },
          layout: {
            padding: {
              left: 77,
              right: 150,
              top: 10,
              bottom: 10
            }
          },
          tooltips: {
            backgroundColor: 'white',
            displayColors: false,
            bodyFontColor: '#062931',
            bodyFontFamily: 'DM Sans',
            bodyFontSize: 13,
            titleFontColor: '#062931',
            titleFontFamily: 'DM Sans',
            titleFontSize: 15,
           
            cornerRadius: 0,
            xPadding: 15,
            yPadding: 15,
            borderColor: '#C3C6D0',
            borderWidth: 1,
            callbacks: {
              label: function(tooltipItem, data) {
                return data.datasets[0].data[tooltipItem.index] + '%'
              },
              title: function(tooltipItem, data) {
                return data.labels[tooltipItem[0].index]
              }
            }
          }
        }
      });
    },
     changeVisibility(e){
      this.visibility=e
      setTimeout(() => {
       if( this.observed > 1)
       this.show = e
      }, 100);
    
    }

  },
  watch: {
    show: function (/*newVal*/) {
    //  if(newVal === false) return
      this.setUpChart()
      if (parseInt(this.price) > parseInt(this.advertisedPrice[0].min) && parseInt(this.price) < parseInt(this.advertisedPrice[this.advertisedPrice.length - 1].max)) {
        this.indicator = (100 * (parseInt(this.price) - parseInt(this.advertisedPrice[0].min)))/(parseInt(this.advertisedPrice[this.advertisedPrice.length - 1].max) - parseInt(this.advertisedPrice[0].min))
        if (parseInt(this.price) > ((parseInt(this.advertisedPrice[this.advertisedPrice.length - 1].max) - parseInt(this.advertisedPrice[0].min))/2) + parseInt(this.advertisedPrice[0].min) ) {
          this.leftText = true
        } else {
          this.leftText = false
        }
      }
    },
    advertisedPrice: function () {
      this.setUpChartData()
      Chart.helpers.each(Chart.instances, function(instance){
        if (instance.chart.canvas.id === 'advertised-price-chart' || instance.chart.canvas.id === 'advertised-price-chart2') {
          instance.update()
        }
      })
      this.setUpChart()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/statsOverlay.scss';
.section-wrapper {
 
  background-color: white;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 22px 20px;
  // padding-bottom: 52px; // 74px -52 =22      52-22 =30
  padding-bottom: 19px;
  padding-top: 16px;
  box-sizing: border-box;
  // width: calc(50% - 5px);
  width:100%;
  min-height: 489px;
  // min-height: 510px;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: space-between;

  .section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 46px;
    div{
      display: flex;
      align-items: center;
    }

    h3 {
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: #5A7E87;

      margin: 0px;
    }
    img {
      cursor: pointer;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    // width: 65%;
    width: 80%;
    margin: 0 auto;
    margin-bottom: 20px;
    // margin-top: 54px;

    .exclamation-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;

      img {
        margin-right: 4px;
        margin-bottom: 3px;
      }
    }
    span {
      font-size: 13px;
      letter-spacing: 0.01em;
      color: #132727;
    }

    #advertised-price-chart, #advertised-price-chart2 {
      min-height: 300px !important;
      max-height: 300px !important;
      height: 300px !important;
    }

    .chart-wrapper {
      position: relative;

      .indicator {
        position: absolute;
        // top: 0;
        // top: -8px;
        top: -41px;
        // height: calc(100% - 41px);
        // height: calc(100% - 39px);
        height: 100%;
        width: 1px;
        white-space: nowrap;
        // background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 85%,rgba(0,0,0,0) 100%);
        border-left: 1px solid #FF9F59;
        span {
          // color: #062931;
          // font-weight: bold;
          // font-size: 22px;
          // line-height: 24px;
          margin-left: 5px;
          // background: white;
          // padding: 4px 2px 0 2px;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          // line-height: 35px;
          padding: 0px 2px 0 5px;

          color: #062931;

        }
        span.left {
          margin-left: -105%;
        }
      }
      .indicator.horizontal {
        border-left: none;
        height: 1px;
        width: calc(100% - 87px);
        left: 87px;
        text-align: right;
        // background: -webkit-linear-gradient(180deg, rgba(0,0,0,0.65) 0%,rgba(0,0,0,0) 85%,rgba(0,0,0,0) 100%);
        border-top: 1px solid #FF9F59;
        span {
          // font-weight: bold;
          // font-size: 22px;
          // line-height: 24px;

          // color: #062931;

          // height: auto;
          // margin-top: 5px;
          // background: white;
          // padding: 4px 2px 0 2px;
          font-style: normal;
          font-weight: bold;
          font-size: 25px;
          line-height: 30px;

          text-align: right;

          color: #062931;
        }
      }
    }
  }
}
.section-wrapper.hideContent{
      min-height:fit-content !important;
      padding-bottom: 6px;
      padding-top: 17px;
      .section-header {
        margin-bottom: 10px;
      }
}
@media only screen and (max-width: 1024px){
  .section-wrapper {
    border-radius: 0px;
    width: 100%;
    .content{
      width: 80%;
      // margin: 0 0;
      // padding-left: 47px;
    }
  }
}
@media only screen and (max-width: 768px){
      .section-wrapper{
        min-height: fit-content;
        padding: 20px 16px;
        .content{
          width: 100%;
          padding-left: 0px
        }
      }
 }
@media only screen and (max-width: 550px){
      .section-wrapper{
        // height: 380px;
        padding: 20px 16px;
        padding-bottom: 60px;
        padding-top:15px !important;

        .section-header {
          display: flex;
          flex-direction: row;
          align-items: initial;
          // justify-content: initial;
          margin-bottom: 48px;

          h3 {
            // margin-top: 2px!important;
            margin-top: 5px!important;
            font-weight: 500;
            font-size: 15px;
            line-height: 25px;
            /* or 167% */

            letter-spacing: 0.05em;
            text-transform: uppercase;

            /* Gray turquoise */

            color: #5A7E87;
            width: 200px;
          }
        }
        .section-header>div{
            height: fit-content;
            align-self: flex-start;

            margin-top: 0px;
        }
        .content{
          width: 100%;
        }
      }
 }
</style>
