<template>
  <div class="optionsPress" @click="goTo">
      <div class="imgPress">
         <!-- img :src="itemData.img" /-->
         <img v-if=" itemPressReleases.image!= null" :src="getBaseUrl() + itemPressReleases.image" :alt="itemPressReleases.img_alt ? itemPressReleases.img_alt : 'main-news-image'">
      </div>
      <div class="txt">
        <p class="titleItemPressReleases" >{{itemPressReleases.title}}</p>
        <p class="dateItemPressReleases" >Published on {{setDate}}</p>
      </div>
  </div>
</template>

<script>
import { baseURL } from '@/api'
import helper from '@/js/helperDate.js'
export default {
    name: 'optionsPress',
    props: {
        itemPressReleases:{
            type: Object
        }
    },
    mounted(){
      this.setDate = helper.formatDate(this.blogData.date)
    },
    data () {
      return{
        setDate:null
      }
    },
    methods: {
        goTo () {
             this.$emit('gotoonepress', this.itemPressReleases)
        },
        getBaseUrl () {
            return baseURL
        }
    }

}
</script>

<style lang="scss" scoped>
.optionsPress {
      cursor: pointer;
      padding-bottom: 23px;
      margin-bottom: 10px;
      display:flex;
      border-bottom: 1px solid #DEE0E5;

      .imgPress{
        box-sizing:border-box;
        width: 286px;
        height: 215px;
        min-width: 286px;
        background: #F8F8F9;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            height: 215px;
            width: auto;
        }
    }
    .txt {
        padding: 10px;
        .titleItemPressReleases {
            // font-family: 'Gotham Pro Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 26px;
            color: #062931;
            margin-bottom: 0;
            margin-top:0;
            cursor: pointer;

        }
        .dateItemPressReleases {
            // font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 26px;
            color: #5A7E87;
            margin: 0;

        }
    }
}

</style>
