<template>
  <div v-waypoint="{ active: activeWayPoint, callback: onWaypoint, options: intersectionOptions }"  class="expWrapp"  :style="{backgroundColor: this.backgroundColor}">
      <div class="txt"><p>How it works</p></div>
      <div class="expWrapp2">
        <explanationStep v-for="(data, index) in expData"
            :key="index"
            :expData='data'
            :index='index'

            :playIndex='playIndex'
            @end="playVideo"
            
        /> 
        <!-- @start="changeBackground" -->
      </div>
  </div>
</template>

<script>// 01_final
 import explanationStep from './explanationStep3'
//  import img1 from '@/assets/images/gif/01 12fps.mp4'
//  import img2 from '@/assets/images/gif/02 12 fps.mp4'
//  import img3 from '@/assets/images/gif/03 12 fps.mp4'
 import img1 from '@/assets/images/gif/01_final.mp4'
 import img2 from '@/assets/images/gif/02_final.mp4'
 import img3 from '@/assets/images/gif/03_final.mp4'
 import poster1 from '@/assets/images/0101.png'
 import poster2 from '@/assets/images/0201.png'
 import poster3 from '@/assets/images/0301.png'

export default {
    name: "explanationWrapp",
    components: {
        explanationStep
    },
    data () {
        return {
             expData: [
                 { img: img1, poster: poster1, title: 'Insert car data', description: 'Enter the details of the vehicle you<br /> wish to assess'},
                 { img: img2, poster: poster2, title: 'See your estimate', description: 'Valuation based on current market<br /> prices in New Zealand'},
                 { img: img3, poster: poster3, title: 'Get the full report', description: 'Log in for the full report including<br /> advanced analytics'}
             ],
             playIndex: null,
             colorChosen: false,
             backgroundColor: "#f8f8f9",
             intersectionOptions: {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                threshold: [0.5, 0.5]  // [0.25, 0.75] if you want a 25% offset!
            } ,
            activeWayPoint:true
        }
    },
    methods: {
        playVideo (e) {
            if(e < 2)
                 this.playIndex = e+1
            else this.playIndex = 0

        },
        changeBackground(e) {
            if(this.colorChosen) {
                return;
            }
            setTimeout(() => {
                // Pravimo mali canvas koji se ne vidi
                var canvas = document.createElement("canvas")
                canvas.width = 8;
                canvas.height = 8;

                var ctx = canvas.getContext("2d");
                // Crtamo sliku iz videa
                ctx.drawImage(e.videoRef, 0, 0, 8, 8);

                // Uzimamo prvih 64 pixela - 8x8
                var p = ctx.getImageData(0, 0, 8, 8).data;

                // Ako su svi pixeli crni, nije se jos ucitao video
                if(p[60] == 0 && p[61] == 0 && p[62] == 0){
                    // Probaj ponovo
                    this.changeBackground(e);
                    return;
                }
                // Postavi boju
                this.backgroundColor = "rgb(" + p[60] + "," + p[61] + "," + p[62] + ")";
                this.colorChosen=true;
            }, 30)

        },
         onWaypoint ({ going, direction }) {
            // going: in, out
            // direction: top, right, bottom, left
            if (going === this.$waypointMap.GOING_IN) {
                console.log('waypoint going IN!')
                this.playIndex = 0
                this.activeWayPoint = false
            }
            if (going === this.$waypointMap.GOING_OUT) {
                console.log('waypoint going OUT!')
           }

            if (direction === this.$waypointMap.DIRECTION_TOP) {
               // console.log('waypoint going top!')
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.expWrapp{
    width:100%;
    // padding-top:27px;
    padding-top: 40px;
    padding-bottom: 93px;
    // min-height: 440px;
    height: initial;
    display:flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff!important;
    .txt{
        text-align: center;
        // margin-bottom:14px;
        // margin-bottom:15px;
        margin-bottom: 42px;
        p{
           font-style: normal;
            font-weight: 500;
            
            // font-size: 15px;
            // line-height: 40px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 308% */
            text-align: center;
            letter-spacing: 0.03em;
            text-transform: uppercase;
            // color: #9498A0;
            // color:#134753;
            color: #5A7E87;
            margin:0;
           // margin-top: 5px;
        }
    }
    .expWrapp2{
        // max-width:1905px;
        // width: 80%; 


        /* height: 440px; */
        box-sizing: border-box;
        display: flex;
        justify-content: center !important;
        // align-items: flex-start;

            width:1278px;
            max-width: 100% !important;
            justify-content: space-around !important;
        
    }
}
 @media only screen and (max-width: 1500px){
     .expWrapp{
        .expWrapp2 {
            max-width: 100% !important;
            justify-content: space-between !important;
        }
     }

  }
   @media only screen and (max-width: 1215px){
     .expWrapp{
        .expWrapp2 {
            max-width: 100% !important;
             width: 90%;
            justify-content: space-between !important;
        }
     }

  }
 @media only screen and (max-width: 767px){
     .expWrapp{
        .expWrapp2 {
            max-width: 98% !important;
            display: initial !important;
            .txt{
            margin-bottom:4px !important;
            }
        }
     }

  }

</style>
