import homeIcon from '@/assets/images/icons/home.svg';
import homeSolidIcon from '@/assets/images/icons/homeSolidIcon.svg';
import estimateIcon from '@/assets/images/icons/estimateIcon.svg';
import estimateSolidIcon from '@/assets/images/icons/estimateSolidIcon.svg';
import blogIcon from '@/assets/images/icons/blogIcon.svg';
import blogSolidIcon from '@/assets/images/icons/blogSolidIcon.svg';
import moreIcon from '@/assets/images/icons/more.svg';
import moreSolidIcon from '@/assets/images/icons/moreSolidIcon.svg';
import accIcon from '@/assets/images/icons/accIcon.svg';
import accSolidIcon from '@/assets/images/icons/accSolidIcon.svg';

let icons = {
    homeIcon,
    homeSolidIcon,
    estimateIcon,
    estimateSolidIcon,
    blogIcon,
    blogSolidIcon,
    moreIcon,
    moreSolidIcon,
    accIcon,
    accSolidIcon
};

export default icons;