<template>
  <div class="checkboxBlog" v-if="checkedList.length> 0">
      <div class="setCheck" > 
            <input type="radio"  id="All" value="all" v-model="radioList"  hidden>
            <label for="All" :class=" all === radioList ? 'all checked' : 'all' " >All</label> <img  @click="showLabel=!showLabel" class="arrSearch" :class="{ isActive: !showLabel }" src="../../assets/images/arrSearch.svg" />
        </div>
        <div class="setCheck"  
            v-for="(caat, index) in categorise" :key="index">
            <!-- <input v-if="checkedList.length> 0 && checkedList[index]" type="radio"  name="selectCate" :id="caat.id" :value="caat.id" v-model="radioList" hidden >
            <label v-if="!showLabel"  :class="caat.id ===  radioList ? 'caat checked ' :'caat'" :for="caat.id">{{caat.value}}</label> -->
            <input v-if="checkedList.length> 0 && checkedList[index]" type="radio"  name="selectCate" :id="caat.cat_id" :value="caat.cat_id" v-model="radioList" hidden >
            <label v-if="!showLabel"  :class="caat.cat_id ===  radioList ? 'caat checked ' : 'caat'" :for="caat.cat_id">{{caat.cat_name}}</label>
       
        </div>
        <!-- {{radioList}} -->
  </div>
</template>

<script>
export default {
    name: 'checkboxBlog',
    props:['categorise'],
    data (){
        return{
              checkedList: [],
              all: "all",
              showLabel: true,
              radioList: 'all'  
              }
    },
    created () {
      window.addEventListener("resize", this.resize)
      this.resize()
    },
    destroyed () {
      window.removeEventListener("resize", this.resize)
    },
    mounted(){
        this.setData()
    },
    methods: {
        setData(){
            for(let i = 0; i < this.categorise.length; i++){
                let obj= new Object();
                obj[this.categorise[i].cat_id]= false
                this.checkedList.push(obj)

                // obj.hasOwnProperty(i)
            }

        },
        resize(){
            if (window.innerWidth <= 500) {
            this.showLabel = true
            } else {
            this.showLabel = false
            }
        }
    },
    watch:{
        radioList: {
            handler(){
            this.$emit('checkcate', this.radioList)
        },
        deep: true,
        immediate: true

        }
    }

}
</script>

<style lang="scss" scoped>

    .checkboxBlog{
        display:flex;
        flex-wrap: wrap;
        padding: 10px 0;
        .setCheck{
            padding-right: 25px;
            margin: 5px 0;
            word-wrap: unset;

            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            /* identical to box height, or 133% */

            display: flex;
            align-items: center;
            letter-spacing: 0.01em;

            /* black */

            color: #062931;

            .arrSearch{
                display:none;
            }
            .all{
                cursor: pointer;
            }
            .caat{
                cursor: pointer;
                color: #062931;
            }
            .all:hover, 
            .caat:hover{
                // color: #104756;
                opacity: .8;
            }
            .checked{
                // color: #FF6359;
                color: #467F8F;
            }

        }
    }
    @media only screen and (max-width: 768px){
        .checkboxBlog{
            display:flex;
            flex-wrap: wrap;
            justify-content: center;

            .setCheck{
                padding-right: 25px;
                margin: 5px 0;
                word-wrap: unset;

                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                /* identical to box height, or 133% */

                display: flex;
                align-items: center;
                letter-spacing: 0.01em;

                /* black */

                color: #062931;

                .arrSearch{
                   display:none;
                }
                .caat{
                }

            }
        }
    }

     @media only screen and (max-width: 500px){
        .checkboxBlog{
            display:flex;
            flex-wrap: wrap;
            justify-content: initial;
            padding: 10px 0;
            .setCheck{
                padding-right: 15px;
                margin: 5px 0;
                word-wrap: unset;

                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 20px;
                /* identical to box height, or 133% */

                display: flex;
                align-items: center;
                letter-spacing: 0.01em;

                /* black */

                color: #062931;

                .arrSearch{
                    display:initial;
                    margin-left: 11px;
                    cursor: pointer;
                }

                .isActive {
									transform: rotate(180deg);
                }

            }
        }
    }



</style>