<template>
  <div :class="getIsIosTenAndMore ? 'wrappBadges wrappBadgesIOS' : 'wrappBadges'">
    <div class="center cf">
      <div class="logoMobile">
            <span class='arowOpenBadges'>
                <img @click="goMainSide" :src="require('@/assets/images/logo.svg')" />
            </span>
      </div>
    <div class="count">
      <p @click="openArrow()" :style="{cursor: shouldShowArrow ? 'pointer' : 'initial'}">
        <span class="circleBadge"></span>
         Car<span class="colon">:</span> <!-- {{numMarked + " / "+ numBaige}}  -->
        <span  class='arowOpenBadges'>
          <img :class="arrowOpen ?  'arrRotate' : 'arrNoRotate'" :src="arrowOpen ? require('@/assets/images/arrowDownW.svg'):  require('@/assets/images/arrowDown.svg')" />
        </span>
      </p>
      <div id="triangle-up"  v-if="arrowOpen" ></div>
      <div class="mobile-reset"  v-if="resetPos">
        <restart @changeactivebadge="restartBadge" />
      </div>
    </div>

    <div class="badges" v-if="arrowOpen">
      <div class="border"></div>
      <badge v-for="( value, name, index) in selected" :active="indexBedge >= index" :key="index" :badgeName="name" :value="value" @changeactivebadge="changeActBg" @nextbadge="nextBadge"/>
    
    </div>

    <div class="reset"  v-if="arrowOpen && !resetPos">
     <restart @changeactivebadge="restartBadge" />
    </div>
</div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import badge from'./badge.vue'
import restart from './restartB.vue'
// import eventBus from '@/js/eventBus'
export default {
    name: 'wrappBadges1',
    components: {
        badge,
        restart
    },
    props: [
      'activeBadge'
    ],
    data () {
      return {
        numBaige: 0,
        numMarked: 1,
        rotate: false,
        hover: false,
        arrowOpen: true,
        indexBedge: 0,
        shouldShowArrow:false,
        resetPos: false,
        // pisiIos: false
      }
    },
    created(){
      window.addEventListener("resize", this.resize)
      this.resize()
    },
    mounted() {
      this.init()
      // eventBus.$on('pisi', (e) => {
      //   console.log(e)
      //  this.pisiIos=e
      // })
    },
    methods:{
       
       resize () {
        if (window.innerWidth <= 787) {
          this.arrowOpen = false
          this.shouldShowArrow = true
          this.resetPos = true
           this.$emit('arrowopen', this.arrowOpen)
        } else {
          this.resetPos = false
          this.arrowOpen = true
          this.shouldShowArrow = false
           this.$emit('arrowopen', false)
        }
      },
      init () {
        this.numBaige = this.order.length
        this.setNumMarked()
        if(this.getPageBefore === 'estimate' && window.innerWidth <= 787){
          this.arrowOpen=false
          this.$emit('arrowopen', this.arrowOpen)
        }
      },
      changeActBg(e) {
        this.$emit('changeactivebadge', e)
      },
      nextBadge (e) {
        this.$emit('nextbadge', e)
      },
      setNumMarked () {
        this.numMarked = 1
        for (let i in this.selected) {
          if(this.selected[i] !== null) {
            this.numMarked +=1
          }
         // if(this.numMarked > 8) this.numMarked =8
        }
        
        
      },
       setNumMarked2 (index) {
        this.numMarked = 1
        for (let i=0; i < this.order.length; i++) {
          if(i < index) {
            this.numMarked +=1
          }
        }
      },
       restartBadge(e){
          if(this.noEdit === true) {
            return
          } else{
            this.$emit('changeactivebadge', e)
            }
        },
      checkHover () {
       if(this.rotate) {return}
        setTimeout(() => {
          this.hover=false
        },400)
        // this.hover=false
      },
      mouseOver () {
         setTimeout(() => {
             this.hover=true
        }, 400)
      },
      openArrow () {
        if(this.shouldShowArrow) {
          this.arrowOpen = !this.arrowOpen
          this.$emit('arrowopen', this.arrowOpen)
      }else return
      },
      goMainSide() {
            this.$router.push('/')
      }    
    },
    computed: {
      ...mapState(['order', 'selected', 'isIosTenAndMore']),
      ...mapGetters(['getAllSelected','getIsIosTenAndMore','getPageBefore'])
    },
    watch: {
      activeBadge:{
        handler (setNewValue) {
        this.indexBedge = this.order.indexOf(setNewValue.toLowerCase())
        this.setNumMarked2(this.indexBedge)
      }
    },
    arrowOpen:{
      handler () {
        // this.$emit('arrowopen', this.arrowOpen)
      }
    }
  },
  destroyed() {
    // eventBus.$off('pisi')
    window.removeEventListener("resize", this.resize);
  }

}
</script>

<style lang="scss" scoped>
.wrappBadges {
    width:100%;
    box-sizing: border-box;
    position: relative;
    // min-height: 80px;
    min-height: 70px;
    // background: $green-color;
    background: #104756;
    display: flex;
    flex-wrap: wrap;

   .center{ 
    width: 100%;
    max-width: calc(1340px + 60px);
    max-width: 1276px;
    margin: 0 auto;
    box-sizing: border-box;
    padding: 0 10px;
    position: relative;
    // min-height: 80px;
    min-height: 70px;
    display: flex;
    flex-wrap: nowrap;
   }
   .logoMobile{
     display: none;
   }
    .count{
      float:left;
      order:1;
      min-width:67px;
      // height: 80px;
      height:70px;
      box-sizing: border-box;
      display:flex;
      align-items: center;
      // text-transform: uppercase;
      font-style: normal;
      font-weight: 500;
      font-size: 22px;
      line-height: 40px;
      /* identical to box height, or 182% */

      display: flex;
      align-items: center;

      /* White */

      color: #FFFFFF;
      p{
        .circleBadge{
          display:inline-block;
          width: 8px;
          height: 8px;
          background: #FB9100;
          border-radius: 4px;
          margin-bottom: 3px;
        }
      }
   
      .arowOpenBadges{
        display:none;
      }
    }
    .badges{ 
      order:2;
      // padding-top:15px;
      // padding-top:20px;
      padding-top:15px;
      float:left;
      box-sizing: border-box;
      width:auto;
      // min-height: 80px;
      min-height: 70px;
      height:initial;
      padding-right: 0px;
      display:flex;
      flex-wrap: wrap;
      align-items:center;
      position:relative !important;
      .border{
        display:none !important;
      }
    }
    .reset{
      float: right;
       order:3;
       min-width:130px !important;
       //  height: 80px;
       height:70px;
       // min-width: fit-content;
       box-sizing: border-box;
       display: Flex;
       justify-content:center;
       align-items: center;
       font-weight: 500;
       font-size: 14px;
       /* line-height: 24px; */
       letter-spacing: 0.02em;
       text-transform: capitalize;
       color: #6A7F83;
       margin-left: auto;
       .hover{
         cursor: pointer;
         color: #ffffff;
         background: rgba(255, 255, 255, 0.103);
         /* opacity: 0.1; */
       }
    }
    // .mobile-reset {
    //   justify-self: flex-end;
    // }


}
.rotate {
  animation: rotation 1s;
  color: #ffffff;

}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.cf:after{
  content:" ";
  display:table;
  clear:both;   
  }

@media only screen and (max-width: 1323px){
  .wrappBadges{
    .center{
      .logoMobile{
       display: none;
      }
      .count{
        min-width:88px;
        margin-left: 21px !important;

      #triangle-up{
         display: none;
       }

      }
    
      .reset{
          margin-right: 14px;
      }
    }
  }
}
@media only screen and (max-width: 787px){
  .wrappBadges{
    .center{
        width:100%;
        display: flex;
        flex-wrap: wrap!important;
        // max-width: 1275px;
        margin: 0 auto !important;
        box-sizing: border-box;
        padding: 0 0px;
        // padding-top: 12px;
        position: relative;
        min-height: 70px;
        height: fit-content;
        // min-height: 80px;
        justify-content: flex-start;
        margin: 0 auto;
        box-sizing: border-box;

        .logoMobile{
         display: none;
        }
        .count{
          height: 70px;
          order:1;
          width:36%;
          min-width: 88px;
          margin-left: 40px !important;
          box-sizing: border-box;
          justify-content: flex-start;
          align-items: center;
          font-size: 22px;
          line-height: 40px;
           position: relative;
          p{
            margin:0;
            display: flex;
            min-width: 88px;
            align-items: center;

            .circleBadge{
              margin-right: 5px;
              margin-bottom: 0;
            }

          }
          .colon{
            display: none;
          }
          .arowOpenBadges{
            display: flex;
            align-items: center;
            padding-left:5px;
            padding-top: 2px;
          // transform: rotate(0deg);
          }
          .arrNoRotate{
            transform: rotate(0deg);
          }
          .arrRotate {
            transform: rotate(180deg) !important;
          }
          #triangle-up {
            display: initial;
            position: absolute;
            bottom: 0;
            left: 3px;
            width: 0;
            height: 0;
            border-left: 18px solid transparent;
            border-right: 18px solid transparent;
            border-bottom: 11px solid #467F8F;
          }
  

    }
    .badges{
      order:3;
      width:100%;
      padding: 15px 27px;
      padding-bottom: 25px;
      flex-wrap: wrap;
      position:relative !important;
      height: fit-content;
      background: #467F8F;
      // .border{
      //   display:initial !important;
      //   position: absolute;
      //   top:3px;
      //   left:-5%;
      //   width: 110%;
      //   height: 0;
      //   opacity: 0.1;
      //   border: 1px solid #FFFFFF;
      // }
    }

     .reset{
     
      // height:80px;
      height: initial !important;
      order:3;
      width:100%;
      box-sizing: border-box;
      // padding-right:27px;
      justify-content: flex-start;
      align-items: flex-start;
      background: #467F8F ;
      margin-right: 0 !important;
      padding-left: 27px;
      padding-bottom: 20px;
          p{
              span{
                padding-right: 0px;
              }
          }
      }
    }
  }

    
}
@media only screen and (max-width: 500px){
  .wrappBadges {
     min-height: 60px !important;
     .center{
       min-height: 60px !important;
       padding-left:0;
       padding-right:0;

       .logoMobile{
          height:60px !important;
          display: none !important;
        }
       .count{
         padding-top:0 !important;
         margin-left: 16px!important;
         display: flex !important;
         flex-direction: row !important;;
         align-items: center !important;
         height: 60px !important;
        //  width:fit-content;
        justify-content: space-between;
         width: 100%;
         font-size: 16px;
         line-height: 25px;
         order: 3;
         .arrNoRotate{
            // transform: rotate(0deg);
             transform: rotate(180deg) !important;
          }
        .arrRotate {
            // transform: rotate(180deg) !important;
            transform: rotate(0deg) !important;

        }
         #triangle-up {
            display: initial;
            position: absolute;
            z-index: 2;
            bottom: 60px;
            left: 17px;
            width: 0;
            height: 0;
            border-left: 18px solid transparent;
            border-right: 18px solid transparent;
            border-bottom: 11px solid #104756;
         }
  
       }
       .reset{
         height:60px !important;
         padding-top: 0;
         order: 2;
         padding-left:16px;
        
       }
     }
     .badges{
      order: 1 !important;
      width:100%;
      padding: 0px 16px !important;
      padding-top: 30px !important;
      padding-bottom: 25px !important;
      // div:last-child {
      //   margin-bottom: 25px;
      // }
      // .border{
      //   display:initial !important;
      //   position: absolute;
      //   top:0px !important;
      //   left:-5%;
      //   width: 110%;
      //   height: 0;
      //   opacity: 0.1;
      //   border: 1px solid #FFFFFF;
      // }
     }
  }
}

</style>
<style lang="scss">
@media only screen and (max-width: 500px) {
 
   .wrappBadges{
     position: fixed;
     left:0;
     bottom:61px !important;
     z-index: 2;
   }
   .wrappBadgesIOS{
          // position: fixed;
          // left:0;
          // // bottom:61px !important;
          // z-index: 2;
          // background-color: brown !important;
    } 


 @supports (-webkit-overflow-scrolling: touch) { 

        // padding-bottom:70px
        // height: 60px; standard 
       .wrappBadgesIOS{
          position: fixed;
          left:0;
          // bottom:90px !important;
          z-index: 2;
          // background-color: brown !important;
        } 
    }
}


</style>

