<template>
  <div :class="open? 'oneFaq openWrapp':'oneFaq'">
      <div class="question" @click="openFaq">
          <span> {{faq.question}} </span>
          <img :class="open? 'img open':'img' " src="../../assets/images/faqArrow.svg" />
      </div>
      <div v-if="open" >
        <div class="answer" :class="calcPage ? 'no-pad': ''" v-html="faq.answer"></div>
        <faqFeedback v-if="showFeedback" :id="faq.faq_id" />
      </div>
  </div>
</template>

<script>
import faqFeedback from './faqFeedback.vue'
export default {
    props:{
        faq:{
             required: true
        },
        showFeedback: {
            required: true
        }
    },
    components:{
        faqFeedback
    },
    computed: {
        calcPage() {
            return this.$route.name === "pricing"
        }
    },
    data(){
        return{
            open:false,
        }
    },
    methods:{
        openFaq(){
            this.open= !this.open
        }
    }

}
</script>

<style lang="scss" scoped>
.oneFaq{
        width:100%;
        background: #FFFFFF;
        border: 1px solid #CCCCCC;
        box-sizing: border-box;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
        border-radius: 5px;
        padding: 0 10px;
        margin-bottom:10px;
        // min-height: 60px;
        // height: fit-content;
        transition-duration: .3s;
    .question{
        height: 60px;
        font-style: normal;
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
        /* identical to box height, or 147% */

        display: flex;
        align-items: center;

        /* black */

        color: #062931;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .img{
            margin-left: 11px;
        }
        .img.open{
            transform: rotate(180deg);
        }

       

    }
    .answer{
        padding-top: 8px;
        padding-left: 10px;
        font-style: normal;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;
        /* or 147% */


        /* black */

        color: #062931;
        margin-right: 50px;
        padding-bottom : 32px;

    }
    .no-pad {
        padding-left: 0;
    }
}
.oneFaq.openWrapp{
    border: 1px solid #5A7E87;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.07);
}

  @media only screen and (max-width: 500px){
      .oneFaq{
            padding: 12px 14px 0px 15px;
            .question{
                align-items: flex-start;
                height: unset;
                padding-bottom: 13px;
                font-size: 16px;
                span {
                    max-width: 331px;
                }
                .img{
                    margin-top: 8px;
                    margin-left: 6px;
                }
            }
            .answer {
                font-size: 16px;
                padding-left: 0px;
                padding-bottom: 28px;
            }

      }
  }


</style>