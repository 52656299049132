export default {
  fixParams (selected) {
    let res = {}
    for (var variable in selected) {
      if (Object.prototype.hasOwnProperty.call(selected, variable) && selected[variable]) {
        if (variable === 'make') {
          res.mak_id = selected[variable].key
        } else if (variable === 'model') {
          res.mod_id = selected[variable].key
        } else if (variable === 'year') {
          res.year = selected[variable].key
        } else if (variable === 'body') {
          res.bod_id = selected[variable].key
        } else if (variable === 'fuel') {
          res.fue_id = selected[variable].key
        } else if (variable === 'transmission') {
          res.tra_id = selected[variable].key
        } else if (variable === 'engine') {
          res.ens_id = selected[variable].key
        } else if (variable === 'odometer') {
          res.car_km = selected[variable].key
        }
      }
    }
    return res
  },
  generateHash (selected) {
    return selected.make.value + '-' +
     selected.model.value + '-' +
     selected.body.value + '-' +
     selected.transmission.value + '-' +
     selected.fuel.value + '-' +
     selected.year.value
  },
  createPayload (res) {
    let payload = {}
    if (Object.prototype.hasOwnProperty.call(res, 'makes')) {
      payload.key = 'make'
      let arr = []
      for (let i = 0; i < res.popular.length; i++) {
        let obj = {
          key: res.popular[i].mak_id,
          value: res.popular[i].mak_name,
          popular: true
        }
        arr.push(obj)
      }
      let obj = {
        key: 'line',
        value: 'line'
      }
      arr.push(obj)
      for (let i = 0; i < res.makes.length; i++) {
        let obj = {
          key: res.makes[i].mak_id,
          value: res.makes[i].mak_name
        }
        arr.push(obj)
      }
      payload.value = arr
    } else if (Object.prototype.hasOwnProperty.call(res, 'models')) {
      payload.key = 'model'
      let arr = []
      for (let i = 0; i < res.popular.length; i++) {
        let obj = {
          key: res.popular[i].mod_id,
          value: res.popular[i].mod_name,
          popular: true
        }
        arr.push(obj)
      }
      let obj = {
        key: 'line',
        value: 'line'
      }
      arr.push(obj)
      for (let i = 0; i < res.models.length; i++) {
        let obj = {
          key: res.models[i].mod_id,
          value: res.models[i].mod_name
        }
        arr.push(obj)
      }
      payload.value = arr
    } else if (Object.prototype.hasOwnProperty.call(res, 'modelYears')) {
      payload.key = 'year'
      let arr = []
      for (let i = 0; i < res.modelYears.length; i++) {
        let obj = {
          key: res.modelYears[i].year,
          value: res.modelYears[i].year
        }
        arr.push(obj)
      }
      payload.value = arr
    } else if (Object.prototype.hasOwnProperty.call(res, 'body_styles')) {
      payload.key = 'body'
      let arr = []
      for (let i = 0; i < res.body_styles.length; i++) {
        let obj = {
          key: res.body_styles[i].bod_id,
          value: res.body_styles[i].bod_name
        }
        arr.push(obj)
      }
      payload.value = arr
    } else if (Object.prototype.hasOwnProperty.call(res, 'fuel_types')) {
      payload.key = 'fuel'
      let arr = []
      for (let i = 0; i < res.fuel_types.length; i++) {
        let obj = {
          key: res.fuel_types[i].fue_id,
          value: res.fuel_types[i].fue_name
        }
        arr.push(obj)
      }
      payload.value = arr
    } else if (Object.prototype.hasOwnProperty.call(res, 'transmission_types')) {
      payload.key = 'transmission'
      let arr = []
      for (let i = 0; i < res.transmission_types.length; i++) {
        let obj = {
          key: res.transmission_types[i].tra_id,
          value: res.transmission_types[i].tra_name
        }
        arr.push(obj)
      }
      payload.value = arr
    } else if (Object.prototype.hasOwnProperty.call(res, 'engine_sizes')) {
      payload.key = 'engine'
      let arr = []
      for (let i = 0; i < res.engine_sizes.length; i++) {
        let obj = {
          key: res.engine_sizes[i].ens_id,
          value: res.engine_sizes[i].ens_shortsize.toFixed(1) + '&thinsp;L'
        }
        arr.push(obj)
      }
      payload.value = arr
    }
    return payload
  }
}
