<template>
  <div :class="{ blogPage : blogPage }" class="home-blog-wrapper">
    <div v-if="blogPage" class="blog-title">
      <router-link to="/blog">
        <span>RELATED CONTENT</span>
      </router-link>
    </div>
    <div v-else class="home-blog-title">
      <span>DISCOVER ON OUR BLOG</span>
    </div>
    <div class="content-wrapper">
      <blogAd v-for="(blog, index) in blogs" :key="index" :blogData="blog" />
    </div>
    <div class="btn-wrapper">
      <router-link to="/blog">
        <div class="view-all-btn">
          View all
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { api } from '@/api'
import blogAd from '@/components/blog/blogAd.vue'
export default {
  name: "homeBlogWrapper",
  components: {
    blogAd
  },
  props: {
    blogPage: {
      required: false,
      default: false
    },
    blogId: {
      required: false
    }
  },
  data () {
    return {
      blogs: []
    }
  },
  mounted () {
    this.getBlogs()
  },
  methods: {
    navigateToBlogList () {
      this.$router.push('/blog')
    },
    getBlogs () {
      let params = {
        count: 3,
        offset: 0
      }
      if (this.blogPage && this.blogId) {
        params.id = this.blogId
      }
      api.getBlogs(params).then(res => {
        this.blogs = res.data.data
      }).catch(e => {
        console.log(e);
      })
    }
  },
  watch: {
    blogId: function () {
      this.getBlogs()
    }
  }
}
</script>

<style lang="scss" scoped>
.home-blog-wrapper {
  width: calc(100% - 32px);
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  // padding-top: 20px;
  // padding-bottom: 100px;
  // padding-bottom: 130px;
  // padding-bottom: 90px;
  padding-bottom: 100px;

  a {
    text-decoration: none;
  }

  .btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    .view-all-btn {
      width: 168px;
      height: 50px;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
       /* rgb(6, 41, 49) is the same as #062931; */ 
      border: 1px solid rgb(6, 41, 49, .8);
      // outline: 1px;
      box-sizing: border-box;
      border-radius: 52px;
      text-align: center;
      // background: rgb(255, 255, 255);
      cursor: pointer;
      transition-duration: .1s;
      // color: #4C4C52;
      color: #062931;
      display: flex;
      justify-content: center;
      align-items: center;
      // opacity: 0.8;
    }
    .view-all-btn:hover {
      // border: 1.8px solid #062931;
      border: 2px solid #062931;
      color: #062931;
      // outline: 2px !important;
      // opacity: 1;
    }
  }

  .home-blog-title {
    // font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    max-width: 1278px;
    // height: 52px;
    // margin-bottom: 35px;
    // margin-top: 20px;
    // margin-bottom: 43px;
     margin-bottom: 36px;
    // margin-top: 29px;
    margin-top:40px;

    span {
      // font-family: "Gotham Pro";
      // font-size: 15px;
      // line-height: 36px;
      // letter-spacing: 0.02em;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      letter-spacing: 0.03em;
      text-transform: uppercase;
      text-transform: uppercase;
      // color: #9498A0;
      // color: #134753;
      color: #5A7E87;
    }

    .view-all-btn {
      position: absolute;
      right: 0px;
      width: 168px;
      height: 52px;
      line-height: 52px;
      // border: 1px solid #4C4C52;
       /* rgb(6, 41, 49) is the same as #062931; */ 
      border: 1px solid rgb(6, 41, 49, .8);
      outline: 1px;
      // opacity: 0.8;
      box-sizing: border-box;
      border-radius: 52px;
      text-align: center;
      // background: white;
      cursor: pointer;
      font-size: 15px;
    }
    .view-all-btn:hover {
      // border: 1.8px solid #062931;
      border: 2px solid #062931;
      color: #062931;
      // outline: 2px !important;
      // opacity: 1;
    }
  }
  .blog-title {
    height: 70px;
    height: 96px;
    width: 100%;
    // border-top: 1px solid #787883;
    padding-top: 40px;
    box-sizing: border-box;
    text-align: center;
    
    span {
        // font-family: 'Gotham Pro Medium';
        // font-size: 13px;
        // line-height: 36px;
        // letter-spacing: 0.02em;
        // text-transform: uppercase;
        // // color: #9498A0;
        // cursor: pointer;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height, or 125% */

        // display: flex;
        // align-items: center;
        letter-spacing: 0.03em;
        text-transform: uppercase;
        /* Dark blue - */

        color: #134753 !important;

    }
  }
  .content-wrapper {
    margin-top: 3px;
    max-width: 1278px;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
}
.blogPage.home-blog-wrapper {
  padding-left: 0px;
  padding-right: 0px;
  width: 100%;
  // padding-top: 40px;
  background: inherit;
  // background:#F8F8F9;
  .blog-title {
    height: 96px;// +5px
    height: 101px;
    width: 100%;
    // border-top: 1px solid #787883;
    // padding-top: 40px; -1px
    padding-top: 39px;
  }
}
@media only screen and (max-width: 768px){
  .home-blog-wrapper {
    //  padding-bottom: 121px;
     padding-bottom: 100px;
    .btn-wrapper {
      display: block;
      // margin-left: 28px;
      // margin-left: 30px;
      margin-left: 20px;
    }
    .home-blog-title {
      margin-top: 27px;
       margin-bottom: 24px;
      .view-all-btn {
        display: none;
      }
    }
  }
}
@media only screen and (max-width: 500px){

  .home-blog-wrapper {
    padding-bottom: 0px;
    // padding-bottom: 81px;
    padding-bottom: 120px;
     .home-blog-title {
       height: fit-content;
        // margin-bottom: 26px;
        // margin-top: 17px;
        margin-bottom: 25px;
        margin-top: 25px;
     }
     .blog-title{
       height: 70px;
       padding-top: 25px;
     }
  }
}
</style>
