<template>
  <div id="home-page">
    <main-wrapp />
    <explanationWrapp />
    <wrapVideo />
    <reportWrap />
    <footerUp2 />   <footerUp v-if="false && !remove" />
    <homeBlogWrapper />
    <footerWrapper />
  </div>
</template>

<script>
import explanationWrapp from '../components/explanation/explanationWrapp3.vue'
import wrapVideo from '../components/wrappVideo/wrapVideo.vue'
import reportWrap from '../components/report/reportWrap.vue'
import mainWrapp from '@/components/main/main.vue'
import footerWrapper from '../components/footer/footer-wrapper.vue'
// import footerUp2 from '@/components/footer/footerUp2ParaHand.vue'
import footerUp2 from '@/components/newsletter/newsletter.vue'
import homeBlogWrapper from '@/components/blog/homeBlogWrapper.vue'
export default {
  name: 'Home',
  components: {
    explanationWrapp,
    wrapVideo,
    reportWrap,
    mainWrapp,
    footerWrapper,
    footerUp2,
    homeBlogWrapper
  },
  data (){
    return{
      remove: false,
      title: 'Carvisor',
      titleTemplate: 'Relevant price estimates and analytics',
    }
  },
   metaInfo () {
    return {
      title: this.title,
      titleTemplate: '%s - ' +  this.titleTemplate ,
       meta:[
                {
                    vmid: 'description',
                    name: 'description',
                    content:"Hello, we are Carvisor and we are here to help you find the best price for the vehicle you’re interested in. We use proprietary AI/machine learning technology to analyse the car market in New Zealand, and provide users with fast and reliable car valuations and in-depth analytics."
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content: "Hello, we are Carvisor and we are here to help you find the best price for the vehicle you’re interested in. We use proprietary AI/machine learning technology to analyse the car market in New Zealand, and provide users with fast and reliable car valuations and in-depth analytics."
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.title + ' - '+ this.titleTemplate
                }
                //, {
                //     hid: 'keywords',
                //     name: 'keywords',
                //     content:  this.tempData.title
                // },
            ]
    }
  },
  created(){
    window.addEventListener("resize", this.resize)
    this.resize()
  },
  activated () {
    this.getDropdownData()
  },
  mounted () {
    if (this.$route.hash === '#scrollToHowItWorks') {
      let el = document.querySelector('.expWrapp')
      console.log(el);
      setTimeout(function () {
        el.scrollIntoView({behavior: "smooth"});
      }, 500);
      this.$router.replace({ path: '/', hash: '' })
    }
    this.getDropdownData()
     this.$analytics.fbq.event('PageView', {
        content_name: 'Home Page'
      })
  },
  methods: {
    resize () {
      if (window.innerWidth <= 787) {
        this.remove = true
      } else {
        this.remove = false
      }
    },
    getDropdownData () {
      let payload = {
        key: 'make',
        value: null
      }
      this.$store.commit('setOption', payload)
      this.$store.commit('setIdealPrice', null)
      this.$store.dispatch('getCars')
    }
  }
}
</script>
<style lang="scss" scoped>
#home-page {
  max-width: 100%;
  background: $background-color;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}
</style>
