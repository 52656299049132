<template>
    <div class="wrapForgot"> 
        <div class="enterPassword">
            <div class="title">Change password</div>
            <div class="txt">Please enter your new password and then type it again to confirm. Your password should be strong and unique, but easy for you to remember.</div>
            
            
                <input 
                    :class="passwordErr? 'basic inputErr':'basic'" 
                    v-model="password" 
                    type="password" 
                    placeholder="New password" 
                    v-hideMoblieNav
                >
                <input 
                    :class="passwordErr? 'basic inputErr':'basic'"
                    v-model="retypePassword" 
                    type="password" 
                    placeholder="New password confirmation" 
                    v-hideMoblieNav
                 >

                <div class="button" @click="checkForm">Confirm password change</div>
                <p class="errMsg">{{msg}}</p>
        </div>
    </div>
</template>

<script>
import { api } from '@/api'

export default {
    name: 'enterPassword',
    data(){
        return{
            password:null,
            retypePassword:null,
            passwordErr:false,
            msg:''
        }
    },
     mounted () {
        if (!this.tokenString) {
            this.$router.push({name: 'Home' })
        }
    },
    methods:{
            checkForm(){
            this.passwordErr = null
            this.msg =''

            if(this.password === '' || this.password.length <= 6  ){
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "The password must contain a minimum 7 characters.": "All fields must be entered correctly"
            }else if(this.password !== this.retypePassword) {
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "Passwords don’t match.": "All fields must be entered correctly"
            
            }else {
                console.log('successShow')
                let params={
                    token: this.tokenString,
                    password: this.password
                }
                api.recover(params).then(res => {
                    console.log(res)
                    this.$router.push({name: 'login' })
                }).catch(e =>{
                    this.msg = 'invalid-token'
                    console.log(e)
                })
            }
         },
    }, computed: {
        tokenString () {
        return this.$route.query.token
        }
    }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/singUp.scss';
.wrapForgot { 
    display: flex;
    // width: 100vw;
    height: calc(100vh - 80px);
    justify-content: center;
    // align-items: center;
    // padding-top: 87px;
    padding-top: 98px;
    }

.enterPassword{
    width:406px;
    display: flex;
    height: fit-content;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .title{
        // font-family: Gotham Pro;
        // font-family: 'Gotham Pro Medium';
        // font-style: normal;
        // font-weight: 500;
        // font-size: 28px;
        // line-height: 40px;
        // /* identical to box height, or 143% */

        // // display: flex;
        // // align-items: center;
        // text-align: center;

        // /* black */

        // color: #062931;
        color: #062931;
        font-weight: bold;
        font-size: 36px;
        line-height: 40px;
        /* identical to box height, or 111% */
        text-align: center;
        margin-bottom: 34px;

    }
    .txt{
        // font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        // font-size: 16px;
        // line-height: 24px;
        font-size: 17px;
        line-height: 25px;


        text-align: center;

        /* black */

        color: #062931;
        margin-top: 0px;
        // margin-bottom:23px;
        margin-bottom:33px;

    }

    .button{
        // margin-top:30px;
        margin-top: 40px;
        margin-top:20px;
        max-width:278px;
        width: 100%;
        box-sizing:border-box;
        height: 50px;
        background: #FF6359;
        // border-radius: 5px;
        border-radius: 35px;

        // font-family: Gotham Pro;
        // font-style: normal;
        // font-weight: 500;
        // font-size: 16px;
        // line-height: 24px;
        /* identical to box height, or 150% */
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        /* identical to box height, or 133% */

        letter-spacing: 0.01em;


        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        /* White */

        color: #FFFFFF;
    }
}
@media only screen and (max-width: 500px){
   .enterPassword{
     max-width: 335px;
     margin:0 20px;
    // padding: 0 20px;
    .title{
        // font-family: Gotham Pro;
        // font-family: 'Gotham Pro Medium';
        font-style: normal;
        font-weight: 500;
        font-size: 28px;
        line-height: 40px;
        /* identical to box height, or 143% */

        // display: flex;
        // align-items: center;
        text-align: center;

        /* black */

        color: #062931;
    }
    .txt{
        font-size: 15px;
        line-height: 24px;
        margin-top: 39px;
        margin-bottom:22px;

    }

    .button{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }
}

}
</style>