<template>
  <div>
    <div v-if="!getViewSimilar && showmail" class=" colorBlackBlure">
      <modal v-if="false && !getViewSimilar && showmail" @send="send" :msg="msg" @closeModal="showmailFun" />
      
      <login v-if="!getViewSimilar && showmail && login"  @closeModal="showmailFun" @setlogin="login=false" />
      <signup v-else @closeModal="showmailFun" @setlogin="login=true"/>
    </div>

 <modalShare 
         v-if="showmodal"
         @closeshowmodal="showModalEmit"  
        :descriptionP="descriptionShare"
        :isblog="false"
    />
<div v-if="!getViewSimilar" class="similar-wrapp">
      <div style="position: relative;">
        <div v-if="showLoader" class="loader-wrapper">
          <loader />
        </div>
        <div class="estimate-wrapper" :style="altMobileStyle">

          <div  class="left-wrapper2" :style="{top: altTop + 'px'}">
            <sidebar :activeArr="activeArr"/>
                <!-- <attractivness  @unlock="unlock()" :falseData="falseData" :blur="!stats.general" :score="score"/>
                <avtimeMarket @unlock="unlock()" :falseData="falseData" :blur="!stats.general" :timeMarket="timeMarket"/> -->
          </div><!-- end left-wrapper2-->

          <div class="right-wrapper" >
            <price :price="getIdealPrice" @unlock="unlock()"  :falseData="falseData" :blur="!stats.general" id="estimate"/>
            <div class="main-wrapper" id="debug">
              <div v-if="!stats.general" class="overlay">
                <div v-if="!falseData" @click="unlock()" class="overlay-btn">
                  <img :src="require('@/assets/images/lock.png')" alt="lock image">
                  Unlock results
                </div>
              </div>
              <div :class="!visibility ? 'h3-wrapper visibilityMain' : 'h3-wrapper'" id="trans-trigg">
                <h3>Transaction trigger</h3>
                <div>
                  <hideShow @visibility="changeVisibility"/>
                  <div @click="transactionInfo = true" v-click-outside="closeTransactionInfo" class="">
                    <!-- <img :src="require('@/assets/images/info.png')" alt=""> -->
                    <tooltip
                      :isOpen="transactionInfo"
                      :msg="msgSlide"
                    />
                  </div>
                </div>
              </div>
              <div v-if="visibility" class="slider-parent-wrapper">
                <slider v-if="price" @changePrice="price = $event" :horizontal="horizontal" :price="getPrice" :min="getMin" :max="getMax" :above="getAbove" :below="getBelow" :greatPrice="greatPrice" :badPrice="badPrice" />
              </div>
               <div v-if="visibility" class="instruction">
                    <img :src="require('@/assets/images/sliderMove.svg')" alt="slider move icon">
                    <span>Move the slider to analyze the price</span>
                </div>
                <div v-if="visibility" class="generalMsg">
                    <p v-if="stats.general" v-html="getSliderMsg"> </p>
                </div>
              <div v-if="visibility" class="analytics">
                 <div class="left1">
                 <span class="analGreen">Great price</span> <br /> <span>&lt;</span> <span v-html="getNumber(greatPrice)"></span>
                </div>

                <div v-if="visibility" class="left">
                 
                    <span class="analTxt">Current market price</span> <br><span v-html="getNumber(min)"></span> - <span v-html="getNumber(max)"></span>
                    <br />
                    <span class="analTxt">Fair Price</span> <br /><span v-html="getNumber(below)"></span> - <span v-html="getNumber(above)"></span><br>
              </div>

                <div v-if="visibility" class="right">
                   <!-- <span class="analTxt">Below Market</span> <br /><span>&lt; </span><span v-html="getNumber(below)"></span><br>
                   <span class="analTxt">Above Market</span> <br /><span>&gt; </span><span v-html="getNumber(above)"></span><br> -->
                   <span class="analTxt">Above Market</span> <br /><span>&gt; </span> <span v-html="getNumber(above)"></span><br>
                   <span class="analTxt">Below Market</span> <br /><span>&lt; </span> <span v-html="getNumber(below)"></span><br>
                </div>
              </div>
            </div>


            <div class="report-wrapper2">
                <attractivness @unlock="unlock()" :falseData="falseData" :blur="!stats.general" :score="score" id="model-att"/>
                <avtimeMarket @unlock="unlock()" :falseData="falseData" :blur="!stats.general" :timeMarket="timeMarket"/>
            </div>
         
            <wrappMarketValue @unlock="unlock()" :falseData="falseData" :blur="!stats.general" v-if="getIdealPrice" :valueMarket="setmarket(true)" :i="1" id="market-val"/>
            <!-- <advertised-price-chart @unlock="unlock()" :falseData="falseData" :blur="!stats.advertised_price" :price="price" :advertisedPrice="stats.advertised_price ? stats.advertised_price : advertisedPrice" /> -->
            <pie-chart @unlock="unlock()" :blur="!stats.regions" :falseData="falseData" :regions="stats.regions ? stats.regions : regions" />
            <myChart
                v-if="(!stats.general || falseData) && !getData"
                @unlock="unlock()"
                :blur="!stats.general"
                :falseData="falseData"
            />
            <myChart
                v-else-if="getData"
                :distance_breakdown="distance_breakdown"
                :average_km='average_km'
                :km='getAllSelected.odometer.value'
            />
            <!-- <pie-chart @unlock="unlock()" :blur="!stats.regions" :falseData="falseData" :regions="stats.regions ? stats.regions : regions" /> -->
            <advertised-price-chart @unlock="unlock()" :falseData="falseData" :blur="!stats.advertised_price" :price="price" :advertisedPrice="stats.advertised_price ? stats.advertised_price : advertisedPrice" />
            <wrappMarketValue @unlock="unlock()" :falseData="falseData" :blur="!stats.general" v-if="getIdealPrice" :valueMarket="setmarket(false)" :i="0" id="seller-type"/>
            <expMarket v-if="!stats.general" @unlock="unlock()" :falseData="falseData" :blur="!stats.general" :insights='insights' :interestedBuyersPr='interestedBuyersPr'   :looksBuyersPr='looksBuyersPr'  :looksBuyersN='looksBuyersN' :carOfModelOnMarket='carOfModelOnMarket' id="insights"  />
            <expMarket  v-else @unlock="unlock()" :falseData="falseData" :blur="!stats.general" :insights='insights' :interestedBuyersPr='interestedBuyersPr'   :looksBuyersPr='looksBuyersPr'  :looksBuyersN='looksBuyersN' :carOfModelOnMarket='carOfModelOnMarket' id="insights"  />
            <similar-cars @unlock="unlock()" :falseData="falseData" :blur="!stats.general" :idealPrice="getIdealPrice" :title="'Recently on the market'" v-if="isAllSelected" id="recent"/>
           <shareFooterUp />
       
        </div>
            <newShare v-if="newShareTop" @changeactivebadge="restartBadge" @showmodalopen="showModalEmit" :altTop="altTop"/>
        </div>
        <newShare v-if="!newShareTop" @changeactivebadge="restartBadge" @showmodalopen="showModalEmit" :class="{'is-scrolled': navBgHelper}"/>
        <footerDown v-if="!showMobileNav" :gray="true" />
        <div v-else style="height:60px"></div>
      </div>
    </div>

    <div v-else class="view-similar-weapper">
      <div class="view-similar-weapper-inner">
        <div class="badges">
          <!-- <wrappBadgesEstim @changeactivebadge="restartBadge" /> -->
        </div>
        <div class="view-similar">
          <div class="view-similar-message">
            <span v-if="getDeleted">Oops, this valuation has expired. Please make a new inquiry to get the latest market data for your vehicle of choice.</span>
            <span v-else-if="getMileageLow">Oops! There are no comparable cars on the market. We can’t provide relevant estimation for this mileage. Try increasing the mileage or make a new inquiry.</span>
            <span v-else-if="getMileageHigh">Oops! There are no comparable cars on the market. We can’t provide relevant estimation for this mileage. Try reducing the mileage or make a new inquiry.</span>
            <span v-else-if="getNoPrice">Oops! We don't have enough data for this car at the moment. Take a look at the prices of similar cars below or make a new inquiry.</span>
            <span v-else-if="(getIdealPrice === null || parseInt(getIdealPrice) > 100) && getNumCars > 1">
              Oops! We don't have enough data for this car at the moment. Take a look at the prices of similar cars below or make a new inquiry.
            </span>
            <span v-else-if="(getIdealPrice === null || parseInt(getIdealPrice) > 100) && getNumCars == 0">
              Oops! We don't have enough data for this car at the moment. You can make a new inquiry.
            </span>
            <span v-else-if="(getIdealPrice === null || parseInt(getIdealPrice) > 100) && getNumCars <= 1">
              Oops! We can’t provide relevant estimation as we could detect only 1 car like this on the market. Take a look at the price of that car below or make a new inquiry.
            </span>
            <div @click="resetParameters" class="btn">
              New estimate
            </div>
          </div>
          <div v-if="isAllSelected" class="similar-wrapper">
            <similar-cars :idealPrice="getIdealPrice" :title="'Recently on the market'" :getViewSimilar="getViewSimilar" />
          </div>
        </div>
      </div>

      <footerDown v-if="!showMobileNav" :gray="true" />
      <div v-else style="height:60px"></div>
    </div>
   
  </div>
</template>

<script>
import sidebar from '@/components/sidebar.vue'
import loader from '@/components/loader.vue'
import { api } from '@/api'
import Slider from '@/components/slider/sliderNew.vue'
import PieChart from '@/components/pieChart.vue'
import AdvertisedPriceChart from '@/components/advertisedPriceChart.vue'
import SimilarCars from '@/components/similarCars.vue'
import Tooltip from '@/components/tooltip2.vue'
import { mapGetters } from 'vuex'
import attractivness from '@/components/attractivnesMarket/attractivness.vue'
import avtimeMarket from '@/components/attractivnesMarket/avtimeMarket.vue'
import price from '@/components/attractivnesMarket/newPrice.vue'
import wrappMarketValue from '@/components/writeMarketValue/wrappMarketVaule.vue'
import expMarket from '@/components/writeMarketValue/expMarket2.vue'
// import expMarket from '@/components/writeMarketValue/expMarket.vue'
import myChart from '@/components/myChart.vue'
import helper from '@/js/helper.js'
import shareFooterUp from '@/components/footer/shareFooterUp.vue'
import modal from '@/components/modal.vue'
import login from '@/components/loginComponents/loginCompModal.vue'
import signup from '@/components/loginComponents/signCompModal.vue'
import newShare from '@/components/shareComponents/newShare.vue'
import modalShare from '@/components/shareComponents/modalShare.vue'
// import footerDown from '@/components/footer/footerDown'
import footerDown from '@/components/newsroom/footerDown'
import hideShow from '@/components/HideShow.vue'

export default {
  name: 'estimatePageVersionFourth',
  components: {
    Slider,
    footerDown,
    PieChart,
    AdvertisedPriceChart,
    attractivness,
    avtimeMarket,
    price,
    SimilarCars,
    wrappMarketValue,
    expMarket,
    Tooltip,
    myChart,
    loader,
    shareFooterUp,
    modal,
    login,
    signup,
    newShare,
    sidebar,
    modalShare,
    hideShow

  },
  data () {
    return {
      falseData:true,
      stats: {},
      loaded: 0,
      transactionInfo: false,
      reportInfo: false,
      showLoader: true,
      showStats: true,
      price: null,
      min: null,
      max: null,
      above: null,
      below: null,
      greatPrice: null,
      badPrice: null,
      horizontal: true,
      email: null,
      msg: '',
      score: 0,
      timeMarket:null,
      interestedBuyersPr:0,// promeniti u procentima
      looksBuyersPr:0, //promeniti u procentima
      looksBuyersN:0, // promeniti u broju osoba zainteresovani
      carOfModelOnMarket:0, //promeniti broj koliko trenutno ima odabranog modela na trzistu
      insights:{
        interestedBuyersPr:'',
        looksBuyersPr:'',
        looksBuyersN:'X',
        carOfModelOnMarket:'X',
      },
      distance_breakdown:null,
      average_km:null,
      tooltipOrg1:false,
      tooltipOrg2:false,
      msgfree:'Advanced analytics unravel plenty of details about the car you’re interested in and its current market value. Our report includes model attractiveness, estimated time to sell, private & dealer market value, market breakdowns, interesting insights and a selection of cars currently available on the market.',
      msgSlide:"<p>Our tool constantly analyses the market and forms a fair price range for the searched vehicle. By moving the slider on the diagram you can find three Transaction triggers:</p><p></span><span style='font-weight: 500'>Above the market </span> - shows where the price of the vehicle is higher than the fair price, increasing the time needed to sell the vehicle.</p><p><span style='font-weight: 500'>Below the market</span>  - shows where the price of the vehicle is lower than the fair price, decreasing the time the vehicle spends on the market.</p><p><span style='font-weight: 500'>Great price</span> badge indicates the price that is significantly below fair price, enabling you to sell your car faster.</p><p>Please have in mind that certain cars are in a better condition than others and come with additional equipment which can affect asking price.</p>",
      show:false,
      mobile:false,
      showmail:false,
      getData:false,
      regions: [
        {reg_name: "Auckland", value: 201, percentage: 56.5, reg_id: 74},
        {reg_name: "Wellington", value: 38, percentage: 10.7, reg_id: 75},
        {reg_name: "Canterbury", value: 31, percentage: 10.4, reg_id: 77},
        {reg_name: "Waikato", value: 17, percentage: 4.8, reg_id: 78},
        {reg_name: "Otago", value: 12, percentage: 3.4, reg_id: 81},
        {reg_name: "Other Regions", value: 41, reg_id: 0, percentage: 14.2}
      ],
      advertisedPrice: [
        {value: 32.9, min: 7000, max: 10000},
        {value: 48, min: 10000, max: 13000},
        {value: 14.6, min: 13000, max: 16000},
        {value: 3.9, min: 16000, max: 19000},
        {value: 0.3, min: 19000, max: 22000},
        {value: 0.3, min: 22000, max: 25000}
      ],
      showMobileNav:false,
      login: true,
      reportRouterName : false, // set param is from link from email or or not
      newShareTop: true,
      showmodal: false,
      visibility: true,
      activeArr: [],
      helperArr: [
        'estimate',
        'trans-trigg',
        'model-att',
        'market-val',
        'pie-chart-wrapper',
        'chart',
        'advertised-price-wrapper',
        'seller-type',
        'insights',
        'recent'
      ],
      isLevel: false,
      altTop: 60,
      bgHelper: false,
      navBgHelper: false,
    }
  },
  async created() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
    this.handleResize()
    if (!this.getIdealPrice) {
       console.log('created IF')
      //if(!this.getLoged) 
      this.setUpData()
    } else {
       console.log('created ELSE')
        this.show = true
        //fix
        let params = helper.fixParams(this.getAllSelected)
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('estimate').catch(() => {})
        params.token = token
        if (this.loaded <= 1 || this.getLoged) {
           api.getStats(params).then(res => {
          console.log('349 349 349')
          this.showLoader = false
            this.stats = res.data
            this.loaded++
            localStorage.setItem('loaded', this.loaded)
          // } else if (this.getLoged){
          //   this.stats = res.data
          this.$store.dispatch('setOpinionAction',false)
          this.showStats = true
          this.setMyChartValue(this.stats)
          this.$analytics.fbq.event('trackCustom', {
            subscribed: true,
            email:this.email
          })
          this.email = null
          this.msg= ''
          
        }).catch(e => {
          console.log(e)
          this.showLoader = false
          return
        })
        // } else if(!this.getLoged){
        //   console.log(' NIJE ULOGOVAN OVDE   ')
        }else {
            this.showLoader = false
            this.showmail = true
        }
        // this.showLoader = false
        this.msg= ''
      //endFix
    }
  },
  async mounted () {
    this.setUpPrices()
    this.$analytics.fbq.event('ViewContent', {
      content_name: 'Estimate page',
      make: this.getAllSelected && this.getAllSelected.make && this.getAllSelected.make.value ? this.getAllSelected.make.value : null,
      model: this.getAllSelected && this.getAllSelected.model && this.getAllSelected.model.value ? this.getAllSelected.model.value : null,
      year: this.getAllSelected && this.getAllSelected.year && this.getAllSelected.year.value ? this.getAllSelected.year.value : null,
      body: this.getAllSelected && this.getAllSelected.body && this.getAllSelected.body.value ? this.getAllSelected.body.value : null,
      fuel: this.getAllSelected && this.getAllSelected.fuel && this.getAllSelected.fuel.value ? this.getAllSelected.fuel.value : null,
      transmission: this.getAllSelected && this.getAllSelected.transmission && this.getAllSelected.transmission.value ? this.getAllSelected.transmission.value : null,
      engine: this.getAllSelected && this.getAllSelected.engine && this.getAllSelected.engine.value ? this.getAllSelected.engine.value.replace("&thinsp;", "") : null,
      odometer: this.getAllSelected && this.getAllSelected.odometer && this.getAllSelected.odometer.value ? this.getAllSelected.odometer.value : null
    })
    let loaded = localStorage.getItem('loaded')
    if (loaded) {
      this.loaded = loaded
    } else {
      localStorage.setItem('loaded', 0)
    }


    // google events
     if (!this.getIdealPrice) {
         this.$gtag.event('priceestimate_visit_nodata', {
            'event_category': 'data',
            'event_label': 'no data',
            'value': 1
          })
          this.$gtag.event('priceestimate_view', {
          'event_category': 'data',
          'event_label': ' no data',
          'value': 1
        })
      } else {
         this.$gtag.event('priceestimate_visit_hasdata', {
            'event_category': 'data',
            'event_label': 'has data',
            'value': 1
          })
          this.$gtag.event('priceestimate_view', {
          'event_category': 'data',
          'event_label': 'data',
          'value': 1
        })
      }
  },
  methods: {
    unlock () {
      this.showmail = true
    },
     async setUpData () {
       this.reportRouterName = false
      if (this.$route.name === 'report') {
      console.log('report')
      } else {
        this.showLoader = true
        console.log('big else')
          let params = {}
          await this.$recaptchaLoaded()
          const token = await this.$recaptcha('estimate').catch(() => {})
          await api.getEstimateKey(this.$route.params.key, this.$route.params.odometer, token).then(res => {
            if(res.data && res.data.parameters)
            params = JSON.parse(JSON.stringify(res.data.parameters))
            this.$store.commit('setIdealPrice', res.data.ideal_price)
            this.$store.dispatch('setOpinionAction',false)
          }).catch(e => {
            if (e.response.data.status === 'deleted') {
                // console.log('response.data.status ========================== deleted')
                this.$store.commit('setSimilarCars', true)
                this.$store.commit('setMileageLow', false)
                this.$store.commit('setMileageHigh', false)
                this.$store.commit('setNoPrice', false)
                this.$store.commit('setNumCars', false)
                this.$store.commit('changeDeleted', true)
                let key = e.response.data.cdt_characteristicid
                let hash = helper.generateHash(this.$store.getAllSelected)
                this.$router.push(`estimation/${hash}/${encodeURIComponent(key)}/${params.car_km}`)
            } else if (e.response && e.response.data.message === 'no-cars') {
                this.$store.commit('setSimilarCars', true)
                this.$store.commit('setMileageLow', false)
                this.$store.commit('setMileageHigh', false)
                this.$store.commit('setNoPrice', false)
                this.$store.commit('setNumCars', e.response.data.num_cars)
            } else if (e.response && e.response.data.message === 'bad-request') {
                this.$store.commit('setNoPrice', false)
                if (e.response.data.milage && e.response.data.milage === 'low') {
                  this.$store.commit('setMileageLow', true)
                } else {
                  this.$store.commit('setMileageLow', false)
                }
                if (e.response.data.milage && e.response.data.milage === 'high') {
                  this.$store.commit('setMileageHigh', true)
                } else {
                  this.$store.commit('setMileageHigh', false)
                }
                this.$store.commit('setSimilarCars', true)
                this.$store.commit('setNumCars', null)
            } else if (e.response && e.response.data.message === 'no-price') {
                this.$store.commit('setSimilarCars', true)
                this.$store.commit('setMileageLow', false)
                this.$store.commit('setMileageHigh', false)
                this.$store.commit('setNoPrice', true)
                this.$store.commit('setNumCars', e.response.data.num_cars)
            } else {
                this.$store.commit('setSimilarCars', false)
                this.$store.commit('setMileageLow', false)
                this.$store.commit('setMileageHigh', false)
                this.$store.commit('setNoPrice', false)
                this.$store.commit('setNumCars', null)
                this.$router.push('/')
            }
            if(e.response && e.response.data && e.response.data.parameters) {
            params = JSON.parse(JSON.stringify(e.response.data.parameters))
            }         
          })  // END catch 
          params.car_km = this.$route.params.odometer
          if (!this.isAllSelected) {
            await this.$store.dispatch('setParams', params)
          }
          this.setUpPrices()
      }
      // END big else

// for link come      
      //fix
      let params = helper.fixParams(this.getAllSelected)
      params.report = this.reportRouterName
     
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('estimate').catch(() => {})
      params.token = token
        api.getStats(params).then(res => {
          
        console.log('560 560 560')
        
      // refresh page
          if (this.loaded <= 1 ) {
            this.stats = res.data
            this.loaded++
            localStorage.setItem('loaded', this.loaded)
          } else if (this.getLoged){
            this.stats = res.data
          } else if (this.$route.name === 'report') {
            this.showmail = false
          } else {
            this.showmail = true
          }
          this.showStats = true
          //this.showmail = false
          this.setMyChartValue(this.stats)
          this.$store.dispatch('setOpinionAction',false)
          this.$analytics.fbq.event('trackCustom', {
            subscribed: true,
            email:this.email
          })
          this.email = null
          this.msg= ''
          this.showLoader = false
 
        }).catch(e => {
          console.log(e)
          this.showLoader = false
        })
        //endFix
    },
    resetParameters () {
      this.$store.commit('resetValue')
      this.$router.push('/priceSteps')
    },
    closeTransactionInfo (e) {
      if (this.hasParentWithMatchingSelector(e.target, '.tooltip')) {
        return
      }
      this.transactionInfo = false
    },
    closeReportInfo (e) {
      if (this.hasParentWithMatchingSelector(e.target, '.tooltip')) {
        return
      }
      this.reportInfo = false
    },
    hasParentWithMatchingSelector (target, selector) {
      return [...document.querySelectorAll(selector)].some(el =>
        el !== target && el.contains(target)
      )
    },
    setUpPrices () {
      this.price = parseInt(this.getIdealPrice)
      this.above = parseInt(this.price * 1.1)
      this.below = parseInt(this.price * 0.95)
      this.greatPrice = parseInt(this.price * 0.9)
      this.badPrice = parseInt(this.price * 1.15)
      this.min = parseInt(this.price * 0.75)
      this.max = parseInt(this.price * 1.25)
    },
    getNumber (num) {
      if (!num) {
        return ''
      }
      if (!this.stats || !this.stats.general) {
        return '$'
      }
      num = Math.floor(num/5)*5
      let res = '$&thinsp;'
      res += num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&thinsp;');
      return res
    },
    restartBadge (e) {
        console.log('restartttttttt' + e)
      this.$router.push('/priceSteps')
    },
    handleResize () {
      if (window.innerWidth <= 500) {
        this.showMobileNav = true
        this.newShareTop = false
      } else {
        this.showMobileNav = false
        this.newShareTop = true
      }

      if(window.innerWidth < 550) {
        this.mobile = true
      }else{
        this.mobile = false
      }
      
      if (window.innerWidth <= 550) {
        this.horizontal = false
      } else {
        this.horizontal = true
      }

      if (window.innerWidth < 1024) {
        this.tooltipOrg1 = false
        this.tooltipOrg2 = true
      } else {
        this.tooltipOrg1 = true
        this.tooltipOrg2 = false
      }
    },
   
    sampleReaport () {
      let routeData = this.$router.resolve({name: 'sampleReport'});
      window.open(routeData.href, '_blank');
    },
    matchemail (v) {
      let mailForm = /^(\w+.?)+@(\w+.?)+\.\w+$/
      if (v.match(mailForm)) {
        return true
      } return false
    },
    setmarket (x) {
      let obj= {}
      if(x) {
           obj['Private']= this.isShowPrice(this.stats.general ? this.stats.general.private_avg_price : 0 )
           obj['Dealer']=  this.isShowPrice(this.stats.general ? this.stats.general.dealer_avg_price : 0 )
       } else {
          obj['Private']= this.stats.general ? this.stats.general.private_percentage : 0
          obj['Dealer']= this.stats.general ? this.stats.general.dealer_percentage : 0
       }
      return obj
    },
    helpFunction (x) {
       if(x == null )  return null
        else if (x < 100 ) return null
        else return x
    },
    setMyChartValue (res) {
      if(res !== undefined && res.general !== undefined){
        this.distance_breakdown = res.distance_breakdown
        this.average_km = res.general.average_km

        this.insights.interestedBuyersPr = res.general.body_percentage
        this.insights.looksBuyersPr = res.general.view_precentage
        this.insights.looksBuyersN = res.general.watchers
        this.insights.carOfModelOnMarket = res.general.on_market

        this.interestedBuyersPr = res.general.body_percentage
        this.looksBuyersPr = res.general.view_precentage
        this.looksBuyersN = res.general.watchers
        this.carOfModelOnMarket = res.general.on_market

        this.score= res.general.model_attractiveness
        this.timeMarket= res.general.avg_time_on_market
        this.getData=true
      }
    },
    isShowPrice (setPrice) {
      //console.log(setPrice)
      if (this.isIdealInPriceRange (this.stats.general ? this.stats.general.private_avg_price : 0, this.stats.general ? this.stats.general.dealer_avg_price : 0, this.getIdealPrice )
         && this.isPriceInPercentRange (setPrice, this.getIdealPrice) ) {
        return this.helpFunction(setPrice)
      }
      return null
    },
    isPriceInPercentRange ( price, ideal) {
      if(price < ideal * 0.9 || price > ideal * 1.1) return false
      return true
    },
    isIdealInPriceRange (privatePr, dealer, ideal){
      if (privatePr == null || dealer == null) return true
      if((ideal >= dealer && ideal >= privatePr)
        || (ideal <= dealer && ideal <= privatePr)) {
                return false;
      }
      return true;
    },
    showmailFun () {
      this.showmail = false
      this.falseData=false
      this.msg = ''
    },
    showModalEmit () {
      this.showmodal = !this.showmodal
    },
    changeVisibility(e){
      this.visibility=e
    },
    handleScroll () {
      let oldArr = this.activeArr;
      let newArr = [];
      for(let id in this.helperArr) {
        let rect = document.getElementById(this.helperArr[id]).getBoundingClientRect();
        let res = rect.top >= 0 && rect.bottom - 50 <= (window.innerHeight || document.documentElement.clientHeight) 
        newArr.push( res )
      }
      let isSame = true;
      for(let i = 0; i < newArr.length; i++){
        if(newArr[i] !== oldArr[i]){
          isSame = false;
        }
      }
      if (!isSame) {
        this.activeArr = newArr;
      }
      if (document.querySelector('.newPrice').getBoundingClientRect().top <= 60) {
        this.isLevel = true;
        this.calcTop();
      } else {
        this.isLevel = false;
        this.calcTop();
      }

      let el = document.getElementById("debug")
      if(el.getBoundingClientRect().top + 10 <= 0) {
        if (!this.bgHelper) {
          this.bgHelper = true
        }
      } else {
        if (this.bgHelper) {
          this.bgHelper = false
        }
      }
      this.calcNavBg();
    },
    calcTop () {
      if(this.isLevel) {
        if (document.querySelector('.newPrice').getBoundingClientRect().top >= 0) {
          this.altTop = 90 + (60 - document.querySelector('.newPrice').getBoundingClientRect().top)
        } else {
          this.altTop = 142 + Math.abs(document.querySelector('.newPrice').getBoundingClientRect().top)
        }
      } else {
        if (this.altTop !== 60) {
          this.altTop = 60;
        }
      }
    },
    calcNavBg () {
      if (window.scrollY > 0) {
        if(!this.navBgHelper) {
          this.navBgHelper = true
        }
      } else {
        if(this.navBgHelper){
          this.navBgHelper = false
        }
      }
    }

  },
  computed: {
    ...mapGetters(['getIdealPrice', 'getAllSelected', 'getViewSimilar', 'getMileageHigh', 'getMileageLow', 'getNoPrice', 'getNumCars', 'getDeleted', 'getLoged']),
    isAllSelected () {
      if (this.getAllSelected.make && this.getAllSelected.model &&
          this.getAllSelected.year && this.getAllSelected.body &&
          this.getAllSelected.fuel && this.getAllSelected.transmission &&
          this.getAllSelected.engine && this.getAllSelected.odometer) {
        return true
      } else {
        return false
      }
    },
    titleShare () {
        return  this.getAllSelected.make + ' '+ this.getAllSelected.model
    },
    descriptionShare () {
        return this.getAllSelected.make + ' '+ this.getAllSelected.model+' '+this.getAllSelected.year+ ' '+ this.getAllSelected.body
    }, 
    getPrice () {
      return this.stats && this.stats.general ? this.price : 2
    },
    getAbove () {
      return this.stats && this.stats.general ? this.above : 0
    },
    getBelow () {
      return this.stats && this.stats.general ? this.below : 0
    },
    getMax () {
      return this.stats && this.stats.general ? this.max : 3
    },
    getMin () {
      return this.stats && this.stats.general ? this.min : 1
    },
    getSliderMsg () {
      if (this.price < this.below) {
        return `This price is <b>${this.getNumber(this.below - this.price)}</b> bellow fair price.`
      } else if (this.price >= this.below && this.price <= this.above) {
        return `This is the fair market price.`
      } else {
        return `This price is <b>${this.getNumber(this.price - this.above)}</b> above fair price.`
      }
    },
    altMobileStyle () {
      if(this.bgHelper && window.innerWidth <= 550) {
        return {
          backgroundColor: '#eeeff3'
        }
      } else {
        return {}
      }
    },
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  watch: {
    price: function () {
      this.getSliderMsg
    },
    getIdealPrice :{
        handler () {
            if(this.getIdealPrice) {
              this.show = true
            }
        }
    },
    getLoged:{
      handler(newVal) {
        if(newVal){ 
          this.setUpData()
          this.showmailFun()
          }
      }
    }

 }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/statsOverlay.scss';
.estimate-wrapper-background {
  .view-similar-weapper {
    min-height: calc(100vh - 80px);
    background: white;

    .view-similar-weapper-inner {
      min-height: calc(100vh - 150px);

      .view-similar {
        .view-similar-message {
          min-height: 430px;
          height: fit-content;
          margin: 0 auto;
          max-width: 582px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          box-sizing: border-box;

          span {
            margin-bottom: 39px;
            margin-top: -16px;

            font-style: normal;
            font-weight: normal;
            font-size: 22px;
            line-height: 30px;
            /* or 136% */

            text-align: center;

            /* black */

            color: #062931;

          }
          .btn {
            height: 50px;
            width: 170px;
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;

            display: flex;
            justify-content: center;
            align-items: center;
            // text-align: center;
            letter-spacing: 0.01em;
            color: #FFFFFF;

            background: #FF6359;
            border-radius: 52px;
            cursor: pointer;
            transition-duration: .3s;
          }
          .btn:hover {
            background: $btn-hover;
          }
        }
        .similar-wrapper {
          max-width: 886px;
          padding: 0 20px;
          box-sizing: border-box;
          margin: 0 auto;
          // border-top: 1px solid #C3C6D0;
          margin-bottom: 60px;

          // .section-wrapper {
          //   padding-left: 0px;
          //   padding-right: 0px;
          //   min-height: 0px;
          // }
        }
      }

    }
  }

  
  .h3-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    margin: 0 20px;
    margin-bottom: 30px;
    

    h3 {
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        color: #5A7E87;
        margin: 0px;
        margin-top: -5px;

    }
    div{
      display:flex;
      
      align-items: initial;
      justify-content: flex-end;
    }
    img {
      cursor: pointer;
    }
  }
  .h3-wrapper.visibilityMain {
    // padding-top: 15px !important;
    // margin-bottom: 15px !important;
  }
  .estimate-wrapper {
    max-width: 1275px;
    margin: 0 auto;
    padding: 80px 10px 80px 10px;
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    position: relative;

    .left-wrapper,
    .left-wrapper2,
    .right-wrapper {
      border-radius: 5px;
      box-sizing: border-box;
    }

    .left-wrapper2{
      margin-right: 10px;
      max-width: 311px;
      overflow: hidden;
      width: 100%;
      padding: 0;
      height: 625px;
      display:flex;
      flex-direction: column;
      justify-content: center;

      position: sticky;
      position: -webkit-sticky;
      top: 60px;
    }
  
    .right-wrapper {
      max-width: 846px;
      width: 100%;
      // position: relative;
      .report-wrapper2 {
          display: flex;
          margin-top: 10px;
        
      }
      .main-wrapper {
        background: white;
        min-height: 665px;
        display: block;
        box-sizing: border-box;
        position: relative;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
        border-radius: 0px 0px 5px 5px;
        padding-bottom: 6px;
        // add this for gap in safari
          position: relative;
          height: fit-content;
          min-height: fit-content;
       
        .slider-parent-wrapper {
          margin-bottom: 100px;
          margin-top: 37px;
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .instruction{
            display:flex;
            justify-content: flex-end;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;

            color: #B7B9C0;
            span{
              // margin-left: 20px;
              margin-left: 5px;
              margin-right: 20px;
              margin-top: 5px;
              margin-bottom: -5px;
            }
            img {
              margin-bottom: -5px;
              margin-top: 5px;
            }
        }
        .generalMsg{
            background: #FEF5EB;
            box-shadow: inset 0px 0px 8px rgba(0, 0, 0, 0.05);
            border-radius: 0px;
            height: 59px;
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;

            color: #F18700;
            margin-top: 20px;
            margin-bottom: 42px;
           
        }

        @media only screen and (max-width: 450px){
          .slider-parent-wrapper {
            margin-top: 73px;
            margin-bottom: 60px;
            padding: 0px;
          }
        }
        .analytics {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            font-family:'DM Sans';
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 20px;
            /* or 133% */

            letter-spacing: 0.01em;

            /* black */

            color: #062931;
            margin-left:41px;
            margin-bottom: 60px;
            margin-bottom: 10px;
            padding-bottom: 28px;

            .left1 {
                margin-right: 81px;
                width: fit-content;
                .analGreen{
                  margin-bottom: 0px;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 25px;
                  letter-spacing: 0.01em;
                  color: #06DBF9;
                }
            }
            .left{
                margin-right: 50px;
                width: 204px;

            }
            .right, .left, .left1{
              span{
                display: inline-block;
                margin-bottom: 10px;
                font-style: normal;
                font-weight: 500;
                font-size: 17px;
                line-height: 25px;
                letter-spacing: 0.01em;
              } 

              .analTxt{
                margin-bottom: 0px;
                font-style: normal;
                font-weight: normal;
                font-size: 16px;
                line-height: 25px;
                letter-spacing: 0.01em;
                color: #9498A0;
              }
            }
            span {
              font-weight: 700;
            }
        }
      }

    }
  }
  .is-scrolled {
    background-color: rgba(252, 252, 252, 1);
    border-bottom: 1px solid #dee0e5;
  }
}
.msg{
    height: 24px;
    margin: 14px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    color: $main-color !important;
}
.colorBlackBlure{
  position: fixed !important;
  top: 0;
  left: 0;
  background: #062931f1;
  // opacity: 0.94;
  width: 100vw;
  height: 100vh;
  z-index:1001;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto !important;
}

  


@media only screen and (max-width: 1254px){
  // .estimate-wrapper-background {
   
  //   .estimate-wrapper {
  //     .left-wrapper2 {
  //       display: none;
  //     }
  //   }
  // }
  
}
@media only screen and (max-width: 1024px){
  .estimate-wrapper-background {
   
    .estimate-wrapper {
      width: 100%;
      padding: 0px;
      padding-bottom: 0;
      .left-wrapper,
      .left-wrapper2 {
        display: none;
      }
      .line {
        display: none;
      }
      .right-wrapper {
        max-width: calc(100% - 70px); // 70px side newShare
        height: 100%;
        box-sizing: border-box;

        .main-wrapper {
          display: block;
          margin-bottom: 10px;
          padding-bottom: 1px;
          //   padding: 20px;
          border-radius: 0px;
          position: relative;
          height: fit-content;
          min-height: fit-content;

            .h3-wrapper {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-between;
              padding-top: 20px;
              margin: 0 20px;
              // margin-right: 0;
              // padding-right: 20px;
              margin-bottom: 20px;
              
              div{
                display:flex;
                // width: 94px;
                align-items: center;
                justify-content:space-between !important;
                div{
                  width:fit-content
                }
              }
            }
            .h3-wrapper.visibilityMain {
              padding-top: 17px !important;
              margin-bottom: 15px !important;
            }
          }
        
           
      .line{
             display:block !important;
              box-sizing: border-box;
              width:100%;
              height: 3px;
              position: absolute;
              left: 0px;
              bottom: 0px;
              background: #103D49;
      }
      .report-wrapper2{
       // height:220px;
        box-sizing:border-box;
        width:100%;
        display: flex;
        flex-direction: column;
        div{
          box-sizing: border-box;
          width:49.25%;
          display:block;
        }
        div:first-of-type{
          margin-right: 1.5%;
        }

      }
      }
    }
  }
}
@media only screen and (max-width: 768px){
   .estimate-wrapper-background {
     .estimate-wrapper {
       .right-wrapper{
         
           .report-wrapper2{
       // height:220px;
          box-sizing:border-box;
          width:100%;
          display: block;
          div{
            box-sizing: border-box;
            width:100%;
            display:block;
          }
          div:first-of-type{
            margin-right: 0 !important;
          }

        }
       }
     }
      .view-similar {
        .view-similar-message {
          box-sizing: border-box;
          
         min-height: 396px !important;
         span{
          //  margin-top: 0px!important;
           padding-top: 63px;
           margin-bottom:42px!important ;
         }
        }
         
      }
   }

}
@media only screen and (max-width: 550px){
  .estimate-wrapper-background {
        .estimate-wrapper {
            margin-bottom: 0;
            .right-wrapper {
                // max-width: 100%;
                .main-wrapper{
                    //  padding-left: 16px;
                    //  padding-right: 16px;

                    .h3-wrapper {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: space-between;
                      // padding-top: 20px;
                      padding-top: 22px;
                      margin: 0 20px;
                      // margin-right: 0;
                      // padding-right: 20px;
                      margin-bottom: 20px;
                       h3 {
                          // margin-top: 2px!important;
                          // margin-top: 5px!important;
                           margin-top: 3px!important;
                       }
                      
                      div{
                        display:flex;
                        // width: 94px;
                        align-items: center;
                        justify-content:space-between !important;
                        margin-top: 2px;
                        div{
                          width:fit-content
                        }
                      }
                    }



                    .instruction, .generalMsg{
                        padding-left: 30px;
                        text-align: left;
                        justify-content: flex-start;
                    }
                    .instruction {
                      img {
                        transform: rotate(90deg);
                      }
                    }
                    .generalMsg{
                        margin-top:15px;
                        margin-bottom: 35px;

                    }
                    .analytics {
                        display: flex;
                        flex-direction: column;
                        margin-left: 30px;
                        padding-bottom: 35px; // 55 - 20 ->have span
                         .right, .left, .left1{
                            span{
                                margin-bottom: 20px;
                            }
                         }
                         .analTxt, .analGreen{
                              margin-bottom: 0px;
                         }

                        .left1 {
                            margin-right: 0px;
                            width: fit-content;
                            order: 3;
                        }
                        .left{
                            margin-right: 0px;
                            width: 204px;
                            order: 1;
                        }
                        .right{
                            order: 2;
                        }
                    }
                }
            }
        }
    }
 }

@media only screen and (max-width: 500px){
  .estimate-wrapper-background {
     .estimate-wrapper {
       margin-bottom: 60px ; // newShare 
       .right-wrapper {
          max-width: 100%;
          .main-wrapper{
            //  padding-left: 16px;
            //  padding-right: 16px;
          }
        }
     }
  }
 }


</style>
<style lang="scss">
  .main-wrapper{
    .badge-wrapper{
        border-radius: 5px 5px 0 0 !important;
        // margin-right:200px;
        height: 20px;
        width: auto;
        padding: 4px;
        line-height: 20px !important;
        font-size: 12px !important;
        
        box-sizing: border-box;
        // margin-left: 2px;
        padding-top:0px!important;
        padding-bottom: 0px!important;
        
        text-align: center;
    }
  }

// @media only screen and (max-width: 500px) {
//    .wrappBadgesEstim{
//     //  position: fixed !important;
//     //  left:0;
//     //  bottom:61px !important;
//     //  z-index: 15;
//    }
//      @supports (-webkit-overflow-scrolling: touch) { 
//     .wrappBadgesEstim {
//       // bottom:90px;
//       // z-index: 2;
//     }
//   }
// }
</style>
