<template>
    <div class="singinComponent">
        <div class="singinTitle"> Sign Up</div>
        
         <div v-if="false" v-facebook-login-button="appId" class="buttonSocial">
            <span>Continue with Facebook</span>
            <span ><img 
                :src="require(widthPage?'@/assets/images/fsingin.svg':'@/assets/images/fsinginBright.svg')" />
             </span>
        </div>
        
        <div  v-google-signin-button="clientId" class="buttonSocial">
            <span>Continue with Google</span>
            <span>
                <img :src="require('@/assets/images/gsinginBright.svg')" />
                <!-- <img :src="require(widthPage? '@/assets/images/gsingin.svg':'@/assets/images/gsinginBright.svg')" /> -->
            </span>
        </div>

        <div class="or">
            <div class="line"></div>
            or
            <div class="line"></div>
        </div>
        <div class="round">
            <input v-model="user.usr_name" :class="nameErr? 'basic inputErr':'basic '" @keyup="nameErr=false"  placeholder="Name" type="text" v-hideMoblieNav	 autofocus>
            <img v-if="nameErr" src="../../assets/images/errimg.svg" />
        </div>
        <div class="round">
             <input v-model="user.usr_email" :class="emailErr? 'basic inputErr':'basic'" @keyup="emailErr=false" placeholder="E-mail" type="text" v-hideMoblieNav >
             <img v-if="emailErr" src="../../assets/images/errimg.svg" />
        </div>
        <div class="round">
            <input v-model="user.usr_password" :class="passwordErr? 'basic inputErr':'basic'"  @keyup="passwordErr=false" placeholder="Password" type="password" v-hideMoblieNav >
            <img v-if="passwordErr" src="../../assets/images/errimg.svg" />
        </div>
        <div class="round">
            <input v-model="user.reTypePassword" :class="passwordErr? 'basic  inputErr':'basic'" @keyup="passwordErr=false" placeholder="Re-type password" type="password" v-hideMoblieNav>
            <img v-if="passwordErr" src="../../assets/images/errimg.svg" />
        </div>
           
        
        <p v-if="msg" class="errMsg"><img src="../../assets/images/errImgMsg.svg" /> {{ msg }}</p>
        <p v-if="eermsg" class="errMsg"> <img src="../../assets/images/errImgMsg.svg" /> {{ eermsg }}</p>
        <rember :remember="user.remember" @rememberSend="user.remember=!user.remember" :style="(msg || eermsg) ? 'margin-top:31px': '' " />
        <div class="buttonSend" @click="checkForm" >Sign Up</div>
        <!-- <div class="haveaccount">Already have an account? <router-link :to="{name: 'login'}" class="orange" >Log in</router-link></div>
        <p class="errMsg">{{msg}}</p><p v-if="eermsg" class="errMsg">{{eermsg}}</p>
        <div class="terms" >By clicking the button you agree to our <router-link to="/terms" class="bold">Terms and Privacy policy.</router-link></div> -->
        <div class="terms" >By clicking the button you agree to our <router-link to="/terms" class="bold">Terms and Privacy policy.</router-link></div>
        <div class="haveaccount">Already have an account? <router-link :to="{name: 'login'}" class="orange" >Log in</router-link></div>
         
    </div>
</template>

<script>
import rember from './rememberMe.vue'
import helper from '@/js/helperMail.js'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import FacebookLoginButton from 'vue-facebook-login-button-directive'

import { api } from '@/api'
export default {
    name: 'singinComponent',
    directives: {
        GoogleSignInButton,
        FacebookLoginButton
    },
    props: {
        eermsg: {
            require: false,
            type: String
        }
    },
    components: {
        rember
    },
    data () {
        return {
            user:{
                usr_name: '',
                usr_email: '',
                usr_password: '',
                reTypePassword:'',
                remember: true,
            },
            nameErr: false,
            emailErr: false,
            passwordErr: false,
            msg: '',
            widthPage: null,
            // id app google
            clientId: '306827492643-at00dqjbklkflbi52fnd1ckrkthpahjj.apps.googleusercontent.com' ,
            // fb
            appId: 3821175311320178
        
        }
    },
     created(){
        window.addEventListener("resize", this.resize)
        this.resize()
    },
    methods: {
        // FACEBOOK
         OnFacebookAuthSuccess(idToken) {
              const params={
                token:idToken
            }
            api.loginFacebook(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
                this.msg="Something went wrong, please try again."
                console.log(e.message);
            })
        },
        OnFacebookAuthFail(error) {
            console.log(error)
            this.msg="Something went wrong, please try again."
        },
        // end facebook

         // GOOGLE
        OnGoogleAuthSuccess (idToken) {
            const params={
                token:idToken
            }
            api.loginGoogle(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
                this.msg=" Something went wrong, please try again."
                console.log(e.message);
            })
            
        },
        OnGoogleAuthFail (error) {
            if(error && error.error == 'popup_closed_by_user')
                this.msg= ' Popup closed by user'
            else
                this.msg=" Something went wrong, please try again."
        },
        // end google
         getUserData(sid) {
            let params = {
                sid : sid
            }
            api.getUserData(params).then(res => {
                console.log('res')
                 console.log(res.data.data)
                this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
             console.log(e)
            })
        },
        checkForm(){

            let error = false
            this.clearErr()

            if(this.user.usr_name === '' || this.user.usr_name.trim().length == 0  ){
                error= true
                 this.nameErr = true
                 this.msg= " Name must be entered."
            }
            if(this.user.usr_email === '' || this.user.usr_email.trim().length == 0 || !helper.matchemail(this.user.usr_email) ){
                error= true
                this.emailErr = true
                this.msg= this.msg.length===0 ? " You must enter a valid email.": " All fields must be entered correctly"
            }
            if(this.user.usr_password === '' || this.user.usr_password.length <= 6  ){
                error= true
                this.passwordErr = true
                this.msg= this.msg.length===0 ? " The password must contain a minimum 7 characters.": " All fields must be entered correctly"
            }else if(this.user.usr_password !== this.user.reTypePassword) {
                error= true
                this.passwordErr = true
                this.msg= this.msg.length===0 ? " Passwords don’t match.": " All fields must be entered correctly"
            }
            if(error) {
                return
            }else {
                this.$emit('signin', this.user)
                console.log('SIGNIN---')
            }

        },
        clearErr () {
            this.nameErr = false
            this.emailErr = false
            this.passwordErr = false
            this.msg = ''
        },
        clearData () {
            this.user.usr_name = ''
            this.user.usr_email = ''
            this.user.usr_password = ''
            this.user.reTypePassword= ''
        },
        resize () {
            if (window.innerWidth <= 768) {
                this.widthPage = false
            } else {
                this.widthPage = true
            }
        }
    },
    destroyed() {
        window.removeEventListener("resize", this.resize);
    }
}
</script>

<style lang="scss" scoped>
 @import '@/assets/css/singUp.scss';
.singinComponent{
    //  background-color:rgba(48, 219, 241, 0.609);
        width: 406px;
        height: 711px;

        .singinTitle {
            // text-align: right;
            // font-family: 'Gotham Pro Medium';
            // font-style: normal;
            // font-weight: 500;
            // font-size: 28px;
            // line-height: 40px;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            /* identical to box height, or 111% */

            // display: flex;
            align-items: center;
            text-align: center;
            color: #062931;
            margin-bottom: 52px;
        }
        .terms{
            // font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 12px;
            // line-height: 25px;
            line-height: 20px;
            /* identical to box height, or 208% */

            line-height: 25px;
            /* identical to box height, or 208% */


            /* Gray 3 text */

            // color: #9498A0;
            // color: #5A7E87;
            color: rgba(70, 127, 143, 0.8);
            // padding-top: 13px;
            padding-top: 16px; // on design is 13 but the comment is that it has to go down 3px


            .bold{
            //    color: #5A7E87;
               color: rgba(70, 127, 143, 0.8);
            //    font-weight: bold; 
               font-weight: 500;
               text-decoration: none;
            }
            .bold:hover{
                // color: $link-hover;
                opacity: .8;
            }

        }
        .haveaccount{
            // margin-top: 24px;
            margin-top: 29px;  // on design is 24, but the comment is that it has to go down 5 px

        }
       
    }
@media only screen and (max-width: 500px){
    .singinComponent{
    //  background-color:rgba(48, 219, 241, 0.609);
        width: 334px;
        // height: 711px;
        // margin-bottom: 71px!important;
        height: fit-content;
        

        .singinTitle {
            // font-size: 28px;
            // line-height: 30px;
            font-size: 34px;
            line-height: 40px;
            margin-bottom: 60px;
            margin-bottom: 37px;
            text-align: left;
        }
        .terms{
            // padding-bottom: 120px!important;
            font-size: 11px;
            line-height: 20px;
            // font-size: 12px;
            // line-height: 25px;
        }
       
    }
}

</style>
<style lang="scss">
.singinComponent{
    .basic{
        // margin-bottom: 15px;
    }
    .errMsg{
        margin-top: 30px;
    }
      // }
        .rember {
            // margin-top: 26px;
            // margin-bottom: 28px;
            // margin-top: 25px;
            // margin-bottom: 40px;
            // margin-top: 42px;
            // margin-top: 40px;
            margin-top: 42px;
            margin-bottom: 32px;
        }
        .or{
            //  padding-bottom: 13px !important;
            padding-bottom: 15px !important;
        }
}
@media only screen and (max-width: 500px){
    .singinComponent{
        .basic{
            // margin-bottom: 15px;
        }
        // }
        .rember {
            margin-bottom: 30px;
            margin-top: 29px;
        }
        .or{
            //  padding-bottom: 13px !important;
            // padding-bottom: 15px !important;
        }
        .haveaccount{
            margin-top: 24px !important;
        }
    }

}

</style>