<template>
  <div class="wrappOnePressReleases">
    <div v-if="false" class="pressLine"></div>  
    <div class="holdContent">
            <div class="date">{{setDate}}</div>
            <div class="title"  ><p v-html="tempData.title"></p> </div>
            <div class="subTitle" > <p v-html="tempData.description"> </p> </div>
             <div
                class="content2"> 
                <div class="text" v-html="tempData.content " > </div>
                <div class="pressLine2"></div>
                <roundAllShareComponent
                    @download="download" 
                    :titleP='tempData.title' 
                    :descriptionP='tempData.description'
                    :author='tempData.author' 
                    :isblog='false'
                    class="share-comp"
                />
            </div>


            <!-- old design -or is class content or content2, it can't be both  -->
            <div v-if="false"
                class="content">
                <div class="text" v-html="tempData.content " > </div>
                <sharePressRele
                    v-if="false"
                    @download="download" 
                    :titleP='tempData.title' 
                    :descriptionP='tempData.description'
                    :author='tempData.author' 
                />
            </div>
    </div>
    <footerUp />
    <footerDown :press="true" :white="true" v-if="!showMobileNav" />
  </div>
</template>

<script>
import sharePressRele from '../components/newsroom/sharePressRele2.vue'
import footerUp from '../components/newsroom/footerUpNew.vue'
import footerDown from '../components/newsroom/footerDown'
import roundAllShareComponent from '../components/shareComponents/roundAllShareComponent.vue'
import { baseURL, api } from '@/api'
import helper from '@/js/helperDate.js'

export default {
    name: 'onePressReleases2',
    components: {
        footerUp,
        footerDown,
        sharePressRele,
        roundAllShareComponent
    },
    metaInfo () {
        return {
        title: 'Carvisor',
        titleTemplate:  this.tempData.title  + ' - %s' ,
        meta:[
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.tempData.description
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content:  this.tempData.description
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.tempData.title 
                },
                // {
                //     hid: 'keywords',
                //     name: 'keywords',
                //     content:  this.tempData.title
                // },
                {
                    vmid: 'og:image',
                    property: 'og:image',
                   // itemprop: 'image',
                    content: this.baseURLImg
                }
            ]
        }
    },
    data () {
        return{
            tempData:{
                 title:this.$route.params.id
                // description: 'Mauris vehicula elementum facilisis. Nam diam nunc, sagittis ac odio eget, rhoncus sagittis metus.',
                // content: '<p>Carvisor is an online car valuation platform built to help people find honest prices for used cars. We use proprietary machine learning technology to analyse the car market in New Zealand, and provide users with fast and reliable car valuations.</p><p>Pricing based on book value, that is used by other platforms, does not reflect the current state of the market which makes this approach unreliable. What makes our approach unique is that we do not base our prices on the book value but rather on the latest market trends. Our algorithm updates the data in real time, as we strive to keep our vehicle pricing in-line with today’s dynamic environment.</p><p>Even though our platform uses complex technology behind the scenes, it is very user friendly. To get a valuation, you only need to enter the details of the car you wish to assess. In addition to market based pricing, we offer advanced analytics like ‘Estimated time to sell your vehicle’, ‘Private and dealer market value’, ‘Transaction trigger’, etc., which helps users make informed decisions when selling or buying a car. These tools can also be indispensable for dealers, insurance companies, insurance companies, finance companies and banks.</p><p>Our goal is to help our users avoid the stressful and time-consuming process of figuring out the best price for the cars they wish to buy or sell, and it’s all for free.</p>'
            },
            showPressSmallWidth: false,
            classSafari: false,
            classChrome: false,
            showMobileNav:false,
            setDate: null

        }
        /*
        author: "Mira"
        blogImage: "images/7"
        content: "<h1>Some initial content</h1><p>Some initial content.Some initial content. Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content.</p><p><br></p><p>Some initial content.Some initial content. Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content. Some initial content.Some initial content.Some initial content.</p><h1><br></h1><p><br></p>"
        date: "2021-03-30 08:08:40"
        description: "My news test news"
        id: 8
        img_alt: "bike"
        img_name: "bike"
        next: null
        previous: null
        readTime: null
        title: "My News"

        */
    },
     created() {
        window.addEventListener("resize", this.handleResize);
        this.handleResize()
         this.getPress(this.$route.params.id)
    },
    destroyed () {
      window.removeEventListener("resize", this.handleResize)
    },
    mounted(){
       // this.getPress(this.$route.params.id)
       
    },
    methods:{
       
        getPress(slug){
            // call axios
          api.getNewsData(slug).then(res => {
               this.tempData = res.data.data
               // this.setMeta(this.tempData)
               this.setDate = helper.formatDate(res.data.data.date)
          })
            console.log(slug+ '-from one press')
        },
        download () {
            console.log(this.baseURL+this.tempData.file)
            // this.baseURL+this.tempData.link
            window.open(this.baseURL+this.tempData.file)
        },
        handleResize () {
             if (window.innerWidth <= 500) {
                this.showMobileNav = true
                this.showPressSmallWidth = true
            } else if(window.innerWidth <= 1000){ //change from 768px, due to the design and display of other elements
                this.showPressSmallWidth = true
                this.showMobileNav = false
            }else{
                this.showPressSmallWidth = false
                this.showMobileNav = false
            }
        }
        
    },
     computed: {
        baseURL () {
            return baseURL.substring(0, baseURL.length-1)
        }
  }

}
</script>

<style lang="scss" scoped>
.wrappOnePressReleases {
    .wrappUpFooter {
        margin-top: -5px;
        margin-bottom: 100px;
    }
    .pressLine {
        width:100%;
        box-sizing: border-box;
        border-top:1px solid #DEE0E5;
       
    }
     .holdContent {
        margin:0 auto;
        //  padding-bottom: 100px;
        padding-bottom: 75px;
        padding-top: 37px;
        padding-top: 112px;
        display:flex;
        flex-direction: column;
        // justify-content: space-between;
        width:1278px;
         
         .date{
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 25px;

            color: #062931;
            margin-top: 0px;
            margin-bottom: 51px;

         }
         .title{
                // font-family: 'Gotham Pro Bold';
                // font-style: normal;
                // font-weight: bold;
                // font-size: 40px;
                // line-height: 50px;
                // color: #062931;
                // font-family: 'Gotham Pro Bold';
                // font-style: normal;
                // font-weight: bold;
                // font-size: 40px;
                // line-height: 50px;
                // color: #062931;
                font-style: normal;
                font-weight: bold;
                font-size: 51px;
                line-height: 60px;

                color: #062931;

                max-width: 697px;
                p{
                  margin-top: 0px;
                //   margin-bottom: 11px !important;
                  margin-bottom: 41px;
                }
               
             }
             .subTitle{
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 20px;
                // line-height: 29px;
                // color: #5A7E87;
                max-width: 733px;

                font-style: normal;
                font-weight: normal;
                font-size: 26px;
                line-height: 35px;
                
                color: #88949D;
                color: #5C96A7;
                 p{
                  margin-top: 0px ;
                  margin-bottom: 45px;
                  margin-bottom: 23px;
                }
             }
            .content2{
                width:65%;
                max-width: 733px;
                color: #062931;

                .text{
                    padding-bottom: 74px;
                    
                }
                .text > :first-child{
                        margin-top:0px !important;
                 }
                .pressLine2{
                    width: 100%;
                    height: 0;
                    border-top: 1px solid #DEE0E5;
                    margin-top: -15px;
                    margin-bottom: 24px;

                }

                .share-comp {
                    margin-top: -15px;
                }
                
            }
         .content{
             // border:1px solid brown;
            display: flex;
            //flex-direction: row-reverse;
             
             .text{
                // display:flex;
                // flex-direction: column;
                width:65%;
                max-width: 733px;
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 16px;
                // line-height: 24px;
                // color: #062931;
                font-style: normal;
                font-weight: normal;
                font-size: 17px;
                line-height: 25px;
                color: #062931;

                padding-top:0;
                margin-top:0;
                //justify-self:  start;
                p:first-of-type{
                   // margin-top:0px;
                }

             }
             .text > :first-child{
                 margin-top:0px !important;
             }
         }
         .rightSide{
             padding-top:20vh;
             height:100% !important;
         }
     }



}

@media only screen and (max-width: 1330px){
    .wrappOnePressReleases{
        .holdContent{
            box-sizing: border-box;
            margin:0 auto;
            //width:1278px;
            width: calc(100% - 58px );
            margin: 0 29px;
            .content2{
                width:100%;
            }
        }
    }
}
@media only screen and (max-width: 1000px){ //768px
    .wrappOnePressReleases{
        .holdContent{
             padding-top: 62px;
             .date{
                 margin-bottom: 31px;
             }
            .title{
                // font-size: 34px;
                // line-height: 38px;
                width:100%;
                p{
                // margin-bottom: 11px !important;
                margin-bottom: 41px;
                }
            }
            .subTitle{
                    // font-size: 18px;
                    // line-height: 27px;
                    width:100%;
                    p{
                        margin-top: 0px ;
                        margin-bottom: 52px;
                    }
            }
                
            .content{
                 width:100%;
                 display:flex;
                 flex-direction:column-reverse;

                .text{
                    // display:flex;
                    // flex-direction: column;
                    width:100%;
                    max-width:100% !important;
                }
            } 
        }
    }
}
@media only screen and (max-width:  620px){ //375
    .wrappOnePressReleases{
        .holdContent{
            padding-top: 47px;
            padding-bottom: 57px;
            margin:0 auto;
            height: initial;
            .content{
              width:100%;
              box-sizing: border-box;
            }
        }
    }
}
@media only screen and (max-width: 475px){ //375
    .wrappOnePressReleases{
        // background: bisque;
        .holdContent{
            // padding-top:23px;
            padding-top: 90px;
            // padding-left: 16px;
            // padding-right: 16px;
            height: initial;
            margin-left: 16px;
            margin-right: 16px;
            width: initial;
            .date{
                font-size: 14px;
                line-height: 20px;
                /* or 143% */

                letter-spacing: 0.04em;
                margin-bottom: 31px;

            }
             .title{
                    // font-size: 34px;
                    // line-height: 38px;
                    font-size: 46px;
                    line-height: 50px;
                    
                    p{
                        // margin-bottom: 22px !important;
                        margin-bottom: 35px;
                    }
                }
                .subTitle{
                    // font-size: 18px;
                    // line-height: 27px;
                    font-size: 24px;
                    line-height: 30px;

                    p{
                    margin-top: 0px ;
                    // margin-bottom: 30px;
                    margin-bottom: 23px;
                    }
                }
                .content2{
                    width:100%;
                    // border:1px solid brown;
                    
                        .text{
                            // font-size: 15px;
                            // line-height: 24px;
                            font-size: 16px;
                            line-height: 25px;
                            padding-bottom: 43px;

                        }
                }
             .content{
               width:100%;
             // border:1px solid brown;
            
                .text{
                    // font-size: 15px;
                    // line-height: 24px;
                    font-size: 16px;
                    line-height: 25px;

                }
         }
        }
    }
}
</style>
<style lang="scss">
@media only screen and (max-width: 1000px){ //768px
    .wrappOnePressReleases{
        .holdContent{
            .content{
                 .sharePressRele{
                     margin-bottom: 38px;
                  }
            }
        }
    }
}
@media only screen and (max-width: 1000px){ //768px
    .wrappOnePressReleases{
        .holdContent{
            .content{
                 .sharePressRele{
                    //  margin-bottom: 60px;
                     margin-bottom: 23px;
                  }
            }
        }
    }
}

#aSnipet{
   transition-duration: .1s;
}
#aSnipet:hover {
      background-color:  $btn-hover!important;
}
</style>