<template>
  <div class="modal">
      <div class="exit" @click="$emit('closeModal')">
            <img :src="require('@/assets/images/x.png')">
          </div>
      <div class="mailData">
          <p v-if="false" class="mailTitle">Ready to see the <br class="swidth" >in-depth  <br class="hwidth">analytics?</p>
          <p class="mailTitle">Ready to see the <br class="swidth" >valuation and in-depth analytics?</p>
         
          <p v-if="false" class="mailText">Make sure you’re getting an honest price. Enter your email for a complete  <br class="swidth" >market report on this car. </p>
          <p v-if="false" class="mailText">Make sure you’re getting the whole <br class="swidth" >picture. <br class="hwidth">Enter your email for a complete  <br class="swidth" >market report <br class="hwidth">on this car. </p>
          <p v-if="false" class="mailText">We have noticed that you have used <br class="swidth">our valuation  <br class="hwidth">tool multiple times. Glad <br class="swidth" >you like it! Please log in to  <br class="hwidth">continue to <br class="swidth" >use our service for free.</p>
          <p class="mailText">We have noticed that you have used our valuation tool multiple times. Glad you like it! Please leave your email address to continue to use our service for free.</p>
    
     </div>  
      <div class="mailSend">
        <div class="vinDiv2">
            <div class="roundInput">
                <div class="vinDiv2">
                    <input v-model="email" placeholder="Your e-mail"  v-on:keyup.enter="send" />
                    <div class="buttonVin" @click="send"><a><p>Get estimate</p></a></div>
                </div>
                <p class="msg">{{ msg }}</p>
            </div><!-- end roundInput -->
        </div>
      </div>
  </div>
</template>

<script>
export default {
    name:'modal',
    props:[
           'msg'
        ],
    data () {
        return{
            email:''
        }
    },
    methods:{
         send () {
            this.$emit('send', this.email)
        }
    }

}
</script>

<style lang="scss" scoped>
.modal{
    position:relative;
    background: #FFFFFF;
    border-radius: 5px;
    width: 486px;
    min-height: 342px;
    margin:0 auto;
    z-index: 212;
    .mailData{
        .mailTitle{
            font-family: 'Gotham Pro Medium';
            font-style: normal;
            font-weight: 500;
            font-size: 30px;
            line-height: 40px;
            text-align: left;
            // margin-top: 50px;
            // margin-bottom: 20px;
            margin:44px 40px 15px 40px;
            color: #062931;
        }
        .mailText{
            font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 24px;
            text-align: left;
            margin-top: 0;
            margin-bottom: 23px;
            color: #062931;
            padding-left:40px;
            padding-right: 30px;
        }
        .hwidth{
            display:initial;
        }
        .swidth{
            display:none;
        }
    }
    .mailSend{
        .roundInput{
            box-sizing:border-box;
            width:100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
           @import '@/assets/css/inputButton2.scss';

           .msg{
                height: 24px;
                font-weight: 500;
                font-size: 16px;
                line-height: 24px;
                text-align: center;
                // color: $main-color !important;
                color: $err-color;
            }
          }
    }
    .exit{
        position: absolute;
        right:10px;
        top:10px;
        cursor: pointer;
        text-align: right;
        img{
            width:20px;
        }
    }
}

 @media only screen and (max-width: 550px){
     .modal{
        width: 343px;
        height: initial;
        margin:0 auto;
    .mailData{
        .mailTitle{
            font-size: 28px;
            text-align: left;
             margin-left:13px;
            margin-right:15px;
            .nbspace{
                white-space: nowrap;
            }
        }
        .mailText{
            font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            font-size: 15px;
            line-height: 24px;
            text-align: left;
            margin-top: 0;
            margin-bottom: 32px;
            color: #062931;
            padding-left:13px;
            padding-right:15px;
        }
        .hwidth{
            display:none;
        }
        .swidth{
            display:initial;
        }
    }
    .mailSend{
        .roundInput{
            box-sizing:border-box;
            width:100%;
            display: flex;
            justify-content: center;
           @import '@/assets/css/inputButton2.scss';

           .msg{
                height: 24px;
                font-weight: 500;
                font-size: 14px;
                line-height: 24px;
                text-align: center;
                // color: $main-color !important;
                color:$err-color;
            }
          }
    }
}

          }

</style>
