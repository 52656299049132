<template>
  <router-link v-if="itemData && itemData.link" class="latestPressContentItem"  :to="this.itemData.link">
      <div :class="itemData.image!= null ? 'img' : 'img2'">
         <!-- img :src="itemData.img" /-->
         <div v-if=" itemData.image!= null" class="imgSetBorderRadius">
            <img v-if=" itemData.image!= null" :src="getBaseUrl() + itemData.image" :alt="itemData.img_alt ? itemData.img_alt : 'main-news-image'">
        </div>
      </div>
      <div class="data" >
          <p class="pressDate" >{{setDatePress}}</p>
          <p class="pressTitle" v-html="itemData.title.length > 62 ? itemData.title.slice(0,62).concat('...') : itemData.title"></p>
      </div>
  </router-link>
</template>

<script>
import { baseURL } from '@/api'
import helper from '@/js/helperDate.js'
export default {
    props:{
        itemData:{
          type: Object,
          require: true
        }
    },
     mounted(){
       if(this.itemData.date){
      this.setDatePress = helper.formatDate(this.itemData.date)}
      console.log(this.setDatePress)
    },
    data () {
      return{
        setDatePress:null
      }
    },
    methods: {
        getBaseUrl () {
            return baseURL
        },
          goToOnePress () {
           // this.$emit('gotoonepress', this.itemData.link)
        }
    }

}
</script>

<style lang="scss" scoped>
.latestPressContentItem{
    background: #ffffff;
    max-width:406px;
    width: calc(33% - 17px);
    padding: 0px;
    cursor: pointer;
    text-decoration: none;
   // opacity: 0.6;
    // .img2{
    //     box-sizing:border-box;
    //     width: 406px;
    //     height: 305px;
    //     background: #F8F8F9;
    //     overflow: hidden;
    //     border-radius: 5px;
    //     img{
    //          object-fit: cover;
    //          min-width: 100%;
    //          min-height: 100%;
    //     }
    // }
    .img2{ // no img
        box-sizing:border-box;
        width: 406px;
        height: 305px;
        background: #F8F8F9;
        overflow: hidden;
        -webkit-border-radius: 5px;
        -khtml-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
        border-radius: 5px;
        img{
             object-fit: cover;
             min-width: 100%;
             min-height: 100%;
        }
    }
    .img{
        box-sizing:border-box;
        max-width: 406px;
        max-height: 305px;
       // width: calc(100%);
        height: auto;
        background: #F8F8F9;
        overflow: hidden;
       
        -webkit-border-radius: 5px;
        -khtml-border-radius: 5px;
        -moz-border-radius: 5px;
        border-radius: 5px;
          -webkit-border-top-left-radius: 5px !important; 
          -webkit-border-top-right-radius: 5px !important;
          -webkit-border-bottom-left-radius: 5px !important; 
          -webkit-border-bottom-right-radius: 5px !important; 
          -webkit-transform: translateZ(0);
         // -webkit-mask-image: -webkit-radial-gradient(white, black);
          will-change: transform;
          position: relative;
          z-index: 100;
          // clip-path: circle(80% at center);
          // -webkit-clip-path: circle(80% at center);
          .imgSetBorderRadius {
            box-sizing: border-box;
            border-radius: 5px;
            width: 100%;
            height: 100%;
            margin:0;
            border:0;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        img{
           z-index: 5;
           object-fit: cover;
           min-width: 100%;
           min-height: 100%;
           height: 305px;
           width: auto;
           transition: 1s ease-in-out;
            
        }
        img:hover{
            transform: scale(1.1);
        }

    }


    .data{
        height: 100px;
        cursor: pointer;
        padding-top: 15px;
        overflow: hidden;
        .pressTitle{
            // font-family: 'Gotham Pro Medium' ;
            // font-style: normal;
            // font-weight: 500;
            // font-size: 20px;
            // line-height: 26px;
            color: #062931;
            // padding-top:12px;
            // margin-top:0;
            // margin-bottom: 5px;
            font-weight: bold;
            font-size: 26px;
            line-height: 30px;
            margin-top:0;
            margin-bottom: 0px;

            color: #062931;

        }

        .pressDate{
            // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: normal;
            // font-size: 13px;
            // line-height: 24px;
            // letter-spacing: 0.05em;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            /* identical to box height, or 125% */

            letter-spacing: 0.04em;

            color: #062931;
            margin-top:0px;
            margin-bottom: 0px;
            margin-top: 0px;
            margin-bottom: 9px;
        }
    }

}
.latestPressContentItem:hover{
     .pressTitle{
            color: #000000;
        }
        .img {
            -webkit-border-radius: 5px !important;
            -khtml-border-radius: 5px !important;
            -moz-border-radius: 5px !important;
            border-radius: 5px !important;
            -webkit-border-top-left-radius: 5px !important; 
            -webkit-border-top-right-radius: 5px !important;
            -webkit-border-bottom-left-radius: 5px !important; 
            -webkit-border-bottom-right-radius: 5px !important; 
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            will-change: transform;
            -webkit-transform: translateZ(0);
            img {
             transform: scale(1.1);
             }
        }
}
@media only screen and (max-width: 954px){ //768
    .latestPressContentItem{
        background: #ffffff;
        min-width: 342px;
        padding: 0px;
        // opacity: 0.6;
        .img{
            box-sizing:border-box;
            width: 342px;
            height: 257px;
            background: #F8F8F9;
            img{
                object-fit: cover;
                min-width: 100%;
                min-height: 100%;
            }
        }
        .data{

            .pressTitle{

                 br{
                    display:initial;
                }

            }
            .pressDate{

            }
        }
    }
}
@media only screen and (max-width: 740px){
    .latestPressContentItem{
        background: #ffffff;
        width: calc(100% - 32px);
        max-width:initial;
        padding: 0px;
        // opacity: 0.6;
        .img{
            box-sizing:border-box;
            // max-width: 340px;
            // max-height: 255px;
            height: initial;
            max-width: 100% !important;
            width: 100% !important;
            background: #F8F8F9;
            // border-radius: initial;
            .imgSetBorderRadius{
              display: initial !important;
            }
            img{
                width: 100%;
            }
        }
        .data{
             min-height: initial;
            .pressTitle{
                font-weight: 500;
                font-size: 19px;
                line-height: 26px;
                br{
                    display:none !important;
                }

            }
            .pressDate{

            }
        }
    }
}

</style>
