<template>
  <div :class="visibility? 'section-wrapper': 'section-wrapper hideContent'" id="pie-chart-wrapper">
    <div v-if="blur" class="overlay">
      <div v-if="!falseData" @click="$emit('unlock')" class="overlay-btn">
        <img :src="require('@/assets/images/lock.png')" alt="lock image">
        Unlock results
      </div>
    </div>
    <div class="section-header">
      <h3>Market breakdown by region</h3>
        <div>
          <hideShow @visibility="changeVisibility"/>
          <div @click="tooltip = true" v-click-outside="closeTooltip" class="">
            <!-- <img :src="require('@/assets/images/info.png')" alt=""> -->
            <tooltip
              :isOpen="tooltip"
              :msg="'Certain regions are more distinguishable than others, with unique user preferences and competitive conditions. Find out in which regional market particular models are more likely to be found.'"
            />
          </div>
      </div>
    </div>
    <div v-show="visibility" class="content">
       <div class="pie-chart-legend">
         <div v-for="(region, index) in getLabels" :key="index" class="legend">
          <div class="divCircle" :style="{ 'background-color': chartData.datasets[0].backgroundColor[index] }"></div>
          <div> 
              <div class="legendRegion">{{region}}</div> 
              <div v-if="!blur" class="span"><span class="bold">{{regions[index].percentage}} %</span> - {{regions[index].value}} cars</div>
          </div>
        </div>


        <!-- <div v-for="(region, index) in getLabels" :key="index" class="legend">
          <div><div class="divCircle" :style="{ 'background-color': chartData.datasets[0].backgroundColor[index] }"> <span class="legendRegion">{{region}}</span> </div></div>
          <div v-if="!blur"><span class="bold">{{regions[index].percentage}} %</span> - {{regions[index].value}} cars</div>
        </div> -->
        <!-- <div class="legend">
          <div style="background: #FF5654;"></div>
          <span>Bay of Plenty</span>
        </div>
        <div class="legend">
          <div style="background: #4982FF;"></div>
          <span>Canterbury</span>
        </div>
        <div class="legend">
          <div style="background: #51E6FB;"></div>
          <span>Chatham Islands</span>
        </div>
        <div class="legend">
          <div style="background: #FFF2DC;"></div>
          <span>Gisborne</span>
        </div>
        <div class="legend">
          <div style="background: #CDD8D8;"></div>
          <span>Other Regions</span>
        </div> -->
      </div>
      <div class="chart-wrapper">
        <canvas id="pie-chart" width="350" height="300"></canvas>
      </div>
     
    </div>
  </div>
</template>

<script>
import Tooltip from '@/components/tooltip2.vue'
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import hideShow from '@/components/HideShow.vue'
export default {
  name: 'pieChart',
  components: {
    Tooltip,
    hideShow
  },
  props: {
    regions: {
      required: true
    },
    falseData:{
      required: false,
      // default: false,
      type: Boolean
    },
    blur: {
      required: false,
      default: false,
      type: Boolean
    }
  },
  data () {
    return {
      tooltip: false,
      show: false,
      observed: 0,
      chartData: {
        datasets: [{
          data: [],
          backgroundColor: [
            '#045A6D',
            '#FF8A82',
            '#108FE9',
            '#51E6FB',
            '#F18700',
            '#FCF9F2'
            // '#134753',
            // '#FF5654',
            // '#4982FF',
            // '#51E6FB',
            // '#FFF2DC',
            // '#CDD8D8'
          ],
          hoverBackgroundColor: [
            '#044c5c',
            '#ee7d75',
            '#0f7dcc',
            '#47d3e6',
            '#d67b03',
            '#e9e6df'
            // '#103D49',
            // '#FF4B49',
            // '#3A77FF',
            // '#06DBF9',
            // '#FFEED0',
            // '#CFD8DB'
          ],
          borderColor: [
            '#045A6D',
            '#FF8A82',
            '#108FE9',
            '#51E6FB',
            '#F18700',
            '#FCF9F2'
            // '#C3C6D0',
            // '#C3C6D0',
            // '#C3C6D0',
            // '#C3C6D0',
            // '#C3C6D0',
            // '#C3C6D0'
          ],
          borderWidth: 1
        }],
        labels: [],
      },
       visibility: true
    }
  },
  async mounted () {
    this.createObserver()
    await this.setUpChartData()
    // setTimeout(() => {
    //   this.setUpChart()
    // }, 1000);
  },
  methods: {
    createObserver() {
      let observer;

      let options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.6
      };
      observer = new IntersectionObserver(this.handleIntersect, options);
      let boxElement = document.querySelector("#pie-chart-wrapper");
      observer.observe(boxElement);
    },
    handleIntersect () {
      console.log('observed');
      this.observed += 1
      if (this.observed > 1) {
        this.show = true
      }
    },
    setUpChartData () {
      this.chartData.datasets[0].data = []
      this.chartData.labels = []
      for (var i = 0; i < this.regions.length; i++) {
        this.chartData.datasets[0].data.push(this.regions[i].percentage)
        this.chartData.labels.push(this.regions[i].reg_name)
      }
    },
    closeTooltip (e) {
      if (this.hasParentWithMatchingSelector(e.target, '.tooltip')) {
        return
      }
      this.tooltip = false
    },
    hasParentWithMatchingSelector (target, selector) {
      return [...document.querySelectorAll(selector)].some(el =>
        el !== target && el.contains(target)
      )
    },
    setUpChart () {
      var ctx = document.getElementById('pie-chart');
      new Chart(ctx, {
        type: 'pie',
        plugins: [ChartDataLabels],
        data: this.chartData,
        options: {
          animation: {
            duration: 2000,
          },
          legend: {
            display: false
          },
          plugins: {
            datalabels: {
              anchor: 'end',
              align: 'end',
              color: '#062931',
              position: 'outside',
             // overlap: false,
              segment: true,
              formatter: function(value) {
                return value + '%';
              },
              font: {
                size: this.blur ? 0 :  15,
                family: 'DM sans',
                weight: 500,
              }
            }
          },
          layout: {
            padding: {
              left: 50,
              right: 50,
              top: 20,
              bottom: 20
            }
          },
          tooltips: {
            backgroundColor: 'white',
            displayColors: false,
            bodyFontColor: '#062931',
            bodyFontFamily: 'DM sans',
            // bodyFontFamily: 'Gotham Pro Light',
            bodyFontSize: 15,
            titleFontColor: '#062931',
            titleFontFamily: 'DM sans',
            // titleFontFamily: 'Gotham Pro Medium',
            titleFontSize: 15,
            cornerRadius: 0,
            xPadding: 15,
            yPadding: 15,
            borderColor: '#C3C6D0',
            borderWidth: 1,
            callbacks: {
              label: function(tooltipItem, data) {
                return data.datasets[0].data[tooltipItem.index] + '%'
              },
              title: function(tooltipItem, data) {
                return data.labels[tooltipItem[0].index]
              }
            }
          }
        }
      });
    },
    changeVisibility(e){
      this.visibility=e
      setTimeout(() => {
        if( this.observed > 1)
          this.show = e
      }, 100);
    
    }
  },
  computed: {
    getLabels () {
      return this.chartData.labels
    }
  },
  watch: {
    show: function (newVal) {
      if(newVal === false) return
      this.setUpChart()
    },
    regions: function () {
      this.setUpChartData()
      Chart.helpers.each(Chart.instances, function(instance){
        if (instance.chart.canvas.id === 'pie-chart') {
          instance.update()
        }
      })
      this.setUpChart()
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/statsOverlay.scss';
.section-wrapper {
  background-color: white;
  margin-bottom: 10px;
  border-radius: 5px;
  padding: 22px;
  padding-left: 20px;
  padding-top: 16px;
  padding-bottom: 11px;
  box-sizing: border-box;
  // width: calc(50% - 5px);
  width: 100%;
  min-height: 427px;
  display: flex;
  flex-direction: column;
  position: relative;

  .section-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    // margin-bottom: 30px;
    margin-bottom: 32px;
    div{
      display: flex;
      align-items: center;
    }

    h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;

      letter-spacing: 0.05em;
      text-transform: uppercase;


      color: #5A7E87;

      margin: 0px;
    }
    img {
      cursor: pointer;
    }
  }
  .content {
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    // justify-content: space-between;

    .chart-wrapper {
      // margin-right: 50px;
      margin-top: 8px;
      margin-right: 24px;
      margin-bottom: 20px;
      // margin-left: 20px;
      margin-left: 20px;
      // width: 50%;
    }
    .pie-chart-legend {
      margin-bottom: 23px;
      margin-top: -3px;
      // margin-left: 30px;
      margin-left: 20px;
      margin-right: 145px;
      .legend {
        display: flex;
        flex-direction: row;
        // align-items: center;
        min-width: 136px;
        height: 50px;
        margin-bottom: 10px;
        .legendRegion{
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          letter-spacing: 0.01em;
          color: #76898E;
        }
        .divCircle {
           color: #ee7d75; 
          height: 9px;
          width: 9px;
          border: 1px solid #C3C6D0;
          border-radius: 6px;
          margin-right: 9px;
          margin-top: 7px;
        }
        .span {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 25px;
          /* or 156% */

          letter-spacing: 0.01em;

          /* 55%black */

          color: #062931;

        }
        .bold {
          font-weight: 500;
          font-size: 17px;
          line-height: 25px;
          letter-spacing: 0.01em;

        }
      }
    }
  }
}
.section-wrapper.hideContent{
      min-height:fit-content !important;
      padding-bottom: 6px;
      padding-top: 17px;
      .section-header {
        margin-bottom: 10px;
      }
}
@media only screen and (max-width: 1024px){
  .section-wrapper {
    border-radius: 0px;
    width: 100%;
       .content {
    padding: 10px 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex-grow: 1;
    // justify-content: space-between;

      .chart-wrapper {
        // margin-right: 50px;
        margin-right: 24px;
        margin-bottom: 20px;
        // margin-left: 20px;
        margin-left: 20px;
        // width: 50%;
      }
      .pie-chart-legend {
        margin-bottom: 20px;
        // margin-top: 20px;
        // margin-left: 30px;
        margin-left: 40px;
        margin-right: 65px;
        .legend {
          display: flex;
          flex-direction: row;
          // align-items: center;
          min-width: 136px;
          height: 50px;
          margin-bottom: 10px;
          .legendRegion{
            font-weight: normal;
            font-size: 16px;
            line-height: 25px;
            letter-spacing: 0.01em;
            color: #76898E;

          }
          .divCircle {
            color: #ee7d75; 
            height: 9px;
            width: 9px;
            border: 1px solid #C3C6D0;
            border-radius: 6px;
            margin-right: 9px;
            margin-top: 7px;
          }
          .span {
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 25px;
            /* or 156% */

            letter-spacing: 0.01em;

            /* 55%black */

            color: #062931;

          }
          .bold {
            font-weight: 500;
            font-size: 17px;
            line-height: 25px;
            letter-spacing: 0.01em;

          }
        }
      }
    }
    
  }
}
@media only screen and (max-width: 768px){
  .section-wrapper {
    .chart-wrapper {
     // margin: 0 auto !important;
    }
    .pie-chart-legend {
      margin-top: 45px !important;
    }
  }
}
@media only screen and (max-width: 550px){
      .section-wrapper{
          padding-left: 16px;
          padding-right: 16px;
          // padding-top:20px !important;
          padding-top:15px !important;

          .section-header {
            align-items: initial;
            h3{
              // margin-top: 2px!important;
              margin-top: 5px!important;
              width:200px;
            }
          }
          .section-header>div{
             height: fit-content;
             align-self: flex-start;
          }
     
      .content{
         display: flex;
          flex-direction: column-reverse !important;
          padding-bottom: 23px;
          .chart-wrapper {
            margin: 0 auto !important;
          }
          .pie-chart-legend{
             margin-left: 14px !important;
             margin-right: 16px !important;
             margin-bottom: 0 !important;
             display: flex;
             flex-wrap: wrap;

             .legend {
               box-sizing: border-box;
                display: flex;
                flex-direction: row;
                // align-items: center;
                min-width: 50%;
                width: 50%;
                height: fit-content !important;
                min-height: 50px;
                // margin-bottom: 20px;

                .legendRegion{
                    font-size: 15px;
                    line-height: 20px;
                    width: 100%;
                }
                .span{
                  font-size: 14px;
                  line-height: 20px;
                }
                .bold {
                  font-size:15px;
                  line-height: 20px;
                }
             }
          }
      }
    }
 }
</style>
