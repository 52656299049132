export default {
    base64toFile (dataurl, filename) {
      var arr = dataurl.split(','); var mime = arr[0].match(/:(.*?);/)// [1]
      // console.log('ARR', arr)
      var bstr = atob(arr[1]); var n = bstr.length; var u8arr = new Uint8Array(n)
      // console.log('mime', mime)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], filename, { type: mime })
    },
    dataURItoBlob (dataURI) {
      var byteString
      if (!dataURI) {
        return
      }
      if (dataURI.split(',')[0].indexOf('base64') >= 0) { byteString = atob(dataURI.split(',')[1]) } else { byteString = unescape(dataURI.split(',')[1]) }
      var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
      var ia = new Uint8Array(byteString.length)
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i)
      }
      return new Blob([ia], { type: mimeString })
    }
  }
  