<template>
  <div class="anime">
      <lottie-animation v-if="animeAgain"
        path="data.json"
        :loop="false"
        :autoPlay="true"
        width="428"
        height="200" 
    />
    <div v-if="animeAgain" @click="setAnimeRefersh" style="cursor:pointer">Refresh</div>
  </div>
</template>

<script>
// import axios from "axios";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; 

export default {
  name: 'anime',
  components:{
    LottieAnimation
  },
  data (){
    return{
      // animeDataList: []
      animeAgain:true
    }
  },
  created(){
   // this.getAnime()
  },
  methods: {
    setAnimeRefersh(){
      this.animeAgain= false
       setTimeout(() => {
         this.animeAgain = true
       },300)
    }
    // getAnime () {
    //   axios.get("data.json")
    //   .then(r => {
    //     console.log(JSON.stringify(r))
    //     // return r.json()
    //     // this.animeDataList = JSON.parse(r)
    //     this.animeDataList = r.data
    //   })
    //   // .then(data => {
    //   //   this.animeDataList = data
    //   // })
    //   .catch(err => {
    //     console.log(err)
    //   })
    // }
  }

}
</script>

<style scoped>
.anime{
  width:430px;
}
#content_dc{width: 428px; height: 200px;}

/* Invisible button for background clickthrough. */
#background_exit_dc {position: absolute; width: 100%; height: 100%; top: 0px; left: 0px; cursor: pointer; opacity: 0; z-index: 400;}
:focus {outline:none;}
::-moz-focus-inner {border:0;}

</style>