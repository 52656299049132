<template>
  <div class="question">
      <div class="upQuestion">
         <p> What do you think about our estimated price?</p>
      </div>
      <div class="downQuestion">
        <litle-badge v-for="option in options" 
          :key="option.id" 
          :option="option"
          @sendthink="sendThink" 
        />
      </div>
  </div>
</template>

<script>
import arrUp from '@/assets/images/icons/arrUp.svg'
import Union from '@/assets/images/icons/Union.svg'
import arrDown from '@/assets/images/icons/arrDown.svg'

import litleBadge from './litleBadgeNew.vue'
import { mapGetters } from 'vuex'
import { api } from '@/api'
import helper from '@/js/helper.js'

export default {
  name: 'question',
  components: {
    litleBadge
  },
  data() {
    return{
      options: [
         { id: 1, title: 'High', img: arrUp, selected:false},
         { id: 2, title: 'Fair', img: Union, selected:false},
         { id: 3, title: 'Low', img: arrDown, selected:false}
      ]
    }
  },
  methods: {
    async sendThink (e) {
      // console.log('cdt - '+ this.$route.params.key)
      let params = helper.fixParams(this.getAllSelected)
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('opinion_price_feedback').catch(() => {})
      params.token = token
      params.rating = e
      // let params = helper.fixParams(this.getAllSelected)
     
      // if(this.getuserData !== {} && localStorage.getItem('sid')){
      //   console.log('sendThink '+ localStorage.getItem('sid'))
      //   params.sid = localStorage.getItem('sid')
      // }
      console.log(e)
      api.price_feedback(params).then(res => {
        console.log(res)
        this.setSelected(e)
      }).catch(e => {
        console.log(e)
      })

      


      this.$store.dispatch('setOpinionAction',true)
    },
    setSelected(id){
      for(let i=0; i < this.options.length; i++){
        if(id == this.options[i].id){
          console.log(this.options[i].id)
          this.options[i].selected=true
        }
        else {
          this.options[i].selected=false
        }
      }
    }
  },
  computed: {
    ...mapGetters(['getuserData' , 'getAllSelected'])
  }

}
</script>

<style lang="scss" scoped>
.question{
    width: 184px;
    height: 235px;
    background: #103D49;
    border: 1px solid rgba(90, 126, 135, 0.8);
    box-sizing: border-box;
    border-radius: 5px;
    padding: 15px 10px;
  .upQuestion{

    // font-family: Gotham Pro;
    width:148px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    /* or 129% */

    letter-spacing: 0.01em;

    /* text 80 */

    color: #CFD8DB;    
      p{
        margin: 0;
      }


  }
  .downQuestion{
    padding-top: 16px;
  }

}



@media only screen and (max-width: 1024px){
  .question{
        width: 154px;
        height: 235px;
//     display: flex;
//     align-items: center;
//     flex-wrap: wrap;

    .upQuestion{
         width: 114px;

    }
//     .downQuestion{
//       padding-top: 0px;
//       display: flex;
//     }
  }
}
@media only screen and (max-width: 550px){
  .question{
    display: block;
    width: 100%;
    min-height: 85px;
    height:fit-content;
    padding: 10px;
    padding-bottom: 0px;
    margin-top: 62px;

    .upQuestion{
      width: initial;
      font-size: 13px;
      line-height: 17px;
      /* identical to box height */

      letter-spacing: 0.01em;

    }
    .downQuestion{
      
      padding-top: 10px;
      display: flex;
      height: fit-content;
    }
  }
}
</style>