<template>
  <div class="main-wrapper">
    <!-- import modal video -->
     <div v-if="showModal" class="colorBlackBlure">
       <modalHoldVideo v-if="true" :url="'https://www.youtube.com/embed/_gKQJ9ioKgQ?rel=0'" @closeModal="closeModal" />
    </div>

    <div class="background-wrapper">
      <!-- <img src="@/assets/images/ValuationBackground3.png" alt=""> -->
            <img class="playModal" @click="showModal=true" :src="require('@/assets/images/playModal.svg')" />
    </div>
    <div class="center-wrapper">
      <h1 v-if="showMobTitle">Free car valuation<br>in seconds</h1>
      <h1 v-else>Get a free car valuation<br >in seconds</h1>
      <div v-if="vin" class="inputs-wrapper small">
        <input placeholder="VIN" type="text" v-model="vinNum">
        <div @click="getVin()" class="btn round">
          Get estimate
        </div>
      </div>
      <div v-else class="inputs-wrapper">
        <home-dropdown :type="'make'" :selected="getSelected('make')" :options="getOptions('make')" />
        <home-dropdown class="hide" :type="'model'" :selected="getSelected('model')" :options="getOptions('model')" />
        <home-dropdown class="hide" :type="'year'" :selected="getSelected('year')" :options="getOptions('year')" />
        <router-link class="btn round" id="link" to="/priceSteps">
          
            Get started
          
        </router-link>
      </div>
      <!-- <h3 v-if="!vin">
        Or enter Your <span @click="vin = true">Number Plate</span> or <span @click="vin = true">VIN</span><br>and we’ll find your car faster!
      </h3>
      <h3 v-else>
        Or enter Your <span @click="vin = false">details manualy</span> !
      </h3> -->
    </div>
  </div>
</template>

<script>
import HomeDropdown from '@/components/dropdowns/homeDropdown.vue'
import modalHoldVideo from '@/components/modalHoldVideo.vue'
import { mapGetters } from 'vuex'
import { api } from '@/api'
export default {
  components: {
    HomeDropdown,
    modalHoldVideo
  },
  data () {
    return {
      vin: false,
      vinNum: null,
      showModal: false,
      showMobTitle:false
    }
  },
  created(){
      window.addEventListener("resize", this.resize)
      this.resize()
  },
  computed: {
    ...mapGetters(['getAllSelected', 'getAllOptions']),
    getSelected () {
      return (str) => {
        return this.getAllSelected[str]
      }
    },
    getOptions () {
      return (str) => {
        return this.getAllOptions[str]
      }
    }
  },
  methods: {
    navigate () {
      this.$router.push('/priceSteps')
    },
    async getVin () {
      let params = {
        vin: this.vinNum
      }
      let fixedParams = null
      await api.getVin(params).then(res => {
        fixedParams = this.fixParams(res.data.data)
        fixedParams.car_km = res.data.data.odometer ? res.data.data.odometer : null
      }).catch(e => {
        console.log(e);
      })
      await this.$store.dispatch('setParams', fixedParams)
      this.navigate()
    },
    fixParams (params) {
      let newParams = {
        mak_id: params.make ? params.make.mak_id : null,
        mod_id: params.model ? params.model.mod_id : null,
        year: params.year ? params.year : null,
        bod_id: params.body ? params.body.bod_id : null,
        fue_id: params.fuel ? params.fuel.fue_id : null,
        tra_id: params.transmission ? params.transmission.tra_id : null,
        ens_id: params.engine_sizes ? params.engine_sizes.ens_id : null
      }
      return newParams
    },
     closeModal () {
        this.showModal = false
     },
     resize () {
        if (window.innerWidth <= 500) {
            this.showMobTitle = true
        } else {
          this.showMobTitle = false
        } 
     }
  },
  destroyed () {
     window.removeEventListener("resize", this.resize);
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.main-wrapper {

  .colorBlackBlure{
      position: fixed !important;
      top: 0;
      left: 0;
      // opacity: 0.94;
      width: 100vw;
      height: 100vh;
      z-index: 211;
      display: flex;
      justify-content: center;
      align-items: center;
  }

  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 700px;

  .background-wrapper {
    position: absolute;
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    background-image: url("../../assets/images/ValuationBackground3.png");
    background-position: 50% 50%;
    background-repeat: no-repeat;

    // img {
    //   position: absolute;
    //   height: 100%;
    //   min-width: 100%;
    // }
    .playModal {
      height: initial;
      min-width: inherit;
      margin-bottom: 60px;
      cursor: pointer;
      transition: .03s ease-in-out;
    }
     .playModal:hover {
     transform: scale(1.01);
      cursor: pointer;
    }

  }
  .center-wrapper {
    z-index: 1;
    margin-top: 37px;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;

    h1 {
      color: white;
      text-align: center;
      // line-height: 40px;
      //  font-family: 'Gotham Pro Bold';
      // font-size: 36px;
      // margin-bottom: 62px;
      margin-bottom: 37px;
      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
    }
    h3 {
      text-align: center;
      color: white;
      line-height: 24px;
      font-size: 17px;
      // font-family: 'Gotham Pro Medium';
      font-weight: 400;
      margin: 0;
      cursor: default;

      span {
       //font-family: 'Gotham Pro Bold';
        font-weight: 700;
        border-bottom: 2px solid white;
        cursor: pointer;
      }
    }
    .inputs-wrapper.small {
      width: 376px;

      input {
        max-width: calc(100% - 30px);
        border: none;
        margin: 0;
        padding: 0;
        outline: none;
        height: 38px;
        margin-left: 20px;
        color: #132727;
        font-size: 16px;
        padding-top: 2px;
      }
    }
    .inputs-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      width: 676px;
      border-radius: 30px;
      background: white;
      box-sizing: border-box;
      padding-right: 4px;
      // padding-left: 30px;
      margin-bottom: 15px;

      .btn.round {
        // font-family: 'Gotham Pro Medium';
        font-weight: 500;
        background-color: $main-color;
        height: 52px;
        width: 170px;
        color: white;
        border-radius: 26px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        cursor: pointer;
        transition-duration: .3s;
      }
      .btn.round:hover {
        background-color: $btn-hover;
      }
    }
  }
  .brMedium{
    display: none;
  }
}
@media only screen and (max-width: 1100px){
  .main-wrapper {
      .center-wrapper {
        h1 {

          margin-bottom: 37px;

        }
      }
      .brMedium{
         display: initial;
      }
  }
}
@media only screen and (max-width: 767px){
  .main-wrapper {
    height: 658px;
    .center-wrapper {
      h1 {
        line-height: 36px;
        font-size: 32px;
      }
      h3 {
        font-size: 15px;
        line-height: 24px;
      }
      .hide {
        display: none;
      }
      .inputs-wrapper {
        width: 315px;
        // max-width: calc( 100% - 60px);
        // min-width: 263px;
      }
    }
    .background-wrapper {
      background-position: calc(50% + 120px) 50%;
        .playModal {
          height: initial;
          min-width: inherit;
          margin-bottom: 50px;
          cursor: pointer;
          transition: .03s ease-in-out;
        }
    }
  }
}
@media only screen and (max-width: 600px){
  .main-wrapper {
    .background-wrapper {
        .playModal {
          height: initial;
          min-width: inherit;
          margin-bottom: 40px;
          cursor: pointer;
          transition: .03s ease-in-out;
        }
    }
  }
}
</style>
<style lang="scss">
.home-dropdown-wrapper:first-of-type{
  padding-left:30px;
}

</style>
