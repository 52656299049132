<template>
  <div class="" id="singleBlog" v-if="blog">
    <div v-if="showMobileNav" class="return" @click="backToBlogs">
      <img :src="require('@/assets/images/arrowLeftW.svg')" alt="">
      <span>Back</span>
    </div>
    <img id="main-image" v-if="blog && blog.image" :src="baseURL + blog.image" :alt="blog.img_alt ? blog.img_alt : 'main-blog-image'">
    <!--  -->
      <div class="blog-bar">
        <div class="round-bar-data">
          <span class="date">{{ setDate }}</span>
          <span class="dateSm">{{ setSumDate }}</span>
          <span class="category" v-if="blog && blog.categories " > 
            {{ blogCategoryString }}
         
              <!-- <span class="AllCategory" v-for=" cate in blog.categories" :key="cate.cat_id"> 
                {{cate.cat_name}}
              </span> -->
           </span>
        </div>
      </div>
    <!--  -->
    <div class="single-blog-wrapper">
      <div class="title left">
        {{blog.title}}
      </div>
      <div class="author left">
        By {{blog.author}}
      </div>
      <div class="left-single-blog-details left">
        <!-- <singleBlogDetails @showmodal='showmodal' v-if="showShare" :blog="blog" /> -->
      </div>
      <div class="content-wrapper">
        <div v-html="getContent" class="content left">
            <widgetBright v-if="false" />
        </div>
        <div class="right">
          <!-- <singleBlogDetails v-if="showShare" @showmodal='showmodal' style="position: sticky; top: 20px; z-index: 230;" :blog="blog" /> -->
        </div>
      </div>
      <div class="share left">
        <div class="upper">
           <div class="downAuthor">
             By {{blog.author}}
           </div> 
           <div class="downData">
               <roundAllShareComponent :isblog="true" :titleP="blog.title" :descriptionP="blog.description" :author="blog.author" style="z-index: 100"/>
          
              <div class="previNext">
                <span @click="openBlog(blog.next)" v-if="blog.next">Previous</span>

                <div v-if="blog.previous && blog.next" class="line"></div>
                <span @click="openBlog(blog.previous)" v-if="blog.previous">Next</span>
              </div>
           </div>
        </div>
        <!-- <div class="lower">
          <img :src="require('@/assets/images/fb.svg')" alt="facebook icon">
          <img :src="require('@/assets/images/linkedin.svg')" alt="linkedin icon">
          <img :src="require('@/assets/images/instagram.svg')" alt="instagram icon">
        </div> -->
      </div>
    </div>
      <!-- <newsletter v-if="false" /> -->
    <div class="wrapp-single-blog-wrapper">
      <div class="single-blog-wrapper">
        <homeBlogWrapper v-if="blog.id" :blogId="blog.id" :blogPage="true" />
      </div>
    </div>
    <footerDown v-if="!showMobileNav" :gray="false" :white="true" />
    <!--div v-else style="height:60px" ></div-->
  </div>
</template>

<script>
import { api, baseURL } from '@/api'
import footerDown from '@/components/newsroom/footerDown'
import homeBlogWrapper from '@/components/blog/homeBlogWrapper'
// import singleBlogDetails from '@/components/blog/singleBlogDetails'
// import newsletter from '@/components/newsletter/newsletter'
import  widgetBright from '@/components/widgete/widgetBright' 
import roundAllShareComponent from '../components/shareComponents/roundAllShareComponent.vue'
import helper from '@/js/helperDate.js'
import helperCategoryArrInString from '@/js/helperCategoryArrInString.js'
export default {
  name: 'singleBlog',
  components: {
    footerDown,
    homeBlogWrapper,
    // singleBlogDetails,
    // newsletter,
    widgetBright,
    roundAllShareComponent
  },
  data () {
    return {
      blog: {
        title: this.$route.params.id
      },
      showShare: true,
      supportsPassive : false,
      showMobileNav:false,
      setDate: null,
      setSumDate: null,
      blogCategoryString: ''
    }
  },
   metaInfo () {
        return {
        title: 'Carvisor',
        titleTemplate:  this.blog.title  + ' - %s' ,
        meta:[
           {name: 'robots', content: 'index,follow'} ,
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.blog.description
                },
                {
                     vmid: 'og:description',
                    property: 'og:description',
                    content:  this.blog.description
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.blog.title 
                },
                // {
                //     hid: 'keywords',
                //     name: 'keywords',
                //     content:  this.tempData.title
                // },
                {
                    vmid: 'og:image',
                    property: 'og:image',
                    itemprop: 'image',
                    content: this.baseURL + this.blog.image
                }
            ]
        }
    },
  created () {
    window.addEventListener("resize", this.resize)
    this.resize()
    this.getBlogData()
     try {
      window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get:  this.supportsPassive = true  
      }));
    } catch(e){
      console.log(e)
    }
  },
  destroyed () {
      window.removeEventListener("resize", this.resize)
  },
  computed: {
    baseURL () {
      return baseURL
    },
    getContent () {
      if (this.blog && this.blog.content) {
        return this.blog.content.replaceAll('&lt;', '<').replaceAll('&gt;','>')
      }
      return ''
    }
  },
  methods: {
    getBlogData () {
      this.blog = {}
      this.blog.title = this.$route.params.id
      let params = {
        id: this.$route.params.id
      }
      api.getBlogData(params).then(res => {
        this.blog = res.data.data
         this.setDate = helper.formatDate(res.data.data.date)
         this.setSumDate = helper.formatDate(res.data.data.date, true)
         this.blogCategoryString = helperCategoryArrInString.setBlogCategoryInString(res.data.data.categories)
      }).catch(e => {
        console.log(e);
      })
    },
    openBlog (slug) {
      this.$router.push('/blog/' + slug)
    },
    showmodal(e) {
      let wheelOpt = this.supportsPassive ? { passive: false } : false;
      let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
      if(e){
        // call this to Disable
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
        window.addEventListener("mousedown", this.preventDefault, { passive:false });
        document.body.style.overflow = "hidden"
        window.addEventListener("click", this.preventDefault, { passive:false });
      }else{
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
        window.removeEventListener("mousedown", this.preventDefault, { passive:false });
        window.removeEventListener("click", this.preventDefault, { passive:false });
        document.body.style.overflow = "initial"
      }
    },
    preventDefault(e) {
      e.preventDefault()
        if (e.deltaY < 0) {
          //scroll wheel up
          console.log("up");
        }
        if (e.deltaY > 0) {
          //scroll wheel down
          console.log("down");
        }
    },
    preventDefaultForScrollKeys(e) {
      var keys = {37: 1, 38: 1, 39: 1, 40: 1}
      if (keys[e.keyCode]) {
           this.preventDefault(e)
           return false
      }
    },
    resize () {
      if (window.innerWidth <= 500) {
      this.showMobileNav = true
      } else {
      this.showMobileNav = false
      }
    },
    backToBlogs () {
      this.$router.go(-1)
    }
  },
  watch: {
    '$route': function () {
       this.showShare= false
      console.log(this.$route.params.id)
      this.getBlogData()
        setTimeout(() => {
                this.showShare= true
            }, 500)
    }
  }
}
</script>

<style lang="scss" scoped>
#main-image {
  background: #EEEFF3;
  width: 100%;
  height: auto;
  max-height: 520px;
  object-fit: cover;
  // line-height: 0 !important; 
  display: block;
}
.blog-bar{
  background: #EEEFF3;
  height: 60px;
  .round-bar-data{
    // min-height: calc(100vh - 150px);
    max-width: 1275px;
    margin: 0 auto;
    padding: 0 16px;
    box-sizing: border-box;
    height: 100%;
    display: flex;
    align-items: center;
    overflow:hidden;
    text-overflow: ellipsis;
    .date{
      font-weight: 500;
      font-size: 16px;
      line-height: 25px;
      color: #062931;
      // margin-right: 20px;
      word-wrap: unset !important;
      white-space: nowrap;
      width:161px;
    }
    .dateSm{
      display: none
    }
    .category{
      padding-top: 2px;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      width: calc(100% - 131px);
      color: #134753;
      overflow:hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
       span{
            margin-right:10px;
          }
      .AllCategory{
        display: initial;
      }
      .OneCategory{
        // display: none;
      }

    }
  }
}
.wrapp-single-blog-wrapper{
//  background: #EEEFF3;
  background: #F8F8F9;
}
 
.single-blog-wrapper {
  // min-height: calc(100vh - 150px);
  max-width: 1275px;
  margin: 0 auto;
  padding: 0 16px;
  box-sizing: border-box;
  // padding-top: 42px;
  // padding-top: 11px;
  // padding-top: 36px;
  // padding-top: 38px;
  // padding-top: 18px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: inherit;

  .right {
    width: calc( 100% - 735px );
    max-width: 406px;
    margin-left: auto;
    padding-top: 20px;

    .latest-title {
      margin-top: 40px;
      margin-bottom: 12px;
      font-size: 13px;
      line-height: 24px;
      color: #062931;
      font-family: 'Gotham Pro';
    }
    .latest {
      padding: 25px 20px;
      margin-bottom: 3px;
      background: inherit;
      font-size: 18px;
      line-height: 26px;
      color: #062931;
      font-family: 'Gotham Pro Medium';
    }
  }
  .left {
    // max-width: 735px;
    max-width: 733px;
    width: 100%;
  }
  .title {
    // font-family: 'Gotham Pro Medium';
    // font-size: 40px;
    // line-height: 50px;
    // color: #062931;
    // margin-bottom: 20px;

    font-style: normal;
    font-weight: bold;
    font-size: 51px;
    line-height: 60px;

    color: #062931;
    margin-bottom: 41px;
    margin-top: 38px;

  }
  .left-single-blog-details {
    display: none;
  }
  .content-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  .content {
    // font-family: 'Gotham Pro';
    // font-size: 16px;
    // line-height: 24px;
    // color: #062931;
    // margin-bottom: 57px;
    font-weight: normal;
    font-size: 17px;
    line-height: 25px;

    color: #062931;
  }
  // .content>:first-child{
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 17px;
  //   line-height: 30px;
  //   color: #062931;
  //   margin-top: 0 !important;
  // }
  .content>:last-child{
    margin-bottom: 0 !important;
  }
  .author {
    // font-family: 'Gotham Pro Medium';
    // font-size: 16px;
    // line-height: 26px;
    // color: #062931;
    // margin-bottom: 60px;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 35px;
    /* identical to box height, or 135% */

    display: flex;
    align-items: center;

    /* black */

    color: #062931;
    margin-bottom: 63px;

  }
  .share {
   
    .upper {
      // display: flex;
      // flex-direction: row;
      // justify-content: space-between;
      // padding-right: 40px;
      box-sizing: border-box;
      // margin-bottom: 91px; it says on the design but there is a comment where it says that it should go another 6px upwards
       margin-bottom: 85px;
       .downAuthor{
          // padding-top: 25px;
          font-weight: 500;
          font-size: 17px;
          // line-height: 25px;
          display: flex;
          align-items: initial;
          // color: #062931;
          color: transparent;
          height: 73px; // 73 - 25 
          border-bottom: 1px solid #DEE0E5;

      }
      .downData{
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 9px;
        margin-right: 47px;
      }

      .previNext {
        display: flex;
        flex-direction: row;
        align-items: center;

        .line {
          width: 30px;
          height: 1px;
          background: #9498A0;
          margin: 0 12px;
          margin: 0 7px;
        }
        span {
          cursor: pointer;
          // font-size: 12px;
          // line-height: 24px;
          // font-family: "Gotham Pro Medium";
          // color: #9c1818;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
          /* identical to box height, or 133% */

          // display: flex;
          // align-items: center;
          letter-spacing: 0.01em;

          /* black */

          color: #062931;
         
          &:hover {
            color: #000;
          }

        }

      }
    }
    .lower {
      margin-top: 12px;
      img {
        height: 16px;
        margin-right: 30px;
        cursor: pointer;
      }
      img:last-child {
        margin-right: 0px;
      }
    }
   
  }
   // this is in single-blog-wrapper block
  @media only screen and (max-width: 1200px){
      .title{
        margin-top: 25px;
        margin-bottom: 42px;
      }
      .left {
        max-width: none;
        text-align: left;
      }


    .right {
      display: none;
    }
    
    .left-single-blog-details {
      // display: block;
      // margin-bottom: 40px;
    }
    .author {
      // margin-bottom: 55px;
      margin-bottom: 50px;
    }
    // .share {
    //   .upper {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: flex-end;
    //   }
    // }

    .share {
   
      .upper {
        margin-bottom: 101px;
        padding-right: 0;
        .downAuthor{
            // padding-top: 35px;
        }
        .downData{
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          // margin-top: 9px;
          margin-right: 78px;
        }

      }
    }

  }
  
}
@media only screen and (max-width: 768px){
    // .blog-bar{
    //   .round-bar-data{
    //     // .date{
    //     // }
    //     // .dateSm{
    //     // }
    //     .category{
    //       .AllCategory{
    //         display: none;
    //       }
    //       .OneCategory{
    //         display: initial;
    //       }
    //     }
    //   }
    // }

  
}
 @media only screen and (max-width: 500px){
    #main-image{
      max-height: 280px;
      height: 280px;
    }
    .blog-bar{
       height: 50px;
      .round-bar-data{
        padding: 0 16px;
        box-sizing: border-box;
        height: 100%;
        .date{
          // font-weight: 500;
          // font-size: 15px;
          // line-height: 25px;
          // // margin-right: 20px;
          display: none;
          // word-wrap: unset;
        }
        .dateSm{
          display: initial;
          font-weight: 500;
          font-size: 15px;
          line-height: 25px;
          // margin-right: 20px;
          color: #062931;
          word-wrap: unset;
          white-space: nowrap;
          width:105px;
        }
        .category{
          padding-top:0px;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          width: calc(100% - 105px);
          overflow: hidden;
          text-overflow: ellipsis ;
          white-space: nowrap;

          // .AllCategory{
          //   display: none;
          // }
          // .OneCategory{
          //   display: initial;
          // }
         
        }
      }
    }

     .single-blog-wrapper {
       //  padding-top: 23px !important;
      //  padding-top: 12px !important;

       .title {
          font-style: normal;
          font-weight: bold;
          // font-size: 34px;
          // line-height: 38px;
          font-size: 46px;
          line-height: 50px;
          margin-top: 0;
          margin-top: 12px;
          color: #062931;
          margin-bottom: 35px;
       }
       .author{
         font-size: 21px;
         line-height: 25px;
         margin-bottom: 30px;
       }
       .content {
        // font-family: Gotham Pro;
        font-style: normal;
        font-weight: normal;
        // font-size: 16px !important;
        // line-height: 24px !important;
        font-size: 16px;
        line-height: 25px;
        color: #062931;
        }
     }

    // .share {
    //   .upper {
    //     display: flex;
    //     flex-direction: row;
    //     justify-content: flex-start;
    //     margin-bottom: 51px;  
    //   }
    // }

    .share {
    
      .upper {
        // display: flex;
        // flex-direction: row;
        // justify-content: space-between;
        padding-right: 40px;
        box-sizing: border-box;
        margin-bottom: 80px !important;
        .downAuthor{
            // padding-top: 25px!important;
            font-weight: 500;
            font-size: 16px !important;
            line-height: 25px;
            color: #062931;
            height: 53px!important; // 25 53
            border-bottom: 1px solid #DEE0E5;

        }
        .downData{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 10px !important;
          margin-right: 0px !important;
        }

        .previNext {
          display: flex;
          flex-direction: row;
          align-items: center;

          .line {
            width: 30px;
            height: 1px;
            background: #9498A0;
            margin: 0 12px;
          }
          span {
            cursor: pointer;
            // font-size: 12px;
            // line-height: 24px;
            // font-family: "Gotham Pro Medium";
            // color: #9c1818;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            /* identical to box height, or 133% */

            // display: flex;
            // align-items: center;
            letter-spacing: 0.01em;

            /* black */

            color: #062931;
          

          }
        }
      }
      .lower {
        margin-top: 12px;
        img {
          height: 16px;
          margin-right: 30px;
          cursor: pointer;
        }
        img:last-child {
          margin-right: 0px;
        }
      }
    
    }
   

   }
</style>
<style lang="scss">
/* .content-wrapper .content p {
  margin: 0;
} */
#singleBlog{
  .return {
    position: absolute;
    z-index: 999;
    top: 14px;
    left: 15px;

    span {
      margin-left: 5px;

      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 25px;
      color: #fff;
    }
  }

}

#aSnipet{
   transition-duration: .1s;
}
#aSnipet:hover {
      background-color:  $btn-hover!important;
}
</style>
