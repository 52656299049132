<template>
  <div class="hideShow" @click="changeVisibility">
      <span v-if="visibility">Hide</span>
      <span v-else>Show</span>
    </div>
</template>

<script>
export default {
    name: 'hideShow',
    props:[],
    data(){
        return{
            visibility : true
        }
    },
    mounted(){
         window.addEventListener("resize", this.resize);
         this.resize()
    },
    methods:{
        changeVisibility (){
            this.visibility = !this.visibility
            this.$emit('visibility', this.visibility)
        },
        resize(){
            if (window.innerWidth >= 1024) {
                this.visibility = true
                this.$emit('visibility', true)
            } 
        }
    },
    beforeDestroy() {
        window.removeEventListener("resize", this.resize)
    }

}
</script>

<style lang="scss" scoped>
.hideShow{
    // align-self: flex-start;
    min-width: 0px;
    // margin-top:8px;
    width: fit-content;
      span{
         display: none;
      }
}
@media only screen and (max-width: 1024px){   
.hideShow{
    margin-right: 30px;
    min-width: fit-content;
    span{
        display: initial;
        font-style: normal;
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
        /* identical to box height, or 154% */

        text-align: right;

        /* Gray turquoise */

        color: #5A7E87;
        text-transform: capitalize;
        opacity: 0.8; 
        cursor: pointer;
    }
}
}
@media only screen and (max-width: 450px) {
    .hideShow {
        height: 36px;
        margin-right: 15px;
    }
}
</style>