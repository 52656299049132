<template>
  <div class="footer-wrapper">
    <!-- <footerUp v-if="!remove" /> -->
    <!-- footerDown /-->
    <footerDown v-if="!showMobileNav" :gray="true" />
  </div>
</template>
<script>
// import footerUp from './footerUp2.vue'
// import footerDown from './footerDown.vue'
import footerDown from '../newsroom/footerDown.vue'
export default {
  name: 'Footer-wrapper',
  components: {
    // footerUp,
    footerDown
  },
  data (){
    return{
      remove: false,
      showMobileNav:false
    }
  },
  created(){
      window.addEventListener("resize", this.resize)
      this.resize()
    },
  methods:{
       resize () {
        if (window.innerWidth <= 500) {
             this.showMobileNav = true
        } else if (window.innerWidth <= 787) {
          this.remove = true
          this.showMobileNav = false
        } else {
          this.remove = false
          this.showMobileNav = false
        }
        },
  },
  destroyed () {
     window.removeEventListener("resize", this.resize);
  }
}
</script>

<style lang="css" scoped>
.footer-wrapper{
  width:100%;
  background: #F8F8F9;
}
</style>
