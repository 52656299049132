import axiosBase from 'axios';
// VUE_APP_API_URL_DEV=//028x122.mars-t.mars-hosting.com/api/
let baseURL = process.env.VUE_APP_API_URL
if (process.env.NODE_ENV !== 'production') {
  baseURL = process.env.VUE_APP_API_URL_DEV
}
const axios = axiosBase.create({
  baseURL
})

const api = {
  getCars: params => axios({
    url: 'search_car',
    method: 'GET',
    params: params
  }),
  getEstimate: params => axios({
    url: 'price_estimate',
    method: 'GET',
    params: params
  }),
  getEstimateKey: (key, odometer, token) => axios({
    url: 'price_estimate',
    method: 'GET',
    params: { cdt_characteristicid: key, car_km: odometer, token: token }
  }),
  unsubscribe: params => axios({
    url: 'unsubscribe',
    method: 'POST',
    params: params
  }),
  getStats: params => axios({
    url: 'users',
    method: 'GET',
    params: params
  }),
  getSimilarCars: params => axios({
    url: 'similar-ads',
    method: 'GET',
    params: params
  }),
  getVin: params => axios({
    url: 'vin_lookup',
    method: 'GET',
    params: params
  }),
  getReport: params => axios({
    url: `report/${params.hash}/${encodeURIComponent(params.key)}/${params.odometer}`,
    method: 'GET',
    params: params
  }),
  sendFeedback: params => axios({
    url: 'feedback',
    method: 'POST',
    params: params
  }),
  gateLogIn: params => axios({
    url: 'gate-login',
    method: 'POST',
    params: params
  }),
  login: params => axios({
    url: 'login',
    method: 'POST',
    params: params
  }),
  logOut: params => axios({
    url: 'login',
    method: 'GET',
    params
  }),
  checkSession: params => axios({
    url: 'check-session',
    method: 'GET',
    params
  }),
  getBlogData: params => axios({
    url: `blog/${params.id}`,
    method: 'GET',
    params: params
  }),
  getBlogs: params => axios({
    url: `blog`,
    method: 'GET',
    params: params
  }),
  createBlog: params => axios({
    url: 'admin/news',
    method: 'POST',
    data: params
  }),
  editBlog: params => axios({
    url: 'admin/news',
    method: 'PATCH',
    data: params
  }),
  deleteBlog: params => axios({
    url: `admin/news`,
    method: 'DELETE',
    data: params
  }),
  addMedia: params => axios({
    url: 'admin/images',
    method: 'POST',
    data: params
  }),
  getImages: params => axios({
    url: 'admin/images',
    method: 'GET',
    params: params
  }),
  deleteImage: id => axios({
    url: `admin/images/${id}`,
    method: 'DELETE'
  }),
  createNews: params => axios({
    url: 'admin/news',
    method: 'POST',
    data: params
  }),
  getNews: params => axios({
    url: `news`,
    method: 'GET',
    params: params
  }),
  getNewsData: params => axios({
    url: `news/${params}`,
    method: 'GET',
    params: params
  }),
  deleteNews: params => axios({
    url: `admin/news`,
    method: 'DELETE',
    data: params
  }),
  editNews: params => axios({
    url: 'admin/news',
    method: 'PATCH',
    data: params
  }),
  signUpNewsltter: params => axios({
    url: 'newsletter', // newsletter
    method: 'POST',
    data: params
  }),
  //user
  signUpUser: params => axios({
    url: 'user/register',
    method: 'POST',
    data: params
  }),
  logInUser: params => axios({
    url: 'user/login',
    method: 'POST',
    data: params
  }),
  logOutUser: params => axios({
    url: `user/login`,
    method: 'GET',
    params: params
  }),
  getUserData: params => axios({
    // url: `user/profile/${params}`,
    url: `user/profile`,
    method: 'GET',
    params: params    
  }),
  editUserData: params => axios({
    url: `user/profile`,
    method: 'PATCH',
    data: params
  }),
  loginGoogle: params => axios({
    url: 'user/google',
    method: 'POST',
    data: params
  }),
  loginFacebook: params => axios({
    url: 'user/facebook',
    method: 'POST',
    data: params
  }),
  forgot: params => axios({
    url: 'user/forgot-password',
    method: 'GET',
    params: params
  }),
  recover: params => axios({
    url: 'user/forgot-password',
    method: 'POST',
    data: params
  }),
  price_feedback: params => axios({
    url: 'price_feedback',
    method: 'POST',
    data: params
  }),
  // Reports
  newsletterSubscribers: params => axios({
    url: 'internal_reports/newsletter',
    method: 'GET',
    params: params
  }),
  priceRatingsFeedback: params => axios({
    url: 'internal_reports/price_ratings',
    method: 'GET',
    params: params
  }),
  failedPriceReport : params => axios({
    url: 'internal_reports/failed_price_report',
    method: 'GET',
    params: params
  }),
  getNewsCategories : () => axios({
    url: '/news-categories',
    method: 'GET'
  })

}

axios.interceptors.request.use(config => {
  handleRequestInterceptor(config)
 // console.log(config);
  return config
})

function handleRequestInterceptor (config) {
  try {
    const sid = localStorage.getItem('sid')
    if (config.params && sid) {
      config.params.sid = sid
    } else if (sid) {
      config.params = {
        sid
      }
    }
  } catch (err) {
    console.log('err', err)
  }
}

export {
  baseURL,
  api
}
