<template>
  <div class="wrappFaq"> 
      <div class="heroFaq">
         <h1> FAQ </h1>
      </div>
      <div class="wrappData">
        <faq v-for="faq in faqList" :key="faq.faq_id" :faq="faq" :showFeedback="false"/>

        <div class="faqButton">
          Didn’t find the answer to your question? 
          <router-link to="/help">Contact us</router-link>
        </div>
      </div>
      <footerWrapper v-if="!showMobileNav"/>
      <div v-else style="height:60px"></div>
  </div>
</template>

<script>
import faq from '@/components/faq/oneFaq.vue'
import footerWrapper from '../components/footer/footer-wrapper.vue'
export default {
  components:{
    faq,
    footerWrapper
  },
  mounted(){
      window.addEventListener("resize", this.resize)
      this.resize()
  },
    destroyed () {
      window.removeEventListener("resize", this.resize)
  },
  data(){
    return{
      faqList:[
        {faq_id:1, question: 'Why should I use Carvisor’s pricing tool?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:2, question: 'How are the valuations calculated?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:3, question: 'What information is included with the Carvisor Valuation Report?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:4, question: 'How the Carvisor Valuation Report benefits the seller?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:5, question: 'How the Carvisor Valuation Report benefits the buyer?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:6, question: 'How much is the Valuation Report?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'},
        {faq_id:7, question: 'Which plan should I choose?', answer: 'Quisque pellentesque ullamcorper auctor. Cras sed massa quis erat interdum eleifend id varius lectus. Proin eleifend vel ex at efficitur. Nulla ipsum nisi, pharetra eu dapibus sit amet, varius mattis eros. Sed vestibulum sapien nulla, eu rhoncus nibh pharetra id. Donec nisi velit, vulputate sit amet dapibus ut, eleifend ac erat. Morbi eget nunc vel tortor posuere interdum nec non est. Curabitur id dapibus ante, sit amet feugiat magna...'}

      ],
      showMobileNav: false
    }
  },
  methods:{
    resize () {
      if (window.innerWidth <= 500) {
      this.showMobileNav = true
      } else {
      this.showMobileNav = false
      }
    },
  }

}
</script>

<style lang="scss" scoped>
.wrappFaq{
   
  
    .heroFaq{
      // background: linear-gradient(116.78deg, #104756 13.98%, rgba(16, 71, 86, 0) 75.44%);
      // background: linear-gradient(116.78deg, #104756 43.98%, rgba(16, 71, 86, 0) 99.44%);
      // background: linear-gradient(116.78deg, #104756 13.98%, #104756b4 63.98%, rgba(16, 71, 86, 0) 100%);
      // background: linear-gradient(116.78deg, #104756 13.98%, rgba(16, 71, 86, 0) 75.44%);
      background: linear-gradient(116.78deg, #104756 13.98%, #3D7484 75.44%);
      width: 100%;
      height: 185px;
      // background: linear-gradient(116.78deg, #104756 13.98%, #3D7484 75.44%);
      display: flex;
      justify-content: center;
      position: relative;
      h1 {
        margin: 0;
        position: absolute;
        bottom: 47px;

        font-size: 36px;
        color: #fff;
      }

    }

    .wrappData{
      // max-width: 1275px;
      max-width: 743px;
      margin: 0 auto;
      padding: 60px 10px 100px 10px;
      
    }
    .faqButton{
      max-width: 743px;
      border: 1px solid rgba(6, 41, 49, 0.8);
      box-sizing: border-box;
      border-radius: 50px;
      padding:17px;
      text-align: center;
      margin: 0 auto;
      margin-top: 60px;
      font-weight: 500;
      font-size: 17px;
      line-height: 25px;
      /* identical to box height, or 147% */

      // display: flex;
      // align-items: center;
      // text-align: center;

      /* black */

      color: #062931;
      // justify-content: center;
      // align-items: center;
      a{
        text-decoration: none;
        color: $main-color;
      }
      a:hover{
        color: $btn-hover;
      }
    }
}
@media only screen and (max-width: 786px){
  .wrappFaq{
    .wrappData{
      padding: 40px 27px 80px 27px;
    }
  }

}
@media only screen and (max-width: 500px){
  .wrappFaq{
    .heroFaq {
      h1 {
        font-size: 34px;
        bottom: 32px;
        left: 16px;
      }
    }
    .wrappData{
      padding: 40px 16px 90px 16px;
    }
    .faqButton{
      margin-top: 40px;
    }
  }

}
</style>