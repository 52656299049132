<template>
  <div :class="[{'hide-section': !visibility},'expMarket']">
        <div v-if="blur" class="overlay">
        <div v-if="!falseData" @click="$emit('unlock')" class="overlay-btn">
            <img :src="require('@/assets/images/lock.png')" alt="lock image">
            Unlock results
        </div>
      </div>
      <div class="up">
        <p>Interesting insights</p>
        <div class="right-btns">
            <hideShow  @visibility="changeVisibility" class="flex-end"/>
            <tooltip :msg="msg" class="flex-end"/>
        </div>
      </div>
       <!--div class="down" v-if="blur && selected != null">
         <p> <span>{{interestedBuyersPr}} %</span> of <span class="low">{{selected.body ? selected.body.value :'x' }}</span> buyers are interested in <span class="upp"> {{selected.make? selected.make.value:'x '}}  {{ selected.model?selected.model.value:'x'}}</span></p>
           <p>About <span>{{looksBuyersN}}</span> buyers are currently looking for <span class="upp">{{selected.make? selected.make.value:'x '}}  {{ selected.model?selected.model.value:'x'}}</span></p>
          <p> <span>{{looksBuyersPr}} %</span> {{selected.make? selected.make.value:'x '}} buyers on the market are currently looking for <span class="upp"> {{selected.make? selected.make.value:'x '}}  {{ selected.model?selected.model.value:'x'}}</span></p>
         <p>There are around <span>{{carOfModelOnMarket}}</span> <span class="upp">{{selected.make? selected.make.value:'x ' }}  {{ selected.model?selected.model.value:'x'}}</span>s presently on the market</p>
    </div -->
     <div class="down" v-if="selected && selected.make == null && visibility">
         <p> <animateNumber  :animlength="1000" :end="interestedBuyersPr" :start="0" :value="'%'" /> of <span class="low">{{bodyValue}}</span> buyers are interested in <span class="upp"> {{makeValue + ' ' + modelValue}}</span></p>
           <p>About <animateNumber  :animlength="1000" :end="looksBuyersN" :start="0" :value="null" />  buyers are currently looking for <span class="upp">{{makeValue + ' ' + modelValue}}</span></p>
          <p> <animateNumber  :animlength="1000" :end="looksBuyersPr" :start="0" :value="'%'" /> {{makeValue}} buyers on the market are currently looking for <span class="upp"> {{makeValue + ' ' + modelValue}}</span></p>
         <p>There are around <animateNumber :animlength="1000" :end="carOfModelOnMarket" :start="0" :value="null" /> <span class="upp">{{makeValue + ' ' + modelValue}}</span>s presently on the market</p>
    </div>
    <!-- div class="down" v-else-if="selected.make != undefined && selected.make != null && showData  && selected.model != null && selected.body != null">
         <p> <animateNumber  :animlength="1000" :end="interestBuyersPr" :start="0" :value="'%'" /> of <span class="low"> {{selected.body.value}}</span> buyers are interested in <span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span></p>
           <p>About <animateNumber  :animlength="1000" :end="looBuyersN" :start="0" :value="null" /> buyers are currently looking for {{selected.make.value + ' ' + selected.model.value}}</p>
          <p> <animateNumber  :animlength="1000" :end="looBuyersPr" :start="0" :value="'%'" /><span class="upp"> {{selected.make.value}}</span> buyers on the market are currently looking for <span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span></p>
          <p>There are around <animateNumber :animlength="1000" :end="cOfModelOnMarket" :start="0" :value="null" /> <span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span>s presently on the market</p>
    </div -->
     <div class="down" v-else-if="expMarket1 && selected.make != undefined && selected.make != null && showData  && selected.model != null && selected.body != null && visibility">
         <p> <animateNumber  :animlength="1000" :end="interestedBuyersPr" :start="0" :value="'%'" :expMarket1="expMarket1" /> of <span class="low"> {{selected.body.value}}</span> buyers are interested in <span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span></p>
           <p>About <animateNumber  :animlength="1000" :end="looksBuyersN" :start="0" :value="null" :expMarket1="expMarket1" /> buyers are currently looking for {{selected.make.value + ' ' + selected.model.value}}</p>
          <p> <animateNumber  :animlength="1000" :end="looksBuyersPr" :start="0" :value="'%'" :expMarket1="expMarket1" /><span class="upp"> {{selected.make.value}}</span> buyers on the market are currently looking for <span class="upp">{{false?selected.make.value + ' ' + selected.model.value: selected.model.value}}</span></p>
          <p>There are around <animateNumber :animlength="1000" :end="carOfModelOnMarket" :start="0" :value="null" :expMarket1="expMarket1" /><span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span><template>{{getSufix(selected.model.value)}}</template> presently on the market</p>
    </div>
     <div class="down" v-else-if="selected.make != undefined && selected.make != null && showData  && selected.model != null && selected.body != null && visibility">
         <p> <span>%</span> of <span class="low"> {{selected.body.value}}</span> buyers are interested in <span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span></p>
           <p>About <span>X</span> buyers are currently looking for {{selected.make.value + ' ' + selected.model.value}}</p>
          <p> <span>% </span><span class="upp"> {{selected.make.value}}</span> buyers on the market are currently looking for <span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span></p>
          <p>There are around <span>X</span> <span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span>s presently on the market</p>
    </div>
    <!--
    <div class="down" v-else-if="selected.make != undefined && selected.make != null && showData  && selected.model != null && selected.body != null">
         <p> <animateNumber  :animlength="1000" :end="interestedBuyersPr" :start="0" :value="'%'" /> of <span class="low"> {{selected.body.value}}</span> buyers are interested in <span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span></p>
           <p>About <animateNumber  :animlength="1000" :end="looksBuyersN" :start="0" :value="null" /> buyers are currently looking for {{selected.make.value + ' ' + selected.model.value}}</p>
          <p> <animateNumber  :animlength="1000" :end="looksBuyersPr" :start="0" :value="'%'" /><span class="upp"> {{selected.make.value}}</span> buyers on the market are currently looking for <span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span></p>
          <p>There are around <animateNumber :animlength="1000" :end="carOfModelOnMarket" :start="0" :value="null" /> <span class="upp">{{selected.make.value + ' ' + selected.model.value}}</span>s presently on the market</p>
    </div>
    -->



  </div>
</template>

<script>
import { mapState } from 'vuex'
import tooltip from '../tooltip2.vue'
import animateNumber from '../animateNumber.vue'
import hideShow from '@/components/HideShow.vue';
export default {
    name: 'expMarket2',
    props: {
        interestedBuyersPr:{
            required: false,
            type: Number
        },
        looksBuyersPr:{
            required: false,
            type: Number
        },
        looksBuyersN:{
            required: false,
            type: Number
        },
        carOfModelOnMarket:{
            required: false,
            type: Number
        },
        //for sample Report
        modelValue:{
            required: false,
             default: 'x'
        },
        makeValue:{
            required: false,
             default: 'x'
        },
        bodyValue:{
            required: false,
             default: 'x'
        },
        falseData:{
            required: false,
            // default: false,
            type: Boolean
        },
        blur:{
            required: false,
            default: false,
            type: Boolean
         },
         insights:{
             required: false,
         }

    },
    components:{
        tooltip,
        animateNumber,
        hideShow
    },
    computed:{
        ...mapState(['selected']),
        getSufix () {
          return model => {
            let lastChar = model.charAt(model.length - 1)
            console.log(lastChar);
            if (lastChar === 's' || lastChar === 'z') {
              return 'es'
            } else {
              return 's'
            }
          }
        }
    },
    data () {
        return{
            msg:'We provide you with carefully chosen data from our daily analyses that might be helpful in your decision making process.',
             intersectionOptions: {
                    root: null,
                    rootMargin: '0px 0px 0px 0px',
                    threshold: [0.5, 0.5]  // [0.25, 0.75] if you want a 25% offset!
                },
                activeWayPoint: true,
                num:0,
                num1:0,
                num2:0,
                num3:0,
                showData:true,
                interestBuyersPr:1,
                looBuyersPr:1,
                looBuyersN:1,
                cOfModelOnMarket:1,
                expMarket1: false,
                visibility: true
        }
    },
    mounted(){
        // console.log('selected---'+this.selected)
    },
    methods: {
         getNumber (num) {
            if (!num) { return ''}
            let res = ''
            res += num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&thinsp;');
            return res
        },
        setThinSpce (val) {
            return val+'&thinsp;%'
        },
        setShowData(){

        },
        changeVisibility (e) {
            this.visibility=e
            setTimeout(() => {
            if( this.observed > 1)
            this.show = e
            }, 100);
        }
    },
    watch: {
        blur: {
           deep: true,
           immediate: true,
           handler (newVal) {
           // console.log(' blur------'+ newVal)
            if(newVal === false){

                console.log(' blur '+ newVal)
                this.expMarket1 = true
                }
           }
        },
       insights: {
            deep: true,
            immediate: true,
           handler (newVal) {
               console.log('insights  newValexpMarket1------'+ newVal)
               // this.expMarket1 = true
               // this.showData=false
                this.interestBuyersPr = this.insights.interestedBuyersPr
                this.looBuyersPr = this.insights.looksBuyersPr
                this.looBuyersN = this.insights.looksBuyersN
                this.cOfModelOnMarket = this.insights.carOfModelOnMarket
                setTimeout(() => {
               // this.showData=true

                }, 20)
           }
        }

    }

}
</script>

<style lang="scss" scoped>
@import '@/assets/css/statsOverlay.scss';
.expMarket{
    position:relative;
    background: #FFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    width: 100%;
    //height: 100%;
    height: 503px;
    margin-bottom: 10px;
    // text-align: center;
    display:flex;
    flex-direction: column;
    align-items: center;
     .up{
         display:flex;
         width:100%;
         box-sizing: border-box;
         justify-content: space-between;
         align-items: center;
        //  font-style: normal;
        //  font-weight: bold;
        //  font-size: 18px;
        //  //line-height: 28px;
        //  letter-spacing: 0.01em;
        font-family: 'DM Sans';
        font-weight: 500;
        font-size: 16px;
        line-height: 25px;
        /* or 143% */

        letter-spacing: 0.05em;
        text-transform: uppercase;

        /* Gray turquoise */

        color: #5A7E87;

        //  color: $text-darker;
         padding:0 20px;
         padding-top: 11px;

         p{
             margin-top: 11px;
         }
        .right-btns{
            display: flex;

            margin-top: -4px;
            
        }
     }
     .down{
        // width:82.5%;
        //  padding-left:40px;

        //  padding-top:20px;
         padding: 0px 40px;
         margin :0 auto;
         margin-top: -12px;
         margin-bottom: -18px;
         height:83%;
         display: flex;
         flex-direction: column;
         justify-content: center;
        // align-items: center;

         p{
            font-style: normal;
            // font-weight: normal;
            // font-size: 16px;
            margin-top:0;
            // line-height: 28px;
            //letter-spacing: 0.02em;
            color: #062931 !important;
            //margin: 5px !important;

            font-size: 17px;
            font-weight: 400;
            line-height: 30px;
            text-align: center;
            span{
                // font-family: 'Gotham Pro Bold';
                // font-style: normal;
                // font-weight: bold !important;
                font-size: 26px;
                line-height: 30px;
                /* or 106% */

                /* Text primary darker */

                font-style: normal;
                font-weight: bold;
                // font-size: 36px;
                // line-height: 40px;
                /* or 111% */


                /* black */

                color: #062931;

                // color:#062931;
            }


         }
         p:nth-child(odd) {
            span{
                font-style: normal;
                // font-weight: bold !important;
                // font-size:34px;
                //line-height: 36px;
                /* or 106% */
                 font-size: 26px;
                line-height: 30px;

                /* Text primary darker */

                color: #062931;
            }
             .upp{
                //  font-family: 'Gotham Pro';
                // font-style: normal !important;
                // font-weight: normal !important;
                // font-size: 16px !important;
                // text-transform: capitalize !important;

                font-size: 17px;
                font-weight: 400;
            }
            .low{
                //    font-family: 'Gotham Pro';
                //     font-style: normal !important;
                //     font-weight: normal !important;
                //     font-size: 16px !important;
                //     text-transform: lowercase !important;

                font-size: 17px;
                font-weight: 400;
              }

         }

        .upp{
            // font-family: 'Gotham Pro';
            // font-style: normal !important;
            // font-weight: normal !important;
            // font-size: 16px !important;
            // text-transform: capitalize !important;

            font-size: 17px;
            font-weight: 400;
            }
        .low{
            //    font-family: 'Gotham Pro';
            //     font-style: normal !important;
            //     font-weight: normal !important;
            //     font-size: 16px !important;
            //     text-transform: lowercase !important;

            font-size: 17px;
            font-weight: 400;
        }


     }
}

@media only screen and (max-width: 1024px){
  .expMarket {
    border-radius: 0px;
    width: 100%;
     .down{
         margin: 0 auto;
         width:70%;
         text-align: left;
         p{
            span{

                font-style: normal;
                font-weight: bold;
                font-size: 28px;

            }

         }
          p:nth-child(odd) {
            span{
                font-style: normal;
                font-weight: bolder !important;
                font-size:35px;
            //      .upp{
            //      font-family: 'Gotham Pro';
            //     font-style: normal !important;
            //     font-weight: normal !important;
            //     font-size: 16px !important;
            //     text-transform: capitalize !important;
            // }
            // .low{
            //    font-family: 'Gotham Pro';
            //     font-style: normal !important;
            //     font-weight: normal !important;
            //     font-size: 16px !important;
            //     text-transform: lowercase !important;
            //   }


            }

         }
      }
  }
}
@media only screen and (max-width: 768px){
  .expMarket {
    border-radius: 0px;
    width: 100%;
    height:initial;
    padding-bottom: 70px;
        .up{
            margin-top:20px ;
            p{
                margin-top:2px;
            }
        }
     .down{
         padding: 45px;
         padding-bottom: 13px;
         min-height: min-content;
         p{
             font-size:16px !important;
             line-height: 25px;
            span{
                // font-style: normal;
                // font-weight: bold;
                // font-size: 20px;
                font-style: normal;
                font-weight: 700 !important;
                font-size:25px;
                line-height: 30px;
            }
             .upp{
                 
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 16px !important;
                // font-size: 15px !important;
                // text-transform: capitalize !important;
            }
            .low{
               
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 16px !important;
                // font-size: 15px !important;
                // text-transform: lowercase !important;
            }

         }
          p:nth-child(odd) {
            span{
                font-style: normal;
                font-weight: 700 !important;
                font-size:25px;
                line-height: 30px;

            }
             .upp{
                 
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 16px !important;
                // text-transform: capitalize !important;
            }
            .low{
               
                font-style: normal !important;
                font-weight: normal !important;
                font-size: 16px !important;
                // font-size: 15px !important;
                // text-transform: lowercase !important;
            }

         }
      }
  }
}
@media only screen and (max-width: 450px){
      .expMarket{
        .up{
            padding:0 16px !important;
        }
   }
 }
.hide-section {
    min-height: min-content;
    height: min-content;
    padding-bottom: 5px;
}
</style>
