<template>
  <div class="">
    <div class="blog-list-wrapper">
      <h1>Carvisor blog</h1>
      <div class="content">
        <div class="leftSide">
          <searchBlog @search="getSearchBlogData" />
          <div v-if="blogs.length <= 0" class="noResults">No results found.</div>
          <blogItem :class="{ last : index === blogs.length - 1 }" v-for="(blog, index) in blogs" :key="index" :blogData="blog" />
          <div v-if="!blogs.length <= 0" class="show-more-wrapper">
            <div v-if="more" @click="showMore()" class="show-more">
             <img :class="{spinLoader : spinLoader}" src="../assets/images/loadShowMore.svg" />  Show more
            </div>
          </div>
        </div><!--end leftSide-->
        <div class="rightSide"><widgetBlack v-if="!showWidget"/></div>
      </div><!-- end content-->
    </div>
    <widgetBlack v-if="showWidget" />
    <newsletter v-if="false" />
    <footerDown v-if="!showMobileNav" :gray="true" />
    <div v-else style="height:60px" ></div>
  </div>
</template>

<script>
import { api } from '@/api'
import blogItem from '@/components/blog/blogItem.vue'
import footerDown from '../components/newsroom/footerDown'
import newsletter from '@/components/newsletter/newsletter'
// import widgetBlack from '@/components/widgete/widgetBlack'
import widgetBlack from '@/components/widgete/widgetNewReplaceBlack.vue'
import searchBlog from '@/components/blog/searchBlog.vue'
export default {
  name: "blogList",
  components: {
    footerDown,
    blogItem,
    newsletter,
    widgetBlack,
    searchBlog
  },
  data () {
    return {
      blogs: [],
      offset: 0,
      count: 6,
      more: true,
      title: 'Blog',
      description: 'Blog',
      showWidget: false,
      showMobileNav: false,
      search: '',
      cat_id: 'all',
      spinLoader: false
    }
  },
   metaInfo () {
        return {
        title: 'Carvisor',
        titleTemplate: '%s - ' + this.title ,
        meta:[
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.description
                },
                {
                     vmid: 'og:description',
                    property: 'og:description',
                    content:  this.description
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.title 
                },
                // {
                //     hid: 'keywords',
                //     name: 'keywords',
                //     content:  this.tempData.title
                // },
                // {
                //     vmid: 'og:image',
                //     property: 'og:image',
                //     itemprop: 'image',
                //     content: this.baseURLImg
                // }
            ]
        }
    },
  mounted () {
    this.getBlogs()
  },
  methods: {
     getSearchBlogData(e){
       
        this.count=6
        this.offset=0

      console.log(e)
      this.search = e[0] 
      this.cat_id = e[1] 
 
      this.getBlogs()

    },
    getBlogs () {
      let params = {
        count: this.count,
        offset: this.offset
      }
       if( this.search !== '' && this.search.length > 0){
          params.search = this.search 
       }
      if(this.cat_id !== '' && this.cat_id !== 'all'){
          params.cat_id = this.cat_id 
       }



      api.getBlogs(params).then(res => {
        this.blogs = res.data.data
        if (this.blogs.length === res.data.count) {
          this.more = false
        }else{
          this.more = true
        }
      }).catch(e => {
        console.log(e);
      })
    },
    showMore () {
      this.spinLoader = true
      let currOffset = this.blogs.length
      let params = {
        count: this.count,
        offset: currOffset
      }
       if( this.search !== '' && this.search.length > 0){
          params.search = this.search 
       }
      if(this.cat_id !== '' && this.cat_id !== 'all'){
          params.cat_id = this.cat_id 
       }
      api.getBlogs(params).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.blogs.push(res.data.data[i])
        }
        if (this.blogs.length === res.data.count) {
          this.more = false
        }
        this.spinLoader=false
      }).catch(e => {
        console.log(e);
      })
    },
       //1080px
       resize () {
            if (window.innerWidth <= 500) {
                this.showMobileNav = true
                this.showWidget = true
            } else if (window.innerWidth <= 1080) {
                this.showMobileNav = false
                this.showWidget = true
            } else {
                this.showMobileNav = false
                this.showWidget = false
            }
         }

  },
  created(){
        window.addEventListener("resize", this.resize)
        this.resize()
  },
  destroyed() {
        window.removeEventListener("resize", this.resize);
  }
}
</script>

<style lang="scss" scoped>
.blog-list-wrapper {
  min-height: calc(100vh - 150px);
  max-width: 1275px;
  margin: 0 auto;
  padding: 0 6px;
  box-sizing: border-box;
  // padding-top: 60px;
  padding-top: 98px;

  .content {
    display: flex;
  }
  .leftSide {
    min-width: 951px !important;
  }
  .rightSide {
    display: flex;
    // padding-top: 10px;
    margin-left: auto;
    padding-bottom: 120px;
  }
  .noResults{
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 25px;
    color: #062931;
    margin-top: 54px;
    margin-bottom: 100px;
  }
  h1 {
    margin-top:0px;
    // margin-bottom: 45px;
    margin-bottom: 42px;
    // font-size: 40px;
    // line-height: 46px;
    // color: #062931;
    // font-family: 'Gotham Pro';
    font-weight: bold;
    font-size: 36px;
    line-height: 40px;
    /* identical to box height, or 111% */

    display: flex;
    align-items: center;

    /* black */

    color: #062931;

  }
  .show-more-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 120px;
    margin-bottom: 112px;
    // max-width: 735px;

    .show-more {
       cursor: pointer;
      // font-size: 15px;
      // line-height: 24px;
      // color: #5A7E87;
      // font-family: 'Gotham Pro Medium';

      // font-family: Gotham Pro;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;
      display: flex;
      /* identical to box height, or 160% */


      /* Gray turquoise */

      color: #5A7E87;
      &:hover {
        color: rgba(70, 127, 143, 0.8);;
      }

      img{
       margin-right: 7px;
       
      }
      .spinLoader{
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
      }

      /* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

    }
  }
}

// @media only screen and (max-width: 450px){
//   .blog-list-wrapper {
//     padding-top: 47px;

//     h1 {
//       font-size: 30px;
//     }
//   }
// }
@media only screen and (max-width: 1350px){
  .blog-list-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    // padding-top: 20px;
    .leftSide {
      min-width: initial !important;
    }

    h1 {
      font-size: 34px;
      line-height: 40px;
    }

     .show-more-wrapper {
      margin-top: 41px;
      margin-bottom: 52px;
      // max-width: 735px;

      .show-more {}
    }
  }
}
@media only screen and (max-width: 1050px){
  .blog-list-wrapper {
     min-height: fit-content;
    .leftSide {
      min-width:100% !important;
      max-width: 100%;
      box-sizing: border-box;
    }
    .noResults{
      margin-top: 40px;
    }

  }
}

@media only screen and (max-width: 550px){
  .blog-list-wrapper {
    padding-top: 52px;

    .noResults{
      font-size: 21px;
      line-height: 25px;
      margin-bottom: 60px;
    }

    h1 {
      font-size: 30px;
      margin-bottom: 47px;
    }
  }
}
</style>
<style lang="scss">
.blog-list-wrapper{
  a:first-of-type>.blog-item-wrapper{
    padding-top: 32px;

  }
   a:last-of-type>.blog-item-wrapper{
    border-bottom: none;

  }
}
</style>
