<template>
  <div class="admin-login-wrapper">
    <div class="login-wrapper">
      <input placeholder="email" type="email" v-model="email">
      <input placeholder="password" type="password" v-model="password">
      <div @click="login" class="btn">
        login
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/api'
export default {
  name: 'adminLogin',
  data () {
    return {
      email: null,
      password: null
    }
  },
  methods: {
    async login () {
      await this.$recaptchaLoaded()
      const token = await this.$recaptcha('login').catch(() => {})
      let params = {
        email: this.email,
        password: this.password,
        token: token
      }
      // api.login(params)....before
      api.logInUser(params).then(res => {
        localStorage.setItem('sid', res.data.sid)
        this.$store.commit('changeLoged', true)
        localStorage.setItem('userName', res.data.name)
        this.$store.commit('setUserName', res.data.name)
        this.$store.commit('setUserPrivilege', res.data.type)
        if (res.data.type > 2) {
          this.$router.push({ name: 'admin' })
        } else {
          this.$router.push({ name: 'Home' })
        }
      }).catch(() => {
        localStorage.removeItem('sid')
        this.$store.commit('changeLoged', false)
        localStorage.removeItem('userName')
        this.$store.commit('setUserName', null)
        this.$store.commit('setUserPrivilege', 0)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.admin-login-wrapper {
  min-height: calc(100vh - 80px);
  display: flex;
  align-items: center;
  justify-content: center;

  .login-wrapper {
    padding: 30px;
    border: 1px solid $main-color;
    display: flex;
    flex-direction: column;
    margin-bottom: 10%;

    input {
      margin-bottom: 15px;
      outline: none;
    }
    .btn {
      margin-left: auto;
      background-color: $main-color;
      padding: 7px 10px 5px 10px;
      color: white;
      cursor: pointer;
    }
  }
}
</style>
