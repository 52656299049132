<template>
    <div class="singin">
       <signinComponent 
         @signin="signin"
         :eermsg="eermsg"
       />

    </div>
</template>

<script>
import signinComponent from '@/components/loginComponents/signinComponent'
import { api }from '@/api'
//  import { sha256 } from 'js-sha256'
export default {
    name: 'singin',
    components: {
        signinComponent
    },
    data() {
        return{
            eermsg:''
        }
    },
    methods: {
            async signin (param) {
                await this.$recaptchaLoaded()
                const token = await this.$recaptcha('signup').catch(() => {})
                let params= new FormData();
                params.append( 'token' , token)
                for(let propertyObj in param ){
                    if(propertyObj === 'remember' ) continue
                    // if(propertyObj === 'usr_password') 
                    // params.append(propertyObj, param[propertyObj]) // sha256(param[propertyObj])
                    params.append(propertyObj, param[propertyObj])
                }
                api.signUpUser(params).then(res =>{
                    console.log(res)
                    if(param.remember)
                        this.loginuser({email:param.usr_email, password:param.usr_password})
                    else
                        this.$router.push({ name: 'login' })

                }).catch(e => {
                    console.log(e )
                    this.eermsg='Sign up failed, please try again'
                })
                
            },
            loginuser(e) {
            api.logInUser(e).then(res => {
                console.log(res)
                 this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
       
            }).catch(e => {
             this.msg='Username or password incorrect'
             console.log(e.message);
            })

        },
        getUserData(sid) {
            let params = {
                sid : sid
            }
            api.getUserData(params).then(res => {
                console.log('res')
                 console.log(res.data.data)
                this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
             console.log(e)
            })
        }
    }

}
</script>

<style lang="scss" scoped>
.singin{
    display: flex;
    width: 100%;
    height: calc(100vh - 80px);
    justify-content: center;
    // align-items: center;
    // padding-top: 87px;
    padding-top: 98px;
    
}
@media only screen and (max-width: 500px){
    .singin{
        height: initial;
        padding-top: 73px;
    }
}

</style>