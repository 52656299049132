<template>
  <!-- <div style="width:100%;min-height:320px; background: #EEEFF3; border: 1px solid #DEE0E5; box-sizing: border-box; border-radius: 5px;padding:25px 30px 0px 30px;">
    <div style="margin-bottom:26px;height:25px;padding-top:3px; width:194px; box-sizing:border-box; display:flex; align-items: center; justify-content:center; text-transform:uppercase; border-radius: 5px; color: #FFFFFF;background: #103D49;font-size: 13px;line-height: 0px;"> Carvisor recommends</div>
    <div style="font-family: Gotham Pro;font-style: normal;font-weight: bold;font-size: 36px;line-height: 40px;color: #062931;" >How much is your car worth?</div>
    <div style="font-size: 20px; line-height:24px; color:#062931; margin-top: 16px; margin-bottom:44px;">Get a free car valuation in seconds</div>        
    <a href="/priceSteps" style="font-family: 'Gotham Pro';font-style: normal;font-weight: normal;font-size:15px;line-height: 24px;color: #062931;color:#ffffff; text-decoration:none;background: #FF6359;border-radius: 52px;width:169px;height:52px; display:flex; justify-content:center; align-items:center; margin-bottom:54px; ">Get started</a>
  </div> -->
  <!-- <div style="width:100%;min-height:310px; background: #EEEFF3; border: 1px solid #DEE0E5; box-sizing: border-box; border-radius: 5px;padding:25px 30px 0px 30px;"><div style="margin-bottom:26px;height:25px;padding-top:3px; width:194px; box-sizing:border-box; display:flex; align-items: center; justify-content:center; text-transform:uppercase; border-radius: 5px; color: #FFFFFF;background: #103D49;font-size: 13px;line-height: 0px;"> Carvisor recommends</div><div style="font-family:'Gotham Pro Bold';font-style: normal;font-weight: bold;font-size: 36px;line-height: 40px;color: #062931;" >How much is your car worth?</div><div style="font-size: 20px; line-height:24px; color:#062931; margin-top: 16px; margin-bottom:44px;">Get a free car valuation in seconds</div><a id="aSnipet" href="/priceSteps" style="font-family: 'Gotham Pro';font-style: normal;font-weight: normal;font-size:15px;line-height: 24px;color: #062931;color:#ffffff; text-decoration:none;background: #FF6359;border-radius: 52px;width:169px;height:52px; display:flex; justify-content:center; align-items:center; margin-bottom:64px; ">Get started</a></div> -->

  <!-- <div style="width:100%;min-height:310px; background: #EEEFF3; border: 1px solid #DEE0E5; box-sizing: border-box; border-radius: 5px;padding:25px 30px 0px 30px;"><div style="margin-bottom:26px;height:25px;padding-top:2px; width:194px; box-sizing:border-box; display:flex; align-items: center; justify-content:center; text-transform:uppercase; border-radius: 5px; color: #FFFFFF;background: #103D49;font-size: 13px;line-height: 0px;"> Carvisor recommends</div><div style="font-family:'Gotham Pro Bold';font-style: normal;font-weight: bold;font-size: 36px;line-height: 40px;color: #062931;" >How much is your car worth?</div><div style="font-size: 20px; line-height:24px; color:#062931; margin-top: 16px; margin-bottom:44px;">Get a free car valuation in seconds</div><a id="aSnipet" href="/priceSteps" style="font-family: 'Gotham Pro';font-style: normal;font-weight: normal;font-size:15px;line-height: 24px;color: #062931;color:#ffffff; text-decoration:none;background: #FF6359;border-radius: 52px;width:169px;height:52px; display:flex; justify-content:center; align-items:center; margin-bottom:64px; ">Get started</a></div> -->
  <!-- <div
    style="
      width: 100%;
      min-height: 320px;
      background: #eeeff3;
      border: 1px solid #dee0e5;
      box-sizing: border-box;
      border-radius: 5px;
      padding: 25px 30px 0px 30px;
    "
  >
    <div
      style="
        margin-bottom: 23px;
        height: 25px;
        width: 203px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        border-radius: 5px;
        color: #ffffff;
        background: #103d49;
        font-weight: 500;
        font-size: 14px;
        letter-spacing: 0.05em;
        line-height: 25px;
      "
    >
      Carvisor recommends
    </div>
    <div
      style="
        font-weight: bold;
        font-size: 36px;
        line-height: 40px;
        color: #062931;
      "
    >
      How much is your car worth?
    </div>
    <div
      style="
        color: #062931;
        margin-top: 17px;
        margin-bottom: 45px;
        font-style: normal;
        font-weight: normal;
        font-size: 22px;
        line-height: 25px;
        color: #062931;
      "
    >
      Get a free car valuation in seconds
    </div>
    <a
      id="aSnipet"
      href="/priceSteps"
      style="
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #ffffff;
        text-decoration: none;
        background: #ff6359;
        border-radius: 52px;
        width: 169px;
        height: 52px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 64px;
        letter-spacing: 0.01em;
      "
      >Get started</a
    >
  </div> -->
   <div style=" width: 100%; min-height: 320px; background: #eeeff3; border: 1px solid #dee0e5; box-sizing: border-box; border-radius: 5px;padding: 25px 30px 0px 30px;"><div style="margin-bottom: 23px; height: 25px; width: 203px;box-sizing: border-box;display: flex; align-items: center;justify-content: center;text-transform: uppercase; border-radius: 5px; color: #ffffff; background: #103d49;font-weight: 500;font-size: 14px;letter-spacing: 0.05em;line-height: 25px;">Carvisor recommends</div><div style="font-weight: bold;font-size: 36px;line-height: 40px; color: #062931;">How much is your car worth?</div><div style="color: #062931;margin-top: 17px;margin-bottom: 45px;font-style: normal;font-weight: normal;font-size: 22px; line-height: 25px;color: #062931;">Get a free car valuation in seconds</div><a id="aSnipet" href="/priceSteps" style="font-weight:500;font-size:15px;line-height:20px;color:#ffffff;text-decoration: none; background: #ff6359;border-radius: 52px; width: 169px;height: 52px;display: flex;justify-content: center;align-items: center;margin-bottom: 64px;letter-spacing: 0.01em;">Get started</a></div>
</template>


<script>
export default {
  name: "widgetBlack",
  data() {
    return {
      // code for snippet
    };
  },
};
</script>

<style lang="scss" scoped>
</style>