<template>
  <div class="app-store-wrap">
    <div class="header">download</div>
    <div class="title">
      <h1>Carvisor App</h1>
    </div>
    <div class="text">
      Download our app and acquire unlimited access to our services.
    </div>
    <div class="images-wrap">
      <div class="image">
        <img :src="require(`@/assets/images/applestore.png`)" alt="">
      </div>
      <div class="image">
        <img :src="require(`@/assets/images/googlestore.png`)" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.app-store-wrap {
  width: 100%;
  height: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  // background: linear-gradient(116.78deg, #104756 13.98%, rgba(16, 71, 86, 0) 75.44%);
  background: linear-gradient(116.78deg, #104756 13.98%, #3D7484 75.44%);

  .header {
    color: #fff;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 0.05em;
    margin-bottom: 35px;
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  }
  .title {
    h1 {
      margin: 0;
      color: #fff;
      font-size: 51px;
      line-height: 55px;
      text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    }
  }
  .text {
    font-size: 18px;
    line-height: 30px;
    color: #E7ECED;
    width: 418px;
    margin: 17px 0 52px 0;
    text-align: center;
  }
  .images-wrap {
    width: 290px;
    display: flex;
    justify-content: space-between;
    .image {
      height: 40px;
      width: 135px;
      cursor: pointer;
      img {
        height: 100%;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .app-store-wrap {
    height: 375px;
    width: calc(100% - 16px);
    padding-top: 25px;
    padding-left: 16px;
    align-items: unset;

    .header {
      font-size: 15px;
      margin-bottom: 17px;
    }
    .title {
      h1 {
        font-size: 46px;
      }
    }
    .text {
      width: 315px;
      font-size: 16px;
      line-height: 25px;
      text-align: unset;
      color: #CFD8DB;
      margin: 10px 0 42px 0;
    }
    .images-wrap {
      height: 120px;
      width: 170px;
      flex-direction: column;
      .image {
        height: 50px;
        width: 170px;
      }
    }
  }
}
</style>