<template>
   <div class="forgotpass" >
       <div class="wrappOption">
             <span>
                <img  :src="require('@/assets/images/forgotpass.svg')" /> 
            </span>
            <router-link class="txt"  :to="{name: 'loginForgot'}" >Forgot password</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name:'callForgotpass',
    data () {
        return{
           hover: false 
        }
    }

}
</script>

<style lang="scss" scoped>
 .forgotpass {
            // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: normal;
            // font-size: 13px;
            // line-height: 22px;
            // width: fit-content;
            color: #5A7E87;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        .wrappOption{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width:fit-content;
            height: fit-content;
            opacity: 0.8;
         }
            span {
                display: flex;
                align-items: center;
                cursor: pointer;
            }
            .txt {
                // // color: #5A7E87;
                text-decoration: none;
                // // // padding-top:3px;
                padding-left: 4px;
                // font-family: DM Sans;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 13px;
                // line-height: 15px;
                // /* identical to box height, or 115% */

                // letter-spacing: 0.01em;

                // /* new turquoise */

                // // color: #467F8F;

                font-family: DM Sans;
                font-style: normal;
                font-weight: normal;
                font-size: 13px;
                line-height: 15px;
                /* identical to box height, or 115% */

                letter-spacing: 0.01em;

                /* new turquoise */

                color: #467F8F;

               

            }


        }
         .wrappOption:hover{
             opacity: 1;
            .txt{
                // color: #134753;

                // font-family: DM Sans;
                // font-style: normal;
                // font-weight: normal;
                // font-size: 13px;
                // line-height: 15px;
                // /* identical to box height, or 115% */

                // letter-spacing: 0.01em;

                // /* new turquoise */

                // color: #467F8F;
            }
        }

</style>