<template>
  <router-link v-if="$route.name !== 'admin'" :to="blogData.link">
    <div class="blog-item-wrapper">
      <div class="aspect-wrapper">
        <div class="aspWrap">
          <div class="aspect">
            <div class="img-wrapper">
              <img v-if="blogData.image" :src="baseURL + blogData.image" :alt="blogData.img_alt ? blogData.img_alt : 'main-blog-image'">
            </div>
          </div>
        </div>
      </div>
      <div class="blog-item-description">
        <div class="roundDateCat">
          <div class="dateC">{{setDate}}</div>
          <span class="category" >{{ blogCategoryString }}</span>
        </div>
        <div class="title">{{blogData.title}}</div>
        
        <div class="description">
          {{ blogData.description.length > 150 ? blogData.description.substring(0, 150) + '...' : blogData.description }}
        </div>
        <div class="readMore">Read more <img src="../../assets/images/arrowMore.svg" /></div>
      </div>
    </div>
  </router-link>
  <div v-else @click="openBlog()" class="blog-item-wrapper">
    <div class="aspect-wrapper">
      <div class="aspWrap">
        <div class="aspect">
          <div class="img-wrapper">
            <img v-if="blogData.image" :src="baseURL + blogData.image" :alt="blogData.img_alt ? blogData.img_alt : 'main-blog-image'">
          </div>
        </div>
      </div>
    </div>
    <div class="blog-item-description">
       <div class="roundDateCat">
          <div class="dateC">{{setDate}}</div>
          <div class="category" v-if="blogData && blogData.categories !== null">{{blogCategoryString}}</div>
        </div>
      <div class="title">{{blogData.title}}</div>
      <div class="date">{{setDate}}</div>
      <div class="description">
        {{ blogData.description.length > 150 ? blogData.description.substring(0, 150) + '...' : blogData.description }}
        <span>Read more</span>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL } from '@/api'
import helper from '@/js/helperDate.js'
import helperCategoryArrInString from '@/js/helperCategoryArrInString.js'
export default {
  name: 'blogItem',
  props: {
    blogData: {
      required: true,
      type: Object
    },
    deleteOnClick: {
      required: false,
      default: false
    }
  },
  mounted(){
    this.setDate = helper.formatDate(this.blogData.date)
    this.blogCategoryString = helperCategoryArrInString.setBlogCategoryInString(this.blogData.categories)
  },
  data () {
    return{
      setDate:null,
      blogCategoryString: ''
    }
  },
  computed: {
    baseURL () {
      return baseURL
    }
  },
  methods: {
    openBlog () {
      if (this.deleteOnClick) {
        this.$emit('blogClick')
        return
      }
      this.$router.push(this.blogData.link)
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
.blog-item-wrapper {
  display: flex;
  flex-direction: row;
  // max-width: 950px; 
  max-width: 842px; // because design is change
  border-bottom: 1px solid #DEE0E5;
  padding-bottom: 25px;
  padding-top: 10px;
  align-items: flex-start;
  cursor: pointer;

  .aspect-wrapper {
    width: 43%;
    margin-right: 25px;
    margin-bottom: 15px;
    overflow: hidden;
    border-radius: 5px;
    .aspWrap{
      overflow: hidden;
      border-radius: 5px;
      will-change: transform;
      position: relative;
      z-index: 100;
    }
    .aspect {
      padding-top: 75%;
      // padding-top: 100%;
      position: relative;
      height: 0;

      .img-wrapper {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;
        border-radius: 5px;
        will-change: transform;

        img {
          z-index: 5;
          height: 100%;
          object-fit: cover;
          width: auto;
          border-radius: 5px;
          transition: 1s ease-in-out;
          -webkit-backface-visibility: hidden;
          -ms-transform: translateZ(0); /* IE 9 */
          -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
          transform: translateZ(0);
          -webkit-font-smoothing: antialised;
          backface-visibility: hidden;
          -webkit-font-smoothing: subpixel-antialiased;
          filter: blur(0);
        }
      }

      img:hover {
        // height: 115%;
        // widows: 115%;
         transform: scale(1.1);
      }
    }
  }

  .blog-item-description {
    padding-right: 10px;
    width: calc( 57% - 25px );
    padding-top: 10px;
    .title {
      // font-family: 'Gotham Pro Medium';
      // font-size: 25px;
      // line-height: 30px;
      // color: #062931;
      // margin-bottom: 5px;

      font-weight: bold;
      font-size: 36px;
      line-height: 40px;
      /* or 111% */


      /* black */

      color: #062931;

      transition-duration: .3s;
      margin-top: 13px;
      margin-bottom:24px;

    }
    .title:hover {
      color: #000000;
    }
    .date {
      // font-family: 'Gotham Pro';
      // font-size: 14px;
      // line-height: 24px;
      // letter-spacing: 0.02em;
      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      color: #062931;
      margin-bottom: 16px;
    }
    .description {
      // font-family: 'Gotham Pro';
      // font-size: 16px;
      // line-height: 24px;
      // color: #062931;
      font-weight: normal;
      font-size: 17px;
      line-height: 25px;
      /* or 147% */


      /* black */

      color: #062931;


      span {
        color: $main-color;
        font-weight: bold;
        // font-family: 'Gotham Pro Medium';
      }
    }
    .readMore{
      font-weight: 500;
      font-size: 15px;
      line-height: 25px;
      // color: $main-color;
      color: #062931;
      margin-top:16px;
    }
    .readMore:hover{
      opacity: .8;
    }
    .roundDateCat{
      display:flex;
      overflow: hidden;
      // background-color: red;

      .dateC{
        //  margin-right: 20px;
        width: 159px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        /* identical to box height, or 133% */
        white-space: nowrap;
        letter-spacing: 0.04em;

        /* Dark blue - */

        color: #134753;
      }
      .category{
        width: calc(100% - 159px);
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 143% */
        // background: aqua;
        color: #134753;
        // display: flex;
        align-items: center;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        white-space: nowrap;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        /* Dark blue - */

       

      }
    }
  }
}
a:last-child .blog-item-wrapper {
  border-bottom: none;
  margin-bottom: 80px;
}
.blog-item-wrapper.last {
  border-bottom: none;
}
@media only screen and (max-width: 720px){
  .blog-item-wrapper {
    box-sizing: border-box;
    .aspect-wrapper {
      width: 50%;
      height: auto;
    .aspect {
      padding-top: 75%;
    }
    }
    .blog-item-description {
      // width: calc( 50% - 25px );
    }
  }
}
@media only screen and (max-width: 680px){
  .blog-item-wrapper {
    flex-direction: column;

    .aspect-wrapper {
      box-sizing: border-box;
      width: 100%;
      height: auto;
    }
    .blog-item-description {
      width: 100%;
    }
  }
}
</style>
