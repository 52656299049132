import Vue from 'vue'
import VueRouter from 'vue-router'
import HomePage from '../views/HomePage.vue'
import about from '../views/about.vue'
import terms from  '../views/terms.vue' // '../views/terms.vue' '../components/widgete/widgetBright.vue' 
import help from '../views/help.vue'
import priceSteps from '../views/priceSteps.vue'
import Estimate from '@/views/estimate.vue'
import sampleReport from '@/views/sampleReport.vue'
import unsubscribe from '@/views/unsubscribe.vue'
import adminLogin from '@/views/adminLogin.vue'
import admin from '@/views/admin.vue'
import store from '@/store/index.js'
import { api } from '@/api'
import blogList from '@/views/blogList.vue'
import singleBlog from '@/views/singleBlog.vue'
import PressReleases from '@/views/PressReleases.vue'
import onePressReleases from '@/views/onePressReleases2.vue' // change from onePressReleases.vue
import Newsroom from '../views/Newsroom2.vue' // change in to Newsroom
import mediaAssetsPage from '@/views/mediaAssetsPage2.vue' //mediaAssetsPage.vue'
// import Report from '@/views/report.vue'
import login from '@/views/login.vue'
import signin from '@/views/signin.vue'
import profile from '@/views/profile.vue'
import loginForgot from '@/views/loginForgot.vue'
import forgotPassword from '@/views/forgot-password.vue'
import faq from '@/views/faq.vue'
import pricing from '@/views/pricing.vue';
import payment from '@/views/payment.vue';
import valuations from '@/views/valuations.vue';

import animePage from '@/views/animePage.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage
  },
  {
    path: '/about',
    name: 'about',
    component: about
  },
  {
    path: '/terms',
    name: 'terms',
    component: terms
  },
  {
    path: '/help',
    name: 'help',
    component: help
  },
  {
    path: '/faq',
    name: 'faq',
    component: faq
  },
  {
    path: '/priceSteps',
    name: 'priceSteps',
    component: priceSteps
  },
  {
    path: '/estimation/:hash/:key/:odometer',
    name: 'estimate',
    component: Estimate
  },
  {
    path: '/estimate/:hash/:key/:odometer',
    name: 'report',
    component: Estimate
  },
  {
    path: '/sampleReport',
    name: 'sampleReport',
    component: sampleReport
  },
  {
    path: '/blog',
    name: 'blog',
    component: blogList
  },
  {
    path: '/blog/:id',
    name: 'singleBlog',
    component: singleBlog
  },
  {
    path: '/unsubscribe/:token/:email',
    name: 'unsubscribe',
    component: unsubscribe
  },
  {
    path: '/adminLogin',
    name: 'adminLogin',
    component: adminLogin,
    meta: {
      loginRequired: true
    },
  },
  {
    path: '/admin',
    name: 'admin',
    meta: {
      superAdminRequired: true
    },
    component: admin
  },
  {
    path: '/animePage',
    name: 'animePage',
    component: animePage,
    meta: {
      superAdminRequired: true
    }
  },
  {
    path: '/newsroom',
    name: 'newsroom',
    component: Newsroom
  },
  {
    path: '/pressReleases',
    name: 'pressReleases',
    component: PressReleases
  },
  {
    path: '/news/:id',
    name: 'news',
    component: onePressReleases
  },
  {
    path: '/mediaAssetsPage',
    name: 'mediaAssetsPage',
    component: mediaAssetsPage
  },
  {
    path: '/login',
    name: 'login',
    meta: {
      loginRequired: true
    },
    component: login
  },
  {
    path: '/signin',
    name: 'signin',
    meta: {
      loginRequired: true
    },
    component: signin
  },
  {
    path: '/profile',
    name: 'profile',
    component: profile,
    meta: {
      sidRequired: true
    },
  },
  {
    path: '/loginForgot',
    name: 'loginForgot',
    component: loginForgot,
    // meta: {
    //   loginRequired: true
    // },
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    component: forgotPassword,
    // meta: {
    //   loginRequired: true
    // }
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: pricing
  },
  {
    path: '/payment',
    name: 'payment',
    component: payment
  },
  {
    path: '/valuations',
    name: 'valuations',
    component: valuations,
    meta: {
      isFavPage: false
    }
  },
  {
    path: '/favourites',
    name: 'favourites',
    component: valuations,
    meta: {
      isFavPage: true
    }
  },
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes
})

router.beforeEach((to, from, next) => {
  store.commit('setPageBefore', from.name)
  if (to.meta.sidRequired) {
    if (localStorage.sid && store.state.loged) {
      return next()
    } else {
      return next('/login')
    }
  } else if (to.meta.superAdminRequired) {
    api.checkSession().then(res => {
      if (localStorage.sid && res.data.type === 4) {
        return next()
      } else {
        return next('/')
      }
    }).catch(() => {
      return next('/')
    })
  } else if (to.meta.loginRequired) {
      if (!localStorage.sid) {
        return next()
      } else {
        return next('/')
      }
  } else {
    return next()
  }
})

export default router
