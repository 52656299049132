<template>
  <div class="sortSimilar"  v-click-outside="closeSort">
      <div class="sortShow" @click="openSort">
        <p>
          <img src="@/assets/images/arrowSort.svg" />
         <span> Sort by<span class="span">: {{txt}} </span></span>
        </p>
      </div>
      <div v-if="sortMeni" class="setBackCover"></div>

      <!-- sort meni-->
      <div v-if="sortMeni" :class="getShowBigMenu && getIsIosTenAndMore ?'sortMeni sortMeni1':'sortMeni'">
        <!-- <div class="setBackCover"></div> -->
        <div class="extraDiv">
          <p>Sort by <span @click="closeSort"><img src="@/assets/images/xsort.svg" ></span></p>
        </div>

        <!-- <div class="small">
        <p @click="setParam('recommended',true)">
          <img v-if="recommended" src="@/assets/images/checkBox-full.svg" />
          <img v-else src="@/assets/images/checkBox-empty.svg" />
          <span>Recommended</span>
        </p>
        </div> -->

        <div class="small">
          <label class="container"><span>Similar cars</span>
            <input type="checkbox" v-model="recommended" :disabled="!hprice && !lprice && !hmilage && !lmilage ? true: false" checked>
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="big">

        <!-- <p @click="setParam('price',1)">
          <img v-if="price !=='' && price===1" src="@/assets/images/checkBox-full.svg" />
          <img v-else src="@/assets/images/checkBox-empty.svg" />
          <span>Highest price</span>
        </p> -->
          <label class="container" ><span>Highest price</span>
            <input type="checkbox" v-model="hprice">
            <span class="checkmark"></span>
          </label>


        <!-- <p @click="setParam('price',-1)">
          <img v-if="price !=='' && price===-1" src="@/assets/images/checkBox-full.svg" />
          <img v-else src="@/assets/images/checkBox-empty.svg" />
          <span>Lowest price</span>
        </p> -->
         <label class="container" ><span>Lowest price</span>
            <input type="checkbox" v-model="lprice">
            <span class="checkmark"></span>
          </label>


        <!-- <p @click="setParam('milage',1)">
          <img v-if="milage !=='' && milage===1" src="@/assets/images/checkBox-full.svg" />
          <img v-else src="@/assets/images/checkBox-empty.svg" />
          <span>Highest KMs</span>
        </p> -->
          <label class="container" ><span>Highest kms</span>
            <input type="checkbox" v-model="hmilage">
            <span class="checkmark"></span>
          </label>


        <!-- <p @click="setParam('milage',-1)">
          <img v-if="milage !=='' && milage===-1" src="@/assets/images/checkBox-full.svg" />
          <img v-else src="@/assets/images/checkBox-empty.svg" />
          <span>Lowest KMs</span>
        </p> -->
        <label class="container" ><span>Lowest kms</span>
            <input type="checkbox" v-model="lmilage">
            <span class="checkmark"></span>
          </label>

        </div>
        <div :class="getShowBigMenu && getIsIosTenAndMore ? 'small smallIos': 'small'">
          <div @click="clear" class="clear">Reset</div>
          <div class="spacer"></div>
          <div @click="save" class="button"><p>Sort</p></div>
        </div>
      </div>
      <!-- end sort meni-->
  </div>
</template>

<script>
import eventBus from '@/js/eventBus'
import { mapGetters } from 'vuex'
export default {
  name: 'sortSimilar',
  data() {
    return{
      txt: 'Similar cars',
      sortMeni: false,
      recommended:true,
      price:'' , // descending === 1
      hprice:false,
      lprice:false,

      milage: '', // ascending === -1
      hmilage:false,
      lmilage:false
    }
  },
  methods: {
    closeSort () {
      this.sortMeni= false
      eventBus.$emit('showUpButtonSimilar', true) // also used to stop scroll
    },
    openSort(){
      if(this.sortMeni === false) {
        this.sortMeni= true
        eventBus.$emit('showUpButtonSimilar', false) // also used to stop scroll
      }else{
        this.sortMeni= false
        eventBus.$emit('showUpButtonSimilar', true) // also used to stop scroll
      }

    },
    setParam(/*param, val*/){
      //console.log(this.recommended)
      return
      // if( param === 'recommended' ){
      //   // this.recommended = val
      //   this.price = ''
      //   this.milage = ''
      // }else if (  param === 'price' ){
      //   // this.recommended = false
      //   this.price = val
      // }else if ( param === 'milage' ){
      //   // this.recommended = false
      //   this.milage = val
      // }
      // console.log('this.recommended'+'- '+this.recommended)
      // console.log('this.param'+'- '+this.price)
      // console.log('this.param'+'- '+this.price)
      // console.log('this.milage'+'- '+this.milage)
      // console.log('this.milage'+'- '+this.milage)

    },
    clear(){
      this.recommended = true
      this.price = ''
      this.milage = ''
      this.hprice = false
      this.lprice = false
      this.hmilage = false
      this.lmilage = false
    },
    save(){
      if(this.recommended === true){
        this.$emit('savesort',{recommended:true})
        this.txt = 'Similar cars'
      }else {
        if(this.price !== '' && this.milage !== ''){
          this.txt = 'MultiSort'
        }else if(this.price == 1 && this.milage===''){
          this.txt = 'Highest price'
        }else if(this.price == -1 && this.milage===''){
          this.txt = 'Lowest price'
        }else if(this.milage == 1 && this.price===''){
          this.txt = 'Highest kms'
        }else if(this.milage == -1 && this.price===''){
          this.txt = 'Lowest kms'
        }else{
          this.txt = 'Similar cars'
        }

        this.$emit('savesort',{price:this.price,milage:this.milage})
      }
      this.closeSort()
    }

  },
  watch: {
    recommended:{
      handler(newVal){
        if(newVal === true){
          // this.hprice=false
          // this.lprice=false
          // this.hmilage=false
          // this.lmilage=false
          this.clear()
        }
         else if(newVal === false){
          if(!this.hprice && !this.lprice && !this.hmilage && !this.lmilage){
            this.recommended = true
          }else{
            this.recommended = false
          }
        }
      }

    },
    hprice:{
      handler(newVal){
        if(newVal === true){
          this.lprice = false
          this.recommended = false
          this.price = 1
        }else{
          this.hprice = newVal
          if(!this.lprice)
            this.price = ''

        }

      }
    },
    lprice:{
      handler(newVal){
        if(newVal === true){
          this.hprice =false
          this.recommended = false
          this.price = -1
        }else{
          this.lprice = newVal
          if(!this.hprice)
            this.price = ''
        }

      }
    },
    hmilage: {
      handler(newVal) {
         if(newVal === true){
          this.lmilage =false
          this.recommended = false
          this.milage = 1
        }else{
          this.hmilage = newVal
          if(!this.lmilage)
            this.milage = ''
        }
      }
    },
    lmilage: {
      handler(newVal) {
        if(newVal === true){
          this.hmilage = false
          this.recommended = false
          this.milage = -1
        }else{
          this.lmilage = newVal
          if(!this.hmilage)
            this.milage = ''
        }
      }
    }
  },
  computed:{
        ...mapGetters(['getShowBigMenu', 'getIsIosTenAndMore'])

  }

}
</script>

<style lang="scss">
.sortSimilar{
  margin-left: 40px;
  margin-right: 20px;
  position: relative;
  align-self: flex-start;
  //  top:0;
  //  right:20px;

  .setBackCover{
      width:0;
      height:0;
      display:none !important;
  }

  .sortShow{
    // width: 234px;
    padding-right: 15px;
    height: 36px;
    // left: 0px;
    // top: calc(50% - 36px/2);

    /* Gray 3 text */
    display: flex;
    // justify-content: center;
    padding-left: 18px;
    align-items: center;
    //  border: 1px solid #9498A0;
    // box-sizing: border-box;
    // border-radius: 40px;
    border: 1px solid #CDD8D8;
    box-sizing: border-box;
    border-radius: 40px;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #062931;

     cursor: pointer;
    p{
      margin:0;
      display: flex;
      justify-content: center;
      align-items: center;
      // border: 1px solid #9498A0;
      img{
        width:17;
         margin-right:13px;
      }
      span{
        // padding-top: 2px;
        .span{
          display: initial;

        }
      }
    }

  }

  .sortMeni{
    position: absolute;
    // width: 214px;
    // height: 230px;
    // width: 234px;
    width: 208px;
    height: 235px;
    // right: 10px; // 30px;
    right: 0;
    top: 44px;
    z-index: 5;
    // border: 1.3px solid #E7ECED;
    // box-sizing: border-box;
    // box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    // border-radius: 15px;

    background: #FFFFFF;
    border: 1px solid #CDD8D8;
    box-sizing: border-box;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    .extraDiv{
      display: none;
    }
    .small {
      // font-family: Gotham Pro;
      // font-style: normal;
      // font-weight: normal;
      // font-size: 14px;
      // line-height: 30px;
      box-sizing: border-box;
      height:45px;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 30px;

      letter-spacing: 0.01em;
      color: #062931;
      
      // border-bottom: 1px solid #E7ECED;
      border-bottom: 1px solid #F8F8F9;
      padding-left:15px;
      padding-top:17px;
      p, .container{
        margin:0;
        display: flex;
        align-items: center;
        // background: #42e234;
        span{
          // margin-left: 7px;
          // padding-top: 4px;
          margin-left: 0px;
          // padding-top: 2px;
          line-height: 19px;
        }
      }
      .clear{
        cursor: pointer;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #062931;
        width: 84px;
        height: 30px;
        border: 1px solid rgba(16, 61, 73, 0.8);
        box-sizing: border-box;
        border-radius: 45px;
        display: flex;
        justify-content: center;
        align-items: center;

      }
      .button{
        cursor: pointer;
        // width: 71px;
        // height: 25px;ž
        width: 84px;
        height: 30px;
         background: #FF6359;
        border-radius: 45px;
        transition-duration: 0.3s;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        color: #FFFFFF;

        display: flex;
        align-items: center ;
        justify-content: center;
        p{
          margin:0;
          margin-top:1px;
        }
      }
      .button:hover {
        background: $btn-hover;
      }
    }
    .small:last-of-type {
        // font-family: 'Gotham Pro Medium';
        font-weight: 500;
        // border-top: 1px solid #E7ECED;
        // border-top: 1px solid #CDD8D8;
        
        border-bottom: none !important;
        padding-top:10px;
        padding-right: 15px;
        display: flex;
        align-items:center ;
        justify-content: space-between;

    }
    .big{
      box-sizing: border-box;
      // height: 141px;
      height: fit-content;
      padding-left:15px;
      padding-top:12px;
      // font-family: Gotham Pro;
      font-style: normal;
      font-weight: normal;
      font-size: 15px;
      line-height: 30px;
      /* identical to box height, or 200% */
      letter-spacing: 0.01em;
      color: #062931;
      p, .container{
        margin:0;
        // margin-bottom:13px;
        margin-bottom: 11px;
        display: flex;
        align-items: center;
        // background: chartreuse;

        span{
          margin-left: 0px;
          line-height: 19px;
        }
      }

    }

  }
}

.container  {
  display: block;
  position: relative;
  // padding-left: 35px;
  padding-left:22px;
  margin-bottom: 12px;
  cursor: pointer;
  // font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
// #text{
//   padding-top: 4px;
//   margin-left: 7px;
// }

/* Hide the browser's default checkbox */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 15px;
  height: 15px;
  // background-color: #eee;
  padding-top:0 !important;
  margin-left:0px !important;
  // border: 1px solid #9498A0;
  border: 1.2px solid #103D49;
  box-sizing: border-box;
 border-radius: 5px;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  // background-color: #ccc;
  // background: #9498a042;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background: #062931;
  border-radius: 5px;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  // left: 9px;
  // top: 5px;
  // width: 5px;
  // height: 10px;
  // border: solid white;
  // border-width: 0 3px 3px 0;
  // -webkit-transform: rotate(45deg);
  // -ms-transform: rotate(45deg);
  // transform: rotate(45deg);

  // left: 4px;
  // top: 1px;
  // width: 3px;
  // height: 8px;
  // border: solid white;
  // border-width: 0 2px 2px 0;
  // -webkit-transform: rotate(45deg);
  // -ms-transform: rotate(45deg);
  // transform: rotate(45deg);
    left: 4px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

}




@media only screen and (max-width: 650px){
  .sortSimilar{
    margin-bottom: 20px;
    .setBackCover{
      display:none !important;
    }

    .sortShow{
      // width: 123px;
      // height: 36px;
      // border: 1px solid #9498A0;
      // box-sizing: border-box;
      // border-radius: 40px;
            // justify-content: center;
            // padding-left: 0px;

      p{
        img{
          width:17;
        }
        span{
          .span{
            display: none ;
            display: initial;
          }
        }
      }

    }
  }

}


@media only screen and (max-width: 500px){

  .sortSimilar{
      margin-left:0;
    .setBackCover{
      display:initial !important;
      position: fixed;
      top:0;
      left:0;
      width:100vw;
      height: 100vh;
      // background: #CFD8DB;
      background: #062931;
      opacity: 0.94;
      //  z-index: 1004;
      z-index: 15;
    }
    .sortShow{
      width: 123px;
      height: 36px;
      border: 1px solid #9498A0;
      box-sizing: border-box;
      border-radius: 40px;

      /* black */

      color: #062931;
      // justify-content: center;
      // padding-left: 0px;

      p{
        img{
          width:17;
         // margin-right: 11px;
        }
        span{
          .span{
            display: none !important;
          }
        }
      }
    }
    .sortMeni{
      position: fixed  !important;
      left: 0;
      top:initial;
      bottom: 0!important;
      width: 100%;
      min-height: 390px;
      height: fit-content;
      z-index: 1005;
      background: #FFFFFF;
      border: 1.3px solid #E7ECED;
      box-sizing: border-box;
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
      border-radius: 7px 7px 0px 0px;
      .extraDiv{
        display: initial;
        height: 50px;
        left: 0px;
        top: 1px;
        background: #FFFFFF;
        border-radius: 5px 5px 0px 0px;
        padding-left:42px;
        padding-right: 20px;
        display: flex;
        align-items: center;
        p{
          width:100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin:0;
          font-weight: 500;
          font-size: 15px;
          line-height: 25px;
          /* or 167% */

          display: flex;
          align-items: center;
          letter-spacing: 0.05em;
          text-transform: uppercase;

          /* Gray turquoise */

          color: #5A7E87;
          span{
            cursor: pointer;
          }
        }
      }
      .small {

        // border-bottom: 1px solid #E7ECED;
        border-bottom: 1px solid rgba(222, 224, 229, 0.8);
        padding-left:20px;
        padding-top:10px;
        // font-size: 15px;
        font-size: 16px;
        line-height: 50px;
        letter-spacing: 0.02em;
        text-transform: capitalize;

        color: #062931;

        height:49px;
        p{
          margin:0;
          display: flex;
          align-items: center;
          span{
            margin-left: 7px;
            padding-top: 4px;
          }
        }

        .clear{
          
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #062931;
          width: 100%;
          max-width: calc(50% - 5px);
          height: 44px;
          letter-spacing: 0.01em;
          box-sizing: border-box;

        }
        .button{
          width: 100%;
          max-width: calc(50% - 5px);
          height: 44px;
           box-sizing: border-box;
          border-radius: 45px;
          transition-duration: 0.3s;
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: 0.01em;
          color: #FFFFFF;

          display: flex;
          align-items: center ;
          justify-content: center;
          p{
            margin:0;
            margin-top:1px;
          }
        }
        .button:hover {
          background: $btn-hover;
        }
      }
      .small:last-of-type {
        // font-family: 'Gotham Pro Medium';
        font-weight: 500;
        // border-top: 1px solid #E7ECED;
        border-bottom: none !important;
        padding-top:18px;
        padding-bottom: 18px;
        padding-right: 15px;
        padding-left: 15px;


        min-height: fit-content;
        height: fit-content;

        .spacer {
          min-width: 10px;
          max-width: 10px;
          width: 10px;

          height: 10px;
        }

      }
      .big{
        box-sizing: border-box;
        height: initial;
        // font-size: 15px;
        font-weight: normal;
        font-size: 16px;
        line-height: 50px;
        /* identical to box height, or 312% */

        letter-spacing: 0.02em;
        text-transform: capitalize;

        /* Text primary darker */

        color: #062931;
        padding-top:0px;
        padding-left:20px !important;
        .container{
          margin-top: 16px;
          margin-bottom: 0 !important;
          padding-bottom: 18px;
          border-bottom: 1px solid #F8F8F9;
         
        }
        .container:last-of-type{
           border-bottom: 1px solid transparent;
        }
        .checkmark{

        }

        p{
          margin:0;
          margin-bottom:23px;
          display: flex;
          align-items: center;

          span{
            //  display: flex;
            // align-items: center !important;
            padding-top: 4px;
            margin-left: 7px;
          }
        }

      }

    }
  }



  @supports (-webkit-overflow-scrolling: touch) {
    .sortSimilar{
      .sortMeni{
        .small:last-of-type {
          height: fit-content;
          padding-bottom: 20px;
        }

      }
      .sortMeni1{
         min-height: 327px !important;
       }
      .smallIos{
           min-height: 68 !important;
           margin-bottom: 20px !important;
        }
    }

  }
// .sortMeni1{
//         height: 337px !important;
// }
// .smallIos{

//            min-height: 78 !important;
//            margin-bottom: 30px !important;
//         }


  .container  {
    display: block;
    position: relative;
    // padding-left: 35px;
    padding-left: 28px;
    margin-bottom: 20px !important;
    cursor: pointer;
    // font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .container:last-of-type{
    margin-bottom: 14px !important;
  }
  // #text{
  //   padding-top: 4px;
  //   margin-left: 7px;
  // }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 1.5px;
    left: 0;
    width: 16px;
    height: 16px;
    // background-color: #eee;
    padding-top:0 !important;
    margin-left:0px !important;
    // margin-right: -20px;
    border: 1.2px solid #103D49;
    box-sizing: border-box;
    border-radius: 8px;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    // background-color: #ccc;
    // background: #9498a042;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background: #103D49;
    border-radius:8px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    // left: 4px;
    // top: 1px;
    // width: 3px;
    // height: 8px;
    border: solid transparent;
    border-width: 0 0px 0px 0;
    // -webkit-transform: rotate(45deg);
    // -ms-transform: rotate(45deg);
    // transform: rotate(45deg);

  }
} // end 500px

</style>
