<template>
  <div class="mediaAssetsPage">
    <div v-if="false" class="pressLine"> </div>
    <div class="holdContent">

        <div class="title"><p>Carvisor media assets</p></div>
        <div class="description"> 
            <!-- p>
                All the media below can be used freely for press purposes. If possible, please cite the source of the image in the description as ‘Carvisor’, and link to our homepage 
                <span class="link" @click="goToSite()">carvisor.co.nz</span>.
            </p -->
            <p>
               All the media below can be used freely for press purposes. Please cite the source of the image in the description as ‘Carvisor’, and, if possible, link to our homepage 
               <span class="link" @click="goToSite()">carvisor.co.nz</span>. For all press related enquiries regarding Carvisor, or to be added to the mailing list, please reach us at 
              <a class="link" href="mailto:contact@carvisor.co.nz">contact@carvisor.co.nz</a>.
            </p>
            <p>
              More information about us can be found 
              <a class="link" href="about" >here</a>.   
            </p>
        </div>
        <div class="wrapplogos">
            <p class="titleWrapp">Logo variants</p>
            <div class="logos">
                <assetsImg  
                    v-for='item in items' 
                    :key="item.img" 
                    :item='item'   
                    @savefile='savefile' 
                />
            </div>
        </div>
         <div class="wrappotherImg">
             <p class="titleWrapp">Other imagery</p>
             <div class="otherImg">
                 <assetsImgOther  
                    v-for='item in otherImg' 
                    :key="item.img" 
                    :item='item'   
                    @savefile='savefile' 
                 />
            </div>
        </div>
    </div>
    <footerUp v-if="false" />
    <footerDown  v-if="!showMobileNav" :white="true"/>
    <div v-else style="height:60px" ></div>
  </div>
</template>

<script>
import assetsImg from '../components/newsroom/assetsImg2.vue'
import assetsImgOther from '../components/newsroom/assetsImgOther.vue'
import footerUp from '../components/newsroom/footerUp.vue'
import footerDown from '../components/newsroom/footerDown' 
import img1 from '@/assets/images/assetsImage/Img1.png'
import img2 from '@/assets/images/assetsImage/Img2.png'
import img3 from '@/assets/images/assetsImage/Img3.png'
import img4 from '@/assets/images/assetsImage/Img4.png'
import img5 from '@/assets/images/assetsImage/Img5.png'
import img6 from '@/assets/images/assetsImage/Img6.png'
import img7 from '@/assets/images/assetsImage/Img7.png'
import img1d from '@/assets/images/assetsImage/download/Logo on transparent background.png'
import img2d from '@/assets/images/assetsImage/download/Logo b&w.png'
import img3d from '@/assets/images/assetsImage/download/Logo b&w negative.png'
import img4d from '@/assets/images/assetsImage/download/Logo all white.png'
import img5d from '@/assets/images/assetsImage/download/Logo on background.png'
import img6d from '@/assets/images/assetsImage/download/Symbol on transparent background.png'
import img7d from '@/assets/images/assetsImage/download/Symbol orange.png'

import img11d from '@/assets/images/assetsImage/download/Average time on market.jpg'
import img12d from '@/assets/images/assetsImage/download/Transaction trigger tool.jpg'
import img13d from '@/assets/images/assetsImage/download/Market brakedown by advertised price.jpg'
import img14d from '@/assets/images/assetsImage/download/Carvisor - Online car valuation platform and analytics.jpg'
import img15d from '@/assets/images/assetsImage/download/Illustration.jpg'
import img16d from '@/assets/images/assetsImage/download/Transaction trigger tool1.jpg'
import img17d from '@/assets/images/assetsImage/download/App views.jpg'
import img18d from '@/assets/images/assetsImage/download/Private & Dealer market value.jpg'

import { baseURL } from '@/api'
export default {
    name: 'mediaAssetsPage2',
    components: {
        footerUp,
        footerDown,
        assetsImg,
        assetsImgOther
    },
    metaInfo () {
        return {
        title: 'Carvisor',
        titleTemplate: '%s - ' + this.title ,
        meta:[
           {name: 'robots', content: 'index,follow'} ,
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.description
                },
                {
                     vmid: 'og:description',
                    property: 'og:description',
                    content:  this.description
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.title 
                }
                // ,
                // {
                //     hid: 'keywords',
                //     name: 'keywords',
                //     content:  this.tempData.title
                // },
                // {
                //     vmid: 'og:image',
                //     property: 'og:image',
                //     itemprop: 'image',
                //     content: this.baseURL + this.blog.image
                // }
            ]
        }
    },
    data () {
        return {
            title: 'Media assets',
            description: 'The media you find here can be used freely for press purposes. Other content can also be found on our social media channels.',
             items : [
                {title: 'Logo on transparent background ', text: 'png', img: img1, download: img1d},
                {title: 'Logo b&w ', text: 'png', img: img2, download: img2d},
                {title: 'Logo b&w negative ', text: 'png', img: img3, download: img3d},
                {title: 'Logo all white', text: 'png', img: img4, download: img4d},
                {title: 'Logo on background ', text: 'png', img: img5, download: img5d},
                {title: 'Symbol on transparent background ', text: 'png', img: img6, download: img6d},
                {title: 'Symbol orange ', text: 'png', img: img7, download: img7d},
            ],
             otherImg : [
                {title: 'Average time on market ', text: 'jpg', img: img11d, download: img11d},
                {title: 'Transaction trigger tool', text: 'jpg', img: img12d, download: img12d},
                {title: 'Market brakedown by advertised price', text: 'jpg', img: img13d, download: img13d},
                // {title: 'Carvisor - Online car valuation platform and analytics', text: 'jpg', img: img14d, download: img14d},
                {title: 'Carvisor - Online car valuation and analytics', text: 'jpg', img: img14d, download: img14d},
                {title: 'Illustration', text: 'jpg', img: img15d, download: img15d},
                {title: 'Transaction trigger tool', text: 'jpg', img: img16d, download: img16d},
                {title: 'App views', text: 'jpg', img: img17d, download: img17d},
                {title: 'Private & Dealer market value', text: 'jpg', img: img18d, download: img18d}
            ],
            showMobileNav:false
        }
    },
    created () {
      window.addEventListener("resize", this.resize)
      this.resize()
    },
    destroyed () {
        window.removeEventListener("resize", this.resize)
    },
    methods: {
        savefile (e) {
            console.log(this.getBaseUrl +e)
            // window.open(this.getBaseUrl +e)
        },
        goToSite () {
            window.open('https://carvisor.co.nz', '_blank');
        },
        resize () {
            if (window.innerWidth <= 500) {
              this.showMobileNav = true
            } else {
               this.showMobileNav = false
            }
        }
     },
    computed: {
        getBaseUrl () {
         return baseURL.substring(0, baseURL.length - 4)
        }
    }

}
</script>

<style lang="scss" scoped>
.mediaAssetsPage {
    .pressLine {
        width:100%;
        box-sizing: border-box;
        border-top:1px solid #DEE0E5;
    }
     .holdContent {
         margin:0 auto;
         display:flex;
         flex-direction: column;
         justify-content: space-between;
         width:1278px;

         .title {
        //    font-family: 'Gotham Pro Medium';
        //     font-style: normal;
        //     font-weight: 500;
        //     font-size: 40px;
        //     line-height: 40px;
        //     display: flex;
        //     align-items: center;
        //     color: #062931;

            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            /* identical to box height, or 111% */

            display: flex;
            align-items: center;

            /* black */

            color: #062931;
            p {
                // margin:87px 0 25px 0;
                margin: 98px 0 34px 0;
            }

         }
         .description {
            // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: normal;
            // font-size: 16px;
            // line-height: 26px;
            // color: #062931;
            max-width: 733px;
            // margin-bottom: 62px;

            max-width: 842px;
            max-width: 867px; // changed from 862
            font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 25px;

            color: #062931;
            

             p {
                //  margin: 0 0 0 0;
                .link {
                    // color:$main-color;
                    color: #062931;
                    text-decoration: none;
                    font-weight: 500;
                }
                .link:hover{
                    // color: $btn-hover;
                    opacity: .8;
                    cursor: pointer;
                }
             }
             p:first-of-type{
                 margin-top:0;
             }
             p:last-of-type{
                 margin: 0 0 0 0;
             }
         }
         .wrapplogos{
            //  margin-bottom: calc(50px - 30px); // 119(all space)-89(on design) = 30 (should be reduced) 
              margin-bottom: 24px;                   
              .titleWrapp{
                    // margin-top: 0px;
                    // margin-bottom: 26px;
                    // font-family: 'Gotham Pro Medium';
                    // font-style: normal;
                    // font-weight: 500;
                    // font-size: 22px;
                    // line-height: 40px;
                    // /* identical to box height, or 182% */

                    // display: flex;
                    // align-items: center;

                    // /* black */

                    // color: #062931;

                    margin-top: 47px;
                    margin-bottom: 31px;

                    font-style: normal;
                    font-weight: bold;
                    font-size: 26px;
                    line-height: 35px;
                    display: flex;
                    align-items: center;
                    color: #062931;


                }
            .logos {
                box-sizing: border-box;
                width:100%;
                display:flex;
                flex-wrap: wrap;
            }
        }
        .wrappotherImg{
            //   margin-bottom: calc( 100px - 20px ); //(135- 115 = 20 px )
            margin-bottom: calc( 189px - 20px ); //(135- 115 = 20 px )
             .titleWrapp{
                    // margin-top: 0px;
                    // margin-bottom: 26px;
                    // font-family: 'Gotham Pro Medium';
                    // font-style: normal;
                    // font-weight: 500;
                    // font-size: 22px;
                    // line-height: 40px;
                    // display: flex;
                    // align-items: center;
                    // color: #062931;
                    font-weight: bold;
                    font-size: 26px;
                    line-height: 35px;
                    display: flex;
                    align-items: center;

                    /* black */

                    color: #062931;
                    margin-top: 0px;
                    margin-bottom: 31px;

                }
            .otherImg {
                box-sizing: border-box;
                width:100%;
                display:flex;
                flex-wrap: wrap;

            }
        }

     }



}

@media only screen and (max-width: 1330px){
    .mediaAssetsPage{
        .holdContent{
            box-sizing: border-box;
            // margin:0 auto;
            //width:1278px;
            width: calc(100% - 29px );
            margin: 0 0 0 29px !important;
            margin-right:0px;

            .title {
                p {
                    margin:46px 0 25px 0;
                }

            }
            .description {
                font-size: 16px;
                line-height: 26px;
                max-width: 733px;
                p {
                    margin: 0 0 0 0;
                }
            }
           
            
        }
    }
}
@media only screen and (max-width: 768px){ //768
    .mediaAssetsPage{
        .holdContent{
            // padding-top: 46px;
            padding-top: 58px;
            // padding-bottom: 72px;
            margin:0 auto;
            height: initial;
            width: calc(100% - 36px );

              .title {
                // font-size: 34px;
                // line-height: 40px;
                font-size: 36px;
                line-height: 40px;
                p {
                    // margin:0px 0 23px 0;
                    margin: 0 0 34px 0;
                }

            }
            .description {
                // font-size: 16px;
                // line-height: 26px;
                // max-width: 316px;
                font-size: 17px;
                line-height: 25px;

                // margin-bottom: 61px;
                margin-bottom: 47px;
                p {
                    margin: 0 0 0 0;
                }
            }

             .wrapplogos{
             margin-bottom: calc(50px - 30px); // 119(all space)-89(on design) = 30 (should be reduced) 
                                 
              .titleWrapp{
                  margin: 0 0 31px 0;
              }
            }
            .wrappotherImg{
                margin-bottom: 64px;
                .titleWrapp{

                }

            }
            
        }
    }
}

@media only screen and (max-width:  620px){ //375
    .mediaAssetsPage{
        max-width:100vw !important;
        .holdContent{
            box-sizing: border-box;
            // padding-top: 57px !important;
            padding-top: 73px;
            // padding-bottom: 72px;
            padding-bottom: 56px;
            padding-left: 16px;
            padding-right: 16px;
            margin-left:0!important;
            height: initial;
            width: 100%!important;

              .title {
                // font-size: 30px;
                // line-height: 40px;
                font-weight: bold;
                font-size: 34px;
                line-height: 40px;
                p {
                    // margin:0px 0 23px 0;
                      margin:0px 0 34px 0;
                }

            }
            .description {
                // font-size: 16px;
                // line-height: 26px;
                // max-width: 316px;
                font-size: 16px;
                line-height: 25px;
                // margin-bottom: 41px;
                margin-bottom: 37px;
                p {
                    margin: 0 0 0 0;
                }
            }

            .wrapplogos{
            //  margin-bottom: calc(50px - 30px); // 119(all space)-89(on design) = 30 (should be reduced) 
                                 
              .titleWrapp{
                  font-size: 25px;
                  line-height: 35px;
                //   margin: 0 0 31px 0;
                  margin: 0 0 41px 0;
              }
            }
            .wrappotherImg{
                margin-bottom: 64px;
                 margin-bottom: 0px; // hold content padding is 72px
                .titleWrapp{
                    font-size: 25px;
                    line-height: 35px;
                    margin-bottom: 50px;

                }

            }
        }
    }
}
@media only screen and (max-width: 475px){ //375
    .mediaAssetsPage{
        // background: bisque;
        .holdContent{
            // padding-top:34px;
            // padding-left: 16px;
            // padding-right: 16px;
            height: initial;
            margin-left: 16px;
            margin-right: 16px;
            width: initial;
        }
    }
}
</style>

<style lang="scss">
.mediaAssetsPage{
        .holdContent{
            .logos,
            .otherImg {
                .wrappAssetsImg:nth-of-type(4n){
                    margin-right: 0px;
                }
            }
           
        }
    }

    
@media only screen and (max-width: 1330px){
   .mediaAssetsPage{
        .holdContent{
            .logos,
            .otherImg {
                   .wrappAssetsImg:nth-of-type(4n){
                   // padding-right: 30px;
                   // margin-right: 30px;
                }
                 .wrappAssetsImg:nth-of-type(3n){
                   //padding-right: 0px;
                   margin-right: 0px !important;
                }
            }
           
        }
    }
}
@media only screen and (max-width: 768px){
   .mediaAssetsPage{
        .holdContent{
            .logos,
            .otherImg {
                .wrappAssetsImg:nth-of-type(2n){
                    //padding-right: 0px;
                }
                .wrappAssetsImg:last-of-type{
                    //padding-right: 0px;
                    .info {
                        padding-bottom:0px;
                    }
                }
            }
           
        }
    }
}

</style>
