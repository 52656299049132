<template>
  <div class="edit-blogs-wrapper">
    <div v-if="!blog" class="no-selection">
      <blogItem :class="{ last : index === blogs.length - 1 }" @blogClick="selectBlog(blog)" v-for="(blog, index) in blogs" :key="index" :deleteOnClick="true" :blogData="blog" />
      <div class="show-more-wrapper">
        <div v-if="more" @click="showMore()" class="show-more">
          Show more
        </div>
      </div>
    </div>
    <div v-else class="selected">
      <div @click="blog = null" class="return">
        return
      </div>
      <div class="input-wrapper">
        <span class="title">Title: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.title">
      </div>
      <div class="input-wrapper">
        <span class="title">Description: (1 - 500 characters)</span>
        <input type="text" maxlength="500" v-model="blog.description">
      </div>

      <div class="input-wrapper">
        <span class="title">Categories: </span>
        <div class="setCheck">
          <div class="itemCheck" v-for="(caat, index) in categoriseApi" :key="index">
            <input type="checkbox"  name="selectCate" :id="caat.cat_id" :value="caat.cat_id" v-model="categorSelect" @change="setNewCat($event)" >
            <label class='caat' :for="caat.cat_id">{{caat.cat_name}}</label>
          </div>
          <!-- {{ categorSelect }} -->
        </div>
      </div>

      <div class="input-wrapper">
        <span class="title">Date: (yyyy-mm-dd)</span>
        <input type="text" @keypress="validate($event)" placeholder="yyyy-mm-dd" maxlength="10" v-model="blog.date">
      </div>
      <div class="input-wrapper">
        <span class="title">Minutes to read:</span>
        <input type="number" max="255" v-model="blog.readTime">
      </div>
      <div class="input-wrapper">
        <span class="title">Author: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.author">
      </div>
      <div class="input-wrapper">
        <span class="title">Link: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.link">
      </div>

      <div v-if="blog.thumb === oldBlog.thumb && oldBlog.thumb != null" class="input-wrapper">
        <span class="title">Current thumb image:</span>
        <div class="current-image-wrapper">
          <img :src="baseURL + blog.thumb" alt="current-image" class="current-image">
          <div @click="removeImageThumb()" class="remove">
            remove
          </div>
        </div>
      </div>
      <div class="input-wrapper">
        <span class="title">Image small (THUMB for sharing - 1.91:1 aspect ratio):</span>
        <input ref="thumb" type="file" accept=".png, .jpg, .jpeg" @change="setImageThumb($event)">
      </div>
      <div class="input-wrapper">
        <span class="title">Thumb alt: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.thumb_alt">
      </div>
      <div class="input-wrapper">
        <span class="title">Thumb name: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.thumb_name">
      </div>

      <div v-if="blog.image === oldBlog.image && oldBlog.image !=null" class="input-wrapper">
        <span class="title">Current main image:</span>
        <div class="current-image-wrapper">
          <img :src="baseURL + blog.image" alt="current-image" class="current-image">
          <div @click="removeImage()" class="remove">
            remove
          </div>
        </div>
      </div>
      <div class="input-wrapper">
        <span class="title">New main image: (0 - 1.5mb)</span>
        <input id="image" type="file" accept=".png, .jpg, .jpeg" @change="setImage($event)">
      </div>
      <div class="input-wrapper">
        <span class="title">Image alt: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.img_alt">
      </div>
      <div class="input-wrapper">
        <span class="title">Image name: (1 - 255 characters)</span>
        <input type="text" maxlength="255" v-model="blog.img_name">
      </div>
      <div class="input-wrapper">
        <span class="title">Content: (1 - 60 000 characters)</span>
        <vue-editor v-model="blog.content"></vue-editor>
        <div class="preview">
          <div class="title">Preview:</div>
          <div v-html="getPreview" class="content">
          </div>
        </div>
      </div>
      <div class="btn-wrapper">
        <div @click="resetPage" class="btn reset">
          Reset
        </div>
        <div @click="edit" class="btn">
          Edit
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL, api } from '@/api'
import blogItem from '@/components/blog/blogItem.vue'
import { VueEditor } from "vue2-editor";
export default {
  name: 'editBlog',
  components: {
    blogItem,
    VueEditor
  },
  data () {
    return {
      blogs: [],
      offset: 0,
      count: 6,
      more: true,
      blog: null,
      oldBlog: null,
      required: {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: null,
        content: null,
        link: null,
      },
      categorSelect: []
    }
  },
  created(){
    this.setCategory()
  },
  activated () {
    this.getBlogs()
  },
  methods: {
    setImage (e) {
      if (e.target.files && e.target.files[0].size >= 1500000) {
        alert('image too big')
        document.querySelector('#image').value = null
        return
      }
      this.blog.image = e.target.files[0]
    },
    setImageThumb (e) {
      if (e.target.files && e.target.files[0].size >= 1500000) {
        alert('thumb is too big')
        this.$refs.thumb.value = null
        return
      }
      this.blog.thumb = e.target.files[0]
    },
    validate(evt) {
      var theEvent = evt || window.event;

      // Handle paste
      if (theEvent.type === 'paste') {
          key = event.clipboardData.getData('text/plain');
      } else {
      // Handle key press
          var key = theEvent.keyCode || theEvent.which;
          key = String.fromCharCode(key);
      }
      var regex = /[0-9-]|\./;
      if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
      }
    },
    getBlogs () {
      let params = {
        count: this.count,
        offset: this.offset
      }
      api.getBlogs(params).then(res => {
        this.blogs = res.data.data
        if (this.blogs.length === res.data.count) {
          this.more = false
        }
      }).catch(e => {
        console.log(e);
      })
    },
    resetPage () {
      this.oldBlog.link = '/blog/' + this.oldBlog.link
      this.selectBlog(this.oldBlog)
    },
    selectBlog (blog) {
      let params = {
        id: blog.link.substring(6, blog.link.length),
      }
      api.getBlogData(params).then(res => {
        this.blog = JSON.parse(JSON.stringify(res.data.data))
        this.blog.delete_image = false
        this.blog.date = this.blog.date.substring(0, 10)
        this.oldBlog = JSON.parse(JSON.stringify(res.data.data))
        this.oldBlog.delete_image = false
        this.oldBlog.date = this.oldBlog.date.substring(0, 10)
        /////// IZMENITI 
        this.oldBlog.cat_id = this.blog.categories ? this.setOldBlogArrayId(this.blog.categories): []
        this.categorSelect = this.oldBlog.cat_id
      }).catch(e => {
        console.log(e);
      })
    },
    setOldBlogArrayId( arrCatId){
      let helpArr=[]
      for(let i=0; i< arrCatId.length; i++){
        helpArr.push(arrCatId[i].cat_id)
      }
      return helpArr

    },
    edit () {
      if (!this.checkAllParams()) {
        return
      }
      console.log('fghjkjhgf')
      let changes = new FormData()
      for (var variable in this.blog) {
        if (Object.prototype.hasOwnProperty.call(this.blog, variable)) {
           if((variable === 'thumb' && this.blog[variable] === null) || (variable === 'image' && this.blog[variable] === null)) {
                  console.log(variable)
           } else if (this.blog[variable] !== this.oldBlog[variable]) {
             if(variable === 'categories'){
                console.log(variable)
             }else{
               changes.append(variable, this.blog[variable])
             }
            // changes[variable] = this.blog[variable]
            
          }
        }
      }

      if (this.checkCheckedCategory()){
         changes.append('categories', JSON.stringify (this.categorSelect))
      }


      // changes.id = this.blog.id
      if (changes.entries().next().done) {
          return
      }
      changes.append('id', this.blog.id)
      // let params = new FormData()
      // for (var variablee in this.changes) {
      //   params.append(variablee, this.changes[variablee])
      //   console.log(params[variablee]);
      // }
      api.editBlog(changes).then(() => {
        this.blog = null
        this.oldBlog = null
        alert('Blog edited successfuly')
        this.getBlogs()
      }).catch(e => {
        alert('Error editing blog')
        console.log(e);
      })
    },
    checkAllParams () {
      for (var variable in this.required) {
        if (this.blog[variable] === null || this.blog[variable] === '') {
          alert(this.getName(variable) + ' is missing!')
          return false
        }
      }
      return true
    },
    setNewCat(e){
      console.log(e.target.checked)
      console.log(e.target.defaultValue)

    },
    getName (variable) {
      if (variable === 'img_alt') {
        return 'image alt'
      }
      if (variable === 'img_name') {
        return 'image name'
      }
      if (variable === 'readTime') {
        return 'minutes to read'
      }
       if (variable === 'thumb') {
        return 'thumb image'
      }
      if (variable === 'thumb_name') {
        return 'thumb name'
      }
      if (variable === 'thumb_alt') {
        return 'thumb alt'
      }
      return variable
    },
    removeImage () {
      this.blog.delete_image = true
      this.blog.image = null
    },
    removeImageThumb () {
      this.blog.delete_thumb = true
      this.blog.thumb = null
    },
    showMore () {
      let currOffset = this.blogs.length
      let params = {
        count: this.count,
        offset: currOffset
      }
      api.getBlogs(params).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.blogs.push(res.data.data[i])
        }
        if (this.blogs.length === res.data.count) {
          this.more = false
        }
      }).catch(e => {
        console.log(e);
      })
    },
    async setCategory(){
     await api.getNewsCategories().then(res => {
        console.log(res.data.data)
        this.categoriseApi = res.data.data
      }).catch(e => {
        console.log(e)
      })
    },
    checkCheckedCategory(){
      if(this.oldBlog.cat_id.length !== this.categorSelect.length && this.categorSelect.length <= 0){
        console.log('11111111')
        return false
      }else if(this.oldBlog.cat_id.length !== this.categorSelect.length && this.categorSelect.length > 0){
        console.log('2222222')
        return true
      } else if (this.oldBlog.cat_id.length === this.categorSelect.length) {
        console.log('3333333')
        let equal= 0
        for(let i=0; i < this.categorSelect.length; i++ ){
          for(let j=0; j < this.oldBlog.cat_id.length; j++ ){
            if(this.categorSelect[i] === this.oldBlog.cat_id[j] ){
              equal +=1
            }
          }
        }
        console.log(equal + ' ---- ' +this.categorSelect.length)
        if(equal === this.categorSelect.length ){
          return false
        } else {
          return true
        }
      }
    }
  },
  computed: {
    getPreview () {
      return this.blog.content.replaceAll('&lt;', '<').replaceAll('&gt;','>')
    },
    baseURL () {
      return baseURL
    }
  }
}
</script>

<style lang="scss" scoped>
.edit-blogs-wrapper {
  padding-bottom: 30px;

  .no-selection {
    .show-more-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 120px;
      max-width: 735px;

      .show-more {
        cursor: pointer;
        font-size: 15px;
        line-height: 24px;
        color: #5A7E87;
        // font-family: 'Gotham Pro Medium';
      }
    }
  }
  .current-image-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;

    .remove {
      cursor: pointer;
      margin-left: 20px;
    }
    .current-image {
      max-height: 150px;
      width: auto;
    }
  }

  .return {
    margin-bottom: 30px;
    margin-left: auto;
    cursor: pointer;
    width: 50px;
  }
  .btn-wrapper {
    display: flex;
    flex-direction: row;

    .btn:first-child {
      margin-right: 20px;
    }
  }
  .btn.reset {
    background: white;
    border: 1px solid $main-color;
    color: $main-color;
  }
  .btn {
    height: 50px;
    width: 170px;
    line-height: 52px;
    color: white;
    // font-family: 'Gotham Pro Medium';
    text-align: center;
    background: $main-color;
    border-radius: 52px;
    cursor: pointer;
    transition-duration: .3s;
    margin-bottom: 40px;
  }
  // .btn:hover {
  //   background: #FF4B49;
  // }
  .btn:hover{
     background-color:  $btn-hover!important;
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .title {
      margin-bottom: 5px;
    }
    .setCheck{
      display: flex;
      flex-wrap: wrap;


      .itemCheck{
        margin-right:20px;
        word-wrap: unset;
        padding: 10px ;
      }
    }
  }
  .preview {
    margin-top: 30px;
    margin-bottom: 30px;

    .content {
      border: 1px solid rgb(204, 204, 204);
      padding: 10px;
    }
  }
}
</style>
