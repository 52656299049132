<template>
<div class="wrappOneBadge">
  <div v-if="value !== null" :class="[ active ? 'badge badgeMark' : 'badge', (value !== null && noEdit1 !==true)? 'pointer':'' ]"  @click="chBedge" v-html="badgeValue"></div>
  <div v-else-if="noEdit && coreData" :class="active ? 'badge badgeMark2 cap' : 'badge cap'" @click="nextBadge" v-html="coreData"></div>
  <div v-else :class="active ? 'badge badgeMark2 cap' : 'badge cap'" @click="nextBadge">{{badgeName}}</div>
</div>
</template>

<script>
export default {
  name: 'badge',
  props:[
     'badgeName',
     'value',
     'coreData',
     'active',
     'noEdit',
     'noEdit1'
  ],
  data (){
    return{
      setVal:null
    }
  },
  mounthed (){
    console.log(this.noEdit +'no edit')
    console.log(this.value +'----------------------------------------------')
  },
  methods: {
    chBedge () {
      this.$emit('changeactivebadge', this.badgeName)
    },
    nextBadge () {
       this.$emit('nextbadge', this.badgeName)
    }
  },
  watch: {

  },
  computed: {
    badgeValue() {
      if(this.badgeName.toLowerCase() == "odometer") {
        // If value exists
        if(!this.value &&
            !this.value.value &&
            // And isn't an empty string
            this.value.value.trim().length == 0) return "";
            if(isNaN(this.value.value)) return 'Odometer'
            return this.value.value.toLocaleString('en').split(',').join('&thinsp;')+ '&thinsp;km'
      }
      return this.value.value;
    }
  }

}
</script>

<style lang="scss" scoped>
.wrappOneBadge{
  //margin:15px 0;
  margin:0;
  padding: 0;
}
.badge{
  margin-bottom: 15px;
  padding:7px 20px 8px 20px;
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  line-height: 25px;
  color: rgba(255, 255, 255, 0.5);
  border: 1px solid rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
  border-radius: 20px;
  height: max-content;
  max-height:40px;
  display: flex;
  justify-content: center;
  align-items:center;
  // float: left;
  margin-right:10px;
  white-space: nowrap !important;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.3);
  }
  
}
.badgeMark {
  border: 1px solid #FFFFFF;
  color: #FFFFFF;
}
.badgeMark:hover{
  box-sizing: border-box;
  cursor: initial;
  border: 1.3px solid #fff;
  // padding:7px 20px 8px 20px;
  padding:6.7px 19.7px 7.7px 19.7px;
}
.badgeMark2{
  border: 1px solid #FFFFFF;
  color: #FFFFFF;

  &:hover {
    border: 1px solid rgba(255, 255, 255, 0.8);
  }
  
}
.pointer{
  cursor: pointer !important;
}
.cap{
  text-transform: capitalize;
}
@media only screen and (max-width: 500px){
  .badge{
    // margin-bottom: 15px;
    // margin-bottom: 20px;
    margin-bottom: 10px;
  }
}
</style>
