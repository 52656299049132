<template>
   <div :class=" changeSide ? 'mediaAssets mediaAssets2': 'mediaAssets'">
    <div v-if="!changeSide" class="imgAssets "> 
        <!-- img :src="require('@/assets/images/assetsPlaceholderRight.svg')" / -->
    </div>
    <div v-else class="textAssets  textAssets1">
          <div class="contentAssets">
            <p class="assetsTitle">Carvisor media assets</p>
            <!-- div class="assetsText">All the media below can be used freely for press purposes. If possible, please cite the source of the image in the description as ‘Carvisor’, and link to our homepage 
                <span class="link" @click="goToSite()">carvisor.co.nz</span>.
            </div -->
            <div class="assetsText">The media you find here can be used freely for press purposes. Other content can also be found on our social media channels. If you need more material, don’t hesitate to reach us at 
                <!--span class="link" @click="goToSite()">carvisor.co.nz</span-->
                <a class="link" href="mailto:contact@carvisor.co.nz">contact@carvisor.co.nz</a>.
            </div>

            <router-link class="assetsButton" to="mediaAssetsPage">Media assets</router-link>
         </div>
    </div>
     <div v-if="changeSide" class="imgAssets imgAssets1 "> 
        <!-- img :src="require('@/assets/images/assetsPlaceholderRight.svg')" / -->
    </div>
    <div v-else class="textAssets">
        <p class="download">DOWNLOAD</p>
        <p class="assetsTitle">Carvisor media assets</p>
        <!-- <div class="assetsText">Please feel free to use any of the Cazoo images below for press purposes. All we ask is that you credit the image ‘Cazoo’ and ideally link back to Cazoo.co.uk when using the images online. </div> -->
        <div class="assetsText">The media you find here can be used freely for press purposes. Other content can also be found on our social media channels. If you need more material, don’t hesitate to reach us at 
                <!--span class="link" @click="goToSite()">carvisor.co.nz</span-->
                <a class="link" href="mailto:contact@carvisor.co.nz">contact@carvisor.co.nz</a>.
        </div>
        <router-link class="assetsButton" to="mediaAssetsPage">Media assets</router-link>
    </div>
  </div>
</template>

<script>
export default {
    name: 'mediaAssets',
    components: {
    },
    data () {
        return {
        }
    },
    props: ['changeSide'],
    methods: {
        goToAssets () {
           // this.$emit('gotoassets')
        },
         goToSite () {
            window.open('https://carvisor.co.nz', '_blank');
        }
    },


}
</script>

<style lang="scss" scoped>
.mediaAssets{
   background: #EEEFF3;
   box-sizing: border-box;
   display: flex;
  // width:100%;
    //    height: 437px;
    height: 480px;
   .imgAssets{
       box-sizing: border-box;
       width:50%;
       height:100%;
      // @/assets/images/assetsPlaceholderRight.svg
       background-image: url("../../assets/images/assetsPlaceholderRight.svg");
       background-repeat: no-repeat;
       background-position: center right; 
       background-size: cover;
       
   }
   .imgAssets1{
       box-sizing: border-box;
       width:50%;
       height:100%;
       background-image: url("../../assets/images/assetsPlaceholderLeft.svg"); 
       background-repeat: no-repeat;
       background-position: center left; 
       background-size: cover;
       display: flex;
       justify-content: flex-end;
   }
    .textAssets1{
       display: flex;
       justify-content: flex-end !important;
        padding: 23px 0 0px 0px !important;
        .contentAssets{
            width: fit-content;
            display:flex;
            flex-direction: column;
            margin:0;
            margin-right: 153px;
         }

   }

   .textAssets{
       box-sizing: border-box;
       width:50%;
    //    padding: 35px 0 0px 60px;
       padding: 40px 0 0 67px;
       margin-right:20px;
       .download{
            font-family: Poppins;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.05em;
            text-transform: uppercase;
            color: #134753;
            margin: 0;

       }
       .assetsTitle{
            // margin-bottom: 44px;
            margin-bottom: 33px;
            margin-top: 48px;
            // font-weight: bold;
            // font-size: 36px;
            // line-height: 40px;
            // color: #062931;

            font-family: DM Sans;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            color: #062931;


       }
       .assetsText{
            // max-width:481px;
            max-width:471px;
            // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: normal;
            // font-size: 16px;
            // line-height: 24px; 

            // font-weight: normal;
            // font-size: 17px;
            // line-height: 25px;
            // color: #062931;

            // font-style: normal;
            font-weight: normal;
            font-size: 17px;
            line-height: 25px;
            /* or 147% */


            /* black */

            color: #062931;
            margin-bottom: 53px;

       }
       .assetsButton{
            width:max-content;
            width: 150px;
            // padding:13px 30px 13px 31px; // set top and bottom from 14 to 13 because border and height to be 52px
            height:50px;
            // padding: 0px 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            // border: 1px solid #4C4C52;

            /* rgb(6, 41, 49) is the same as #062931; */
            // border: 1px solid rgb(6, 41, 49, .8);
            border: 1px solid #062931;
            box-sizing: border-box;
            border-radius: 52px;
            font-size: 15px;
            line-height: 24px;
            // color: #4C4C52;
            color: #062931;
            margin-left: 15px; /*remove when assets are shifted to the right*/
            margin-left: 16px;
            text-decoration: none;
       }
       .assetsButton:hover{
           color:#062931;
           cursor: pointer;
           //    border: 1.8px solid #062931;
           border: 2px solid #062931;
       }
   }
}
.mediaAssets2 {
        display: flex;
        flex-direction: row !important;
    }
    .link {
        // color:$main-color;
        color: #062931;
        text-decoration: none;
    }
    .link:hover{
        // color: $btn-hover;
        opacity: .8;
        cursor: pointer;
    }
@media only screen and (max-width: 1330px){
    .mediaAssets{
         display: flex;
         flex-direction: row;
        .imgAssets1{
            box-sizing: border-box;
            width:50%;
            height:100%;
            background-image: url("../../assets/images/assetsPlaceholderLeft.svg"); 
            background-repeat: no-repeat;
            background-position: center left; 
            background-size: cover;
            display: flex;
            justify-content: flex-end;
        }
        .textAssets1{
            display: flex;
            justify-content: flex-start !important;
            padding: 35px 0 0px 28px !important;
            .contentAssets{
                width: fit-content;
                display:flex;
                flex-direction: column;
                margin:0;
                margin-right: 0px;
            }

        }

    }
    .mediaAssets2 {
        display: flex;
        flex-direction: row !important;
    }
}
@media only screen and (max-width: 875px){
    .mediaAssets{
        .imgAssets{
            // box-sizing: border-box;
            // width:100%;
            // height: 423px;
            // // @/assets/images/assetsPlaceholderRight.svg
            // background-image: url("../../assets/images/assetsPlaceholderCenter.svg");
            // background-repeat: no-repeat;
            // background-position: center center; 
            
        }
        .textAssets{
            // box-sizing: border-box;
            // width:100%;
            // height: 423px;
            // padding: 25px 0 0px 29px;
            .assetsTitle{
                 margin-top: 0px; // 36px
            }
            .assetsText {
                // padding-bottom: 30px; // 64px
            }
        }
    }
}
@media only screen and (max-width: 768px){
    .mediaAssets{
        display: flex;
        flex-direction: column;
        // width:100%;
        height: initial;
        .imgAssets{
            box-sizing: border-box;
            width:100%;
            height: 423px;
            // @/assets/images/assetsPlaceholderRight.svg
            background-image: url("../../assets/images/assetsPlaceholderCenter.svg");
            background-repeat: no-repeat;
            background-position: 58% center; 
            
        }
        .textAssets{
            box-sizing: border-box;
            width:100%;
            // height: 423px;
            height: 455px;
            padding: 25px 0 0px 29px;
            padding: 40px 0 0 27px;
            .download{
                font-size: 14px;
                line-height: 20px;
            }
            .assetsTitle{
                    // margin-bottom: 44px;
                    // margin-top:36px;
                    margin-bottom: 33px;
                    margin-top:48px;
            }
            .assetsText{
                    max-width:471px;
                    max-width: 590px;
                    padding-bottom: 54px;
                    padding-bottom: 53px;
                    margin-bottom: 0;
                    font-size: 17px;
                    line-height: 25px;
            }
            .assetsButton{
                    width:max-content;
                    padding:14px 30px 14px 31px;
                    border: 1px solid #4C4C52;
                    box-sizing: border-box;
                    border-radius: 52px;
                    font-size: 15px;
                    color: #4C4C52;
            }
            .assetsButton:hover{
                cursor:pointer;
            }
        }
        .textAssets1{
            display: flex;
            justify-content: flex-start !important;
            // padding: 35px 0 0px 28px !important;
            // padding: 35px 16px 0px 89px !important;
             padding: 23px 16px 0px 89px !important;
            .contentAssets{
                width: fit-content;
                display:flex;
                flex-direction: column;
                margin:0;
                margin-right: 0px;
            }

        }
    }
    .mediaAssets2 {
        flex-direction: column-reverse !important;
    }

}


@media only screen and (max-width: 575px){ //375
    .mediaAssets{
        height: initial;
        padding: 0px;
        .imgAssets{
            box-sizing: border-box;
            width:100%;
            height: 343px;
            // @/assets/images/assetsPlaceholderRight.svg
            // background-image: url("../../assets/images/assetsPlaceholderSmall.svg");
            background-repeat: no-repeat;
            background-position: 57% center; 
            background-size: cover;
            
        }
        .textAssets{
            box-sizing: border-box;
            width:100%;
            // height: 420px;
            min-height: 455px;
            padding: 25px 16px 0px 16px;
            .assetsTitle{
                    margin-top:0px;
                    margin-top: 22px;
                    margin-bottom: 34px;
                    font-size: 34px;
                    line-height: 40px;
                    color: #062931;
            }
            .assetsText{
                    // max-width:280px;
                    padding-bottom: 43px;
                    // font-size: 15px;
                    // line-height: 24px;
                    font-size: 16px;
                    line-height: 25px;

            }
            .assetsButton{
                    width:max-content;
                    padding:14px 30px 14px 31px;
                    border: 1px solid #4C4C52;
                    box-sizing: border-box;
                    border-radius: 52px;
                    font-size: 15px;
                    color: #4C4C52;
                    margin-bottom: 43px;
                    margin-bottom: 65px;
                    margin-left: 14px;
            }
            .assetsButton:hover{
                cursor:pointer;
            }
        }
         .textAssets1{
            padding: 35px 0 0px 16px !important;
            padding: 23px 0 0px 16px !important;
            .contentAssets{
                width: fit-content;
                display:flex;
                flex-direction: column;
                margin:0;
                margin-right: 0px;
            }

        }
    }

}

</style>