<template>
  <div class="card-wrapper" :style="isSpecial">
    <div class="title"> {{ cardObj.title }} </div>
    <div class="info-wrapp">
      <div class="info-item">
        <div class="icon">
          <img :src="require(`@/assets/images/icons/check.svg`)" alt="">
        </div>
        <div class="text"> {{ cardObj.numOfReps }} </div>
      </div>
      <div class="info-item">
        <div class="icon">
          <img :src="require(`@/assets/images/icons/check.svg`)" alt="">
        </div>
        <div class="text"> Search access for {{ cardObj.search }} days </div>
      </div>
      <div class="info-item">
        <div class="icon">
          <img v-if="cardObj.canFav" :src="require(`@/assets/images/icons/check.svg`)" alt="">
          <img v-else :src="require(`@/assets/images/icons/cross.svg`)" alt="">
        </div>
        <div :class="{'text': true, 'disabled': !cardObj.canFav}"> Save your favorites </div>
      </div>
    </div>
    <div class="payment-wrap">
      <div class="price-wrap">$ {{ cardObj.price }}</div>
      <button class="join-btn">Join</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'planCard',
  data() {
    return {
    }
  },
  props: {
    cardObj: {
      required: true
    },
  },
  computed: {
    isSpecial() {
      if (this.cardObj.isSpecial) {
        console.log("was executed");
        return {
          borderColor: "#5A7E87",
          backgroundColor: "#FFF"
        }
      } else {
        console.log("wasnt executed");
        return {}
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.card-wrapper {
  width: 325px;
  height: calc(255px - 52px);
  background-color: #F8F8F9;
  padding-top: 22px;
  padding-bottom: 30px;
  border: 1px solid #DEE0E5;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 22px;

  .title {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #5A7E87;

    padding-left: 50px;
    margin-bottom: 25px;
  }

  .info-wrapp {
    width: 100%;
    margin-bottom: 25px;
    .info-item {
      display: flex;
      .icon {
        width: 12px;
        height: 25px;
        margin: 0 18px 0 20px;
        img {
          width: 100%;
        }
      }
      .text {
        font-size: 17px;
        font-weight: 500px;
        line-height: 25px;
        color: #062931;
      }
      .disabled {
        color: #787883;
        opacity: 0.7;
      }
    }
  }

  .payment-wrap {
    width: calc(100% - 70px);
    padding: 0 20px 0 50px;
    display: flex;
    justify-content: space-between;
    .price-wrap {
      font-size: 26px;
      font-weight: 700px;
      color: #062931;
      mix-blend-mode: normal;
    }
    .join-btn {
      width: 94px;
      height: 40px;
      background-color: #FF6359;
      border: 1px solid #FF6359;
      border-radius: 45px;
      color: #fff;
      font-family: 'DM Sans';
      font-size: 16px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  .card-wrapper {
    width: 100%;
  }
}

@media only screen and (max-width: 480px) {
  .card-wrapper {
    height: calc(245px - 52px);
    .title {
      font-size: 15px;
    }
    .info-wrapp {
      .info-item {
        .text {
          font-size: 16px;
        }
      }
    }
    .payment-wrap {
      .join-btn {
        font-size: 14px;
      }
    }
  }
}
</style>