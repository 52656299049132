<template>
  <div :class="white ? 'wrappUpFooter wrappUpFooter2 ': 'wrappUpFooter' ">
      <div class="holdContent">
          <p>For all press related enquiries regarding Carvisor, or to be added to the mailing ist, please reach us at
              <span class="link" ><a href="mailto:contact@carvisor.co.nz">contact@carvisor.co.nz</a></span>.
          
             More information about us can be found 
              <a class="link" href="about" >here</a>.
          </p>
      </div>
  </div>
</template>

<script>
export default {
    name:'wrappUpFooter',
    props: ['white'],
    methods: {
        //  goTo () {
        //      let routeData = this.$router.resolve({name:'about'});
        //       window.open(routeData.href, '_blank');
        // }
    }

}
</script>

<style lang="scss" scoped>
.wrappUpFooter{
    color: #062931;
    background-color: #FFFFFF;
    margin-bottom: 95px;
    .holdContent{
       //  background: white;
        //  padding-top:32px;
        margin:0 auto;
        width:1278px;
        font-style: normal;
        font-weight: bold;
        font-size: 15px;
        line-height: 20px;
        /* or 133% */
        p{
            max-width: 733px;
            margin: 0;
        }

        .link {
             color:$main-color;
             text-decoration: none;
             a {
                 text-decoration: none;
                 color: $main-color;
             }
         }
         .link:hover{
                color: $btn-hover;
                cursor: pointer;
             a {
                color: $btn-hover;
                cursor: pointer;
           }
         }

    }
}


@media only screen and (max-width: 1330px){
    .wrappUpFooter{
        .holdContent{
            box-sizing: border-box;
            margin:0 auto;
            //width:1278px;
            width: calc(100% - 58px );
            margin: 0 29px;
            // height: 202px;
            .small{
             display:none;
            }
            .bg{
             display: initial;
            }
            .med{
                display: none;
            }


        }
    }
}

@media only screen and (max-width: 788px){ // 768
    .wrappUpFooter{
        // background: bisque;
        .holdContent{
            margin:0 auto;
            flex-direction: column;
            width: calc(100% - 54px);
            box-sizing: border-box;
            // height: initial;
            // padding-top:32px;
            padding-left: 0px;
            padding-right: 0px;
            // padding-bottom: 43px;
            margin: 0 27px;
            margin-right: 7px!important;
            

            font-size: 15px;
            line-height: 20px;
        }
    }
}

@media only screen and (max-width: 475px){ //375
 .wrappUpFooter{
        // background: bisque;
         padding-bottom: calc(55px + 61px); // 55px design + 61px toolbar
        .holdContent{
            left: 16px;
            top: 1635px;
            height: initial;
            margin: 0px 16px;
            width: initial;
            // letter-spacing: initial;
            
        }
    }
}
</style>