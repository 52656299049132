<template>
  <div class="info-wrapper">
    <div class="title">enter details</div>
    <div class="input-wrap" style="margin-top: 25px">
      <div class="label">Name</div>
      <div class="single-input">
        <input id="name" type="text" v-model="name" readonly>
        <span @click="setEdit(`name`)">Edit</span>
      </div>
    </div>
    <div class="input-wrap">
      <div class="label">E-mail</div>
      <div class="single-input">
        <input id="email" type="text" v-model="email" readonly>
        <span @click="setEdit(`email`)">Edit</span>
      </div>
    </div>
    <div class="btn-wrap">
      <button class="btn" @click="prev">Back</button>
      <button class="btn filled" @click="next">Confirm and proceed</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "infoFields",
  data() {
    return {
      name: "Petar Petrovic",
      email: "petar.petrovic@enon.com"
    }
  },
  methods: {
    setEdit (id) {
      let el = document.getElementById(id)

      el.removeAttribute('readonly')
      el.focus()
    },
    prev () {
      this.$router.push('/pricing')
    },
    next () {
      this.$emit("next", {title: "details", info: [this.name, this.email]})
    }
  },
}
</script>

<style lang="scss" scoped>
.info-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 25px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #5A7E87;
  }
  .input-wrap {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 22px;
    .label {
      font-size: 17px;
      font-weight: 500;
      line-height: 25px;
      color: #062931;
      margin-bottom: 3px;
    }
    .single-input {
      width: 100%;
      height: 48px;
      // background-color: blue;
      position: relative;
      input {
        margin: 0;
        padding: 0;
        padding-left: 5px;
        height: 100%;
        width: calc(100% - 5px);
        border: 1px solid rgba(6, 41, 49, 0.8);
        border-radius: 5px;
        font-weight: 400;
        font-size: 15px;
        letter-spacing: 0.01em;
        font-family: 'DM Sans';
        color: #062931;
      }
      span {
        font-size: 12px;
        line-height: 20px;
        font-weight: 500;
        letter-spacing: 0.02em;
        color: #9CB2B5;
        position: absolute;
        right: 15px;
        top: 15px;
        cursor: pointer;
      }
    }
  }
  .btn-wrap {
    margin-top: 40px;
    .btn {
      font-family: inherit;
      padding: 12px 40px;
      border: 1px solid rgba(6, 41, 49, 0.8);
      border-radius: 55px;
      font-size: 15px;
      line-height: 20px;
      font-weight: 500;
      letter-spacing: 0.01em;
      color: #062931;
      background-color: #fff;
      cursor: pointer;
    }
    .filled {
      color: #fff;
      background-color: #FF6359;
      border: none;
      margin-left: 10px;
    }
  }
}

@media only screen and (max-width: 500px) {
  .info-wrapper {
    .title {
      font-size: 15px;
    }
    .input-wrap {
      margin-top: 20px;
      .label {
        font-size: 16px;
      }
    }
    .btn-wrap {
      .btn {
        font-size: 14px;
        padding: 12px 35px;
      }
    }
  }
}
</style>