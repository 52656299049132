import Vue from 'vue'
import Vuex from 'vuex'
import { api } from '@/api'
import helper from '@/js/helper.js'
import router from '@/router'
import eventBus from '@/js/eventBus.js'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loged: false,
    userName: null,
    privilege: 0,
    userData: {},
    idealPrice: null,
    viewSimilar: false,
    mileageLow: false,
    mileageHigh: false,
    noPrice: false,
    numCars: null,
    deleted: false,
    isIosTenAndMore: false,
    showBigMenu: false,
    pageBefore: '',
    opinion: false,
    isSafari:false,
    selected: {
      make: null,
      model: null,
      year: null,
      body: null,
      fuel: null,
      transmission: null,
      engine: null,
      odometer: null
    },
    options: {
      make: null,
      model: null,
      year: null,
      transmission: null,
      body: null,
      fuel: null,
      engine: null,
      odometer: null
    },
    order: [
      'make',
      'model',
      'year',
      'body',
      'fuel',
      'transmission',
      'engine',
      'odometer'
    ],
    socials: {
      facebook: 'https://www.facebook.com/carvisorgroup',
      // instagram: 'https://instagram.com/carvisor_nz?igshid=hh4pv3wr3ruh',
      instagram: 'https://www.instagram.com/carvisor_nz/',
      linkedin: 'https://www.linkedin.com/company/carvisor-group',
      twitter: 'https://twitter.com/?lang=en'
    },
    report: false
  },
  mutations: {
    changeDeleted(state, payload) {
      state.deleted = payload
    },
    changeLoged(state, payload) {
      state.loged = payload
    },
    setUserName(state, payload) {
      state.userName = payload
    },
    setUserPrivilege(state, payload) {
      state.privilege = payload
    },
    setuserData(state, payload) {
      state.userData = payload
    },
    setDropdowns(state, payload) {
      state.options[payload.key] = payload.value

      // remove all filters and options after cur option
      let remove = false
      for (let i = 0; i < state.order.length; i++) {
        if (remove) {
          state.options[state.order[i]] = null
          state.selected[state.order[i]] = null
        }
        if (state.order[i] === payload.key) {
          remove = true
        }
      }
    },
    setOption(state, payload) {
      let hadSelected = state.selected[payload.key] ? true : false
      state.selected[payload.key] = payload.value

      // remove all filters and options after cur option *if option is changed*
      if (hadSelected) {
        let remove = false
        for (let i = 0; i < state.order.length; i++) {
          if (remove) {
            state.options[state.order[i]] = null
            state.selected[state.order[i]] = null
          }
          if (state.order[i] === payload.key) {
            remove = true
          }
        }
      }
    },
    setOdometer(state, payload) {
      let res = null
      if (payload !== null) {
        res = {}
        res.key = payload
        res.value = payload
      }
      state.selected['odometer'] = res
    },
    setSimilarCars(state, payload) {
      state.viewSimilar = payload
    },
    resetValue(state) {
      for (let i = 0; i < state.order.length; i++) {
        state.selected[state.order[i]] = null
        if (state.order[i] != 'make') {
          state.options[state.order[i]] = null
        }
        console.log('remove', state.order[i]);
      }
    },
    setIdealPrice(state, payload) {
      state.idealPrice = payload
    },
    setMileageLow(state, payload) {
      state.mileageLow = payload
    },
    setNoPrice(state, payload) {
      state.noPrice = payload
    },
    setMileageHigh(state, payload) {
      state.mileageHigh = payload
    },
    setNumCars(state, payload) {
      state.numCars = payload
    },
    SET_IS_IOS_TEN_AND_MORE(state, payload) {
      state.isIosTenAndMore = payload
    },
    setShowBigMenu(state, payload) {
      state.showBigMenu = payload
    },
    setPageBefore(state, payload) {
      state.pageBefore = payload
    },
    setOpinion(state, payload) {
      state.opinion = payload
    },
    setReportSend(state, payload) {
      state.report = payload
    }
  },
  actions: {

    getCars: (context) => {
      let params = helper.fixParams(context.state.selected)
      return api.getCars(params).then(res => {
        let payload = helper.createPayload(res.data)
        // console.log(payload, 'asd');
        if (payload.value && payload.value.length === 1) {
          let payload2 = {
            key: payload.key,
            value: payload.value[0]
          }
          context.commit('setOption', payload2)
          context.dispatch('getCars')
        }
        context.commit('setDropdowns', payload)
        context.commit('setIdealPrice', null)
        if (payload.key !== 'make') {
          eventBus.$emit('setOption')
        }
      }).catch(e => {
        console.log(e)
      })
    },
    getEstimate: ({ state, commit }, payload) => {
      let params = helper.fixParams(state.selected)
      params.token = payload
      api.getEstimate(params).then(res => {
        commit('setReportSend', false)
        commit('setIdealPrice', res.data.ideal_price)
        if (parseInt(res.data.ideal_price) < 100) {
          commit('setSimilarCars', true)
        } else {
          commit('setSimilarCars', false)
        }
        let key = res.data.cdt_characteristicid
        let hash = helper.generateHash(state.selected)
        // console.log('+++++++++++++++++no cars  -++++++++++++++++++++++++----  ')
        router.push(`estimation/${hash}/${encodeURIComponent(key)}/${params.car_km}`)
      }).catch(e => {
        commit('setReportSend', true)
        if (e.response.data.status === 'deleted') {
          console.log('response.data.status ========================== deleted')
          commit('setSimilarCars', true)
          commit('setMileageLow', false)
          commit('setMileageHigh', false)
          commit('setNoPrice', false)
          // commit('setNumCars', e.response.data.num_cars)
          commit('setNumCars', false)
          commit('changeDeleted', true)
          console.log('e.response.data................' + e.response.data.cdt_characteristicid)
          let key = e.response.data.cdt_characteristicid
          let hash = helper.generateHash(state.selected)
          console.log(key)
          console.log(encodeURIComponent(key))
          router.push(`estimation/${hash}/${encodeURIComponent(key)}/${params.car_km}`)
        } else if (e.response.data.message === 'no-cars') {
          // console.log('+++++++++++++++++no cars  -++++++++++++++++++++++++----  ')
          console.log('no-car navigate');
          commit('setSimilarCars', true)
          commit('setMileageLow', false)
          commit('setMileageHigh', false)
          commit('setNoPrice', false)
          commit('setNumCars', e.response.data.num_cars)
          let key = e.response.data.cdt_characteristicid
          let hash = helper.generateHash(state.selected)
          router.push(`estimation/${hash}/${encodeURIComponent(key)}/${params.car_km}`)
        } else if (e.response.data.message === 'bad-request') {
          // console.log('+++++++++++++++++ bad-request  -++++++++++++++++++++++++----  ')
          commit('setNoPrice', false)
          if (e.response.data.milage && e.response.data.milage === 'low') {
            commit('setMileageLow', true)
          } else {
            commit('setMileageLow', false)
          }
          if (e.response.data.milage && e.response.data.milage === 'high') {
            commit('setMileageHigh', true)
          } else {
            commit('setMileageHigh', false)
          }
          commit('setSimilarCars', true)
          let key = e.response.data.cdt_characteristicid
          let hash = helper.generateHash(state.selected)
          router.push(`estimation/${hash}/${encodeURIComponent(key)}/${params.car_km}`)
          commit('setNumCars', null)
        } else if (e.response.data.message === 'no-price') {
          commit('setNoPrice', true)
          commit('setSimilarCars', true)
          commit('setMileageLow', false)
          commit('setMileageHigh', false)
          commit('setNumCars', null)
          let key = e.response.data.cdt_characteristicid
          let hash = helper.generateHash(state.selected)
          router.push(`estimation/${hash}/${encodeURIComponent(key)}/${params.car_km}`)
        } else {
          commit('setSimilarCars', false)
          commit('setMileageLow', false)
          commit('setMileageHigh', false)
          commit('setNoPrice', false)
          commit('setNumCars', null)
          router.push('/')
        }
      })
    },
    async setParams(context, payload) {
      // make:
      let par1 = {}
      await api.getCars(par1).then(res => {
        let payload2 = helper.createPayload(res.data)
        context.commit('setDropdowns', payload2)
        for (var i = 0; i < payload2.value.length; i++) {
          if (payload2.value[i].key === payload.mak_id) {
            let res = {
              key: 'make',
              value: payload2.value[i]
            }
            context.commit('setOption', res)
          }
        }
      }).catch(e => {
        console.log(e)
      })
      // model
      let par2 = {
        mak_id: payload.mak_id
      }
      await api.getCars(par2).then(res => {
        let payload2 = helper.createPayload(res.data)
        context.commit('setDropdowns', payload2)
        for (var i = 0; i < payload2.value.length; i++) {
          if (payload2.value[i].key === payload.mod_id) {
            let res = {
              key: 'model',
              value: payload2.value[i]
            }
            context.commit('setOption', res)
          }
        }
      }).catch(e => {
        console.log(e)
      })
      // year
      let par3 = {
        mak_id: payload.mak_id,
        mod_id: payload.mod_id
      }
      await api.getCars(par3).then(res => {
        let payload2 = helper.createPayload(res.data)
        context.commit('setDropdowns', payload2)
        for (var i = 0; i < payload2.value.length; i++) {
          if (payload2.value[i].key === payload.year) {
            let res = {
              key: 'year',
              value: payload2.value[i]
            }
            context.commit('setOption', res)
          }
        }
      }).catch(e => {
        console.log(e)
      })
      // body
      let par4 = {
        mak_id: payload.mak_id,
        mod_id: payload.mod_id,
        year: payload.year
      }
      await api.getCars(par4).then(res => {
        let payload2 = helper.createPayload(res.data)
        context.commit('setDropdowns', payload2)
        for (var i = 0; i < payload2.value.length; i++) {
          if (payload2.value[i].key === payload.bod_id) {
            let res = {
              key: 'body',
              value: payload2.value[i]
            }
            context.commit('setOption', res)
          }
        }
      }).catch(e => {
        console.log(e)
      })
      // fuel
      let par5 = {
        mak_id: payload.mak_id,
        mod_id: payload.mod_id,
        year: payload.year,
        bod_id: payload.bod_id
      }
      await api.getCars(par5).then(res => {
        let payload2 = helper.createPayload(res.data)
        context.commit('setDropdowns', payload2)
        for (var i = 0; i < payload2.value.length; i++) {
          if (payload2.value[i].key === payload.fue_id) {
            let res = {
              key: 'fuel',
              value: payload2.value[i]
            }
            context.commit('setOption', res)
          }
        }
      }).catch(e => {
        console.log(e)
      })
      // transmission
      let par6 = {
        mak_id: payload.mak_id,
        mod_id: payload.mod_id,
        year: payload.year,
        bod_id: payload.bod_id,
        fue_id: payload.fue_id
      }
      await api.getCars(par6).then(res => {
        let payload2 = helper.createPayload(res.data)
        context.commit('setDropdowns', payload2)
        for (var i = 0; i < payload2.value.length; i++) {
          if (payload2.value[i].key === payload.tra_id) {
            let res = {
              key: 'transmission',
              value: payload2.value[i]
            }
            context.commit('setOption', res)
          }
        }
      }).catch(e => {
        console.log(e)
      })
      // engine
      let par7 = {
        mak_id: payload.mak_id,
        mod_id: payload.mod_id,
        year: payload.year,
        bod_id: payload.bod_id,
        fue_id: payload.fue_id,
        tra_id: payload.tra_id
      }
      await api.getCars(par7).then(res => {
        let payload2 = helper.createPayload(res.data)
        context.commit('setDropdowns', payload2)
        for (var i = 0; i < payload2.value.length; i++) {
          if (payload2.value[i].key === payload.ens_id) {
            let res = {
              key: 'engine',
              value: payload2.value[i]
            }
            context.commit('setOption', res)
          }
        }
      }).catch(e => {
        console.log(e)
      })
      context.commit('setOdometer', parseInt(payload.car_km))
    },
    setOpinionAction(context, payload) {
      context.commit('setOpinion', payload)
    },
    isSafariAction (context, payload) {
      context.commit('isSafari', payload)
    }
  },
  getters: {
    getIsSafari: (state) => {
      return state.isSafari
    },
    getAllSelected: (state) => {
      return state.selected
    },
    getAllOptions: state => {
      return state.options
    },
    getOrder: state => {
      return state.order
    },
    getIdealPrice: state => {
      return state.idealPrice
    },
    getViewSimilar: state => {
      return state.viewSimilar
    },
    getSocials: state => {
      return state.socials
    },
    getMileageLow: state => {
      return state.mileageLow
    },
    getMileageHigh: state => {
      return state.mileageHigh
    },
    getNoPrice: state => {
      return state.noPrice
    },
    getNumCars: state => {
      return state.numCars
    },
    getDeleted: state => {
      return state.deleted
    },
    getLoged: state => {
      return state.loged
    },
    getuserData: state => {
      return state.userData
    },
    getIsIosTenAndMore: state => {
      return state.isIosTenAndMore
    },
    getShowBigMenu: state => {
      return state.showBigMenu
    },
    getPageBefore: state => {
      return state.pageBefore
    },
    getOpinion: state => {
      return state.opinion
    },
    getReportSend: state => {
      return state.report
    }

  },
  modules: {
  }
})
