<template>
  <div class="animePage">
    <anime />
  </div>
</template>

<script>
import anime from '@/components/anime/anime.vue'
export default {
  name: 'animePage',
  components: {
    anime
  }

}
</script>

<style>

</style>