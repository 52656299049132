<template>
  <div class="reportsAdmin">
      <h1>Reports</h1>
      <div class="flexButton">  
      <div @click="newsletterSubscribers" class="buttonReset">
        <p>Newsletter Subscribers</p>
      </div>
      <div @click="priceRatingsFeedback" class="buttonCreate">
        <p>Price ratings Feedback</p>
      </div>
       <div @click="failedPriceReport" class="buttonReset">
        <p>Failed price report</p>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/api'
export default {
    name: 'Reports',
    methods: {
        newsletterSubscribers (){
            api.newsletterSubscribers().then(res => {
                let blob = new Blob([res.data], { type: 'application/csv' })
                let url = window.URL.createObjectURL(blob)
                const link = document.createElement('a');
                link.href = url
                link.setAttribute('download', 'Newsletter_Subscribers.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();

            }).catch(e => {
                console.log(e)
            })
        },
         priceRatingsFeedback (){
            api.priceRatingsFeedback().then(res => {
                // console.log(res.data)
                //  const downloadUrl = window.URL.createObjectURL(new Blob([res]));
                //         const link = document.createElement('a');
                //         link.href = downloadUrl;
                //         link.setAttribute('download', 'Price ratings Feedback');
                //         document.body.appendChild(link);
                //         link.click();
                //         link.remove();
                 let blob = new Blob([res.data], { type: 'application/csv' })
                let url = window.URL.createObjectURL(blob)
                const link = document.createElement('a');
                link.href = url
                link.setAttribute('download', 'Price_ratings_Feedback.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();

            }).catch(e => {
                console.log(e)
            })
        },
         failedPriceReport (){
            api.failedPriceReport().then(res => {
                console.log(res)
                let blob = new Blob([res.data], { type: 'application/csv' })
                let url = window.URL.createObjectURL(blob)
                const link = document.createElement('a');
                link.href = url
                link.setAttribute('download', 'failed_Price_Report.csv');
                document.body.appendChild(link);
                link.click();
                link.remove();

            }).catch(e => {
                console.log(e)
            })
        },
        
        
    }

}
</script>

<style lang="scss" scoped>
.reportsAdmin{
    max-width: 100%;
  .flexButton{
    display: flex;
    flex-wrap: wrap;
   .buttonCreate,
   .buttonReset{
        height: 52px;
        min-width: 170px;
        line-height: 52px;
        color: white;
        border: 1px solid $main-color;
        // font-family: 'Gotham Pro Medium';
        text-align: center;
        background: $main-color;
        border-radius: 52px;
        cursor: pointer;
        transition-duration: .3s;
        margin-bottom: 40px;
        padding:0 20px;
        margin-right: 20px;
       p {
         margin: auto;

       }
    }
    .buttonReset {
      background: white;
      border: 1px solid $main-color;
      color: $main-color;
      
    }
    .buttonCreate:hover{
      background: $btn-hover;
    }
    .buttonReset:hover{
      border: 1px solid rgb(206, 15, 2);;
      color: rgb(206, 15, 2);
    }
  }
}
</style>