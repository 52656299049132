<template>
  <div class="wrappProfile">
      <div class="holdContent">
          <profileData 
          v-if="profileInfo.usr_name !== '' "
           :profileInfo="profileInfo" 
           @changedata='changeData' 
           @changemsg = "msgerr=''"
           :msgerr='msgerr' 
           />
      </div>
  </div>
</template>

<script>
import profileData from '@/components/profileComponents/profileData'
import { mapGetters } from 'vuex'
import { api } from '@/api'
// import { sha256 } from 'js-sha256'

export default {
    name: 'profile',
    components: {
        profileData
    },
    created() {
        this.getUserData()
        // this.setData(this.getuserData)
        console.log('getUserData')
        console.log(this.getuserData)
    },
    data () {
        return{
            profileInfo1: {
                name: 'Nikola Nikolić',
                email: 'nikolanikolic@email.com',
                password: '1234567',
                image: null,
                usr_active: 0,
                usr_email: "milos@carvisor.co.nz",
                usr_name: "Miloš"
            },
            profileInfo: {
                usr_name: '',
                usr_active: 0,
                usr_email:'',
                usr_password: '',
                usr_newPassword: '',
                usr_retypePassword: '',
                image: null

            },
            msgerr:''
        }
    },
    methods:{
        changeData (e) {
            console.log('changeData')
            console.log(e)
            let params = new FormData()
            params.append( 'sid' , localStorage.getItem('sid'))

            for (var variable in e) {
                if(variable === 'usr_password') continue
                if(e[variable]!==''&& e[variable]!== this.profileInfo[variable]){
                    if(variable === 'usr_newPassword')
                        params.append('usr_password', e[variable]) // milos123
                    else if (variable === 'image')
                        continue
                    else
                        params.append(variable, e[variable])
                }
                console.log(e[variable])
            }
            
            api.editUserData(params).then(res => {
                console.log('res.data.data')
                console.log(res.data.res)
                // this.$store.commit('setuserData', res.data.data)
                this.msgerr='You have successfully changed the data'
                this.getUserData()
            }).catch(e => {
                 if(e.response.data.message === 'invalid-password') {
                     this.msgerr='You have entered the wrong old password, please try again'
                     return
                 } else if(e.response.data.message === 'Nothing changed'){
                      this.msgerr='You have not made any changes to the data'
                     return
                 }
                this.msgerr='Data editing failed, please try again'
                console.log(e)
            })

        },
        getUserData() {
            let params = {
                sid : localStorage.getItem('sid')
            }
            api.getUserData(params).then(res => {
                this.profileInfo = res.data.data
                console.log(res.data.data)
                this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
             console.log(e)
            })
        },
        setData (getuserData) {
            //  this.profileInfo = getuserData
                for (let property in this.profileInfo) {
                    if(Object.hasOwnProperty.call(getuserData, property))
                        this.profileInfo[property]=getuserData[property]
                }
        },
    },
    watch: {
        // getuserData: {
        //     handler(newVal){
        //         // console.log('newVal')
        //         // console.log(newVal)
        //         this.setData(newVal)
        //     },
        //     deep:true
        // }
    },
    computed: {
    ...mapGetters(['getLoged', 'getuserData'])
    }

}
</script>

<style lang="scss" scoped>
.wrappProfile{
    z-index:1000;
    min-height: calc(100% - 80px);
    width:100% !important;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    display:flex;
    margin: 0 auto;
    padding-top: 87px;
    padding-top: 98px;
     .holdContent {
        box-sizing: border-box;
        background: rgb(255, 255, 255);
        // display:flex;
        // justify-content: center;
        // align-items: center;
        padding: 0 30px;
        max-width: 1275px;
        width:1275px;
        margin: 0 auto;
        // min-height: 588px;
       
     }
}
 @media only screen and (max-width: 1305px){
     .wrappProfile{
        // flex-direction: column-reverse;
         width:100%;
         display: flex;
         .holdContent{
               display: flex;
                width:100%;
                display: flex;
                // justify-content: center;
                // align-items: center;
                // padding: inherit 0 !important;
         }
     }
 }

// @media only screen and (max-width: 900px){
//      .wrappProfile{
//             //  flex-direction: column;
//             //  align-items: flex-start;
//             // height: 120px;
//             //  padding:22px 35px;
//           .holdContent{
//             box-sizing:border-box;
//             height: 120px;
//             padding:22px 15px;
//             padding: 0 10px;
//         }
//      }
// }
@media only screen and (max-width: 767px){
     .wrappProfile{
         height: initial !important;
        //  padding-top: 47px;
         padding-top: 48px;
        .holdContent{
            padding:0px 35px;
           
            height:initial;
        }
    }
}
@media only screen and (max-width: 750px){
     .wrappProfile{
         height: initial !important;
         //  padding-top: 72px;
         padding-top: 73px;
        .holdContent{
            padding:0px 16px;
            height:initial;
            width: 100%;
            box-sizing: border-box;
        }
    }
}

</style>