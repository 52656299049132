<template>
  <div class="edit-news-wrapper">
    <div v-if="!editNews">
        <optionsPress v-for="(i, index) in news "
            :key="index" 
            :itemPressReleases="i"
            @gotoonepress="gotoonepress"
          />
        <div v-if="existsMoreNews" class="showeMore" @click="showeMore">Show more</div>
     </div>
     <div v-else class="edit">
        <div @click="cancel" class="return">
        return
      </div>
          <div class="input-wrapper">
            <span class="title">Title:</span>
            <input type="text" v-model="editNews.title" :class="{err:errTitle}" @keyup="errTitle=false" >
          </div>
          <div class="input-wrapper">
            <span class="title">Description:</span>
            <input type="text" v-model="editNews.description" >
          </div>
          <div v-if="false" class="input-wrapper">
            <span class="title">Minutes to read:</span>
            <input type="number" v-model="editNews.readTime">
          </div>
          <div class="input-wrapper">
            <span class="title">Author:</span>
            <input type="text" v-model="editNews.author">
          </div>
          <div class="input-wrapper">
            <span class="title">Link:</span>
            <input type="text" v-model="editNews.link" :class="{err:errSlug}" @keyup="errSlug=false">
          </div>
           <div v-if="editNews.thumb" class="input-wrapper">
            <span class="title">Current image:</span>
            <div class="currentImg">
              <img v-if="!editNews.delete_thumb && prewImage==null" :src="baseURL + editNews.thumb" alt="current-image" class="current-image">
              <img v-else :src="prewImage" alt="" class="current-image">
            </div>
            <div @click="removeImage()" class="remove">
                remove
              </div>
          </div>
          <div v-else class="input-wrapper">
            <span class="title">Image:</span>
            <input type="file" ref="thumb" @change="setImg($event.target.files)" accept=".png, .jpg, .jpeg">
             <div v-if="errImg != ''" class="errTxt">
              {{errImg}}
            </div>
          </div>
          <div class="input-wrapper">
            <span class="title">Image alt:</span>
            <input type="text" v-model="editNews.thumb_alt">
          </div>
          <div class="input-wrapper">
            <span class="title">Image name:</span>
            <input type="text" v-model="editNews.thumb_name">
          </div>
           <div v-if="prewFile" class="input-wrapper ">
              <span class="title">File: </span>
              <p>{{editNews.file}}</p>
              <div v-if="false" div @click="removeFile()" class="remove">
                remove
              </div>
          </div>
           <div  class="input-wrapper "> <!-- v-else -->
              <!-- span class="title">File: </span -->
              <input :class="{errTxt:errFile}" type="file" ref="file" @change="setFile($event.target.files)" accept=".pdf">
          </div>
          <div class="input-wrapper">
            <span class="title">Content:</span>
            <vue-editor v-model="editNews.content" @keyup="errContent=''"></vue-editor>
            <div v-if="errContent != ''" class="errTxt">
              {{errContent}}
            </div>
            <div v-if="resOk != ''" class="errTxt">
              {{resOk}}
            </div>
            <div v-html="getPreview" class="">
            </div>
          </div>
          <div class="flexButton">
            <!--div @click="resetFields" class="buttonVin">
              <p>Reset </p>
            </div -->
             <div @click="cancel" class="buttonReset">
              <p>Cancel</p>
            </div>
              <div @click="edit2" class="buttonCreate">
              <p>Edit</p>
            </div>
          </div>
    </div>
  </div>
</template>

<script>
import optionsPress from '@/components/newsroom/optionsPress.vue'
import { api, baseURL } from '@/api'
import { VueEditor } from "vue2-editor";
export default {
  name: 'editNews',
  components: {
    optionsPress,
    VueEditor
  },
  data () {
    return {
      news: [],
      offset: 0,
      count: 5,
      editNews: null,
      oldNews: null,
      errContent: '',
      resOk: '',
      errTitle:false,
      errSlug:false,
      prewImage: null,
      errImg:'',
      existsMoreNews: true,
      prewFile:false,
      errFile: false
    }

  },
    activated () {
        this.getNews()
    },
    methods: {
        gotoonepress (id) {
            console.log('link '+id.link.substring(6, id.link.length))
             let params = {
              id: id.link.substring(6, id.link.length)
            }
            api.getNewsData(params.id).then( res =>{
              console.log( JSON.parse(JSON.stringify(res.data.data)))
              this.editNews = JSON.parse(JSON.stringify(res.data.data))
              this.oldNews = JSON.parse(JSON.stringify(res.data.data))
              this.editNews.delete_thumb = false
              this.oldNews.delete_thumb = false
              if( this.editNews.file){
                 this.prewFile = true
              }
            }).catch(e => {
              console.log(e)
            })

            // this.$router.push({name:'onePressReleases', params:{id}})
           // this.$router.push(id)
        },
        edit () {
            let edit = new FormData()
            for (let variable in this.editNews) {
               console.log(this.editNews.variable != this.oldNews.variable)
               console.log(variable)
                if(variable === 'thumb' && this.editNews[variable] === null) {
                  console.log(variable)
                } else if (this.editNews[variable] != this.oldNews[variable]) {
                  console.log('this.editNews[variable] != this.oldNews[variable]')
                  edit.append(variable, this.editNews[variable])
                  
                }
            }
            edit.append('id', this.editNews.id)
           
            api.editNews(edit).then(() => {
              console.log('edit')
              this.cancel()
              this.getNews()
            }).catch(e => {
              console.log(e);
            })

        },
        edit2(){
            let changes = new FormData()
          for (var variable in this.editNews) {
            if (Object.prototype.hasOwnProperty.call(this.editNews, variable)) {
                if(variable === 'thumb' && this.editNews[variable] === null) {
                  console.log(variable)
                } else if (this.editNews[variable] !== this.oldNews[variable]) {
              
                changes.append(variable, this.editNews[variable])
              }
            }
          }
          changes.append('id', this.editNews.id)
        
          api.editNews(changes).then(() => {
            console.log('edit')
                  this.cancel()
                  this.getNews()
                  this.resetErr()
          }).catch(e => {
            console.log(e);
          })
        },
        resetFields () {
          this.editNews = this.oldNews
        },
        getNews () {
            let params= {
                count: this.count,
                offset: this.offset
            }
            api.getNews(params).then( res =>{
                console.log(res.data.data)
                this.news= res.data.data
                this.checkIfExistsMoreNews(this.news)
            }).catch(e => {
                console.log(e);
            })

        },
        showeMore () {
          let offsetCurr = this.news.length
          let params = {
             count: this.count,
             offset: offsetCurr
          }
           api.getNews(params).then( res =>{
                console.log(res.data.data)
               // this.news= res.data.data
                for (let i = 0; i < res.data.data.length; i++) {
                  this.news.push(res.data.data[i])
                }
                this.checkIfExistsMoreNews(this.news)
            }).catch(e => {
                console.log(e);
            })

        },
        checkIfExistsMoreNews (news) {
          let params2= {
            count: 1,
            offset: news.length
          }
          api.getNews(params2).then( res=>{
            console.log(res.data.data.length)
            if(res.data.data.length > 0)
                    this.existsMoreNews = true
               else 
                    this.existsMoreNews = false
            }).catch(e => {
                console.log(e)
            })

        },
        cancel () {
           this.editNews = null
           this.oldNews = null
        },
         resetErr () {
          this.errContent = '' 
          this.errTitle = false 
          this.errSlug = false
          this.prewImage = null
          this.errImg = ''
          this.errFile = false
        },
         removeImage () {
          this.editNews.delete_thumb = true
          this.editNews.thumb = null
          this.prewImage = null
        },
         removeFile () {
          this.editNews.delete_file = true
          this.editNews.file = null
          this.prewFile = false
        },
        setFile (files) {
          this.prewFile = false
          this.errFile = false
          this.editNews.file = files[0]
          this.editNews.delete_file = false
        },
        setImg (files) {
            this.errImg = ''
           // console.log(files[0])
           if (files[0].size > 400000) {
              this.$refs.thumb.value = null
              this.errImg ='The image must not be larger than 400kb'
              return
            }
           this.editNews.delete_thumb = false
           this.editNews.thumb = files[0]
          
           let reader = new FileReader()
           reader.onload = (e) => {
            this.prewImage = e.target.result
            // console.log(this.prewImage)
          }
          reader.readAsDataURL(files[0])
       }
    },
     computed: {
    getPreview () {
      return this.editNews.content.replaceAll('&lt;', '<').replaceAll('&gt;','>')
    },
    baseURL () {
      return baseURL
    }
  }


}

</script>

<style lang="scss" scoped>
.edit-news-wrapper {
   padding-bottom: 100px;
  .showeMore {
    margin: 35px auto;
    // font-family: Gotham Pro;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    width:fit-content;
    display: flex;
    align-items: center;
    text-align: center;
    color: #4C4C52;
    border: 1px solid #C3C6D0;
    box-sizing: border-box;
    border-radius: 52px;
    padding: 14px 30px;
    cursor:pointer;

  }
  .edit {
    padding-bottom: 100px;
    .input-wrapper {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .title {
        margin-bottom: 5px;
      }
        .currentImg {
          // display: flex;
          // flex-direction: row;
          // align-items: center;
          width: 280px;
          height: 215px;
          overflow: hidden;
          
          .current-image {
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
            height: 215px;
            width: auto;
          }
      }
      .remove {
            cursor: pointer;
            margin-left: 20px;
            color:$main-color;
          }
    }
    .err{
      // border: 1px solid  $main-color;
      border: 1px solid $err-color;
    }
    .errTxt {
          font-weight: 500;
          font-size: 14px;
          text-align: center;
          // color: $main-color;
          color: $err-color;
          margin-top: 40px;
          // font-family: 'Gotham Pro Medium'
    }
    .flexButton{
      display: flex;
   .buttonCreate,
    .buttonReset{
          height: 50px;
          width: 170px;
          line-height: 52px;
          color: white;
          // font-family: 'Gotham Pro Medium';
          text-align: center;
          background: $main-color;
          border-radius: 52px;
          cursor: pointer;
          transition-duration: .3s;
          margin-bottom: 40px;
        p {
          margin: auto;

        }
      }
      .buttonReset {
        background: white;
        border: 1px solid $main-color;
        color: $main-color;
        margin-right: 20px;
      }
      .buttonCreate:hover{
        background: $btn-hover;
      }
      .buttonReset:hover{
        border: 1px solid rgb(206, 15, 2);;
        color: rgb(206, 15, 2);
      }
    }
  }
}
.return {
    margin-bottom: 30px;
    margin-left: auto;
    cursor: pointer;
    width: 50px;
  }
</style>
