<template>
   <div class="imageFrame">
        <Cropper v-if="showCrop" :src="imageForCropp" :extension="extension"  @cropp="croppImage" @giveup="giveup"/>
            <div class="wrapImg">
                <!--img :src="require('@/assets/images/profile/profiImg.png')" / -->
                <img v-if="imgShow.length > 0" :src="imgShow" />
                <img v-else-if="getuserData && getuserData.image && showImage" :src="getMainImage()" />
                <img v-else :src="require('@/assets/images/profile/profileReplace.svg')" />
                <div class="imageFilter"></div>
                <div class="divImg">
                    <!--img :src="require('@/assets/images/profile/profiImg.png')" /-->
                    <img v-if="imgShow.length > 0" :src="imgShow" />
                    <img v-else-if="getuserData && getuserData.image && showImage" :src="getMainImage()" />
                    <img v-else :src="require('@/assets/images/profile/profileReplace.svg')" />
                </div>
            </div>
            <div class="wrapTxt">
                <input style="display: none;" ref="main_image" id="main_image" accept=".png, .jpg, .jpeg" type="file" @change="setMainImage($event.target.files)">
                <label for="main_image"> Upload new avatar</label>
                <span @click="deleteImage" @mouseover="hoverTrash=true" @mouseleave="hoverTrash=false">
                    <img v-if="!hoverTrash" :src="require('@/assets/images/profile/trash.svg')" />
                    <img v-else :src="require('@/assets/images/profile/trashHover.svg')" />
                </span>
            </div>
        </div>
</template>

<script>
import Cropper from  './cropper.vue'
import helper from '@/js/helperImg'
import { api, baseURL } from '@/api'
import { mapGetters } from 'vuex'
export default {
    components: {
        Cropper
    },
    props: ['oldImg'],
    data () {
        return {
            showCrop: null,
            imageForCropp: null,
            temporarilyPicName:'',
            mainImageUrl: [],
            mainImage:[],
            imgShow:'',
            mainSend: [],
            showImage:true,
            extension:'',
            hoverTrash: false
        }
    },
    methods: {
        croppImage (e) {
        this.imgShow = e
        this.mainSend.push(helper.base64toFile(e, this.temporarilyPicName))
        this.changeImg(this.mainSend[0])
        //  this.$emit('changedata', obj )
        // this.mainImageUrl.push({ name: this.temporarilyPicName, src: e })
        this.showCrop = false
        this.imageForCropp = null
        this.$refs.main_image.value = null
        this.temporarilyPicName = ''

        },
        giveup () {
            this.showCrop = false // mainP
            this.pic = false
            this.imageForCropp = null
            this.$refs.main_image.value = null
            this.temporarilyPicName = ''
        },
        setMainImage (files) {
      if (files.length > 0) {
        // if (files[0].size > 409600) {
        //   this.error = 'imageSizeError'
        //   this.$refs.main_image.value = null
        //   return
        // }
        
        let reader = new FileReader()
            reader.onload = (e) => {
                this.imageForCropp = e.target.result
                this.temporarilyPicName = Math.random() + files[0].name
                this.extension=files[0].type

                if (this.imageForCropp !== null) {
                    this.showCrop = true // this.mainP = true
                }
            }
        reader.readAsDataURL(files[0])
        // this.changeImg(files[0])
      }
    },
     stopScroll(e) {
      let wheelOpt = this.supportsPassive ? { passive: false } : false;
      let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
      if(e){
        // call this to Disable
        window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
        window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
        window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
        window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
        window.addEventListener("mousedown", this.preventDefault, { passive:false });
        document.body.style.overflow = "hidden"
      }else{
        window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
        window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
        window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
        window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
        window.addEventListener("mousedown", this.preventDefault, { passive:false });
        document.body.style.overflow = "initial"
      }
    },
    preventDefault(e) {
        console.log(e)
      e.preventDefault()
    },
    preventDefaultForScrollKeys(e) {
      var keys = {37: 1, 38: 1, 39: 1, 40: 1}
      if (keys[e.keyCode]) {
           this.preventDefault(e)
           return false
      }
    },
    changeImg (e) {
        console.log('change data')
        console.log(e)
            let params = new FormData()
            params.append( 'sid' , localStorage.getItem('sid'))
            params.append('image', e)
            api.editUserData(params).then(res => {
                this.getUserData()
                console.log(res)
                // this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
                console.log(e)
            })

        },
        deleteImage () {
            let params = new FormData()
            params.append( 'sid' , localStorage.getItem('sid'))
            params.append('image', null)
            params.append('delete_image',true)
            api.editUserData(params).then(res => {
                this.getUserData()
                // this.$store.commit('setuserData', res.data.data)
                console.log(res)
            }).catch(e => {
                console.log(e)
            })
        },
         getMainImage () {
             return baseURL+this.getuserData.image
        },
         getUserData() {
            let params = {
                sid : localStorage.getItem('sid')
            }
            this.imgShow=''
             this.$refs.main_image.value = null
            api.getUserData(params).then(res => {
                // this.profileInfo = res.data.data
                console.log(res.data.data)
                // this.imgShow=''
                this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
             console.log(e)
            })
        },
    },
    watch: {
        showCrop:{
            handler(newValue) {
                console.log(newValue)
                // this.stopScroll(newValue)
            
            }
            // deep: true,
            // immediate: true
        },
          getuserData:{
            handler(){
                this.showImage= false
                setTimeout(() => {
                  this.showImage= true
            }, 200);
              
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
    ...mapGetters(['getLoged','getuserData'])
    }

}
</script>

<style lang="scss" scoped>
  .imageFrame{
    z-index:1000;
    position: relative;
    height:216px;
    width: 216px;
    // background-color: rgb(5, 5, 5);
    // border:1px solid #000000;
    // background: linear-gradient(150.58deg, #000000 1.1%, rgba(0, 0, 0, 0) 42.66%);
    // padding-left: 12px;
    .wrapImg{
        height: 188px;
        width: 188px;
        border-radius: 2.69213px;
        overflow: hidden;
        // background: #C4C4C4;
        position: relative;
        background: #CFD8DB;
        img{
            height: 100%;
            width: 100%;
        }
        .imageFilter{
            position: absolute;
            top: 0;
            left: 0;
            height: 188px;
            width: 188px;
            // background: #c4c4c488;
            // background: #ffffffb6;
            background: #fff;
            opacity: 0.7;
            overflow: hidden;

        }
        .divImg{

            position: absolute;
            top:0;
            left: 0;
            box-sizing: border-box;
            height: 188px;
            width: 188px;
            border-radius:50%;
            overflow: hidden;
            img{
                height: 100%;
                width: 100%;
            }
            
        }
    }
    .wrapTxt{
        // font-family: Gotham Pro;
        // font-style: normal;
        // font-weight: 500;
        // font-size: 12px;
        // line-height: 20px;
        /* identical to box height, or 167% */

        // display: flex;
        // align-items: center;
        // // justify-content: space-between;
        // letter-spacing: 0.02em;
        // // text-transform: uppercase;

        // /* Gray turquoise - */

        // color: #9CB2B5;
        // padding-top: 8px;
        // font-style: normal;

        padding-top: 7px;
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        /* identical to box height, or 133% */

        display: flex;
        align-items: center;
        letter-spacing: 0.01em;

        /* Gray turquoise - */

        color: #9CB2B5;

        
        label, span{
            cursor: pointer;
        }
        label:first-of-type{
            // margin-right: 55px;
            // margin-right: 37px;
            margin-right: 35px;
        }
        label:hover{
            color:  #5A7E87;
        }

    }

}

@media only screen and (max-width: 750px){
    .imageFrame {
        padding-left: 0;
        .wrapImg{
            height: 200px;
            width: 200px;

            .imageFilter{
                position: absolute;
                top: 0;
                left: 0;
                height: 200px;
                width: 200px;
                // background: #c4c4c488;

            }
            .divImg{

                position: absolute;
                top:0;
                left: 0;
                box-sizing: border-box;
                height: 200px;
                width: 200px;
            }
        }
    }
}

</style>