<template>
  <div class="user-menu-wrapper" @click="close">
    <div class="menu-wrapper" :style="getHeight">
      <div class="text-wrapper">
        <div class="title">ACCOUNT</div>
        <div v-if="!getLoged" class="section">
          <div class="section-item" @click="reroute(`login`)">Log in</div>
          <div class="section-item" @click="reroute(`signin`)">Sign up</div>
        </div>
        <div v-else class="section">
          <div class="section-item" @click="reroute(`profile`)">Personal information</div>
          <div class="section-item" @click="reroute(`reports`)">My reports</div>
          <div class="section-item" @click="reroute(`fav`)">Favourites</div>
          <div class="section-item" @click="logout">Log out</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { api } from "@/api";

export default {
  name: "userMenu",
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters(["getLoged"]),
    getHeight () {
      if (this.getLoged) {
        return {
          height: "317px"
        }
      } else {
        return {
          height: "195px"
        }
      }
    },
  },
  methods: {
    close() {
      this.$emit("close")
    },
    logout () {
      api.logOutUser().then(() => {
        localStorage.removeItem("sid");
        localStorage.removeItem("userName");
        this.$store.commit("setuserData", { name: "", email: "", img: "" });
        this.$store.commit("changeLoged", false);
        this.$store.commit("setUserName", null);
        this.$store.commit("setUserPrivilege", 0);
        this.$router.push({ name: "Home" });
      }).catch(() => {
        localStorage.removeItem("sid");
        localStorage.removeItem("userName");
        this.$store.commit("setuserData", { name: "", email: "", img: "" });
        this.$store.commit("changeLoged", false);
        this.$store.commit("setUserName", null);
        this.$store.commit("setUserPrivilege", 0);
      });
    },
    reroute (param) {
      switch (param) {
        case "login":
          this.$router.push("/login");
          break;
        case "signin":
          this.$router.push("/signin");
          break;
        case "profile":
          this.$router.push("/profile");
          break;
        case "reports":
          this.$router.push("/valuations");
          break;
        case "fav":
          this.$router.push('/favourites');
          break;

        default:
          console.log("Invalid path");
          break;
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.user-menu-wrapper {
  width: 100%;
  height: 100vh;
  z-index: 999;
  background-color: rgba(6, 41, 49, 0.9);

  position: absolute;
  box-sizing: border-box;
  bottom: 60px;
  left: 0;
  display: flex;
  align-items: flex-end;
  .menu-wrapper {
    width: 100%;
    background-color: #fff;
    border-radius: 11px 11px 0 0;

    padding-top: 27px;

    .text-wrapper {
      width: 100%;
      .title {
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 0.05em;
        color: #467F8F;

        padding-left: 30px;
        height: 60px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid #EEEFF3;
      }
      .section {
        width: 100%;
        .section-item {
          padding-left: 30px;
          height: 60px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #EEEFF3;
        }
        > *:last-child {
          padding-bottom: 12px;
        }
      }
    }
  }
}
</style>