<template>
  <div class="marketValue">
      <p v-if="false"  v-html="val" class="value"></p>
      <p v-if="blur" class="value" v-html="(i==1) ? '$' : '%'"></p>
      <animateNumber  class="value" v-else-if="val" :animlength="1000" :end="val" :start="0" :value="(i==1) ? '$' : '%'" />
      <p v-else class="pLineMV"></p>
      <p class="name">{{name}}</p>
  </div>
</template>

<script>
import animateNumber from '../animateNumber.vue'
export default {

    props: ['val', 'name', 'i', 'blur'],
     components:{
       animateNumber
    },
    data (){
        return{
            intersectionOptions: {
                    root: null,
                    rootMargin: '0px 0px 0px 0px',
                    threshold: [0.5, 0.5]  // [0.25, 0.75] if you want a 25% offset!
                },
                activeWayPoint: true,
                num:null
        }
    },
    mounted () {
       // console.log(this.val)
    }

}
</script>

<style lang="scss" scoped>
.marketValue{
    width:49%;
    height: 40%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -1px;
    margin-bottom: 9px;
    .value{
        font-style: normal;
        font-weight: 500;
        font-size: 26px;
        line-height: 35px;
        text-align: center;
        color: #062931;

    }
    .name{
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        color: #76898E;
        margin: 5px ;
        margin-top: 1px;
        margin-bottom: 14px;
    }
    .valueLine{
        width: 27px;
        height: 35px;
        font-family: Gotham Pro;
        font-style: normal;
        font-weight: bold;
        font-size: 30px;
        line-height: 35px;
        text-align: center;

        color: #062931;
    }
     .pLineMV{
            width: 27px;
            height:3px;
            font-family: Gotham Pro;
            // font-family: 'Gotham Pro Bold';
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 35px;
            /* identical to box height, or 117% */

            text-align: center;
            margin:17px;

            /* black */

            background-color: #062931;
        }
}

@media only screen and (max-width: 1024px){
  .marketValue {
    
  }
}
@media only screen and (max-width: 550px){
  .marketValue {
      height: 60px;
      .value{
        font-size: 25px;
        line-height: 30px;
      }
      .name{
        font-size: 15px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
       
    
  }
  .pLineMV{
            width: 27px;
            height:3px !important;
            /* black */
            margin:10px !important;
            background-color: #062931;
        }
}

</style>