<template>
  <div class="priceSteps">
      <wrappBadges v-if="!showMobileNav" :activeBadge='activeBadge' @changeactivebadge='activeBadgeCh' @nextbadge="setUpActiveBadge(), centerTitle()" @arrowopen="setArrowOpen" />
     
      <div :class="{ disappear : disappear }" class="center-wrapper">
        <div v-if="!arrOpen" class="writeSteps">Step {{indexBedge + " / "+ numBaige}}</div>
        <h1 v-if="!arrOpen">{{centerTitle()}}</h1>
        <div class="anchor"></div>
        <div :class="{ missing : odometerMissing }" v-if="allSelected && activeBadge === 'odometer'" class="inputs-wrapper" :style="calcLastStep">
           
           <input
            type="text"
            
            inputmode="numeric"
            pattern="[0-9\s]*" 
            oninput="this.value = this.value.replace(/[^0-9]/g, '');"

            placeholder="km"
            v-model="odometer"
            
             @input="fixOdometer"
            v-on:keyup.enter=" getEstimate()"
            v-on:keydown ="setR "
            @focus="odometerMissing = false "
            v-hideMoblieNav
            maxlength="9"
            ref="inputOdometer"
            >
           <div @click="getEstimate()" class="btn round">
             <span>See estimate</span>
           </div>
        </div>
        <div :class="{ missing : odometerMissing }" v-else class="inputs-wrapper">
          <div class="left-wrapper">
            <div class="search-ico">
              <img :src="require('@/assets/images/icons/zoom.svg')" alt="">
            </div>
            <home-dropdown 
              @setOption="setUpActiveBadge(), centerTitle()" 
              @backClick="navigateBack()" 
              :backText="getBackText" 
              :fixed="true" 
              @select="setUpActiveBadge(), centerTitle()" 
              @focused="odometerMissing = false, scroll()" 
              :type="getActiveBadge" 
              :selected="getSelected" 
              :options="getOptions"
              @closedrop="closedrop"
              @isTyping='checkTyping'
            />
          </div>
            <!--  @closedrop="closedrop" -->
           <!-- <div @click="setUpActiveBadge(), centerTitle()" class="btn round">
             <span>Next step</span>
           </div> -->
           <div v-if="isSearching" class="clear" @click="clearInput">
             <img :src="require('@/assets/images/icons/close.svg')" alt="">
           </div>
        </div>
        <!--  v-if="activeBadge !== 'make'" -->
        <span @click="navigateBack()" class="return">
          <img :src="require('@/assets/images/arrowLeft.png')" alt="">
          <span v-if="!showMobileNav">
            Return to Step {{this.getOrder.indexOf(this.activeBadge)}}
          </span>
          <span v-else>
            Back
          </span>
        </span>
        <span v-if="odometerMissing" class="error">
          Please enter a value
        </span>
      </div>
      <wrappBadges v-show="showMobileNav && closeDrop" :activeBadge='activeBadge' @changeactivebadge='activeBadgeCh' @nextbadge="setUpActiveBadge(), centerTitle()" />
  </div>
</template>

<script>
import eventBus from '@/js/eventBus.js'
import HomeDropdown from '@/components/newPriceStepComponents/homeDropdown.vue'
import wrappBadges from '../components/newPriceStepComponents/wrappBadges.vue'
import { mapGetters } from 'vuex'
export default {
    name: 'priceSteps',
    components: {
        wrappBadges,
        HomeDropdown
    },
    metaInfo () {
        return {
        title: 'Carvisor',
        titleTemplate: '%s - ' + this.title ,
        }
    },
    data () {
      return {
        title: 'Price steps',
        activeBadge: 'make',
        odometer: null,
        odometerMissing: false,
        disappear: false,
        showMobileNav:false,
        closeDrop:true,
        ss: 0,
        se: 0,
        oldOdometer: null,
        setR_methodRun: false,
        numBaige: 0,
        indexBedge:1,
        arrOpen: false,
        isSearching: false
      }
    },
    created(){
      
    },
    mounted () {
      window.addEventListener("resize", this.resize)
      this.resize()
     
      // this.setUpActiveBadge()
      // eventBus.$emit('closedrop', true)
       this.numBaige = this.getOrder.length
       eventBus.$on('closedrop', (e) => {
          this.closeDrop=e
        })

      this.lastActiveBadge()
      this.$analytics.fbq.event('PageView', {
        content_name: 'Price step'
      })

     if (this.getAllOptions.make === null) {
        this.$store.dispatch('getCars')
      }
      eventBus.$on('setOption', () => {
        this.setUpActiveBadge()
        this.centerTitle()
        this.odometerMissing = false
      })

    },
    destroyed () {
      eventBus.$off('closedrop')
      eventBus.$off('setOption')
      window.removeEventListener("resize", this.resize);
    },
    methods: {
      scrollToTop () {
        window.scrollTo({ top: 0, behavior: 'smooth' })
        let el = document.querySelector('.scrollbar')
        if (el) {
          el.scrollTop = 0
        }
      },
      scroll () {
        setTimeout(function () {
          let el = document.querySelector('.anchor')
          if (el) {
            el.scrollIntoView({behavior: "smooth", block: "start", inline: "nearest"})
          }
        }, 300);
      },
      async getEstimate () {
        if (this.odometer === null || this.odometer === '') {
          this.odometerMissing = true
          return
        }
        await this.$recaptchaLoaded()
        const token = await this.$recaptcha('estimate').catch(() => {})
        
        await this.$store.dispatch('getEstimate', token)
        this.$store.dispatch('setOpinionAction',false)
      },
      setR(){
       this.ss=this.$refs.inputOdometer.selectionStart
       this.se=this.$refs.inputOdometer.selectionEnd
        console.log('setR '+this.ss)
        this.setR_methodRun= true
      },
      fixOdometer (e) {
        // if(!this.setR_methodRun){
        //  this.ss=this.$refs.inputOdometer.selectionStart
        // } 
        // this.se=this.$refs.inputOdometer.selectionEnd

        // setTimeout(() => {
          
        // }, 1000);
 // console.log('------------\nfixOdometer\nss: ' + this.ss + ', se: ' + this.se)
        if (e.target.value) {
          //  const valueAfter = parseInt(e.target.value).toLocaleString('en').split(',').join(' ')
         
         // Insert whitespace after every 3 character 
          const valueAfter = e.target.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");


         this.odometer = valueAfter
          console.log(this.oldOdometer +' - ' + this.odometer)
          
          console.log(this.odometer)

          const lengthBefore = this.oldOdometer? this.oldOdometer.length: 0
          const lengthAfter = this.odometer.length

          let cursorPositionChange = 0;
          console.log('lengthAfter - '+lengthAfter + ' - '+lengthBefore)
          if((this.se > this.ss  ) && (lengthAfter == 1)){cursorPositionChange = 1}
          else if((this.se > this.ss  ) && (lengthAfter > 1)){cursorPositionChange = 0}
          else if ((lengthAfter === 1 && lengthBefore == 1) /*|| (lengthBefore > lengthAfter )*/ ){cursorPositionChange = 1}
          
          else if (lengthAfter - lengthBefore == 2) { cursorPositionChange = 2}
          else if (lengthAfter - lengthBefore == 1) { cursorPositionChange = 1}
          else if (lengthAfter - lengthBefore == -2) {cursorPositionChange = -2}
          else if (lengthAfter - lengthBefore == -1) {cursorPositionChange = -1}
          // else {cursorPositionChange = lengthAfter - lengthBefore}

// console.log("cpc: " + cursorPositionChange)

          this.oldOdometer = this.odometer
          setTimeout(() => {
            const newCursorPosition = this.ss + cursorPositionChange === -1 ? 0 : this.ss + cursorPositionChange
// console.log("ss: " + this.ss + ", ncp: " + newCursorPosition)

            this.$refs.inputOdometer.setSelectionRange( newCursorPosition, newCursorPosition)

            //  if (this.ss > 3){
            //    this.$refs.inputOdometer.setSelectionRange( newCursorPosition, 0, "backward")
            // }
            // else{
            //     this.$refs.inputOdometer.setSelectionRange( newCursorPosition, 0, "forward")
            // } 



          }, 1);
          //  console.log('------------')
        }
      },
      navigateBack () {
        if (this.getOrder.indexOf(this.activeBadge)-1 >= 0) {
          this.activeBadge = this.getOrder[this.getOrder.indexOf(this.activeBadge)-1]
        } else {
          this.$router.push('/')
        }
      },
      setUpActiveBadge () {
        if (!this.getSelected) {
          this.odometerMissing = true
        } else {
          this.lastActiveBadge()
        }
        // if (this.getOrder[this.getOrder.indexOf(this.activeBadge) + 1]) {
        //   if (this.getAllOptions[this.getOrder[this.getOrder.indexOf(this.activeBadge) + 1]]) {
        //     this.activeBadge = this.getOrder[this.getOrder.indexOf(this.activeBadge) + 1]
        //   } else {
        //     if (this.getAllSelected['engine'] && this.activeBadge === 'engine') {
        //       this.activeBadge = 'odometer'
        //     }
        //   }
        // } else if (this.getAllSelected['engine'] && this.activeBadge === 'engine') {
        //   this.activeBadge = 'odometer'
        // }
        // for (var i = 0; i < this.getOrder.length; i++) {
        //   if (this.getAllOptions[this.getOrder[i]]) {
        //     this.activeBadge = this.getOrder[i]
        //   }
        //   if (this.getAllSelected['engine']) {
        //     this.activeBadge = 'odometer'
        //   }
        // }
      },
      activeBadgeCh (e) {
        this.activeBadge = e
      },
      lastActiveBadge () {
        this.disappear = true
        setTimeout(() => {
          this.scrollToTop()
          this.disappear = false
          for (var i = 0; i < this.getOrder.length; i++) {
            if (this.getAllOptions[this.getOrder[i]] && this.getAllSelected[this.getOrder[i]] === null) {
              this.activeBadge = this.getOrder[i]
              return
            }
            if (this.getAllSelected['engine']) {
              this.activeBadge = 'odometer'
            }
            if (this.getAllSelected['odometer']) {
              this.odometer = this.getAllSelected['odometer'].value.toLocaleString('en').split(',').join(' ')
            }
          }
        }, 300);
      },
      centerTitle () {
        if (this.activeBadge === 'make') {
          return 'Select a make'
        } else if (this.activeBadge === 'model') {
          return `Select a model`
        } else if (this.activeBadge === 'year') {
          return `Select a year`
        } else if (this.activeBadge === 'body') {
          return `Select body type`
        } else if (this.activeBadge === 'fuel') {
          return `Fuel type?`
        } else if (this.activeBadge === 'transmission') {
          return `Transmission type?`
        } else if (this.activeBadge === 'engine') {
          return `Engine size?`
        } else if (this.activeBadge === 'odometer') {
          return `Enter mileage`
        }
      },
       resize () {
        if (window.innerWidth <= 500) {
            this.showMobileNav = true
        } else {
            this.showMobileNav = false
        } 

        // this.showMobileNav = window.innerWidth <= 500
      },closedrop (e) {
        console.log('closedrop')
        console.log(e)
        this.closeDrop = e
        eventBus.$emit('closedrop', e)

      },
      setArrowOpen (e) {
        // console.log(e)
        this.arrOpen=e
      },
      clearInput () {
        eventBus.$emit("clearInput")
      },
      checkTyping (e) {
        this.isSearching = e
      }
    },
    computed: {
      ...mapGetters(['getAllSelected', 'getAllOptions', 'getOrder', 'getPageBefore']),
      getSelected () {
        return this.getAllSelected[this.activeBadge]
      },
      getOptions () {
        return this.getAllOptions[this.activeBadge]
      },
      getActiveBadge () {
        return this.activeBadge
      },
      allSelected () {
        let res = true
        for (var i = 0; i < this.getOrder.length - 1; i++) {
          if (!this.getAllSelected[this.getOrder[i]]) {
            res = false
          }
        }
        return res
      },
      getBackText () {
        if (this.activeBadge === 'make') {
          return 'Back'
        }
        return `Return to Step ${this.getOrder.indexOf(this.activeBadge)}`
      },
      isLastStep () {
        return this.getOrder.indexOf(this.activeBadge) !== 7;
      },
      calcLastStep () {
        if (this.activeBadge === 'odometer') {
          return {
            paddingLeft: '0px',
            paddingRight: '5px'
          }
        } else {
          return {}
        }
      }
    },
    watch: {
      odometer: {
        handler () {
          if (this.odometer !== null) {
            this.$store.commit('setOdometer', parseInt(String(this.odometer).split(' ').join('')))
            this.odometerMissing = false
          } else {
            this.$store.commit('setOdometer', null)
          }
        }
      },
      activeBadge: {
        handler (newVal) {
          console.log(newVal)
          this.indexBedge = this.getOrder.indexOf(newVal.toLowerCase()) + 1
          this.scrollToTop()
          this.odometerMissing = false
          if (this.odometer) {
            this.$store.commit('setOdometer', parseInt(String(this.odometer).split(' ').join('')))
          } else {
            this.$store.commit('setOdometer', null)
          }
        }
      }
    }
}
</script>

<style lang="scss" scoped>
.priceSteps{
    width:100%;
    min-height: calc(100vh - 80px);
    box-sizing: border-box;
    padding: 0;
    margin: 0;
   // position: relative;

    .footerAbso{
       // position: absolute;
       // bottom:0;
       // left:0;
    }
    .center-wrapper.disappear {
      opacity: 0;
      transition-duration: .3s;
    }
    .center-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      // justify-content: center;
      min-height: calc(100vh - 160px);
      transition-duration: .3s;
      opacity: 1;
      .writeSteps{
        font-style: normal;
        font-weight: 400;
        font-size: 15px;
        line-height: 25px;
        color: #76898E;
        margin-top:69px;
      }
      h1 {
        margin-top: 35px;
        margin-bottom: calc(52px - 15px); // 15 margin on inputs-wrapper
        font-weight: bold;
        font-size: 36px;
        line-height: 40px;
        text-align: center;
        color: #062931;

        padding: 0 20px;
      }
      
      @media only screen and (max-width: 450px){
        h1 {
          margin-top: 15px !important;
          margin-bottom: calc(42px - 15px);
        }
      }
      .anchor {
        // margin-bottom: 15px;
      }
      .inputs-wrapper.missing {
        // border: 1px solid $main-color;
        border: 1px solid $err-color;
      }
      .inputs-wrapper {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        height: 60px;
        // width: 355px;
        width: 418px;
        border-radius: 52px;
        box-sizing: border-box;
        margin-bottom: 25px;
        padding: 0px 20px;
        margin-top: 15px;
        // background: #F8F8F9;
        // border: 1px solid #DEE0E7;
        background: #F8F8F9;
        /* 1px line Gray light (4) */

        // border: 1px solid #DEE0E5;
        .left-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;

          .search-ico {
            margin-right: 15px;
            margin-bottom: -5px;
            img {
              height: 16px;
              width: 16px;
            }
          }
        }

        .clear {
          &:hover {
            cursor: pointer;
          }
          img {
            height: 10px;
            width: 10px;
          }
        }
        .btn.round {
          background-color: $main-color;
          height: 50px;
          // width: 140px;
          // padding:0 30px !important;ž
          width: 140px;
          color: white;
          border-radius: 52px;
          text-align: center;
          cursor: pointer;
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;

          display: flex;
          align-items: center;
          text-align: center;
          letter-spacing: 0.01em;
          
          /* identical to box height, or 133% */
          transition-duration: .3s;
          span{
            display:inline-block;
            margin:auto;
          }
        }
        .btn.round:hover {
          background-color: $btn-hover;
        }
        input {
          max-width: 147px;
          border: none;
          margin: 0;
          padding: 0;
          outline: none;
          height: 38px;
          padding-right: 10px;
          background: #F8F8F9;
          margin-left: 20px;
          color: #062931;
          padding-top: 3px;
          font-size: 16px;
          line-height: 24px;
        }
        input::placeholder {
          opacity: 1;
          // color: #9498A0;
          color: #9498A0;
        }
      }
      @media only screen and (max-width: 450px){
        .inputs-wrapper {
          width: 280px;

          input {
            max-width: 90px !important;
          }
          .home-dropdown-wrapper {
            max-width: 120px !important;
          }
        }
      }
      .return {
        font-size: 13px;
        line-height: 28px;
        color: $text-lighter;
        // font-family: 'Gotham Pro Medium';
        font-weight: 500;
        cursor: pointer;
        // display: flex;
        // align-items: center;
      }
      .error {
        font-weight: 500;
        font-size: 14px;
        text-align: center;
        // color: #FF5654;
        color: $err-color;
        margin-top: 40px;
        // font-family: 'Gotham Pro Medium'
        font-weight: 500;
      }
      @media only screen and (max-width: 768px){
        .inputs-wrapper {
          width: 400px;
          .btn.round {
            height: 46px;
          }
        }
      }
    }
  // .dont {
  //   display: none;
  // }
}
@media only screen and (max-width: 500px) {
  .priceSteps{
    // padding-bottom: 60px;
    // // min-height: calc(100vh - 140px);
    // min-height: calc(100vh - 140px);
    // max-height: calc(100vh - 140px);
    // // min-height: calc(100% - 140px);
    .center-wrapper {
      .inputs-wrapper {
        width: 315px;
        height: 50px;
        padding: 0 20px 0 15px;

        .left-wrapper {
          .search-ico {
            margin-right: 12px;
          }
        }
      }
      .return {
        position: absolute;
        top: 20px;
        left: 16px;
        // top: 17px;
        // left: 15px;

        font-weight: 500;
        font-size: 15px;
        line-height: 14px;
        letter-spacing: 0.01em;

        // color: rgba(70, 127, 143, 0.8);
      }
    }
  }
  
}

</style>
<style lang="scss">
@media only screen and (max-width: 500px) {
 .priceSteps{
   .wrappBadges{
     position: fixed;
     left:0;
     bottom:61px !important;
     z-index: 2;
   }

 }
 @supports (-webkit-overflow-scrolling: touch) { 
    .priceSteps {
        // padding-bottom:70px
        // height: 60px; standard 
       .wrappBadgesIOS{
          // position: fixed;
          // left:0;
          // bottom:90px;
          // bottom:61px;
          // z-index: 2;
        } 
    }
}
}


</style>
