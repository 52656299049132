<template>
  <div class="reportWrap">
      <div class="wrapp">
        <div class="contentReport">
            <h1>Our report includes</h1>
            <expVideoTxt v-for="txt in arr" :key="txt" :txt="txt" />
            <router-link class="button" to="sampleReport" target="_blank"><p>Sample report</p></router-link>
        </div>
        <div class="contentReport white">
            <img :src="require('../../assets/images/Image.svg')" />
        </div>
      </div>
  </div>
</template>

<script>
import expVideoTxt from '../wrappVideo/expVideoTxt.vue'
export default {
    name: 'reportWrap',
    components: {
        expVideoTxt
    },
    data () {
        return{
        arr: [
                'Price estimates',
                'Model attractiveness',
                'Great price indicator',
                'Estimated time to sell',
                'Market breakdowns by advertised price, region, seller type,<br /> travelled kilometers '
            ]
      }
    },
     methods: {
         goToSampleReport () {
                // this.$router.push('/sampleReport')
                 let routeData = this.$router.resolve({name: 'sampleReport'});
                 window.open(routeData.href, '_blank');
         }
     }

}
</script>

<style lang="scss" scoped>
.reportWrap {
    width:100% !important;
    box-sizing: border-box;
    background: rgb(255, 255, 255);
    display:flex;
    justify-content: center;
    // padding: 0 30px;
    //max-width: 1275px;
    margin: 0 auto;
    min-height: 557px;
    align-items: initial;
    .wrapp{
        box-sizing: border-box;
        background: rgb(255, 255, 255);
        display:flex;
        justify-content: center;
        padding: 0 30px;
        padding-top:24px;
        max-width: 1275px;
        margin: 0 auto;
        min-height: 555px;
        align-items: initial;
    }
    .contentReport {
        box-sizing:border-box;
        margin:33px 15px 10px 0;
        // flex-grow: 1;
         h1{
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            /* or 111% */


            /* black */

            color: #062931;
        }
        .button{
           // font-family:'Gotham Pro Medium';
            margin-top: 50px;
            width: 168px;
            height: 52px;
            /* Text primary darker */
            // color: #4C4C52;
            // border: 1px solid #4C4C52;
            /* rgb(6, 41, 49) is the same as #062931; */
            border: 1px solid rgb(6, 41, 49, .8);
            color: #062931;
            // outline: 1px solid #062931;
            // opacity: 0.8;
            box-sizing: border-box;
            border-radius: 52px;
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;
            font-style: normal;
            font-weight: 500;
            // font-size: 14px;
            font-size: 15px;
            //line-height: 20px;
            text-decoration: none;
        }
        .button:hover{
            cursor: pointer;
            // border: 1.8px solid #062931;
            border: 2px solid #062931;
            // border: 2px solid #062931;
            // color: #062931 !important;
            outline: 1.8px;
            // opacity: 1;
            // border-radius: 52px;
            // font-style: normal;
            // font-weight: 500;
            // font-size: 15px;
            // line-height: 24px;
           
        }
    }
    .white{
        box-sizing: border-box;
        margin:0;
       // background: #ffffff;
       // height: 468px;
       height: auto;
       width: 625px;
       // height:25vw;
        border-radius: 22px;
        img{
            width:100%;
        }
    }
}
 @media only screen and (max-width: 1100px){
     .reportWrap{
         display: flex;
        // flex-direction: column-reverse;
         width:100%;
         display: flex;
         justify-content: center;
         align-items: center;
         padding: inherit 0 !important;
         .wrapp{
              display: flex;
                flex-direction: column-reverse;
                width:100%;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: inherit 0 !important;
         }
         .contentReport {
            width:100%;
            //  padding:60px;
            padding-bottom: 90px;
            padding-left: 59px;
          }
          .white{
               box-sizing: border-box;
               width: 88vw;
               height: auto;
               margin: 0 auto;
               padding:0 !important;
               text-align:center;
                img{
                 max-width:95%;
            }
          }
     }
 }
  @media only screen and (max-width: 767px){
      .reportWrap{
            box-sizing: border-box;
            padding: 0px 0 !important;
           // padding-bottom:50px;
           .wrapp{
               box-sizing: border-box;
               padding: 9px 0 !important;
               padding-bottom: 30px !important ;
           }
          .contentReport{
            width:100%;
            padding:20px;
            margin-top: 0px;
            padding-top:0px;
          //  height: fit-content;
           h1{
            font-style: normal;
            font-weight: bold;
            font-size: 30px;
            line-height: 36px;
            font-size: 34px;
            line-height: 40px;
            /* identical to box height, or 120% */
            /* black */
            color: #132727;
            margin-top:0px;
            margin-bottom: 28px;
        }
        .button{
            margin-top: 43px;
            margin-left: 10px;
            box-sizing: border-box;
            border-radius: 52px;
            height: 48px;
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: 500;
            font-size: 14px;
            //line-height: 26px;

        }
        }
         .white{
               width: 88vw;
               height: auto;
               margin: 0 auto;
               padding:0 !important;
                img{
                 max-width:98%;
            }
          }
      }
  }
  @media only screen and (max-width: 600px){
    .reportWrap{
        .contentReport{
            .button{
                box-sizing: border-box;
                padding-top: 12px;
                margin-left: 10px;
                width: 140px;
                height: 45px;
                align-items: initial;
                p{
                    margin: 0px;
                }
            }
        }
    }
}


</style>
