<template>
  <div class="success">
      <div class="title">
          Success!
      </div>
      <div class="txt">
          You will receive an email with instructions<br> on how to reset your password.
      </div>
  </div>
</template>

<script>
export default {
    name: 'success'

}
</script>

<style lang="scss" scoped>
.success{
     width: 406px;
    .title{
        // font-family: Gotham Pro;
        // font-family: 'Gotham Pro Medium';
        // font-style: normal;
        // font-weight: 500;
        // font-size: 28px;
        // line-height: 40px;
        // /* identical to box height, or 143% */

        // /* black */
        font-weight: bold;
        font-size: 36px;
        line-height: 40px;
        /* identical to box height, or 111% */

        // display: flex;
        // align-items: center;
        text-align: center;

        color: #062931;
        margin-bottom:44px;
        margin-bottom: 34px;

    }
    .txt{
       
        // font-family: Gotham Pro;
        // font-style: normal;
        // font-weight: normal;
        // font-size: 16px;
        // line-height: 24px;
        // text-align: center;
        // color: #062931;
        font-weight: normal;
        font-size: 17px;
        line-height: 25px;
        /* or 147% */

        text-align: center;

        /* black */

        color: #062931;

    }

}
@media only screen and (max-width: 500px){
    .success{
         max-width: 335px;
         margin:0 20px;
        .title{
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 39px;
        }
        .txt{
            font-size: 15px;
            line-height: 24px;
        }

    }

}
</style>