<template>
	<span v-html="getNumber(currentValue, value)" v-waypoint="{ active: activeWayPoint, callback: onWaypoint, options: intersectionOptions }"></span>
</template>

<script>
export default {
    props: //['start','end','animlength','value', 'expMarket1'],
     {
        start:{
            required: false
        },
        end:{
            required: false
        },
        animlength:{
            required: false
        },
        value:{
            required: false
        },
        //for sample Report
       expMarket1:{
            required: false,
        },
        line:{
           required: false, 
        }
    },
    data: function(){
        return {
            currentValue: 0,
            totalSteps: 1,
            STEP_TIME: 40,
            incrementStep: 1,
            currentStep: 0,
            intersectionOptions: {
                    root: null,
                    rootMargin: '0px 0px 0px 0px',
                    threshold: [0.5, 0.5] //[0.0, 0.5]  // [0.25, 0.75] if you want a 25% offset!
                },
            activeWayPoint: true,
        }
    },
    created() {
      window.addEventListener('beforeunload', this.handlerValue)
    },
    destroyed() {
      window.removeEventListener("beforeunload", this.handlerValue);
    },
    mounted() {
        this.currentValue = this.start;
        this.totalSteps = Math.ceil(this.animlength/this.STEP_TIME);
        this.incrementStep = Math.ceil((this.end-this.start)/this.totalSteps);
        // if (this.expMarket1 === true) this.animateStep()
        //this.animateStep();
        //console.log('end'+ this.end)
    },
    methods: {
        animateStep() {
            if(this.currentStep>=this.totalSteps) {
                this.currentValue = this.end;
                return;
            }
            this.currentValue += this.incrementStep;
            this.currentStep++;

            setTimeout(() => {
                this.animateStep();
            }, this.STEP_TIME);
        },
         getNumber (num,value) {
            // if( value ==='$') return '$&thinsp;'+ num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&thinsp;')
            
            // if( value ==='%') return  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&thinsp;') +'&thinsp;%'
            if( value ==='$') return '$&thinsp;'+ num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&thinsp;')
            
            if( value ==='%') return  num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&thinsp;') +'%'
           
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '&thinsp;')+'&thinsp;'
         },
         onWaypoint ({ going , _entry}) {
            //  console.log(el)
            // console.log(_entry.target.offsetParent.className === "expMarket" )
            // going: in, out
            // direction: top, right, bottom, left
            if ( _entry.target.offsetParent &&  _entry.target.offsetParent.className && _entry.target.offsetParent.className === "expMarket") {
                //console.log(_entry.target.offsetParent.className )
                this.animateStep()
                // this.activeWayPoint = true
            }
            
          if (going === this.$waypointMap.GOING_IN) {
                this.animateStep()
                this.activeWayPoint = false
                
               }
            
              /* else if (going === this.$waypointMap.GOING_OUT) {
                // this.animateStep();
                this.activeWayPoint = true 
               }
               */

        },
        handlerValue () {
            this.activeWayPoint = true
           // console.log('beforeunload')

        }
    },
     watch: {
        expMarket1: {
           deep: true,
           immediate: true,
           handler (newVal) {
           // console.log('expMarket1 in newVal animate num -----'+ newVal)
            
           if(newVal) {
                setTimeout(() => {
                    this.animateStep(); 
                   // console.log('izvrsi')
                
                }, 520)   
              
               }
          
           }
        } 
    }
}
</script>

<style lang="scss">

</style>