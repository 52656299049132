<template>
  <div class="shareFooter">
      <a name='scroll' id='scroll' ></a>
      <div class="shareHeaderTxt">
          <p class="shareHeader ">Tell a friend!</p>
          <p class="shareTxt">Share the valuation of this car with your friends.</p>
          <p class="link" @click="copyTestingCode"><a >{{ setTxtUrl(url1, currentUrl) }} </a></p>
          <a v-if="false" class="link">http://carvisor/estimation/estimate/BMW%23%.. </a>
      </div>
      <div class="shareIcon">
        <ShareNetwork
            :url=" obj.network==='email' ? 'Check this at: ' + url1 + currentUrl : url + currentUrl"
            :title='title'
            :description="'\n '+description +' \n \n'+ url + ' \n \n'"
            :quote="url"
            :hashtags="hashtags"
            v-for="(obj , index) in list"
            :key="index"
            :network="obj.network"
            class="spanImg"
            @open="scroll"
            
        > <a href='#scroll' > 
           <img class="aLink" :src="obj.img" alt="/">
           <p>{{obj.txt}}</p></a>
        </ShareNetwork>   

      </div>

  </div>
</template>

<script>
import facebookImg from '../../assets/images/Face.svg'
import twitterImg from '../../assets/images/twitter.svg'
import emailImg from '../../assets/images/email.svg'
export default {
    data () {
        return{
            title: 'Carvisor Price Evaluation for Year Make Model',
            description: "Our unique car valuation engine constantly analyzes the market, providing you with reliable price estimates and analytics, thereby creating a more efficient car-buying experience.",
            url: "https://carvisor.co.nz/",
            url1: "https://carvisor.co.nz",
            hashtags: "car,usedcar,usedcarforsale,carvaluation,carpricing,priceestimation,carvisorvaluation",
            list: [
                {network: 'facebook', img: facebookImg, txt: 'Facebook'},
                {network: 'twitter', img: twitterImg, txt: 'Twitter'},
                {network: 'email', img: emailImg, txt: 'E-mail'},
            ],
            currentUrl: null
        }
    },
     created (){
        // window.addEventListener('scroll', this.noScroll);
        // this.$root.$on('share_network_open', function (e) {
        //  console.log("share_network_open"+e)
        // });
       this.currentUrl = this.$route.path
       this.setUrl()
        // console.log(this.currentUrl);

      },
      mounted() {
      },
      methods: {
          scroll (e) {
               console.log(e)
              // window.scrollTo(10000000000000000000000000000,0);
          },
          setTxtUrl (url, currentUrl) {
           return (url + currentUrl).slice(0,57).concat('...')
          },
           copyTestingCode () {
            // this.copyM = true
            this.$clipboard(this.url1 + this.currentUrl)
            //   setTimeout(() => {
            //     this.copyM = false
            // }, 2000);
         },
         setShareUrl( slash) {
            // if(slash)
            //     return this.baseURL.slice(0,this.baseURL.length-5)
            // else 
            //      return this.baseURL.slice(0,this.baseURL.length-4)
            if(slash)
                return this.baseURL //.concat('/')
            else 
                 return this.baseURL
        },
        setUrl() {
            //  this.url= 'https:'+this.setShareUrl(false) // "https://carvisor.co.nz/"
            //  this.url1= 'https:'+this.setShareUrl(true) //"https://carvisor.co.nz"
            this.url= this.setShareUrl(false) // "https://carvisor.co.nz/"
            this.url1= this.setShareUrl(true) //"https://carvisor.co.nz"

        }
       

      },
      computed: {
        baseURL () {
            if (process.env.NODE_ENV !== 'production') {
               return 'https://028x122.mars-t.mars-hosting.com'
            }
            return window.location.protocol + '//' + window.location.host
            }
        }

}
</script>

<style lang="scss" scoped>
.shareFooter{
    margin:0px;
    height:420px;
    background: #5C96A7;
    // border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-radius: 5px;
    .shareHeaderTxt{
        //  padding-top:76px;
        padding-top: 72px;
        //  margin-bottom: 34px;
        margin-bottom: 33px;
        .shareHeader{
            font-style: normal;
            font-weight: bold;
            font-size: 46px;
            line-height: 55px;
            /* identical to box height, or 120% */
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFFFFF;

            margin: 0;
        }
        .shareTxt{
            text-align: center;
            // color: #062931;
            // margin-bottom:0;
            margin-top: 10px;
            margin-bottom: 5px;
            // font-weight: 500;
            // font-size: 17px;
            // line-height: 25px;
            // color: #ffffff;

            font-size: 17px;
            line-height: 25px;
            text-align: center;
            color: #103D49;

        }
        .link{
            margin-bottom: 0;
            margin-top: 0;
            text-align: center !important;
            color: #CFD8DB;
            font-size: 17px;
            line-height: 25px;
            transition: 0.3s;
        }
         .link:hover{
           cursor: copy;
        }
        .link:active{
           cursor: grabbing;
           color: #bfc2c3;
        }

    }
    .shareIcon{
        display: flex;
        justify-content: center;
        align-items: center;
        .spanImg{
            margin:0 15px;
            width:70px;
            text-decoration: none!important;
            text-align: center;
            a{
                // display: inline-block;
                // height: fit-content;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
            
                .aLink{
                    // display: inline-block;
                    height: 70px !important;
                    margin: 0;
                }
                p{
                    text-decoration: none!important;
                    // margin:5px 0;
                   
                    font-weight: 500;
                    font-size: 15px;
                    line-height: 20px;
                    /* identical to box height, or 133% */

                    text-align: center;
                    letter-spacing: 0.01em;

                    /* text 80 */

                    color: #CFD8DB;
                    margin:10px 0;
                }
            }
        }
    }


}
a{
    text-decoration: none;
    }
@media only screen and (max-width: 768px){
            .shareFooter{
                // padding:0 26px;
                .shareHeaderTxt{
                    // padding-top:27px;
                    margin-bottom: 37px;
                    .shareHeader{
                        box-sizing: border-box;
                        // font-size: 42px;
                    }
                    .shareTxt{
                         box-sizing: border-box;
                        //  font-size: 13px;
                    }
                    .link{
                         box-sizing: border-box;
                        //  font-size: 13px;
                    }

                }
                .shareIcon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .spanImg{
                        margin:0 10px;
                        text-align: center;
                        .aLink{
                                height: 60px !important;   
                        }
                        p{
                           font-size: 15px;
                            line-height: 20px;
                        }
                    }
                }


        }
  }
@media only screen and (max-width: 550px){
            .shareFooter{
                box-sizing: border-box;
                padding:0 26px;
                margin-bottom: 0px;
                height:355px;
                .shareHeaderTxt{
                    // padding-top:27px;
                     padding-top: 54px;
                    margin-bottom: 38px;
                    text-align: center;
                    .shareHeader{
                        font-size: 40px;
                        line-height: 45px;
                    }
                    .shareTxt{
                        margin-top: 8px;
                        font-size: 16px;
                        line-height: 25px;
                        width: 255px;
                        
                       
                    }
                    .link{
                      width: 275px;
                       font-size: 16px;
                       line-height: 25px;
                       overflow: hidden;
                       text-overflow: ellipsis;
                       white-space: nowrap;
                    }

                }
                .shareIcon{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    .spanImg{
                        margin:0 13px;
                        text-align: center;
                         width:50px;
                         a{
                            .aLink{
                                    height: 50px !important;   
                            }
                            img{
                                width: 50px;
                            }
                            p{
                                font-weight: normal;
                                font-size: 14px;
                                line-height: 20px;
                                /* identical to box height, or 143% */

                                text-align: center;

                                /* text 80 */

                                color: #CFD8DB;
                            }
                         }
                    }
                }


        }
  }

</style>