<template>
  <div :style="{ 'background-color': backgroundColor, color: color }" class="badge-wrapper">
    {{text}}
  </div>
</template>

<script>
export default {
  name: 'badge',
  props: [
    'backgroundColor',
    'color',
    'text'
  ]
}
</script>

<style lang="scss" scoped>
.badge-wrapper {
  // margin-right:200px;
  height: 20px;
  width: auto;
  padding: 4px;
  line-height: 20px !important;
  font-size: 12px !important;
  
  box-sizing: border-box;
  border-radius: 5px;
  // margin-left: 2px;
  padding-top:0px!important;
  padding-bottom: 0px!important;
  
  text-align: center;
}
@media only screen and (max-width: 1024px){
  .item .badge-wrapper {
    // display: none;
  }
}
</style>
