<template>
  <div class="aspect-wrapper">
    <router-link :to="blogData.link">
      <div class="aspWrap">
      <div class="aspect">
        <div class="blog-ad-wrapper">
          <img v-if="blogData.image" :src="baseURL + blogData.image" :alt="blogData.img_alt ? blogData.img_alt : 'main-blog-image'">
        </div>
      </div>
      </div><!-- aspWrapp -->
      <div class="dataWrapp">
        <span class="date">{{setDate}}</span>
        <span class="category" v-if="blogData.categories">{{categoryString}}</span>
      </div>
      <div class="title">
        {{blogData.title}}
      </div>
    </router-link>
  </div>
</template>

<script>
import { baseURL } from '@/api'
import helper from '@/js/helperDate.js'
import helperCategoryArrInString from '@/js/helperCategoryArrInString.js'
export default {
  name: 'blogAd',
  props: {
    blogData: {
      required: true,
      type: Object
    }
  },
  mounted(){
    this.setDate = helper.formatDate(this.blogData.date)
    this.categoryString = helperCategoryArrInString.setBlogCategoryInString(this.blogData.categories)
  },
  data () {
    return{
      setDate:null,
      categoryString: null
    }
  },
  computed: {
    baseURL () {
      return baseURL
    }
  },
  methods: {
    openBlog () {
      this.$router.push(this.blogData.link)
    }
  }
}
</script>

<style lang="scss" scoped>
.aspect-wrapper {
  width: calc(33% - 10px);
  margin-bottom: 52px; // -9px figma comment
  margin-bottom: 43px;
  cursor: pointer;
  a {
    // display: block;
    text-decoration: none;
  }
  .aspWrap{
    overflow: hidden;
    border-radius: 5px;
  }
  .aspect {
    padding-top: 75%;
    // padding-top: 100%;
    position: relative;
    margin-bottom: 10px;
    -webkit-border-radius: 5px;
    -khtml-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
     // -webkit-mask-image: -webkit-radial-gradient(white, black);
    will-change: transform;
    position: relative;
    z-index: 1;
  }
  span {
    // margin-bottom: 5px;
    font-size: 13px;
    line-height: 24px;
    // font-family: "Gotham Pro";
    letter-spacing: 0.05em;
    color: #062931;

    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height, or 125% */

    letter-spacing: 0.04em;

    /* black */

    color: #062931;
  }
  .dataWrapp {
    margin-top: 3px; // +2px comment from figma
    margin-top: 5px;
    display: flex;
    .date{
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      /* identical to box height, or 125% */

      letter-spacing: 0.04em;
      white-space: nowrap;
      color: #062931;
      margin-right: 20px;
    }
    .category{
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      // display: flex;
      align-items: center;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #134753;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

    }
  }
  .title {
    // font-size: 20px;
    // line-height: 26px;
    // color: #062931;
    // font-family: "Gotham Pro Medium";
    font-style: normal;
    font-weight: bold;

    margin-bottom: 3px;
    margin-top: 9px;
    transition-duration: .3s;
    font-weight: bold;
    font-size: 26px;
    line-height: 35px;
    color: #062931;
  }
  // .title:hover {
  //   color: #000000;
  // }
}
.blog-ad-wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  will-change: transform;

  img {
    z-index: 1;
    height: 100%;
    object-fit: cover;
    width: auto;
    border-radius: 5px;
    transition: 1s ease-in-out;
    -webkit-backface-visibility: hidden;
    -ms-transform: translateZ(0); /* IE 9 */
    -webkit-transform: translateZ(0); /* Chrome, Safari, Opera */
    transform: translateZ(0);
    -webkit-font-smoothing: antialised;
    backface-visibility: hidden;
    -webkit-font-smoothing: subpixel-antialiased;
    filter: blur(0);
  }
  // img:hover {
  //   transform: scale(1.1);
  // }
}
//// hover
.aspect-wrapper:hover {
  cursor: pointer;
  .aspect {
           -webkit-border-radius: 5px !important;
            -khtml-border-radius: 5px !important;
            -moz-border-radius: 5px !important;
            border-radius: 5px !important;
            -webkit-border-top-left-radius: 5px !important;
            -webkit-border-top-right-radius: 5px !important;
            -webkit-border-bottom-left-radius: 5px !important;
            -webkit-border-bottom-right-radius: 5px !important;
            // -webkit-mask-image: -webkit-radial-gradient(white, black);
            will-change: transform;
            -webkit-transform: translateZ(0);
            overflow: hidden;

    .blog-ad-wrapper {
       border-radius: 5px !important;
       overflow: hidden;
      img {
        transform: scale(1.1);
      }
    }
  }
  .title {
    // color: #000000;
  }
}
@media only screen and (max-width: 768px){
  .aspect-wrapper {
    width: calc(50% - 15px);
    margin-bottom: 44px;
    .aspect {
       padding-top: 75%;
    }
  }
}
@media only screen and (max-width: 450px){
  .aspect-wrapper {
    width: 100%;
    margin-bottom: 44px;
  }
   .title {
    font-size: 25px;
    line-height: 30px;
   }
    .dataWrapp {
      margin-top: 0px;

      .date{
        font-size: 14px;
        line-height: 20px;
        /* identical to box height, or 125% */
        letter-spacing: 0.04em;
        margin-right: 15px;
      }
      .category{
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.1em;

      }
    }
}
</style>
