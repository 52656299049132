<template>
  <div class="resetB">
      <p :class=" hover && noEdit!=true ? ' hover': ''" 
         :style="{cursor: !noEdit ? 'pointer' : 'initial'}"
         @click="rotateSpiner" 
         @mouseover="mouseOver"
         @mouseleave="checkHover">
          <span>
            <img v-if="hover" :class="{ rotate: rotate} " :src="require('@/assets/images/refreshWhite.svg')">
            <img v-else :class="{ rotate: rotate} " :src="require('@/assets/images/refreshWhite.svg')">
          </span>
          <span 
           class="spTxt"
           :style="{cursor: !noEdit ? 'pointer' : 'initial'}" >
            Restart
          </span>
      </p>
    </div>
</template>

<script>
export default {
  props:[
    'noEdit'
  ],
    data () {
        return {
            hover: false,
            rotate:false
        }
    },
    methods: {
         rotateSpiner () {
           if(this.noEdit == true) return
        this.rotate = true
        this.hover = true
        this.$emit('changeactivebadge', 'make')
        this.$store.commit('resetValue')
         setTimeout(() => {
                 this.rotate = false
                 this.hover = false
        }, 1100)
      },
      checkHover () {
        if(this.rotate) {return}
        setTimeout(() => {
          this.hover=false
        },0)
        // this.hover=false
      },
      mouseOver () {
        if(this.noEdit)return
         setTimeout(() => {
             this.hover=true
        }, 0)
      }
    }

}
</script>

<style lang="scss" scoped>
 .resetB{
      box-sizing: border-box;
      display: flex;
      align-items: center;
      font-style: normal;
    
      text-transform: capitalize;

      font-weight: 500;
      font-size: 15px;
      line-height: 20px;
      text-align: right;
      letter-spacing: 0.01em;

      // color: rgba(255, 255, 255, 0.7);
      color: #ffffff;
      

       
       p{
         white-space: nowrap;
         padding:5px 10px 4px 10px;
         margin-left: 10px;
         border-radius: 30px !important;
         display: -webkit-flexbox;
         display:flex;
         -webkit-box-align: center;
         -webkit-flex-align: center;
         justify-content: center;
         align-items: center !important;
         opacity: .7;
         span{
           padding-right: 9px;
           height:15px;
           img{
             height: 15px;
           }
         }
         .spTxt{
           cursor: pointer;
           transition-duration: .2s;
          //  margin-top:2px;
          height:15px;
          display:flex;
          justify-content: center;
          align-items: center;
         }
       }
       .hover{
         cursor: pointer;
        //  color: #ffffff;
        //  background: rgba(255, 255, 255, 0.103);
        opacity: 1;
       }
    }

.rotate {
  animation: rotation 1s;
  color: #ffffff;

}
@keyframes rotation {
  from {
    // transform: rotate(0deg);
     transform: rotate(360deg);
  }
  to {
    // transform: rotate(360deg);
     transform: rotate(0deg);
  }
}
@media only screen and (max-width: 787px){
 .resetB{
    p{
      margin:10px 0;
      margin-left:0px !important;
    }
  }
}
@media only screen and (max-width: 500px){
  .resetB {
    //  min-height: 60px!important;
    // padding-top: 0 !important;
    height: 100%;
    box-sizing: border-box;
    // margin-top: 11px;
  }
}

</style>