<template>
  <div class="valuations-wrapper">
    <div class="title">
      <h1>
        {{ title }}
      </h1>
    </div>
    <div v-if="testVar" class="content-wrapper">
      <div v-if="!isFavPage" class="info-wrap">
        <span>You have X reports in your tank. Click <a href="/">here</a> to refil.</span>
      </div>
      <div class="reports-wrap">
        <div v-if="!showMobileNav" class="reports-header">
          <div class="heading car">vehicle</div>
          <div class="heading expire">expires in</div>
          <div class="heading expire">price</div>
        </div>
        <div v-else class="dummy"></div>
        <myReports 
          v-for="(car, index) in newCars.slice(startIndex, endIndex)" 
          :key="index"
          :isFav="car.isFav"
          :isExpired="car.isExpired"
          :expDate="car.expDate"
          :price="car.price"
          :stonks="car.stonks"
          :carInfo="car"
          @changeFav="changeFav($event, index)"
        />
      </div>
      <div v-if="carsDummy.length > 11" class="steps-wrap" :style="calcStyle">
        <div class="box chevron" :class="{disabled: activeStep === 1}" @click="prev">
          <div class="ico-wrap">
            <img :src="require('@/assets/images/chevronLeft.svg')" alt="">
          </div>
        </div>
        <div v-if="activeStep !== 1" class="box count" @click="setRange(1)"> 1 </div>
        <div v-if="activeStep > 3" class="box dots">...</div>
        <div v-for="n in calcShit" :key="n" class="box count" :class="{active: n === activeStep}" @click="setRange(n)"> {{ n }} </div>
        <div v-if="activeStep < maxVal - 2" @click.stop class="box dots">...</div>
        <div v-if="activeStep !== maxVal" class="box count" @click="setRange(maxVal)"> {{ maxVal }} </div>
        <div class="box chevron" :class="{disabled: activeStep === maxVal}" @click="next">
          <div class="ico-wrap">
            <img :src="require('@/assets/images/chevronRight.svg')" alt="">
          </div>
        </div>
      </div>
      <div class="see-fav-btn-wrap">
        <div v-if="!isFavPage" class="see-fav-btn" @click="goToFav">
          See your favourites
        </div>
        <div v-else class="see-fav-btn" @click="goToAll">
          See all reports
        </div>
      </div>
      <footerWrapper v-if="!showMobileNav"/>
      <div v-else class="dummy-footer"></div>
    </div>
    <div v-else class="no-reports-wrap">
      <span class="no-report-text">
        {{debugVar ? 
          "You don’t have any searches saved. Get started and your searches will show up here." : 
          "You haven’t favourited any car valuations yet. Press the heart button in the corner of the valuation report to add the vehicle to your favourites."}}
      </span>
      <div class="start-val-btn">Start a new valuation</div>
    </div>
  </div>
</template>

<script>
import myReports from '@/components/valuations/myReports.vue';
import footerWrapper from '../components/footer/footer-wrapper.vue';


export default {
  name: 'Valuations',
  components: {
    myReports,
    footerWrapper
  },
  data() {
    return {
      title: "My reports",
      isFavPage: false,
      startIndex: 0,
      endIndex: 11,
      carsDummy: [
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat2 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: true,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat3 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 2,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat4 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat5 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 15,
          price: 42069,
          stonks: 2
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat2 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: true,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat3 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 2,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat4 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat5 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 15,
          price: 42069,
          stonks: 2
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiats 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat2 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: true,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat3 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 2,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat4 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat5 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 15,
          price: 42069,
          stonks: 2
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat2 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: true,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat3 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 2,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat4 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat5 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 15,
          price: 42069,
          stonks: 2
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiats 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat2 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: true,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat3 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 2,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat4 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat5 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 15,
          price: 42069,
          stonks: 2
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat2 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: true,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat3 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 2,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat4 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat5 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 15,
          price: 42069,
          stonks: 2
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiats 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat2 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: true,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat3 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 2,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat4 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat5 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 15,
          price: 42069,
          stonks: 2
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat2 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: true,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat3 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 2,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat4 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat5 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 1
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 15,
          price: 42069,
          stonks: 2
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiats 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: false,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
        {
          model: "Fiat 500",
          year: "2020",
          transmition: "Automatic",
          type: "Hachback",
          fuel: "Petrol",
          engine: "1.3 L",
          odometer: 92760,
          isFav: true,
          isExpired: false,
          expDate: 5,
          price: 42069,
          stonks: 0
        },
      ],
      newCars: [],
      showMobileNav: false,
      maxVal: null,
      activeStep: 1,
      testVar: true,
      debugVar: true,
      calcStyle: {},
    }
  },
  mounted () {
    window.addEventListener("resize", this.resize);
    this.resize();
    // this.newCars = Array.from(this.carsDummy);
    this.newCars = this.carsDummy
    this.maxVal = Math.ceil(this.carsDummy.length / 11)
    this.calcWidth()
  },
  computed: {
    calcShit () {
      let arr = []
      if (this.activeStep === this.maxVal && this.maxVal > 3) {
        arr.push(this.activeStep - 2)
      } 
      if(this.activeStep > 2) {
        arr.push(this.activeStep - 1)
      }
      arr.push(this.activeStep)
      if(this.activeStep < this.maxVal - 1) {
        arr.push(this.activeStep + 1)
      }
      if (this.activeStep === 1 && this.maxVal > 3) {
        arr.push(this.activeStep + 2)
      } 
      return arr
    }
  },
  watch: {
    $route () {
      if (this.$route.meta.isFavPage) {
        this.goToFav()
      } else {
        this.goToAll()
      }
    }
  },
  methods: {
    calcWidth () {
      console.log('i was called');
      if (this.showMobileNav) {
        this.calcStyle = {width: 340 + "px"}
      } else {
        this.calcStyle = {width: (this.maxVal + 2)*30 + (this.maxVal+1)*10 + "px"}
      }
    },
    resize () {
      if (window.innerWidth <= 500) {
      this.showMobileNav = true
      } else {
      this.showMobileNav = false
      }
    },
    goToFav () {
      this.title = "Favourites"
      this.newCars = this.newCars.filter(car => car.isFav)
      this.maxVal = Math.ceil(this.newCars.length / 11)
      this.isFavPage = true
    },
    goToAll () {
      this.isFavPage = false
      this.title = "My reports"
      // this.newCars = Array.from(this.carsDummy)
      this.newCars = this.carsDummy
      this.maxVal = Math.ceil(this.newCars.length / 11)
    },
    changeFav (val, index) {
      this.carsDummy[index+this.startIndex].isFav = val;
    },
    prev () {
      if (this.activeStep > 1) {
        this.activeStep--
        this.endIndex -= 11
        this.startIndex -= 11
      }
    },
    next () {
      if (this.activeStep < this.maxVal) {
        this.activeStep++
        this.endIndex += 11
        this.startIndex += 11
      }
    },
    setRange (num) {
      this.endIndex = num * 11;
      this.startIndex = this.endIndex - 11;
      this.activeStep = num
    }
  },
  destroyed () {
    window.removeEventListener("resize", this.resize);
  },
}
</script>

<style lang="scss" scoped>
.valuations-wrapper {
  width: 100%;
  .title {
    width: 100%;
    height: 185px;
    background: linear-gradient(116.78deg, #104756 13.98%, #3D7484 75.44%);
    display: flex;
    justify-content: center;
    position: relative;
    h1 {
      margin: 0;
      position: absolute;
      bottom: 47px;

      font-size: 36px;
      color: #fff;
    }
  }
  .content-wrapper {
    .info-wrap {
      width: calc(846px - 20px);
      padding-left: 20px;
      height: 50px;
      background-color: #FFF;
      border: 1px solid #5A7E87;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.14);
      border-radius: 20px;
      font-size: 17px;
      color: #062931;
      margin: 0 auto;
      margin-top: 50px;
      display: flex;
      align-items: center;
      a {
        color: #ff6359;
        text-decoration: none;
      }
    }
    .reports-wrap {
      width: 846px;
      margin: 0 auto;
      margin-top: 55px;
      display: flex;
      flex-direction: column;
      .report-wrap:nth-child(even) {
        background-color: #EEEFF3;
      }
      .reports-header {
        width: 100%;
        height: 30px;
        display: flex;
        align-items: center;
        .heading {
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          text-transform: uppercase;
          letter-spacing: 0.05em;
          // counter-reset: #5A7E87; ???
        }
        .car {
          margin-left: 50px;
          margin-right: 409px;
        }
        .expire {
          width: 97px;
          margin-right: 10px;
        }
      }
    }
    .steps-wrap {
      height: 30px;
      margin: 0 auto;
      margin-top: 35px;
      display: flex;
      justify-content: space-between;
      .box {
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        font-weight: 500;
        color: #062931;
        cursor: pointer;
        &:hover {
          border-radius: 50%;
          background-color: #062931;
          color: #FFF;
        }
      }
      .active {
        border-radius: 50%;
        background: #062931;
        color: #FFF;
      }
      .chevron {
        &:hover {
          background-color: initial;
          color: #062931;
        }
        &.disabled {
          opacity: 50%;
        }
        .ico-wrap {
          height: 9px;
          width: 4px;
          img {
            display: block;
            height: 100%q;
          }
        }
      }
    }
    .see-fav-btn-wrap {
      .see-fav-btn {
        width: 234px;
        height: 50px;
        margin: 60px auto 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: #FFFFFF;
        border: 1px solid rgba(6, 41, 49, 0.8);
        border-radius: 40px;
        font-weight: 500;
        font-size: 17px;
        line-height: 25px;
        color: #062931;
        cursor: pointer;
      }
    }
  }
  .no-reports-wrap {
    width: 542px;
    margin: 0 auto;
    margin-top: 57px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .no-report-text {
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      color: #062931;
    }
    .start-val-btn {
      width: 248px;
      height: 50px;
      background-color: #ff6359;
      border-radius: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-size: 17px;
      line-height: 25px;
      margin-top: 53px;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  .valuations-wrapper {
    .content-wrapper {
      .info-wrap {
        width: 694px;
      }
      .reports-wrap {
        width: 714px;
        .reports-header {
          .car {
            margin-right: 245px;
          }
          .expire {
            margin-right: 30px;
          }
        }
      }
      .steps-wrap {
        .box {
          &:hover {
            border-radius: 50%;
            background-color: #fff;
            color: #062931;
          }
        }
        .dots {
          pointer-events: none;
        }
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .valuations-wrapper {
    .title {
      h1 {
        font-size: 34px;
        bottom: 32px;
        left: 16px;
      }
    }
    .content-wrapper {
      .info-wrap {
        width: 323px;
        height: 75px;
      }
      .reports-wrap {
        width: 343px;
      }
      .see-fav-btn-wrap {
        width: 343px;
        margin: 0 auto;
        .see-fav-btn {
          margin-left: 0;
          margin-top: 40px;
        }
      }
      .dummy-footer {
        height: 1px; // ?????
      }
    }
    .no-reports-wrap {
      width: 100%;
      // height: calc(100% - 30px);
      margin: 0;
      margin-top: 30px;
      align-items: unset;
      text-align: unset;
      .no-report-text {
        width: 320px;
        margin-left: 16px;
        font-size: 17px;
        line-height: 25px;
        text-align: unset;
      }
      .start-val-btn {
        width: calc(100% - 32px);
        margin: 0 auto;
        border-radius: 5px;
        position: fixed;
        bottom: 80px;
        left: 16px;
      }
    }
  }
}
</style>