<template>
  <div class="cropperWrap">
    <div class="white">
      <div class="content">
          <img ref="image" :src="src" />
        </div>
        <div class="wrapppCroppImage">
          <img :src="destination" />
        </div>
         <div class="wrapButton">
              <div>
                  <p v-if="imageSize">Your image size {{ imageSize}} kb</p>
                  <p v-if="imageSize && imageSize >= 400" class="errMsg" >Image must be less than 400 kb! </p>
              </div> 
              <div class="roundBtn">
                <div class="button" @click="cropp" >Crop</div>
                <div class="button" @click="giveUp" > Give up</div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs'
import helper from '@/js/helperImg.js'

export default {
  name: 'ImageCropper',
  props: ['src', 'extension'],
  data () {
    return {
      cropper: {},
      destination: {},
      image: {},
      msg: false,
      imageSize: ''

    }
  },
  created() {
    // this.cleanField()
    // this.imageSize=''
  },
  mounted () {
    // console.log('src ' + this.src)
    // this.cleanField()
    this.image = this.$refs.image
    this.cropper = new Cropper(this.image, {
      zoomable: false,
      scalable: false,
      aspectRatio: 1 / 1,
      minCropBoxWidth: 1,
      minCropBoxHeight: 1,
      crop: () => {
        const canvas = this.cropper.getCroppedCanvas()
        // this.destination = canvas.toDataURL('image/png')
        this.destination = canvas.toDataURL(this.extension!=='' ? this.extension : 'image/png')
      },
      cropmove: (/*event*/) => {
        // console.log(event)
        // console.log(helper.base64toFile(this.destination, 'picName').size)
      },
      cropend: (/*event*/) => {
        this.imageSize = Math.round(helper.base64toFile(this.destination, 'picName').size * 0.001)

        //  console.log(event)
        // console.log(helper.base64toFile(this.destination, 'picName').size)
      }

    })
  },
  methods: {
    cropp () {
      // console.log(this.destination)
      if (helper.base64toFile(this.destination, 'picName').size > 409600) {
        this.imageSize = Math.round(helper.base64toFile(this.destination, 'picName').size * 0.001)
        this.msg = true
        return
      }
      this.$emit('cropp', this.destination)
     
    },
    giveUp () {
      this.cleanField()
      this.$emit('giveup')
    },
    cleanField () {
      // this.cropper= {}
      this.destination= {}
      this.image= {}
      this.msg= false
      this.imageSize= ''
    }
  }

}
</script>

<style lang="scss" >
.cropperWrap{
    position:fixed;
    z-index:1000;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.555);
    overflow:auto;
    .white{
       background: rgb(255, 255, 255);
       width: 620px;
       height: 90%;
       display:flex;
       flex-direction:column;
       justify-content: center;
       align-items: center;
    }
    .content{
       z-index:10005;
       background: rgb(255, 255, 255);
       width: 600px;
       height: 50%;
       display:flex;
       justify-content: center;
       align-items: center;
       img{
           max-width:100%;
           max-height: 100%;
       }
    }
    .wrapppCroppImage{
        padding: 10px;
        box-sizing:border-box;
        // background:white;
        width: 600px;
        display:flex;
        justify-content: center;
        img{
            background:white;
            border:1px solid rgba(71, 71, 71, 0.616);
            width: 250px;
        }
    }
    .wrapButton{
            width:calc(100% - 20px);
            box-sizing: border-box;
            border-top:2px solid rgba(71, 71, 71, 0.322);
            display:flex;
            // justify-content: flex-end !important;7
            justify-content: space-between;
            margin:0 10px;
            padding: 10px 0;
            .roundBtn {
              display:flex;
            }
            .btnC{
                margin-left:10px;
            }
        }
        .wrapButton>div>p{
          margin-top:0;
          text-align: left;
        }
             .button {
                box-sizing: border-box;
                width:116px;
                height: 50px;
                border-radius: 52px;
                background-color: #FF6359;
                text-align: center;
                padding: 13px 0;
                display: flex;
                justify-content: center;
                // padding-left: 40px;
                color:rgb(255, 255, 255);
                cursor: pointer;

                // font-family: Gotham Pro;
                font-style: normal;
                font-weight: 500;
                font-size: 15px;
                line-height: 24px;

                display: flex;
                align-items: center;
                text-align: center;
                color: #FFFFFF;
                margin-right: 20px;
            }

}
.errMsg {
    // font-family: Gotham Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
    // color: #FF6359;
    color: $err-color;

}

@media only screen and (max-width: 768px) {
    .cropperWrap{
      position:fixed;
      z-index:1000;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100vw;
      height: 100vh;
    //   min-height: 100%;
        .white{
          background: white;
          width: 90vw;
          height: 90%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start !important;
          height:80vh;
          overflow: auto;
        }
        .content{
          min-width: 50%;
          min-height: 50%;
          max-width: 90vw;
        }
        .wrapppCroppImage{
          margin-top:10px;
            padding: 10px;
            box-sizing:border-box;
            // background:white;
            // width: 90vw;
            width:100%;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            img{
                width: 250px;
            }

        }
         .wrapButton{
             display: flex;
             flex-direction: column;
             justify-content: center;
            font-size: 14px;
            line-height: 5px;
            div {
                height: fit-content;
                text-align: center;
            }
             .roundBtn {
                 box-sizing: border-box;
                 padding-top: 20px;
                 display: flex;
                 justify-content: center;
                 align-items: center;
             }
               
            }
        .button {   
            margin-bottom: 21px;
            width:120px;
            height: 48px;
            border-radius: 52px;
            display: flex;
            justify-content: center;
            padding-left: 0;

        }
        .button:last-of-type{
            margin-right: 0;
        }
     }

}
@media only screen and (max-width: 500px) {
  .cropperWrap{
      position:fixed;
      z-index:1000;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      display:flex;
      flex-direction:column;
      justify-content: flex-start;
      align-items:center;
      width: 100vw;
      height: 100vh;
      padding: bottom 220px!important;;
      overflow: auto;
    //   min-height: 100%;
        .white{
          background: white;
          width: 95vw;
          height: 90%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start !important;
          height:80vh;
          overflow: auto;
        }
        .content{
          min-width: 50%;
          min-height: 50%;
          max-width: 90vw;
            display: flex;
          align-items: center;
        }
        .wrapppCroppImage{
          margin-top:10px;
            padding: 10px;
            box-sizing:border-box;
            // background:white;
            // width: 90vw;
            width:100%;
            display:flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            min-height: fit-content;
            img{
                width: 250px;
            }

        }
         .wrapButton{
             display: flex;
             flex-direction: column;
             justify-content: center;
            font-size: 14px;
            line-height: 5px;
            min-height: fit-content;
            div {
                height: fit-content;
                text-align: center;
            }
             .roundBtn {
                 box-sizing: border-box;
                 padding-top: 10px;
                 display: flex;
                 justify-content: center;
                 align-items: center;
             }
               
            }
        .button {   
            margin-bottom: 21px;
            width:120px;
            height: 48px;
            border-radius: 52px;
            display: flex;
            justify-content: center;
            padding-left: 0;

        }
        .button:last-of-type{
            margin-right: 0;
        }
     }

}
</style>
