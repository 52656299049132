<template>
  <div class="media-wrapper">
    <div class="input-wrapper">
      <span class="title">Image:</span>
      <input type="file" accept=".png, .jpg, .jpeg" @change="image.image = $event.target.files[0]">
    </div>
    <div class="input-wrapper">
      <span class="title">Image alt:</span>
      <input type="text" v-model="image.alt">
    </div>
    <div class="input-wrapper">
      <span class="title">Image name:</span>
      <input type="text" v-model="image.name">
    </div>
    <div @click="addImage()" class="btn">
      add image
    </div>
    <div>image list:</div>
    <div v-for="media in mediaList" :key="media.id" class="image-wrapper">
      <div class="img-wrapper">
        <img :src="getLink(media.link)" alt="img">
      </div>
      <div class="info">
        <span>name: {{media.img_name}}</span>
        <span>alt: {{media.img_alt}}</span>
        <div @click="copyImageLink(getLink(media.link))">copy image link</div>
        <div @click="deleteImage(media)">delete image</div>
      </div>
    </div>
    <div class="show-more-wrapper">
      <div @click="showMore()" class="show-more">
        Show more
      </div>
    </div>
  </div>
</template>

<script>
import { baseURL, api } from '@/api'
export default {
  name: 'Media',
  data () {
    return {
      mediaList: [],
      image: {
        image: null,
        alt: null,
        name: null
      },
      offset: 0,
      count: 5
    }
  },
  activated () {
    this.getMedia()
  },
  methods: {
    getMedia () {
      let params = {
        count: this.count,
        offset: this.offset
      }
      api.getImages(params).then(res => {
        this.mediaList = res.data.data
      }).catch(e => {
        console.log(e);
      })
    },
    showMore () {
      let currOffset = this.mediaList.length
      let params = {
        count: this.count,
        offset: currOffset
      }
      api.getImages(params).then(res => {
        for (var i = 0; i < res.data.data.length; i++) {
          this.mediaList.push(res.data.data[i])
        }
      }).catch(e => {
        console.log(e);
      })
    },
    addImage () {
      if (this.image.image === null || this.image.alt === null || this.image.name === null) {
        alert('Make sure to fill image alt and image name fields and chose an image')
        return
      }
      let params = new FormData()
      for (var variable in this.image) {
        params.append(variable, this.image[variable])
      }
      api.addMedia(params).then(res => {
        this.getMedia()
        alert('Success')
        console.log(res);
      }).catch(e => {
        alert('Error adding image')
        console.log(e);
      })
    },
    getLink (link) {
      if (window.location.hostname === "localhost") {
        return baseURL + link
      } else {
        return window.location.origin + '/api/' + link
      }
    },
    copyImageLink (link) {
      navigator.clipboard.writeText(link).then(() => {
        alert('Link copied')
      }).catch(err => {
        console.error('Async: Could not copy text: ', err);
      });
    },
    deleteImage (image) {
      let confirm = window.confirm('Are you sure you want to delete this image?')
      if (confirm) {
        console.log('delete');
        api.deleteImage(image.img_id).then(() => {
          this.getMedia()
          alert('Image deleted')
        }).catch( e => {
          console.log(e);
          alert('Error deleting image')
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.media-wrapper {
  padding-bottom: 30px;

  .show-more-wrapper {
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 40px;
    margin-bottom: 120px;
    max-width: 735px;

    .show-more {
      cursor: pointer;
      font-size: 15px;
      line-height: 24px;
      color: #5A7E87;
      // font-family: 'Gotham Pro Medium';
    }
  }
  .btn {
    height: 50px;
    width: 170px;
    line-height: 52px;
    color: white;
    // font-family: 'Gotham Pro Medium';
    text-align: center;
    background: $main-color;
    border-radius: 52px;
    cursor: pointer;
    transition-duration: .3s;
    margin-bottom: 40px;
  }
  .btn:hover {
    background: #FF4B49;
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .title {
      margin-bottom: 5px;
    }
  }
  .image-wrapper {
    display: flex;
    flex-direction: row;
    margin-bottom: 20px;
    align-items: center;
    flex-wrap: wrap;

    .img-wrapper {
      height: 100px;
      width: 100px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 20px;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .info {
      display: flex;
      flex-direction: column;

      div {
        cursor: pointer;
      }
    }
  }
}
</style>
