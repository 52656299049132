<template>
  <div class="enterEmail">
    <div class="title">Forgot password?</div>
    <div class="txt">
        Please enter the email associated with your accound and we’ll send a link to reset your password. If you don’t receive an email make sure to check your spam folder.
    </div>
    <input v-model="email" :class="emailErr? 'basic inputErr':'basic'"  placeholder="E-mail" type="text"   v-hideMoblieNav > 
    <div class="sendButton" @click="checkForm">Send</div>
    <p class="errMsg">{{msg}}</p>
  </div>
</template>

<script>
import helper from '@/js/helperMail'
import { api } from '@/api'

export default {
    name:'enterEmail',
    data(){
        return{
            email:'',
            emailErr: null,
            msg:null
        }
    },
    methods:{
        async checkForm(){
            this.emailErr = null
             this.msg =''

            if(this.email === '' || this.email.trim().length == 0 || !helper.matchemail(this.email)){
                this.emailErr = true
                this.msg = "You must enter a valid email."
                return
            }else {
                await this.$recaptchaLoaded()
                const token = await this.$recaptcha('email').catch(() => {})
      
                let params={
                    token:token,
                    email:this.email
                }
                console.log('successShow')
                api.forgot(params).then(res => {
                    console.log(res)
                    this.$emit('checkstep', 'successShow')
                }).catch(e =>{
                    console.log(e)
                })
            }
         }
    }

}
</script>

<style lang="scss" scoped>
 @import '@/assets/css/singUp.scss';
    .enterEmail{
        width:406px;
        text-align: center;
        display:flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: fit-content;
        .title{
            // font-family: Gotham Pro;
            // font-family: 'Gotham Pro Medium';
            // font-style: normal;
            // font-weight: 500;
            // font-size: 28px;
            // line-height: 40px;
            // text-align: center;

            color: #062931;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            /* identical to box height, or 111% */

            display: flex;
            align-items: center;
            text-align: center;
             margin-bottom: 34px;


        }
        .txt {
            // font-family: Gotham Pro;
            font-style: normal;
            // font-weight: normal;
            // font-size: 16px;
            // line-height: 24px;
            // text-align: center;
            // color: #062931;
            margin-bottom: 24px;

            font-weight: normal;
            font-size: 17px;
            line-height: 25px;
            /* or 147% */

            text-align: center;

            /* black */

            color: #062931;
            margin-bottom: 33px;

        }
        .sendButton {
            cursor: pointer;
            // margin-top: 30px;
            // margin-top: 15px;
             margin-top: 20px;
            // width: 366px;
            // height: 50px;
            // border-radius: 55px;

            // background: #FF6359;
            // border-radius: 55px;
            // // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: 500;
            // font-size: 16px;
            // line-height: 24px;
            // /* identical to box height, or 150% */

            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            font-weight: 500;
            font-size: 15px;
            line-height: 20px;
            color: #FFFFFF;
            width: 127px;
            height: 50px;
            left: 897px;
            top: 465px;

            /* Orange primary */

            background: #FF6359;
            border-radius: 35px;

        }
        .sendButton:hover{
            background: $btn-hover;
        }

    }
@media only screen and (max-width: 500px){
    .enterEmail{
        max-width:335px;
        margin:0 20px;
        .title{
            font-size: 28px;
            line-height: 30px;
        }
        .txt {
            font-size: 15px;
            line-height: 24px;
            margin-top: 39px;
            margin-bottom: 22px;
        }
        .sendButton {
            // margin-top: 30px;
            margin-top: 15px;
            width: 100%;
            height: 50px;
            border-radius: 55px;
            box-sizing: border-box;
            font-size: 15px;
            line-height: 24px;

        }

    }
    
}

</style>