<template>
    <div class="loginComponent">
        <!-- <div class="loginTitle"> Log In</div> -->

        <div v-if="false" v-facebook-login-button="appId" class="buttonSocial">
            <span>Continue with Facebook</span>
            <span ><img
                :src="require(widthPage?'@/assets/images/fsingin.svg':'@/assets/images/fsinginBright.svg')" />
             </span>
        </div>

        <div v-google-signin-button="clientId"  class="buttonSocial">
            <span>Continue with Google</span>
            <span>
                <img :src="require(widthPage? '@/assets/images/googleNewFont.svg':'@/assets/images/gsinginBright.svg')" />
            </span>
            
        </div>

        <div class="or">
            <div class="line"></div>
            or
            <div class="line"></div>
        </div>
        <div class="round">
            <input v-model="email" :class="emailErr? 'basic inputErr ': 'basic'" @keyup="emailErr=false" placeholder="Enter e-mail" type="text"   v-hideMoblieNav autofocus>
            <img v-if="emailErr" src="../../assets/images/errimg.svg" />
        </div>
        <div class="round">
            <input v-model="password" :class="passwordErr? 'basic last inputErr ': 'basic last'" @keyup="passwordErr=false" placeholder="Password" type="password"  v-hideMoblieNav >
            <img v-if="passwordErr" src="../../assets/images/errimg.svg" />
        </div>
        <callForgotpass />
        <p class="errMsg" :style="msg ?'':'margin-bottom:3px'"><img v-if="msg" src="../../assets/images/errImgMsg.svg" />{{msg}}</p> 
        <p v-if="msgerr" class="errMsg"><img src="../../assets/images/errImgMsg.svg" />{{msgerr}}</p>
         <rember :remember="remember" @rememberSend="remember=!remember" />
        <div class="buttonSend" @click="checkForm">Log In</div>
        <div class="haveaccount">Don’t have an account yet? <router-link to="/signin" class="orange" >Sign up</router-link></div>
       
    </div>
</template>

<script>
import helper from '@/js/helperMail'
import rember from './rememberMe.vue'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import FacebookLoginButton from 'vue-facebook-login-button-directive'
import { api } from '@/api'
import callForgotpass from './callForgotpass.vue'
 // import eventBus from '@/js/eventBus.js'
export default {
    name: 'loginComponent',
     directives: {
        GoogleSignInButton,
        FacebookLoginButton

    },
    props: {
        msgerr:{
            required: false,
            default: null,
            type: String
        }
    },
    components: {
        rember,
        callForgotpass
    },
    data () {
        return {
            email: '',
            password: '',
            remember: true,
            emailErr: false,
            passwordErr: false,
            msg: '',
            widthPage: null,
            // id app google
            clientId: '306827492643-at00dqjbklkflbi52fnd1ckrkthpahjj.apps.googleusercontent.com',
            // fb
            appId: 3821175311320178
        }
    },
    created(){
        window.addEventListener("resize", this.resize)
        this.resize()
    },
    methods: {
        // FACEBOOK
         OnFacebookAuthSuccess(idToken) {
            const params={
                token:idToken
            }

            api.loginFacebook(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                // this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid)
                this.$router.go(-1)
            }).catch(e => {
                this.msg="Something went wrong, please try again."
                console.log(e.message);
            })

        },
        OnFacebookAuthFail(error) {
             console.log(error)
            // if(error && error.error == 'popup_closed_by_user')
            //     this.msg= 'Popup closed by user'
            // else
                this.msg="Something went wrong, please try again."

        },
        // end facebook
        // GOOGLE
        OnGoogleAuthSuccess (idToken) {
            const params={
                token:idToken
            }

            api.loginGoogle(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                // this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid)
                this.$router.go(-1)
            }).catch(e => {
                this.msg="Something went wrong, please try again."
                console.log(e.message);
            })

        },
        OnGoogleAuthFail (error) {
            if(error && error.error == 'popup_closed_by_user')
                this.msg= 'Popup closed by user'
            else
                this.msg="Something went wrong, please try again."
        },
        // end google
        getUserData(sid) {
            let params = {
                sid : sid
            }
            api.getUserData(params).then(res => {
                console.log('res')
                 console.log(res.data.data)
                this.$store.commit('setuserData', res.data.data)
            }).catch(e => {
             console.log(e)
            })
        },
        checkForm(){

            let error = false
            this.clearErr()

            if(this.email === '' || this.email.trim().length == 0 || !helper.matchemail(this.email)){
                error= true
                this.emailErr = true
                this.msg = "You must enter a valid email."
            }
            if(this.password === '' || this.password.length <= 6 ){
                error= true
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "Your password is incorrect.": "All fields must be entered correctly"
            }
            if(error) {
                return
            }else {
                let data = {
                    email:this.email,
                    password: this.password
                }
                this.$emit('login', data)
                this.clearData()
            }

        },
        clearErr () {
            this.emailErr = false
            this.passwordErr = false
            this.msg = ''
            this.$emit('clearmsgerr')
        },
        clearData () {
            this.email = ''
            this.password = ''
        },
        resize () {
            if (window.innerWidth <= 768) {
                this.widthPage = false
            } else {
                this.widthPage = true
            }
         }
    },
    destroyed() {
        window.removeEventListener("resize", this.resize);
    }


}
</script>

<style lang="scss" scoped>
 @import '@/assets/css/singUp.scss';
.loginComponent{
    //  background-color:rgba(48, 219, 241, 0.609);
        width: 406px;
        height: 711px;
        margin: 0 auto;

        .loginTitle {
            // text-align: right;
            // font-family: 'Gotham Pro Medium';
            // font-style: normal;
            // font-weight: 500;
            // font-size: 28px;
            // line-height: 40px;
            text-align: center;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            /* identical to box height, or 111% */

            // display: flex;
            // align-items: center;
            // text-align: center;



            color: #fff;
            margin-bottom: 52px;
        }
    }
@media only screen and (max-width: 500px){
    .loginComponent{
    //  background-color:rgba(48, 219, 241, 0.609);
        // width: 334px;
        width: 343px;
        height: 711px;
        margin-bottom: 71px;

        .loginTitle {
            // font-size: 28px;
            // line-height: 30px;
            font-size: 34px;
            line-height: 40px;
            // margin-bottom: 60px;
            margin-bottom: 37px;
            text-align: left;
        }
        .haveaccount{
            // margin-top: 13px; // + 3px
            margin-top: 16px;
        }

    }
}

</style>
<style lang="scss">
    .loginComponent{
        .last{ // basic:last-of-type
            // margin-bottom: 8px;
            margin-bottom: 7px;
        }
        .rember {
            // margin-top: 24px;
            // margin-bottom: 32px;
            // margin-top: 29px;
            // margin-top: 38px;
            // margin-bottom: 25px;
            // margin-bottom: 32px;
            margin-top: 0px;
            // margin-bottom: 30px;
            margin-bottom: 33px;
        }
        // .errMsg{
        //     margin-bottom: 7px !important;
        // }
    }
@media only screen and (max-width: 500px){
    .loginComponent{
        // .basic:last-of-type{
        //     margin-bottom: 10px;
        // }
        .rember {
            // margin-top: 26px;
            // margin-bottom: 28px;
            margin-top: 27px;
            margin-bottom: 40px;
        }
    }
}

</style>
