<template>
  <div class="sidebar-wrap">
    <div :class="{'sidebar-option': true, 'active': index === activeEl}" @click="scrollTo(option.to, index)" v-for="(option, index) in options" :key="index">
      <a class="title">
        {{ option.title }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    activeArr: {
      type: Array,
    },
  },
  data() {
    return {
      options: [
        {
          title: 'Estimate',
          to: 'estimate'
        },
        {
          title: 'Transaction trigger',
          to: 'trans-trigg'
        },
        {
          title: "Model attractivness & Estimated\ntime to sell",
          to: 'model-att'
        },
        {
          title: 'Private & dealer market value',
          to: 'market-val'
        },
        {
          title: 'Market brakedown by region ',
          to: 'pie-chart-wrapper'
        },
        {
          title: 'Market breakdown by travelled km',
          to: 'chart'
        },
        {
          title: 'Market brakedown by advetised price',
          to: 'advertised-price-wrapper'
        },
        {
          title: 'Market brakedown by seller type',
          to: 'seller-type'
        },
        {
          title: 'Interesting insights',
          to: 'insights'
        },
        {
          title: 'Recently on the market',
          to: 'recent'
        },
      ],
      activeEl: 0,
      isClicked: false
    }
  },
  methods: {
    scrollTo(id, index) {
      this.activeEl = index;

      this.isClicked = true;

      let el = document.getElementById(`${id}`);

      if (el.id === 'estimate') {
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
        setTimeout(
          () => this.isClicked = false,
          700 // add more delay if more elements are added
        )
      } else {
        // el.scrollIntoView({behavior: "smooth"});
        // works funky on safari
        window.scrollTo({top: el.getBoundingClientRect().top + window.scrollY - 60, behavior: 'smooth'});
        setTimeout(
          () => this.isClicked = false,
          700
        )
      }
    },
  },
  watch: {
    activeArr: function() {
      if (!this.isClicked) {
        this.activeEl = this.activeArr.indexOf(true);
      }
    }
  },
}
</script>

<style lang="scss" scoped>
.sidebar-wrap{
  width: 311px;
  height: 625px;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  // gap: 1px;
  justify-content: space-around;
  background-color: rgba(213, 230, 230, 0.5);
  font-size: 15px;
  line-height: 20px;
  // opacity: 0.5;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  .sidebar-option{
    width: 100%;
    min-height: 59px;
    background-color: rgba(255, 255, 255, 0.6);
    cursor: pointer;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    &:hover {
      background-color: rgb(255, 255, 255);
    }
    .title{
      color: #134753;
      text-decoration: none;
      width: 278px;
      position: relative;
      top: 19px;
      left: 15px;
    }
    &:first-child{
      border-radius: 5px 5px 0 0;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    }
    &:nth-child(2){
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
    }
    &:nth-child(3){
      line-height: 25px;
      height: 84px;
      .title{
        top: 16px;
        white-space: pre-line; 
        word-wrap: break-word;
        font-family: inherit;
      }
    }
    &:last-child{
      border-radius: 0 0 5px 5px;
    }
  }
  .active{
    background-color: rgb(255, 255, 255);
  }
}
</style>