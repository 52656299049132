<template>
  <div :class="white ? 'wrappUpFooter wrappUpFooter2 ': 'wrappUpFooter' ">
      <div class="holdContent">
          <p>For all press related enquiries regarding <br class="small" >Carvisor, or to be added to <br class="med" />the mailing <br class="small">list, please reach us at <br class="small">
              <span class="link" ><a href="mailto:contact@carvisor.co.nz">contact@carvisor.co.nz</a></span>.
          </p>
          <p>More information about us can be found 
              <a class="link" href="about" >here</a>.
          </p>
      </div>
  </div>
</template>

<script>
export default {
    name:'wrappUpFooter',
    props: ['white'],
    methods: {
        //  goTo () {
        //      let routeData = this.$router.resolve({name:'about'});
        //       window.open(routeData.href, '_blank');
        // }
    }

}
</script>

<style lang="scss" scoped>
.wrappUpFooter{
    background: #103D49;
    color: #FFFFFF;
    .holdContent{
       //  background: white;
        //  padding-top:32px;
         margin:0 auto;
         width:1278px;
         // height: 202px;
         border-bottom: 1px solid #51696F;
        //  font-family: Gotham Pro;
        //  font-style: normal;
        //  font-weight: 500;
        //  font-size: 17px;
        //  line-height: 26px;
        //  padding-bottom: 42px;

        font-weight: bold;
        font-size: 22px;
        line-height: 30px;
        /* or 136% */


        /* black */

        // color: #062931;
         padding-bottom: 113px;
         padding-top: 89px;

        p{
            max-width: 733px;
            margin: 0;
        }
        p:last-of-type{
            margin-top: 18px;
        }

         .small{
             display: none;
         }
         .bg{
             display: initial;
         }
         .med{
                display: none;
         }
         .link {
             color:$main-color;
             text-decoration: none;
             a {
                 text-decoration: none;
                 color: $main-color;
             }
         }
         .link:hover{
                color: $btn-hover;
                cursor: pointer;
             a {
                color: $btn-hover;
                cursor: pointer;
           }
         }

    }
}
.wrappUpFooter2 {
    background: #FFFFFF;
    color: #062931 !important;
    .holdContent{
         border-bottom: transparent;
    }
}

@media only screen and (max-width: 1330px){
    .wrappUpFooter{
        .holdContent{
            box-sizing: border-box;
            margin:0 auto;
            //width:1278px;
            width: calc(100% - 58px );
            margin: 0 29px;
            // height: 202px;
             padding-bottom: 43px;

            font-size: 21px;
            .small{
             display:none;
            }
            .bg{
             display: initial;
            }
            .med{
                display: none;
            }


        }
    }
}

@media only screen and (max-width: 788px){ // 768
    .wrappUpFooter{
        // background: bisque;
        .holdContent{
            margin:0 auto;
            flex-direction: column;
            width: calc(100% - 54px);
            box-sizing: border-box;
            // height: initial;
            // padding-top:32px;
            padding-left: 0px;
            padding-right: 0px;
            // padding-bottom: 43px;
            margin: 0 27px;
            margin-right: 7px!important;
            // font-family: Gotham Pro;
            // font-style: normal;
            // font-weight: 500;
            // font-size: 17px;
            // line-height: 26px;

            font-weight: 500;
            font-size: 22px;
            line-height: 29px;

            padding-bottom: 73px;
            padding-top: 55px;
            P:first-of-type {
                margin-top: 0;
            }
            .small{
             display:none;
            }
            .bg{
             display: none;
            }
            .med{
                display: initial;
            }
            

        }
    }
}

@media only screen and (max-width: 475px){ //375
 .wrappUpFooter{
        // background: bisque;
        .holdContent{
            // padding-top:34px;
                // padding-left: 16px;
                // padding-right: 16px;
            // padding-bottom: 17px;
                // font-family: Gotham Pro;
                // font-style: normal;
                // font-weight: 500;
                // font-size: 16px;
                // line-height: 24px;
            padding-top: 30px;
            padding-bottom: 35px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            height: initial;
            margin: 0px 16px;
            width: initial;
            // letter-spacing: initial;
            p {
                .small{
                //  display:initial;
                display: none !important;
                }
                .bg{
                display: none;
                }
                .med{
                    display: none;
                }
            }
            p:last-of-type{
                margin-top:0;
            }

        }
    }
}
</style>