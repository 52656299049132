<template>
<div class="wrapModal">
    <div class="exit" @click="$emit('closeModal')">
            <img :src="require('@/assets/images/closeIcon.svg')">
    </div>
    <div class="modal">
        <div class="video">
            <iframe  id="iframe" :src='url' frameborder="0" allow=""  allowfullscreen></iframe>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'modalHoldVideo',
    props:[
           'url'
        ],
    data () {
        return{
            email:''
        }
    },
    methods:{
         send () {
            this.$emit('send', this.email)
        }
    }

}
</script>

<style lang="scss" scoped>
.wrapModal{
    width:100%;
    height:100%;
    background: #062931f1;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal{
        border-radius: 5px;
        width: 70%;
        //background: rosybrown;
        height: initial;
        margin: auto auto;
        z-index: 212;
       .video {
            position: relative;
            overflow: hidden;
            width: 100%;
            // max-width: 100%;
            padding-top: 54%;
            border-radius: 9px;
            //max-width: 735px;
            #iframe {
                position: absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
                width: 100%;
                // max-width: 100%;
                height: 100%;
            }
       }
     
        
    }
     .exit {
            position: absolute;
            top:40px;
            right:60px;
            // margin-top:60px;
            // margin-right: 59px;
            position: absolute;
            img {
                width:22px;
            }
        }
}
 @media only screen and (max-width: 1000px){
     .wrapModal{
        .modal{
            border-radius: 5px;
            width: 80%;
            .video {
               
                #iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        
            
        }
        .exit {
            position: absolute;
            top:40px;
            right:60px;
            // margin-top:60px;
            // margin-right: 59px;
            position: absolute;
            img {
                width:22px;
            }
        }
    }
 }
  @media only screen and (max-width: 600px){
     .wrapModal{
        .modal{
            border-radius: 5px;
            width: 90%;
            .video {
                position: relative;
                overflow: hidden;
                width: 100%;
                padding-top: 56.25%;
                //max-width: 735px;
                #iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    bottom: 0;
                    right: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        
            
        }
        .exit {
            position: absolute;
            top:20px;
            right:20px;
            // margin-top:60px;
            // margin-right: 59px;
            position: absolute;
            img {
                width:18px;
            }
        }
    }
 }

</style>
