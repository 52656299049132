<template>
  <div class="create-blog-wrapper">
    <div class="input-wrapper">
      <span class="title">Title: (1 - 255 characters)</span>
      <input type="text" maxlength="255" v-model="blog.title">
    </div>
    <div class="input-wrapper">
      <span class="title">Description: (1 - 500 characters)</span>
      <input type="text" maxlength="500" v-model="blog.description">
    </div>
    
    <div class="input-wrapper">
      <span class="title">Categories: </span>
      <div class="setCheck">
        <div class="itemCheck" v-for="(caat, index) in categoriseApi" :key="index">
          <input type="checkbox"  name="selectCate" :id="caat.cat_id" :value="caat.cat_id" v-model="checkedList" >
          <label class='caat' :for="caat.cat_id">{{caat.cat_name}}</label>
        </div>
      </div>
    </div>

    <div class="input-wrapper">
      <span class="title">Date: (yyyy-mm-dd)</span>
      <input type="text" @keypress="validate($event)" placeholder="yyyy-mm-dd" maxlength="10" v-model="blog.date">
    </div>
    <div class="input-wrapper">
      <span class="title">Minutes to read:</span>
      <input type="number" max="255" v-model="blog.readTime">
    </div>
    <div class="input-wrapper">
      <span class="title">Author: (1 - 255 characters)</span>
      <input type="text" maxlength="255" v-model="blog.author">
    </div>
    <div class="input-wrapper">
      <span class="title">Link: (1 - 255 characters)</span>
      <input type="text" maxlength="255" v-model="blog.link">
    </div>
     <div class="input-wrapper">
      <span class="title">Image small (THUMB for sharing - 1.91:1 aspect ratio):</span>
      <input ref="thumb" type="file" accept=".png, .jpg, .jpeg" @change="setsmallImage($event)">
    </div>
     <div class="input-wrapper">
      <span class="title">Thumb alt: (1 - 255 characters)</span>
      <input type="text" maxlength="255" v-model="blog.thumb_alt">
    </div>
    <div class="input-wrapper">
      <span class="title">Thumb name: (1 - 255 characters)</span>
      <input type="text" maxlength="255" v-model="blog.thumb_name">
    </div>
    <div class="input-wrapper">
      <span class="title">Image main: (0 - 1.5mb)</span>
      <input id="image" type="file" accept=".png, .jpg, .jpeg" @change="setImage($event)">
    </div>
    <div class="input-wrapper">
      <span class="title">Image alt: (1 - 255 characters)</span>
      <input type="text" maxlength="255" v-model="blog.img_alt">
    </div>
    <div class="input-wrapper">
      <span class="title">Image name: (1 - 255 characters)</span>
      <input type="text" maxlength="255" v-model="blog.img_name">
    </div>
    <div class="input-wrapper">
      <span class="title">Content: (1 - 60 000 characters)</span>
      <vue-editor v-model="blog.content"></vue-editor>
      <div class="preview">
        <div class="title">Preview:</div>
        <div v-html="getPreview" class="content">
        </div>
      </div>
    </div>
    <div class="btn-wrapper">
      <div @click="resetPage" class="btn reset">
        Reset
      </div>
      <div @click="create" class="btn">
        Create
      </div>
    </div>
  </div>
</template>

<script>
import { api } from '@/api'
import { VueEditor } from "vue2-editor";
export default {
  name: 'createBlog',
  components: {
    VueEditor
  },
  data () {
    return {
      blog: {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: null,
        content: "<h1>Some initial content</h1>",
        link: null,
        thumb: null,
        thumb_name: null,
        thumb_alt: null,
        image: null,
        img_alt: null,
        img_name: null,
        type: 0
      },
      required: {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: null,
        content: "<h1>Some initial content</h1>",
        link: null,
      },
      categoriseApi: null,
      checkedList:[]

    }
  },
  created() {
    this.setCategory()
  },
  methods: {
    setImage (e) {
      if (e.target.files && e.target.files[0].size >= 1500000) {
        alert('image too big')
        document.querySelector('#image').value = null
        return
      }
      this.blog.image = e.target.files[0]
    },
    setsmallImage (e) {
      if (e.target.files && e.target.files[0].size >= 1500000) {
        alert('thumb is too big')
        this.$refs.thumb.value = null
        return
      }
      this.blog.thumb = e.target.files[0]
    },
    create () {
      if (!this.checkAllParams()) {
        return
      } else {
        let params = new FormData()
        for (var variable in this.blog) {
          console.log(this.blog[variable]);
          if (this.blog[variable] !== null) {
            params.append(variable, this.blog[variable])
          }
        }
        ///// IZMENITI
        params.append('categories', JSON.stringify(this.checkedList))
        api.createBlog(params).then(() => {
          this.resetPage()
          alert('blog created successfuly')
        }).catch(e => {
          console.log(e);
          alert('error creating blog')
        })
      }
    },
    validate(evt) {
      var theEvent = evt || window.event;

      // Handle paste
      if (theEvent.type === 'paste') {
          key = event.clipboardData.getData('text/plain');
      } else {
      // Handle key press
          var key = theEvent.keyCode || theEvent.which;
          key = String.fromCharCode(key);
      }
      var regex = /[0-9-]|\./;
      if( !regex.test(key) ) {
        theEvent.returnValue = false;
        if(theEvent.preventDefault) theEvent.preventDefault();
      }
    },
    checkAllParams () {
      if(this.checkedList <= 0 ){
        alert('You must have at least one category selected!')
          return false
      }
      for (var variable in this.required) {
        if (this.blog[variable] === null || this.blog[variable] === '') {
          alert(this.getName(variable) + ' is missing!')
          return false
        }
      }
      return true
    },
    getName (variable) {
      if (variable === 'img_alt') {
        return 'image alt'
      }
      if (variable === 'img_name') {
        return 'image name'
      }
      if (variable === 'readTime') {
        return 'minutes to read'
      }
       if (variable === 'thumb') {
        return 'thumb image'
      }
      if (variable === 'thumb_name') {
        return 'thumb name'
      }
      if (variable === 'thumb_alt') {
        return 'thumb alt'
      }
      return variable
    },
    resetPage () {
      this.blog = {
        title: null,
        description: null,
        date: null,
        readTime: null,
        author: null,
        content: "<h1>Some initial content</h1>",
        link: null,
        image: null,
        img_alt: null,
        img_name: null,
        type: 0
      }
      document.getElementById('image').value= null;
    },
    async setCategory(){
     await api.getNewsCategories().then(res => {
        console.log(res.data.data)
        this.categoriseApi = res.data.data
      }).catch(e => {
        console.log(e)
      })
    }
  },
  computed: {
    getPreview () {
      return this.blog.content.replaceAll('&lt;', '<').replaceAll('&gt;','>')
    }
  }
}
</script>

<style lang="scss" scoped>
.create-blog-wrapper {
  padding-bottom: 30px;

  .btn-wrapper {
    display: flex;
    flex-direction: row;

    .btn:first-child {
      margin-right: 20px;
    }
  }
  .btn {
    height: 50px;
    width: 170px;
    line-height: 52px;
    color: white;
    // font-family: 'Gotham Pro Medium';
    text-align: center;
    background: $main-color;
    border-radius: 52px;
    cursor: pointer;
    transition-duration: .3s;
    margin-bottom: 40px;
  }
  .btn:hover {
    background: #FF4B49;
  }
  .btn.reset {
    background: white;
    border: 1px solid $main-color;
    color: $main-color;
  }
  .input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .title {
      margin-bottom: 5px;
    }
    
    .setCheck{
      display: flex;
      flex-wrap: wrap;


      .itemCheck{
        margin-right:20px;
        word-wrap: unset;
        padding: 10px ;
      }
    }
  }
  .preview {
    margin-top: 30px;
    margin-bottom: 30px;

    .content {
      border: 1px solid rgb(204, 204, 204);
      padding: 10px;
    }
  }
}
</style>
