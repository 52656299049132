<template>
  <div class="newsroom">
     <hero /> 
     <latestPress @gopress="goPress"/>
     <!-- <mediaAssets :changeSide="true" @gotoassets="goToAssets"/> -->
      <mediaAssets @gotoassets="goToAssets" />
     <footerUp :white="true"/>
     <footerDown :gray="true" v-if="!showMobileNav" />
     <div v-else style="height:60px" ></div>
  </div>
</template>

<script>
import hero from '../components/newsroom/hero.vue'
import latestPress from '../components/newsroom/latestPress.vue'
import mediaAssets from '../components/newsroom/mediaAssets.vue'
import footerUp from '../components/newsroom/footerUp.vue'
import footerDown from '../components/newsroom/footerDown.vue'
export default {
  name: "newsroom2",
  components:{
    hero,
    latestPress,
    mediaAssets,
    footerUp,
    footerDown
  },
  data () {
    return {
      description: 'This is the home of all our latest news, media, and press releases.',
      title: 'Newsroom',
      showMobileNav: false

    }
  },
  created () {
      window.addEventListener("resize", this.resize)
      this.resize()
  },
  destroyed () {
      window.removeEventListener("resize", this.resize)
  },
   metaInfo () {
        return {
        title: 'Carvisor',
        titleTemplate: '%s - ' + this.title ,
        meta:[
                {
                    vmid: 'description',
                    name: 'description',
                    content: this.description
                },
                {
                    vmid: 'og:description',
                    property: 'og:description',
                    content:  this.description
                },
                {
                    vmid: 'og:title',
                    property: 'og:title',
                    content: this.title 
                },
                // {
                //     hid: 'keywords',
                //     name: 'keywords',
                //     content:  this.tempData.title
                // },
                 {
                    vmid: 'og:image',
                    // name: 'og:image',
                    itemprop: 'image',
                    property:'og:image',
                    content: this.baseURLImg
                }
            ]
        }
    },
  methods: {
      goToAssets () {
         // this.$router.push('/mediaAssetsPage')
      },
       goPress () {
         // this.$router.push('/pressReleases')
     },
     resize () {
      if (window.innerWidth <= 500) {
      this.showMobileNav = true
      } else {
      this.showMobileNav = false
      }
    }
  }

}
</script>

<style lang="scss" scopeed>
.newsroom{
  width:100wh;
}

</style>