<template>
  <div class="wrappLogin" @click="showContent=!showContent" >
      <span class="loginImg">
          <span v-if="getuserData && getuserData.image && showImage" class="imgRoundWrapp"><img class="imgRound" :src="getMainImage()" ></span>
          <span v-else-if="green && getLoged" class="imgRoundWrapp"><img class="imgRound" :src="require('@/assets/images/icons/loginDark.svg')" ></span>
          <span v-else-if="green" class="imgRoundWrapp"><img class="imgRound" :src="require('@/assets/images/icons/logoutDark.svg')" ></span>
        <span v-else-if="getLoged" class="imgRoundWrapp"><img class="imgRound" :src="require('@/assets/images/icons/login.svg')" ></span>
        <span v-else class="imgRoundWrapp"><img class="imgRound" :src="require('@/assets/images/icons/logout.svg')" ></span>
          <span v-if="green || orange || lightGr"  class="arrowRoundWrapp"><img :src="require('@/assets/images/loginArrowGreenBack.svg')"></span>
          <span v-else  class="arrowRoundWrapp"><img :src="require('@/assets/images/loginArrow.svg')"></span>
      </span><!--loginImg --> 

       <div v-if="showContent  && getLoged" class="loginContent wide" style="width:193px">
        <router-link  to="/profile" class="div">
            <span>Personal information</span> 
            <span><img class="arrow" :src="require('@/assets/images/loginArrow.svg')" ></span>
        </router-link>

        <router-link  to="/valuations" class="div">
            <span>My reports</span> 
            <span><img class="arrow" :src="require('@/assets/images/loginArrow.svg')" ></span>
        </router-link>

        <router-link  to="/favourites" class="div">
            <span>Favourites</span> 
            <span><img class="arrow" :src="require('@/assets/images/loginArrow.svg')" ></span>
        </router-link>

         <div class="div"  @click="logout">
            <span>Log out</span>
            <span><img class="arrow" :src="require('@/assets/images/loginArrow.svg')" ></span>
        </div>
      </div><!-- loginContent2 -->

      <div v-else-if="showContent " class="loginContent">
        <router-link to="/login" class="div">
            <span>Log In</span> 
            <span><img class="arrow" :src="require('@/assets/images/loginArrow.svg')" ></span>
        </router-link>

        <router-link  to="/signin" class="div">
            <span>Sign Up</span>
            <span><img class="arrow" :src="require('@/assets/images/loginArrow.svg')" ></span>
        </router-link>

      </div><!--end loginContent -->

      

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { api, baseURL } from '@/api'

export default {
    name: "headerLogin",
    props: ['green', 'orange', 'lightGr', 'close'],
    data () {
        return{
            showContent: false,
            showImage: true
        }
    },
    methods: {
        logout(){
                api.logOutUser().then(() => {
                localStorage.removeItem('sid')
                localStorage.removeItem('userName')
                this.$store.commit('setuserData', {name:'', email:'',img:''})
                this.$store.commit('changeLoged', false)
                this.$store.commit('setUserName', null)
                this.$store.commit('setUserPrivilege', 0)
                this.$router.push({ name: 'Home' })
                }).catch(() => {
                localStorage.removeItem('sid')
                localStorage.removeItem('userName')
                this.$store.commit('setuserData', {name:'', email:'',img:''})
                this.$store.commit('changeLoged', false)
                this.$store.commit('setUserName', null)
                this.$store.commit('setUserPrivilege', 0)
                })
            },
            getMainImage () {
                return baseURL + this.getuserData.image
            },
        
    },
     watch: {
    'close': {
        handler () {
            // console.log(newVal)
            this.showContent= false
            }
        //   ,
        //   deep: true,
        //   immediate: true
        },
        getuserData:{
            handler(){
                this.showImage= false
                setTimeout(() => {
                  this.showImage= true
            }, 200);
              
            },
            deep: true,
            immediate: true
        }
    },
    computed: {
    ...mapGetters(['getLoged','getuserData'])
    }

}
</script>

<style lang="scss" scoped>
.wrappLogin{
    display: inline-block;
    margin-left:25px;
    margin-top: auto;
    position: relative;
    width: 35px;
    cursor: pointer;
    .loginImg{
        display: flex;
        height: 20px;
        margin-top: auto;
        justify-content: space-between!important;
        // align-items:flex-end;
        position: absolute;
        bottom: -4px;
        width: 100%;
        .imgRoundWrapp{
            // position: absolute;
            // bottom: -2px;
            // left: 0;
            width: 20px;
            height:20px;
            .imgRound{
              object-fit: cover;
              border-radius: 50%;
              height: 100%;
              width: 100%;
            }
         
        }
        .arrowRoundWrapp{
            width: fit-content;
            height:20px;
            padding-top: 1px;
            display: flex;
            justify-content: center;
            align-items: center;
            img{
                height: 6px;
                
            }
        }
    }


    .loginContent{
        position: absolute;
        width: 154px;
        height: 70px;
        right: -10px;
        top: 46px;
        z-index: 1000;
        background: #ffffff;
        /* analytic container shadow */

        // box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.05);
        // box-shadow: h-offset v-offset blur spread color ;
        // box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.05);
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
        border-radius: 5px;
        padding: 6px 11px 7px 10px;
        .div{
            text-decoration: none;
            // font-family: Gotham Pro;
            font-style: normal;
            font-weight: normal;
            // font-size: 14px;
            // line-height: 26px;
            font-size: 15px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: center;
            color: #4C4C52;
            box-sizing: border-box;
            //height: calc(50% - 7px);
            height: 50%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            span{
                display: flex;
                justify-content: center;
                align-items: center;
                .arrow {
                    transform: rotate(-90deg);
                }
            }
        }
        .div:hover{
            color: #062931;
        }
        
    }
    .wide {
        height: 140px;
        .div {
            height: 25%;
        }
    }
}
@media only screen and (max-width: 768px){
    .wrappLogin{
         .loginContent{
            right: 0px;
         }

    }
}

</style>