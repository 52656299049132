<template>
    <div class="wrappSignupModal" >
        <div class="exit" @click="$emit('closeModal')" @mouseenter="hover=true" @mouseleave="hover=false">
            <img v-if="!hover" :src="require('@/assets/images/x.svg')">
            <img v-else :src="require('@/assets/images/xHover.svg')">
        </div>
        <div class="singinComponent">
            <div class="singinTitle"> Sign Up</div>

            <div v-if="false" v-facebook-login-button="appId" class="buttonSocial">
                <span>Continue with Facebook</span>
                <span ><img 
                    :src="require(widthPage?'@/assets/images/fsingin.svg':'@/assets/images/fsinginBright.svg')" />
                </span>
            </div>
            
            <div v-google-signin-button="clientId" class="buttonSocial">
                <span>Continue with Google</span>
                <span>
                    <img :src="require('@/assets/images/gsinginBright.svg')" />
                    <!-- <img :src="require(widthPage? '@/assets/images/gsingin.svg':'@/assets/images/gsinginBright.svg')" />                -->
                </span>
            </div>

            <div class="or">
                <div class="line"></div>
                or
                <div class="line"></div>
            </div>
            <div class="round">
            <input v-model="user.usr_name" :class="nameErr? 'basic inputErr':'basic '" @keyup="nameErr=false"  placeholder="Name" type="text" v-hideMoblieNav autofocus>
                <img v-if="nameErr" src="../../assets/images/errimg.svg" />
            </div>
            <div class="round">
            <input v-model="user.usr_email" :class="emailErr? 'basic inputErr':'basic'" @keyup="emailErr=false"  placeholder="E-mail" type="text" v-hideMoblieNav>
                <img v-if="emailErr" src="../../assets/images/errimg.svg" />
             </div>
            <div class="round">
            <input v-model="user.usr_password" :class="passwordErr? 'basic inputErr':'basic'" @keyup="passwordErr=false" placeholder="Password" type="password" v-hideMoblieNav>
                <img v-if="passwordErr" src="../../assets/images/errimg.svg" />
            </div>
            <div class="round">
            <input v-model="user.reTypePassword" :class="passwordErr? 'basic  inputErr':'basic'" @keyup="passwordErr=false" placeholder="Re-type password" type="password" v-hideMoblieNav>
                <img v-if="passwordErr" src="../../assets/images/errimg.svg" />
            </div>
            
             <p v-if="msg" class="errMsg"><img src="../../assets/images/errImgMsg.svg" />{{msg}}</p>
             <rember :remember="user.remember" @rememberSend="user.remember=!user.remember" />
            <div class="buttonSend" @click="checkForm" >See my analyses</div>
            <div class="terms" >By clicking the button you agree to our <router-link to="/terms" class="bold">Terms and Privacy policy.</router-link></div>
            <div class="haveaccount">Already have an account? <span class="orange" @click="$emit('setlogin')" >Log in</span></div>

        </div>
    </div>
</template>

<script>
import rember from './rememberMe.vue'
import helper from '@/js/helperMail.js'
import { api }  from '@/api'
import GoogleSignInButton from 'vue-google-signin-button-directive'
import FacebookLoginButton from 'vue-facebook-login-button-directive'
// import eventBus from '@/js/eventBus.js'

export default {
    name: 'singinComponent',
    directives: {
        GoogleSignInButton,
        FacebookLoginButton
    },
    components: {
        rember
    },
    data () {
        return {
            user:{
                usr_name: '',
                usr_email: '',
                usr_password: '',
                reTypePassword:'',
                remember: true,
            },
            nameErr: false,
            emailErr: false,
            passwordErr: false,
            msg: '',
            widthPage: null,
            // id app google
            clientId: '306827492643-at00dqjbklkflbi52fnd1ckrkthpahjj.apps.googleusercontent.com',
            // fb
            appId: 3821175311320178,
            hover:false

        
        }
    },
     created(){
        window.addEventListener("resize", this.resize)
        this.resize()
    },
    methods: {
        //FACEBOOK 
        OnFacebookAuthSuccess(idToken){
             const params={
                token:idToken
            }
            api.loginFacebook(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                // this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
                this.msg="Something went wrong, please try again."
                console.log(e.message);
            })

        },
        OnFacebookAuthFail(error){
            console.log(error)
            this.msg="Something went wrong, please try again."
       
        },
        // end facebook
        // GOOGLE
        OnGoogleAuthSuccess (idToken) {
            const params={
                token:idToken
            }
            api.loginGoogle(params).then(res => {
               console.log(res)
                this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                // this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                // this.$router.go(-1)
            }).catch(e => {
                this.msg="Something went wrong, please try again."
                console.log(e.message);
            })
            
        },
        OnGoogleAuthFail (error) {
            if(error && error.error == 'popup_closed_by_user')
                this.msg= 'Popup closed by user'
            else
                this.msg="Something went wrong, please try again."
        },

        // end google
        checkForm(){

            let error = false
            this.clearErr()

            if(this.user.usr_name === '' || this.user.usr_name.trim().length == 0  ){
                error= true
                 this.nameErr = true
                 this.msg= "Name must be entered."
            }
            if(this.user.usr_email === '' || this.user.usr_email.trim().length == 0 || !helper.matchemail(this.user.usr_email) ){
                error= true
                this.emailErr = true
                this.msg= this.msg.length===0 ? "You must enter a valid email.": "All fields must be entered correctly"
            }
            if(this.user.usr_password === '' || this.user.usr_password.length <= 6  ){
                error= true
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "The password must contain a minimum 7 characters.": "All fields must be entered correctly"
            }else if(this.user.usr_password !== this.user.reTypePassword) {
                error= true
                this.passwordErr = true
                this.msg= this.msg.length===0 ? "Passwords don’t match.": "All fields must be entered correctly"
            }
            if(error) {
                return
            }else {
                // this.$emit('signin', this.user)
                this.signin(this.user)
                console.log('SIGNIN---')
            }

        },
         async signin (param) {
                await this.$recaptchaLoaded()
                const token = await this.$recaptcha('signup').catch(() => {})
                let params= new FormData();
                params.append( 'token' , token)
                for(let propertyObj in param ){
                    if(propertyObj === 'remember' ) continue
                    // if(propertyObj === 'usr_password') 
                    // params.append(propertyObj, param[propertyObj]) // sha256(param[propertyObj])
                    params.append(propertyObj, param[propertyObj])
                }
                api.signUpUser(params).then(res =>{
                    console.log(res)
                    // if(param.remember)
                    let sendData={
                        email:param.usr_email, 
                        password:param.usr_password
                        }
                        this.loginuser(sendData)
                    // else
                    //     this.$router.push({ name: 'login' })

                }).catch(e => {
                    console.log(e )
                    this.msg='Sign up failed, please try again'
                })
                
            },
            loginuser(e) {
                let params={
                    email:e.email,
                    password: e.password
                }
                console.log(e.email)
            api.logInUser(params).then(res => {
                console.log(res)
                 this.$store.commit('setuserData', res.data)
                localStorage.setItem('sid', res.data.sid)
                localStorage.setItem('userName', res.data.name)
                this.$store.commit('changeLoged', true)
                this.$store.commit('setUserName', res.data.name)
                this.$store.commit('setUserPrivilege', res.data.type)
                // this.$router.push({ name: 'profile' })
                this.getUserData(res.data.sid) 
                
       
            }).catch(e => {
             this.msg='Username or password incorrect'
             console.log(e.message);
            })

        },
        getUserData(sid) {
            let params = {
                sid : sid
            }
            api.getUserData(params).then(res => {
                console.log('res')
                 console.log(res.data.data)
                this.$store.commit('setuserData', res.data.data)
                // this.$emit('closeModal')
            }).catch(e => {
             console.log(e)
            })
        },
        clearErr () {
            this.nameErr = false
            this.emailErr = false
            this.passwordErr = false
            this.msg = ''
        },
        clearData () {
            this.user.usr_name = ''
            this.user.usr_email = ''
            this.user.usr_password = ''
            this.user.reTypePassword= ''
        },
        resize () {
            if (window.innerWidth <= 768) {
                this.widthPage = false
            } else {
                this.widthPage = true
            }
        }
  
    },
    destroyed() {
        window.removeEventListener("resize", this.resize);
    }
}
</script>

<style lang="scss" scoped>
 @import '@/assets/css/singUp.scss';
 .wrappSignupModal{
     background: #FFFFFF;
     border-radius: 5px;
     width: 486px;
    //  height: calc(785px - 100px);
    //  padding-top:40px;
     padding-top:43px;
    //  padding-bottom: 51px;
     position: relative;
     padding-bottom: 47px;

    .exit{
        position: absolute;
        // right:10px;
        // top:10px;
        right:20px;
        top:20px;
        cursor: pointer;
        text-align: right;
        img{
            width:14px;
        }
    }
    .terms{
        // align-self: flex-end;
        // font-family: Gotham Pro;
        // font-style: normal;
        // font-weight: normal;
        // font-size: 12px;
        // line-height: 20px;
        /* or 167% */


        /* Gray 3 text */

        // color: #9498A0;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 25px;
        /* identical to box height, or 208% */


        /* Gray turquoise */

        // color: #5A7E87;
        color: rgba(70, 127, 143, 0.8);
        margin-top: 13px;
        .bold{
            
            text-decoration: none;
            // color: #9498A0;
            // color: $link ; // #5A7E87
            color: rgba(70, 127, 143, 0.8);
            font-weight: bold;
        }
        .bold:hover{
            //  color: $link-hover ;
            //  color:red;
            opacity: .8;
        }
        
    }
    .showErr{
        height:initial;
    }


 }
.singinComponent{
    //  background-color:rgba(48, 219, 241, 0.609);
        width: 406px;
        // height: 711px;
        margin: auto;

        .singinTitle {
            //     font-family: 'Gotham Pro Medium';
            // //    font-family: Gotham Pro;
            //     font-style: normal;
            //     font-weight: 500;
            //     font-size: 28px;
            //     line-height: 36px;
            //     /* identical to box height, or 129% */
            // margin-bottom: 32px ;
            margin-bottom: 42px ;
            font-style: normal;
            font-weight: bold;
            font-size: 36px;
            line-height: 40px;
            /* black */
            color: #062931;
        }
        .haveaccount{
        //   color: red;
         margin-top: 18px; // 22px - 4 px up
        }
       
    }
@media only screen and (max-width: 500px){
    .wrappSignupModal{
        position: absolute;
        bottom: 0;
        left:0;
         z-index:1001;
        width:100%;
        height: fit-content;
        // max-height: 90%;
        max-height: 94%;
        padding-top:29px;
        padding-bottom: 60px;
        box-sizing:border-box;
        box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.18);
        border-radius: 20px 20px 0px 0px;
        overflow: auto !important;
         .exit{
            position: absolute;
            right:15px;
            top:20px;
            cursor: pointer;
            text-align: right;
            img{
                width:20px;
            }
         }
    }

    .singinComponent{
    //  background-color:rgba(48, 219, 241, 0.609);
        width: 334px;
        height: initial;
        margin-bottom: 21px;

        .singinTitle {
            font-size: 28px;
            line-height: 30px;
            margin-bottom: 50px;
        }
        .terms{
           
            // padding-bottom: 100px!important;
             padding-bottom: 14px!important;
        }
       
    }
}

</style>
<style lang="scss">
 .wrappSignupModal{

    .or {
            color: #062931;
            // padding-top: 7px; // 15 px margin from input field // 22 
            padding-top: 4px;
            padding-top: 0px;
            padding-bottom: 23px;
    }
    .basic {
        // margin-bottom: 15px ;
    }
    
 }
 @media only screen and (max-width: 500px){
     .wrappSignupModal{

    .or {
        
        
            color: #062931;
            padding-top: 2px; // 15 px margin from input field // 22 
            padding-bottom: 19px;
    }
 }
 }

</style>