<template>
  <div class="go-top-wrapper">
    <img @click="scrollToTop" :src="require('@/assets/images/goTop2.png')" alt="go to top">
    <img class="hover" @click="scrollToTop" :src="require('@/assets/images/goTopHover2.png')" alt="go to top">
  </div>
</template>

<script>
export default {
  name: 'goTop',
  methods: {
    scrollToTop () {
      window.scrollTo({top: 0, behavior: 'smooth'});
    }
  }
}
</script>

<style lang="scss" scoped>
.go-top-wrapper {
  // position: absolute;
  // bottom: 80px;
  // right: 30px;
   z-index: 2000; // set paretn z-index to not limit the z-index of the child
   position:relative;

  @keyframes example {
    0%   {margin-bottom: 0px}
    50% {margin-bottom: 10px}
    100% {margin-bottom: 0px}
  }

  img {
    cursor: pointer;
    animation-name: example;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    height: 60px;
    width: 60px;
    position: fixed;
    bottom: 80px;
    right: 30px;
    z-index: 1001;
  }
  img.hover {
    opacity: 0;
    transition-duration: .3s;
    z-index: 1001;
  }
  img.hover:hover {
    opacity: 1;
  }
}
@media only screen and (max-width: 768px){
  .go-top-wrapper {
    img {
      height: 50px;
      width: 50px;
    }
  }
}
</style>
