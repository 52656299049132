<template>
  <div class="login">
    <div class="title">
			<h1>
				Log in
			</h1>
		</div>
    <loginComponent @login="loginuser" @clearmsgerr="msg = ''" :msgerr="msg" />
  </div>
</template>

<script>
import { api } from "@/api";
import loginComponent from "@/components/loginComponents/loginComponent";
export default {
  name: "login",
  components: {
    loginComponent,
  },
  data() {
    return {
      msg: "",
    };
  },
  mounted() {},
  methods: {
    loginuser(e) {
      api
        .logInUser(e)
        .then((res) => {
          console.log(res);
          this.$store.commit("setuserData", res.data);
          localStorage.setItem("sid", res.data.sid);
          localStorage.setItem("userName", res.data.name);
          this.$store.commit("changeLoged", true);
          this.$store.commit("setUserName", res.data.name);
          this.$store.commit("setUserPrivilege", res.data.type);
          // this.$router.push({ name: 'profile' })
          this.getUserData(res.data.sid);
          this.$router.go(-1);
        })
        .catch((e) => {
          this.msg = "Username or password incorrect";
          console.log(e.message);
        });
    },
    getUserData(sid) {
      let params = {
        sid: sid,
      };
      api
        .getUserData(params)
        .then((res) => {
          console.log("res");
          console.log(res.data.data);
          this.$store.commit("setuserData", res.data.data);
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  watch: {
    // 'step.successShow':{
    //     handler(newVal){
    //         if(newVal){
    //             setTimeout(() => {
    //                 this.step.successShow = false
    //                 this.step.enterPassShow= true
    //             }, 10000)
    //         }
    //     }
    // }
  },
};
</script>

<style lang="scss" scoped>
.login {
  display: flex;
  max-width: 100vw;
  height: calc(100vh - 80px);
  justify-content: center;
	flex-direction: column;
  // align-items: center;
  // padding-top: 87px;
  // padding-top: 98px;
	.title {
		height: 185px;
		width: 100%;
    background: linear-gradient(116.78deg, #104756 13.98%, #3D7484 75.44%);
		display: flex;
		justify-content: center;
		position: relative;
		margin-bottom: 60px;
		h1 {
			margin: 0;
			position: absolute;
			bottom: 45px;
			font-size: 36px;
			color: #fff;
		}
	}
}
@media only screen and (max-width: 500px) {
  .login {
    height: initial;
    overflow-y: auto;
    // padding-top: 73px;
		.title {
			h1 {
				left: 16px;
				bottom: 30px;
			}
		}
  }
}
</style>
