<template>
  <div class="roundAllShareComponent">
      <div v-if="!isblog" class="downloadDiv"
           @click="$emit('download')">
           <img :src="require('../../assets/images/icons/downloadGreen.svg')" />
      </div>
      <div class="iconShare" 
          @mouseover="hoverShare=true"
          @mouseout="hoverShare=false"
          @click="controlShowModal(true)">
          <img :src="hoverShare ? require('../../assets/images/icons/shareGreenHoverLighter.svg') : require('../../assets/images/icons/shareGreen.svg')" />
      </div>
      <modalShare 
        v-if="showmodal"
        @closeshowmodal="controlShowModal(false)" 
        :author="author" 
        :descriptionP="descriptionP"
        :titleP="titleP"
        :isblog="isblog"
      />
  </div>
</template>

<script>
// import shareIcon from '../shareComponents/ShareIcon.vue'
import modalShare from './modalShare.vue'
import eventBus from '@/js/eventBus'
export default {
    name: 'roundAllShareComponent',
    components: {
      modalShare
    },
    props: ['titleP', 'descriptionP', 'author', 'isblog'],
    computed: {
    },
    data () {
        return {
          supportsPassive : false ,
          showmodal: false ,
          hoverShare: false
        }
    },
    created() {
        try {
            window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
                get:  this.supportsPassive = true  
            }));
        } catch(e){
             console.log(e)
         }
    },
    methods: {
      controlShowModal(setVisible){
        // hack for zindex of the mobile nav bcs i dont know better atm
        if (document.querySelector('.mobileNav') && setVisible) {
          console.log(document.querySelector('.mobileNav'));
          document.querySelector('.mobileNav').style.zIndex = "1"
        } 

        if (!setVisible && document.querySelector('.mobileNav')) {
          console.log("i was called");
          document.querySelector('.mobileNav').style.zIndex = "1000"
        } 

        this.showmodal = setVisible
        eventBus.$emit('ofgotop', !setVisible)
        this.showModalEmit(this.showmodal)

      },
      showModalEmit(e) {
        let wheelOpt = this.supportsPassive ? { passive: false } : false;
        let wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';
        if(e){
          // call this to Disable
          window.addEventListener('DOMMouseScroll', this.preventDefault, false); // older FF
          window.addEventListener(wheelEvent, this.preventDefault, wheelOpt); // modern desktop
          window.addEventListener('touchmove', this.preventDefault, wheelOpt); // mobile
          window.addEventListener('keydown', this.preventDefaultForScrollKeys, false);
          window.addEventListener("mousedown", this.preventDefault, { passive:false });
          document.body.style.overflow = "hidden"
        }else{
          window.removeEventListener('DOMMouseScroll', this.preventDefault, false);
          window.removeEventListener(wheelEvent, this.preventDefault, wheelOpt); 
          window.removeEventListener('touchmove', this.preventDefault, wheelOpt);
          window.removeEventListener('keydown', this.preventDefaultForScrollKeys, false);
          window.addEventListener("mousedown", this.preventDefault, { passive:false });
          document.body.style.overflow = "initial"
        }
      },
      preventDefault(e) {
        e.preventDefault()
      },
      preventDefaultForScrollKeys(e) {
        var keys = {37: 1, 38: 1, 39: 1, 40: 1}
        if (keys[e.keyCode]) {
            this.preventDefault(e)
            return false
        }
      }
  },


}
</script>

<style lang="scss" scoped>
.roundAllShareComponent { 
          //  // margin-top:16px;
          //  background: #ffffff;
          //   margin-left: 130px;
          //   // font-family: 'Gotham Pro Medium';
          //   font-style: normal;
          //   font-weight: 500;
          //   font-size: 15px;
          //   line-height: 22px;
          //   width: 297px;
          //   color: #062931;
          //   height: fit-content;
          //   position: -webkit-sticky;
          //   position: sticky;
          //   top: 20px;
          //   padding-bottom:20px;
         z-index: 1020;
          display: flex;
          width: fit-content;
          background: #ffffff;


          .downloadDiv{
            margin-right: 10px;
            cursor: pointer;
            width: 40px;
            height: 40px;
            border-radius: 20px;
            background: #467F8F;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .downloadDiv:hover{
            background: #134753;
          }
          .iconShare {
            cursor: pointer;

          }

        }
</style>