<template>
  <div :class="getIsSafari ? 'newPrice safari' : 'newPrice'" v-waypoint="{ active: activeWayPoint, callback: onWaypoint, options: intersectionOptions }">
          <div v-if="blur" class="overlay">
            <div v-if="!falseData" @click="$emit('unlock')" class="overlay-btn">
                <img :src="require('@/assets/images/lock.png')" alt="lock image">
                Unlock results
            </div>
          </div>
          <div class="oragngeLIne"></div>

          <div class="wrapValue">
            <div class="txt">
                <p class="title" v-if="sampleReport" >{{title}}</p>
                <p class="title" v-else-if="getAllSelected.odometer !== null">{{getAllSelected.make.value + ' ' +getAllSelected.model.value}}</p>
               
                <p class="description" v-html="setDescription()"></p>
            </div>
            <div class="price" v-if="price">
                 <p class="txtPrice">Price:</p>
                <p v-if="blur" class="p"> $</p>
                <animateNumber v-else class="p" :animlength="500" :end="price" :start="0" :value="'$'" :line="line"></animateNumber>
                <span  class="lineAnimateNum"></span>
            </div>
          </div>
          <div class="wrapQuestioin">
               <question />
          </div>
        </div>
</template>

<script>
import animateNumber from '@/components/animateNumber.vue'
import question from './questionNew.vue'
import { mapGetters } from 'vuex'
export default {
    name:'newPrice',
    props:{
        price:{
            required: false,
        },
        falseData:{
            required: false,
            // default: false,
            type: Boolean
        },
        blur:{
            required: false,
            default: false,
            type: Boolean
         }
    },
    components: {
      animateNumber,
      question
    },
    data () {
      return{
        intersectionOptions: {
              root: null,
              rootMargin: '0px 0px 0px 0px',
              threshold: [0.5, 0.5],  // [0.25, 0.75] if you want a 25% offset!
             
        },
        activeWayPoint: true,
        line:true,
        title: 'Toyota Aqua',
        sampleReport: false
      }
    },
    methods: {
      onWaypoint ({ going }) {
            // going: in, out
            // direction: top, right, bottom, left
            if (going === this.$waypointMap.GOING_IN) {
                this.animeNumbers(this.price)
                this.activeWayPoint = false
               }

        },
        animeNumbers (  ) {
        // for (let i=1; i<=number; i++) {
        // setTimeout(() => {
        //             this.num = i
        //     }, 2000)
        //  }
        },
        setDescription(){
            if(this.getAllSelected
                &&  this.getAllSelected.year != null 
                &&  this.getAllSelected.transmission != null 
                &&  this.getAllSelected.body != null 
                &&  this.getAllSelected.fuel != null
                &&  this.getAllSelected.engine != null 
                &&  this.getAllSelected.odometer != null){
              return  this.getAllSelected.year.value + ', ' +this.getAllSelected.transmission.value + ', ' +this.getAllSelected.body.value + ', ' +this.getAllSelected.fuel.value+ ', ' +this.getAllSelected.engine.value + ', ' +this.getAllSelected.odometer.value+' km'
            }else{
                return '2015, Automatic, Hatchback, Hybrid, 1.5 L, 111111 km'
            }
        }
    },
     computed: {
        ...mapGetters(['getIsSafari','getAllSelected']),
     },
     watch:{
         getAllSelected:{
             handler(newVal){
                 if(newVal.engine!==null)
                 this.setDescription()
             },
             deep: true,
             immediate: true
         },
         '$route': {
            handler: function() {
                //  console.log(this.$route.name)
                if (this.$route.name === 'sampleReport') {
                this.sampleReport = true
                } else {
                this.sampleReport = false
                }
            },
            deep: true,
            immediate: true
         }
     }
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/statsOverlay.scss';
 .newPrice{

        margin-top: 2px;
        position: relative;
        box-sizing: border-box;
        min-height: 319px;
        background: #062931;
        padding:19px 25px; // 15 +4 -oragngeLIne
        padding-top:19px !important;
        padding-right: 21px;
        border-radius: 6px 6px 0px 0px;
        color: #fff;

        display: flex;
        justify-content: space-between;

        .oragngeLIne{
            position: absolute;
            width: 100%;
            box-sizing: border-box;
            height: 5px;
            left: 0%;
            top: -1px;
            background: #FB9100;
            border-radius: 5px 5px 0px 0px;
        }
        .wrapValue{
            .txt{
                margin-top: -5px;
                .title{
                    font-style: normal;
                    font-weight: 500;
                    font-size: 51px;
                    line-height: 55px;
                    /* or 108% */

                    // margin-top: 23px;
                    margin-top: 13px;
                    margin-bottom : 0px;
                    color: #FB9100;
                }
                .description{
                    font-style: normal;
                    font-weight: 400;
                    font-size: 17px;
                    line-height: 30px;
                    /* or 108% */

                    margin-top: 10px;
                    margin-bottom : 0px;
                    color:#CFD8DB; 

                }

            }
            .price{
                margin-top: 45px;
                position: relative;
                width:fit-content;
                p{
                    margin: 0;
                }
                .txtPrice{
                    font-weight: normal;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.05em;
                    text-transform: uppercase;
                    color: #FFFFFF;
                    opacity: 0.4;
                    // margin-bottom: 5px;
                    margin-bottom: 10px;
                }
                .p{
                    display: inline-block;
                    position: relative !important;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 36px;
                    line-height: 40px;
                    /* or 111% */

                    margin-left: 3px;

                    text-align: center;

                    /* Bg gray (6) */

                    color: #F8F8F9;
                }
            }

        }  

 }
.safari{
  // background-color: rgb(134, 94, 44) !important;
//    padding:0px 20px!important;
//    padding-right: 10px !important;
//   .txt{
//       p{
//         margin: 0 !important;
//         font-style: normal;
//         font-weight: bold;
//         font-size: 18px;
//         line-height: 26px;
//           color: $text-darker;
//       }
//     }
}
@media only screen and (max-width: 1024px){
      .newPrice{
            margin: 10px 10px 10px 12px !important;
            padding-left: 15px;
            padding-right: 15px;
            border-radius: 6px 6px 5px 6px;
      }
 }

@media only screen and (max-width: 550px) {
     .newPrice{
         display: block;
        .wrapValue{
            .txt{
                .title{
                    font-weight: 500;
                    font-size: 34px;
                    line-height: 35px;
                }
                .description{
                    font-size: 14px;
                    line-height: 20px;
                }
            }
            .price{
                margin-top: 45px;
                position: relative;
                width:fit-content;
                p{
                    margin: 0;
                }
                .txtPrice{
                    font-size: 14px;
                    line-height: 20px;
                }
                .p{
                    font-size: 34px;
                    line-height: 40px;
                    padding-left: 5px;
                }
            }

        }
      }
    
}

 @supports (-webkit-overflow-scrolling: touch) { 
     .newPrice{
             // padding-right:86px !important;
      }
        
}
</style>
<style>
.lineAnimateNum{
    position: absolute;
    width: 100%;
    height:0;
    z-index:10;
    left: 0;
    bottom:-2px;
    /* background: brown; */

    border-bottom: 3px solid  rgba(255, 159, 89, 0.6);
}
</style>