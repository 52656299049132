<template>
  <div class="html-snippets-wrapper">
    <div class="snippet-wrapper">
      <div class="title">
        Image full width
      </div>
      <textarea v-model="imgFullWidth"></textarea>
    </div>
    <div class="snippet-wrapper">
      <div class="title">
        Image float left
      </div>
      <textarea v-model="imgFloatLeft"></textarea>
    </div>
    <div class="snippet-wrapper">
      <div class="title">
        Image float Right
      </div>
      <textarea v-model="imgFloatRight"></textarea>
    </div>
    <div class="snippet-wrapper">
      <div class="title">
        Responsive iframe
      </div>
      <textarea v-model="responsiveIframe"></textarea>
    </div>
    <div class="snippet-wrapper">
      <div class="title">
        Responsive "Get a free car valuation in seconds" 
      </div>
      <textarea v-model="widget"></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name: 'htmlSnippets',
  data () {
    return {
      imgFullWidth:
      `<div style="margin: 30px 0; margin-bottom: 20px;">
<img style="width: 100%;" src="img-link" ><div style="margin-top: -20px; font-size: 12px; color: #9498A0; line-height: 17px;">Deskripcija ispod slike</div>
</div>`,
      imgFloatLeft: `<img style="float: left; margin-bottom: 20px; margin-right: 20px; width: 50%;" src="img-link" >`,
      imgFloatRight: `<img style="float: right; margin-bottom: 20px; margin-left: 20px; width: 50%;" src="img-link" >`,
      responsiveIframe: `<div style="position: relative; width: 100%; height: 0; padding-bottom: 56.25%;">
      <iframe style="position: absolute; width: 100%; height: 100%; left: 0; top: 0;" src="youtube-link" title="YouTube video player" frameborder="0" allow=""  allowfullscreen></iframe>
</div>`,
      widget:`<div style=“margin: 30px 0;“><div style=" width: 100%; min-height: 320px; background: #eeeff3; border: 1px solid #dee0e5; box-sizing: border-box; border-radius: 5px;padding: 25px 30px 0px 30px;"><div style="margin-bottom: 23px; height: 25px; width: 203px;box-sizing: border-box;display: flex; align-items: center;justify-content: center;text-transform: uppercase; border-radius: 5px; color: #ffffff; background: #103d49;font-weight: 500;font-size: 14px;letter-spacing: 0.05em;line-height: 25px;">Carvisor recommends</div><div style="font-weight: bold;font-size: 36px;line-height: 40px; color: #062931;">How much is your car worth?</div><div style="color: #062931;margin-top: 17px;margin-bottom: 45px;font-style: normal;font-weight: normal;font-size: 22px; line-height: 25px;color: #062931;">Get a free car valuation in seconds</div><a id="aSnipet" href="/priceSteps" style="font-weight:500;font-size:15px;line-height:20px;color:#ffffff;text-decoration: none; background: #ff6359;border-radius: 52px; width: 169px;height: 52px;display: flex;justify-content: center;align-items: center;margin-bottom: 64px;letter-spacing: 0.01em;">Get started</a></div><div style=“margin: 30px 0;“>` }
  }
}
</script>

<style lang="scss" scoped>
.html-snippets-wrapper {
  .snippet-wrapper {
    margin-bottom: 20px;
    textarea {
      width: 100% !important;
      height: 100px !important;
    }
  }
}
</style>
