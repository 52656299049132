<template>
  <div
    id="index"
    :class="{
      blue : customClass === 'blue',
      orange : customClass === 'orange',
      lightGr : customClass === 'lightGr',
      disappearing : $route.name === 'priceSteps' || $route.name === 'estimate' || $route.name === 'report',
      sampleReport : sampleReport
    }"
    class="background"
  >
    <div class="header-wrapper">
      <div v-if="customClass === 'blue'" class="logo-wrapper">
        <router-link to="/">
          <img id="smallLogo" :src="require('@/assets/images/logoWhite.svg')" alt="logo">
          <img id="bigLogo" :src="require('@/assets/images/logoTextWhite.svg')" alt="logo text">
          <!-- <img id="smallLogo" :src="require('@/assets/images/logo.svg')" alt="logo">
          <img id="bigLogo" :src="require('@/assets/images/logoText.svg')" alt="logo text"> -->
        </router-link>
      </div>
      <div v-else-if="customClass === 'lightGr'" class="logo-wrapper">
        <router-link to="/">
          <img id="smallLogo" :src="require('@/assets/images/logoWhite.svg')" alt="logo">
          <img id="bigLogo" :src="require('@/assets/images/logoTextWhite.svg')" alt="logo text">
          <!-- <img id="smallLogo" :src="require('@/assets/images/logo.svg')" alt="logo">
          <img id="bigLogo" :src="require('@/assets/images/logoText.svg')" alt="logo text"> -->
        </router-link>
      </div>
      <div v-else-if="customClass === 'orange'" class="logo-wrapper">
        <router-link to="/">
          <img id="smallLogo" :src="require('@/assets/images/logoWhite.svg')" alt="logo">
          <img id="bigLogo" :src="require('@/assets/images/logoTextAllWhite.svg')" alt="logo text">
        </router-link>
      </div>
      <div v-else class="logo-wrapper">
        <router-link to="/">
          <img id="smallLogo" :src="require('@/assets/images/logo.svg')" alt="logo">
          <img id="bigLogo" :src="require('@/assets/images/logoText.svg')" alt="logo text">
        </router-link>
      </div>
      <router-link v-if="sampleReport" to="/priceSteps">
        <div class="btn">
          Get an estimate
        </div>
          <!-- headerLogin v-if="!sampleReport" :green="green" :orange="orange" / -->
      </router-link>
      <div v-else v-click-outside="closeControl">
        <router-link v-if="$route.name !== 'Home'" to="/">
          <span id="how-it-works">How it works</span>
        </router-link>
        <span v-else id="how-it-works" @click="scrollToHowItWorks">How it works</span>
        <router-link to="/priceSteps">
          <span @click="resetOptions" id="value-my-car">Value my car</span>
        </router-link>
        <router-link to="/blog">
          <span id="blog">Blog</span>
        </router-link>
         <router-link v-if="false" to="/faq">
          <span id="blog">FQ</span>
        </router-link>
       <headerLogin  v-if="!sampleReport" :green="green" :orange="orange" :lightGr="lightGr" :close="close" />
      </div>
    </div>
    <div v-if="sampleReport" class="sample-report-wrapper">
      <div v-for="(i, index) in 20" :key="index" :style="{ 'animation-delay': '-' + (index * 2) + 's' }" class="sample-report">
        sample report
      </div>
    </div>
  </div>
</template>

<script>
import headerLogin from '@/components/loginComponents/headerLogin'
export default {
  name: 'appHeader',
  data () {
    return {
      customClass: null,
      green: false,
      orange: false,
      lightGr: false,
      close: false
    }
  },
  props: {
    sampleReport: {
      default: false
    }
  },
  components: {
    headerLogin
  },
  methods: {
    resetOptions () {
      this.$store.commit('resetValue')
      if(this.$route.path === '/priceSteps'){
        location.reload();
      }
    },
    scrollToHowItWorks () {
      if (this.$route.name !== 'Home') {
        this.$router.push({ path: '/', hash: 'scrollToHowItWorks' })
      } else {
        let el = document.querySelector('.expWrapp')
        el.scrollIntoView({behavior: "smooth"});
      }
    },
    navigateHome () {
      if (this.$route.path !== '/') {
        this.$router.push('/')
      }
    },
    navigateToBlogList () {
      this.$router.push('/blog')
    },
    navigateSteps () {
      this.$router.push('/priceSteps')
    },
    setBackgroundClass (name) {
      if (name === 'help' || name === 'about' || name === 'profile') {
        this.green = true
        this.orange = false
        this.lightGr = false
        this.customClass = 'blue'
      } else if (name === 'terms') {
        this.green = false
        this.orange = true
        this.lightGr = false
        this.customClass = 'orange'
      }else if (name === 'estimate'){
        this.green = false
        this.orange = false
        this.lightGr = true
        this.customClass= "lightGr"
      } else {
        this.green = false
        this.orange = false
        this.lightGr = false
        this.customClass = null
      }
    },
    closeControl () {
      this.close = !this.close
    }
  },
  watch: {
   '$route': {
      handler () {
         this.setBackgroundClass(this.$route.name)
      },
      deep: true,
      immediate: true
    }
  }
}
</script>

<style lang="scss" scoped>
a {
  text-decoration: none;
}
#index {
  position: relative;
}
.sample-report-wrapper {
  background: $main-color;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow-x: hidden;
  z-index: 999;
  position: sticky !important;
  align-self: flex-start;
  top: 0;


  @keyframes example {
    from {right: -200px;}
    to {right: 2760px;}
  }

  .sample-report {
    width: auto;
    // font-family: 'Gotham Pro Bold';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: 0.01em;
    // color: #E7ECED;
    color: #ffffffb3;
    cursor: default;
    position: absolute;
    animation-duration: 40s;
    animation-timing-function: linear;
    animation-name: example;
    animation-iteration-count: infinite;
    white-space: nowrap;
    animation-fill-mode: forwards;
    right: -200px;
  }
}

.header-wrapper {
  // height: 80px;
  height: 70px;
  width: 100%;
  padding: 0 30px;
  max-width: calc(1440px + 60px);
  margin: 0 auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  z-index:210;

  span {
    // font-family: $main-font;
    cursor: pointer;
    color: #4C4C52;
    margin-top: 8px;
  }
  #value-my-car {
    margin-left: 25px;
  }
  #blog {
    margin-left: 25px;
  }
  @media only screen and (max-width: 768px) {
    #how-it-works, #blog {
      display: none;
    }
  }
  .btn {
    font-size: 16px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: right;
    color: $main-color;
    height: 50px;
    box-sizing: border-box;
    border: 1px solid rgba(255, 86, 84, 0.7);
    border-radius: 25px;
    padding: 0px 30px;
    cursor: pointer;
    font-weight: 500;
    // font-family: 'Gotham Pro Medium';
    &:hover {
      border: 1.8px solid #FF5654;
    }
  }
  .logo-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    #smallLogo {
      display: none;
    }
    #bigLogo {
      display: block;
      height: 25px;
    }
  }
  @media only screen and (max-width: 450px) {
    .logo-wrapper #smallLogo {
      display: block;
    }
    .logo-wrapper #bigLogo {
      display: none;
    }
  }
}
 .background{
   position: relative;
   z-index:210 !important;
   background-color: rgb(255, 255, 255);
  //  height: 70px;
   height: fit-content;
 }
 .background.sampleReport{
   height: 110px !important;
   background-color: #fff !important;
 }
.background.blue {
  background-color: #104756;
  z-index:110;

  span {
    color: #E7ECED;
  }
}
.background.orange {
  background-color: #FF5654;
  z-index:110;

  span {
    color: white;
  }
}
.background.lightGr{
  background-color: transparent;
  // padding-top: 11px;
  box-sizing: border-box;
  // background-color: transparent;
  z-index:110;
  .header-wrapper{
  //  height: 60px;
  }
  span {
    color: white;
  }
}
@media only screen and (max-width: 1024px) {
  .lightGr {
    margin-right: 60px;
  }
}
@media only screen and (max-width: 550px) {
  .lightGr {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 450px) {
  .disappearing {
    display: none;
  }
  .header-wrapper {
    padding: 0 16px;
  }
}
</style>
